import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./campaignForm.css";
import axios from "axios";
import { jotFormURL } from "../../config/urls";
import { paintHeader, serviceError, aboutUs } from "./Constant.js";
import {
  regxEmail,
  regxUSPhone,
  regxUSZipcode,
  readCookieValue,
  multipartFormHeader,
  storeFranchiseDataToLS,
  aceBusinessName,
  extractPath,
  getFranchiseDataFromLS,
} from "../../config/utility";

const CampaignForm = ({
  contentData,
  franchiseDetails,
  franchiseDataToGetEstimate: { franchiseId, franchiseName, stateAbbr } = {},
}) => {
  const navigate = useNavigate();
  const initStateYotrackData = {
    formID: "230454322493250",
    website: "",
    simple_spc: "230454322493250-230454322493250",
    q12_typeA: "",
    q13_send_to: "",
    q14_from_page: "",
    q15_business_name: "",
    q17_territory: "not-swapped",
    q19_uid: "",
    event_id: "",
  };

  //States for all the input fields
  const [fullnameValue, setFullnameValue] = useState("");
  const [projectValue, setProjectValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState("");
  const [zipCode, setZipCode] = useState();
  const [matchLoc, setMatchLoc] = useState("");
  const [yotrackData, setYotrackData] = useState(initStateYotrackData);
  const [isPopulatedProject, setIsPopulatedProject] = useState(true);
  const [zipLenErr, setZipLenErr] = useState(false);
  const [zipcodeValue, setZipcodeValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isServiceDown, setIsServiceDown] = useState(false);
  const [serviceDownMessage, setServiceDownMessage] = useState("");
  //States for validity check of the input fields
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  //States for fields which are having error
  const [isErrorPhoneNo, setIsErrorPhoneNo] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorZipCode, setIsErrorZipCode] = useState(false);

  // CampaignId
  const { pathname, search } = useLocation();
  const queryParamObject = new URLSearchParams(search);
  const campaignID = queryParamObject.get("campaignid");

  const handymanId = process.env.REACT_APP_HANDYMANID;
  const corpClientId = process.env.REACT_APP_CORP_CLIENTID;
  const corpEmailId = process.env.REACT_APP_JOTFORM_EMAIL;

  const handleFirstNameChange = (e) => {
    setFullnameValue(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleProjectChange = (e) => {
    setProjectValue(e.target.value);

    //reading cookie's UUID and updating the yoTrack data
    const uidCookieValue = readCookieValue("uid");

    setYotrackData((prevState) => ({
      ...prevState,
      q13_send_to: pathname,
      q19_uid: uidCookieValue ? uidCookieValue : "",
    }));
  };
  var matchingZipcode = "";
  const handleZipcodeChange = (e) => {
    e.preventDefault();
    let isValidZip;
    const zipVal = e.target.value;
    setZipcodeValue(zipVal);
    isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipVal);

    if (!isValidZip || zipVal === "") {
      setZipLenErr(false);
    }

    let checkZipCode = false;
    checkZipCode = contentData?.zipCodeList[0].includes(zipVal);
    if (checkZipCode) {
      matchingZipcode = zipVal;
    }

    setZipCode(zipVal);

    if (!zipVal) setZipLenErr(false);
    else if (zipVal?.length === 5 && isValidZip) {
      //checking the LocalOffices array contains any match with user entered ZIPCODE
      const locationMatch = contentData?.localOfficeList?.filter(
        (office) =>
          office?.OwnedZipcodes?.some((zip) => zip === zipVal) ||
          office?.AllowedZipcodes?.some((zip) => zip === zipVal)
      );

      storeFranchiseDataToLS(
        "recentEstimateSubmissionPath",
        `/${locationMatch[0]?.HomePageLink?.URL}`
      );
      const zipLocationMatch =
        locationMatch && locationMatch[0]?.HomePageLink?.URL;
      zipLocationMatch && setMatchLoc(zipLocationMatch);
      const franchiseClientId = locationMatch && locationMatch[0]?.ClientId;
      //siteTrackingId available as part of localoffice api, assigning to
      //siteTrackId from the matched location
      const siteTrackId = locationMatch && locationMatch[0]?.SiteTrackingId;
      if (siteTrackId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q14_from_page: siteTrackId,
        }));
      }

      //siteId either FranchiseClientId or corpId
      let siteId;
      if (franchiseClientId) {
        siteId = franchiseClientId;
      } else siteId = corpClientId;

      if (locationMatch?.length > 0 && franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: "",
          q15_business_name: `${aceBusinessName} ${locationMatch[0]?.FranchiseName}, ${locationMatch[0]?.StateAbbreviation}`,
          q17_territory: extractPath(
            locationMatch[0]?.HomePageLink?.URL
          )?.replace("/", ""),
        }));
      } else if (locationMatch?.length === 0 && franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: "",
          q15_business_name: `${aceBusinessName} ${franchiseName}, ${stateAbbr}`,
          q17_territory: "no-location-returned",
        }));
      } else if (locationMatch?.length > 0 && !franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: corpEmailId,
          q15_business_name: `${aceBusinessName} ${locationMatch[0]?.FranchiseName}, ${locationMatch[0]?.StateAbbreviation}`,
          q17_territory: extractPath(
            locationMatch[0]?.HomePageLink?.URL
          )?.replace("/", ""),
        }));
      } else if (locationMatch?.length === 0 && !franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: corpEmailId,
          q15_business_name: aceBusinessName,
          q17_territory: "fma-loc-not-found",
        }));
      }
      setZipLenErr(false);
    } else {
      setZipLenErr(true);
    }
  };

  const handleApiCalls = (formData) => {
    axios
      .post(jotFormURL, formData, multipartFormHeader)
      .then((res) => {
        if (res?.request?.status === 200) {
          const splitUrl = res?.data && res?.data?.split(";")[0];
          const regxSingleQuote = /\'(.*)\'/i;
          const centerMarkUrl = splitUrl
            .match(regxSingleQuote)[0]
            ?.replace(/['']/g, "");

          axios
            .get(centerMarkUrl)
            .then((res) => {
              if (res?.request?.status === 200) {
                const leadCorrelationRes = res && JSON.parse(res?.data);

                //redirection to contact success page
                const recentFranchisePath = getFranchiseDataFromLS(
                  "recentFranchisePath"
                );
                const recentEstimateSubmissionPath = getFranchiseDataFromLS(
                  "recentEstimateSubmissionPath"
                );

                if (matchLoc && recentFranchisePath) {
                  if (recentFranchisePath === recentEstimateSubmissionPath) {
                    const rgxforSlashes = /^\/|\/$/g;
                    const franchisePath = recentFranchisePath?.replace(
                      rgxforSlashes,
                      ""
                    );
                    navigate(
                      `/${franchisePath}/contact_success?yodle_formThankYou=${leadCorrelationRes?.leadCorrelatorId}`
                    );
                  } else if (
                    recentFranchisePath !== recentEstimateSubmissionPath
                  ) {
                    const rgxforSlashes = /^\/|\/$/g;
                    const franchisePath = recentEstimateSubmissionPath?.replace(
                      rgxforSlashes,
                      ""
                    );
                    navigate(
                      `/${franchisePath}/contact_success?yodle_formThankYou=${leadCorrelationRes?.leadCorrelatorId}`
                    );
                  }
                } else {
                  navigate(
                    `/contact_success?yodle_formThankYou=${leadCorrelationRes?.leadCorrelatorId}`
                  );
                }
              }
            })
            .catch((err) => console.log(err));
          setTimeout(() => setIsSubmitting(false), 2000);
        } else {
          setIsSubmitting(false);
          setIsServiceDown(true);
          setServiceDownMessage({ serviceError });
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        setIsServiceDown(true);
        setServiceDownMessage({ serviceError });
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let payloadData = {};
    const isValidPhone = regxUSPhone.test(phoneNumber);
    const isValidEmail = regxEmail.test(email);
    const isValidZipcode = regxUSZipcode.test(zipCode);

    //Validating all the fields
    if (!isValidPhone || phoneNumber === "") {
      setIsErrorPhoneNo(true);
      setIsValidPhone(false);
    }
    if (!isValidEmail || email === "") {
      setIsErrorEmail(true);
      setIsValidEmail(false);
    }
    if (!isValidZipcode || zipCode === "") {
      setIsErrorZipCode(true);
    }
    if (fullnameValue === "") {
      setIsValidFirstName(false);
    }
    if (projectValue === "") {
      setIsPopulatedProject(false);
      setProjectValue({ aboutUs });
    }
    if (
      isValidPhone === true ||
      isValidEmail === true ||
      isValidZipcode === true ||
      isValidFirstName === true
    ) {
      setIsErrorZipCode(false);
      setIsErrorPhoneNo(false);
      setIsErrorEmail(false);

      const payloadData = {
        q3_fullName: fullnameValue,
        q4_phoneNumber: phoneNumber,
        q5_emailAddress: email,
        q6_zipCode: zipCode,
        q7_tellUs: projectValue,
        ...yotrackData,
      };
      const formData = new FormData();
      Object.keys(payloadData).forEach((key) =>
        formData.append(key, payloadData[key])
      );

      setIsSubmitting(true);

      handleApiCalls(formData);
    }
  };

  return (
    <div className="red-bg">
      <div className="container">
        <div className="header">
          <h3>{paintHeader}&#8482;</h3>
        </div>
        <div className="main-container">
          <form onSubmit={handleSubmit}>
            <div className="row1">
              <div className="fields">
                <label>
                  <span className="fieldNames">Full Name&#42;</span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  required
                  onChange={handleFirstNameChange}
                  value={fullnameValue}
                />
              </div>

              <div className="fields">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.PhoneNumber?.Value}
                    &#42;
                  </span>
                </label>
                <input
                  type="tel"
                  className="inputField"
                  required
                  onChange={handlePhoneChange}
                  value={phoneNumber}
                />
                {isErrorPhoneNo && (
                  <span className="error-field">Phone No is Incorrect</span>
                )}
              </div>
              <div className="fields">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.EmailAddress?.Value}
                    &#42;
                  </span>
                </label>
                <input
                  type="email"
                  className="inputField"
                  required
                  onChange={handleEmailChange}
                  value={email}
                />
                {isErrorEmail && (
                  <span className="error-field">Email is Incorrect</span>
                )}
              </div>
            </div>
            <div className="row2">
              <div className="fields">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.ZipCode?.Value}&#42;
                  </span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  pattern="[0-9]{5}"
                  required
                  onChange={handleZipcodeChange}
                  value={zipCode}
                />
                {isErrorZipCode && (
                  <span className="error-field">Zipcode is Incorrect</span>
                )}
              </div>
              <div className="fields">
                <label>
                  <span className="fieldNames">
                    {franchiseDetails[0]?.EstimateOnlyTray?.TellUsAbout?.Value}
                  </span>
                </label>
                <input
                  type="text"
                  className="aboutUs-input"
                  required
                  onChange={handleProjectChange}
                  value={projectValue}
                />
              </div>
            </div>
            <div className="submitBtn">
              <button className="btnSubmit">
                {!isSubmitting
                  ? franchiseDetails[0]?.EstimateOnlyTray?.SubmitButtonText?.Value
                    ? franchiseDetails[0]?.EstimateOnlyTray?.SubmitButtonText?.Value
                    : "Submit"
                  : "Please wait..."}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CampaignForm;
