import React from "react";
import { useLocation } from "react-router-dom";
import DynamicLinkList from "../common/DynamicLinks";
import SvgPhoneIcon from "../common/SvgPhoneIcon";
import {
  formatPhone,
  bgPatternTriangles,
} from "../../config/utility";
import { ingeniuxURL, } from "../../config/urls";
import { CLICK_TO_CALL, trackAnalytics } from "../../config/gTrack";
import "./offices.css";

const Offices = ({ ctaData, localOffices, zipcodes }) => {

  // const scheduleAppointmentLink = process.env.REACT_APP_DISPATHME;
  const { pathname } = useLocation();

  //state names have space between, creating id from state names
  const createCustomStateId = (stateName) =>
    stateName?.replace(/[\W_]/g, "-")?.toLowerCase();

  const handleScrolltoState = (e, scrollElement) => {
    e.preventDefault();
    const officesState = document.getElementById(scrollElement);
    window.scrollTo({
      top: officesState?.offsetTop - 270,
      left: 0,
      behavior: "smooth",
    });
  };

  const existingStates = () => {
    const states = new Set();
    for (var i = 0, len = localOffices.length; i < len; i++) {
      states.add(localOffices[i]?.State);
    }
    return states;
  };

  const compareStateNames = (a, b) => {
    if (a.State < b.State) {
      return -1;
    }
    if (a.State > b.State) {
      return 1;
    }
    return 0;
  };

  let combinedProps;
  combinedProps = {
    ...{
      ...ctaData?.FindAnOffice,
      ...{ zipCodeList: zipcodes },
      ...{ localOfficeList: localOffices },
    },
  };

  //GA Phone click tracker
  const handleWindowTracker = (e, gaEventName, originInfo, pageUrl) => {
    e.stopPropagation();
    trackAnalytics(gaEventName, originInfo, pageUrl);
  };

  return (
    <div
      className="body-wrap"
      style={{
        backgroundImage: bgPatternTriangles
          ? `url(${ingeniuxURL}${bgPatternTriangles})`
          : "none",
      }}
    >
      <section id="offices-banner">
        <div className="banner-slide default"></div>
      </section>

      <section id="content" className="content-section twelve">
        <div id="state-list">
          <div className="offices-container">
            <h1 className="offices-heading">
              Locations of Ace Hardware Painting Services
            </h1>
          </div>
        </div>

        <div className="container">
          {[...existingStates()].map((USAState, index) => {
            const stateId = createCustomStateId(USAState);
            return (
              <div key={index} className="country row twelve">
                <div className="offices-container">
                  <div className="state row" id={stateId}>
                    <div className="offices-header">
                      {USAState?.replace("_", " ")}
                    </div>
                    {Array.isArray(localOffices) &&
                      localOffices
                        ?.sort(compareStateNames)
                        ?.map((localOffice, index) => {
                          const phoneNumber = localOffice?.PhoneNumber;
                          return (
                            localOffice?.State === USAState && (
                              <div className="location col-sd-12 col-md-6 col-ddd-6">
                                <div className="name">
                                  {localOffice?.FranchiseName}
                                </div>
                                <div className="service-areas">
                                  {" "}
                                  Servicing{" "}
                                  {localOffice?.ServiceAreaCities?.map(
                                    (city, index) => {
                                      const limit = 3;
                                      return (
                                        <span key={index}>
                                          {index < limit && (
                                            <>
                                              {city}
                                              {index + 1 === limit ||
                                                index + 1 === 
                                                localOffice?.ServiceAreaCities
                                                  ?.length
                                                ? " "
                                                : ", "}
                                            </>
                                          )}
                                        </span>
                                      );
                                    }
                                  )}
                                  {localOffice?.ServiceAreaCities?.length >
                                    3 && (
                                      <>
                                        &amp;{" "}
                                        <DynamicLinkList
                                          pagePath={`/${localOffice?.HomePageLink?.URL}`}
                                          pageApiUrl={
                                            localOffice?.HomePageLink?.URL
                                          }
                                          pageName={"MORE"}
                                          basePageURL={"/offices"}
                                          pagePhone={phoneNumber}
                                        />
                                      </>
                                    )}
                                </div>
                                {phoneNumber?.length && (
                                  <div className="phone">
                                    <span>
                                      <SvgPhoneIcon />
                                    </span>
                                    <a
                                      href={`tel: ${formatPhone(
                                        phoneNumber
                                      )}`}
                                      onClick={(e) => {
                                        handleWindowTracker(
                                          e,
                                          CLICK_TO_CALL,
                                          "Corporate",
                                          pathname
                                        );
                                      }}
                                    >
                                      {phoneNumber &&
                                        formatPhone(phoneNumber)}
                                    </a>
                                  </div>
                                )}
                                <div className="links">
                                  {/* schedule appointment dynamically generated link */}
                                  <a href={`/#GetEstimateLink`}>
                                    Get a Free Estimate
                                  </a>
                                  <DynamicLinkList
                                    pagePath={`/${localOffice?.HomePageLink?.URL}`}
                                    pageApiUrl={localOffice?.HomePageLink?.URL}
                                    pageName={"View website"}
                                    basePageURL={"/offices"}
                                    pagePhone={phoneNumber}
                                  />
                                </div>
                              </div>
                            )
                          );
                        })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Offices;
