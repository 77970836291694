import React from 'react';
import { Markup } from 'interweave';

import { ingeniuxURL } from '../../config/urls';
import "./trayRight.css";

const TrayRight = ({rightTrayContent,heading,img,bgColor,displyGrd}) => {
    return (
    <section className="changing bg-grey twelve"
    style={{ 
        background: bgColor === "Gray"? '#d8d8da' : `${bgColor}`
      }}>
    <div className="container container-1040">
      <div className="row mb-0">
        <div className="left col-sd-12 col-dd-8">
          <h3>{heading}</h3>
          <Markup content={rightTrayContent} />
        </div>
        <div className="right col-sm-12 col-dd-4">
        {img && <img className="lazy loaded"
              data-src="images/home-maintenance.jpg"
              alt="An Ace Craftsman wearing protective gear installs a new doorknob and lock on a front door."
              loading="lazy"
              src={`${ingeniuxURL}${img}`}
              data-ll-status="loaded"
              width="420"
              height="400"
              />}
        </div>
      </div>
    </div>
  </section>
    );
};

export default TrayRight;

