import React from "react";
import { Markup } from "interweave";

import "./CopyAside.css";
import { ingeniuxURL } from "../../../config/urls";

const CopyAside = ({ contentData }) => {
    return (
        contentData ? (
            <div className="philanthropy-contents">
                <div className="body-wrap">
                    <section id="content" className="content-section twelve">
                        <div className="content-wrap">
                            <>
                                <div className="container row">
                                    {
                                        contentData?.Copy &&
                                        <div className="col-dd-8 pad-right-lg">
                                            {/* the copy text contained HTML string,
                                        need to convert them to proper html opening closing tags */}
                                            <Markup content={contentData?.Copy?.Value
                                                ?.replaceAll(/&lt;/g, "<")?.replaceAll(/&gt;/g, ">")
                                            } />
                                        </div>
                                    }

                                    {
                                        contentData?.AsideImage &&
                                        <div className="col-dd-4" style={{ display: `${contentData?.AsideImage?.Value !== undefined ? 'block' : 'none'}` }}>
                                            <figure>
                                            {contentData?.AsideImage?.Value && <img src={`${ingeniuxURL}${contentData?.AsideImage?.Value}`}
                                                    alt={contentData?.AsideImage?.AlternateText ? contentData?.AsideImage?.AlternateText : ""}
                                                    title={contentData?.AsideImage?.AlternateText ? contentData?.AsideImage?.AlternateText : ""}
                                                />}
                                                {/* <img src="https://www.acehardwarepainting.com/images/philanthropy.png" alt="Building bathrooms and donating them is one of the many ways Ace Hardware Painting Services gives back to the community." /> */}
                                            </figure>
                                        </div>
                                    }

                                </div>
                            </>
                        </div>
                    </section>
                </div>
            </div>
        ) : null
    );
};

export default CopyAside;