import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./ourWork.css";
import { ingeniuxURL } from "../../../config/urls";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import OurWorkModalWindow from "../OurWorkModal";
import gallerySpacerSquare from "../images/gallery-spacer-square.png";

const gallerySettings = {
  infiniteLoop: true,
  showIndicators: false,
  verticalSwipe: "natural",
  swipeable: false,
  showStatus: false,
};

const OurWorkFranchiseHome = ({ otherData }) => {
    const gallery = otherData?.FranchiseOwnersControlNew?.FranchisePages?.find((ele, i)=> {
        if(ele?.ComponentName === "Our Work") {
          return ele
        } 
      });
    
  const [galTab, setGalTab] = useState("Show All");
  const [showModal, setShowModal] = useState(false);
  const [initGallerySlide, setInitGallerySlide] = useState(0);
  const [currentClickId, setCurrentClickId] = useState("");

  let location = useLocation();

  useEffect(() => {
    const hashValue = location?.hash?.replace(/^#/, "");
    if (hashValue === "our-work") {
      setTimeout(() => {
        const ourWork = document.getElementById("our-work");
        window.scrollTo({
          top: ourWork?.offsetTop - 190,
          left: 0,
          behavior: "smooth",
        });
      }, 370);
    }
    if (hashValue === "why-choose-us") {
        setTimeout(() => {
            const WhyChooseUs = document.getElementById("why-choose-us")
            window.scrollTo({ top: WhyChooseUs?.offsetTop - 170, left: 0, behavior: 'smooth' });
        }, 370)
    }
  }, [location]);

  const handleImageModalToggle = (e, key) => {
    e.preventDefault();
    setInitGallerySlide(key);
    setShowModal((prevSt) => !prevSt);
  };
  const handleImageModalToggleB = (e, key) => {
    e.preventDefault();
    setInitGallerySlide(key);
    setShowModal((prevSt) => !prevSt);
  };

//   array of gallery's ImageCategories values tabs
  var superGalleryTabs = [];
  for (
    var i = 0;
    i < gallery?.GalleryImage?.length;
    i++
  ) {
    superGalleryTabs.push(
        gallery?.GalleryImage[i]
        ?.ImageCategories?.Value
    );
  }

  // if each ImageCategories value is separated by '|', then split the value into an array
  for (var i = 0; i < superGalleryTabs?.length; i++) {
    if (superGalleryTabs[i]?.includes("|")) {
      superGalleryTabs[i] = superGalleryTabs[i]?.split("|");
    }
  }

  // flatten the superGalleryTabs array and move the items into a Set to eliminate duplicates
  var galleryTabSet = new Set(["Show All"]);
  for (var i = 0; i < superGalleryTabs?.flat().length; i++) {
    galleryTabSet.add(superGalleryTabs?.flat()[i]);
  }

  const gallerySlideHanlder = (gallaryList, startIndx) => {
    let finalList;
    if (gallaryList) {
      const categoryList = gallaryList?.filter((el) =>
        el?.ImageCategories?.Value?.split("|").includes(galTab)
      );
      const currentClickedItem = categoryList
        ?.map((el, ind) => {
          //currentClickId - state value updated on click of info button
          //to find the array index position of the image, the following
          //if condition added and returns index position
          //final filter method removed the empty array elements and returns only index
          if (el?.GalleryImage.ID === currentClickId) return ind;
        })
        ?.filter((el) => el !== undefined);
      finalList = gallaryList.filter((el, ind) => {
        return ind === startIndx || ind > startIndx;
      });

      //sortedList will return from the clicked index onwards the complete list using the slice
      //method and the clicked index not the first, then the remaining items appended to the array
      const sortedList = [
        ...categoryList.slice(currentClickedItem[0]),
        ...categoryList.slice(0, currentClickedItem[0]),
      ];

      return galTab === "Show All" ? finalList : [...sortedList];
    }
  };

  return (
    <>
      {gallery?.GalleryImage && (
        <div id="our-work" className="our-work">
          <h2>Our Work</h2>
          <div className="our-work-gallery-wrap row">
            <div className="container">
              <div className="twelve">
                <div className="col-sd-12">
                  <div id="gallery-container" style={{ position: "relative" }}>
                    <div className="row twelve image-set">
                      {galTab === "Show All"
                        ? gallery?.GalleryImage?.map(
                            (galImg, key) => {
                              const limit = 6;
                              return (
                                key < limit && (
                                  <div
                                    className="image image-toggle bathroom interior col-sd-6 col-md-4"
                                    data-num="0"
                                  >
                                    <figure>
                                      <button
                                        type="button"
                                        onClick={(e) =>
                                          handleImageModalToggle(e, key)
                                        }
                                      >
                                        Info
                                      </button>
                                      {gallerySpacerSquare && (
                                        <img
                                          src={gallerySpacerSquare}
                                          alt="gallery-spacer-image"
                                          width="50"
                                          height="50"
                                        />
                                      )}
                                      {galImg?.GalleryImage?.Value && (
                                        <img
                                          className={`item ${
                                            parseInt(
                                              galImg?.GalleryImage?.Width
                                            ) /
                                              parseInt(
                                                galImg?.GalleryImage?.Height
                                              ) >=
                                            1
                                              ? "landscape"
                                              : "portrait"
                                          }`}
                                          src={`${ingeniuxURL}${galImg?.GalleryImage?.Value}`}
                                          alt={
                                            galImg?.GalleryImage?.AlternateText
                                              ? galImg?.GalleryImage
                                                  ?.AlternateText
                                              : ""
                                          }
                                          title={
                                            galImg?.GalleryImage?.AlternateText
                                              ? galImg?.GalleryImage
                                                  ?.AlternateText
                                              : ""
                                          }
                                          width="250"
                                          height="150"
                                        />
                                      )}
                                    </figure>
                                  </div>
                                )
                              );
                            }
                          )
                        : gallery?.GalleryImage?.map(
                            (galImg, key) => {
                              return (
                                galImg?.ImageCategories?.Value?.split(
                                  "|"
                                ).includes(galTab) && (
                                  <div
                                    className="image image-toggle bathroom interior col-sd-6 col-md-4"
                                    data-num="0"
                                  >
                                    <figure>
                                      <button
                                        type="button"
                                        id={galImg?.GalleryImage?.ID}
                                        onClick={(e) => {
                                          handleImageModalToggleB(e, key);
                                          setCurrentClickId(
                                            galImg?.GalleryImage?.ID
                                          );
                                        }}
                                      >
                                        Info
                                      </button>
                                      {gallerySpacerSquare && (
                                        <img
                                          src={gallerySpacerSquare}
                                          alt="gallery-spacer-image"
                                          width="50"
                                          height="50"
                                        />
                                      )}
                                      {galImg?.GalleryImage?.Value && (
                                        <img
                                          className={`item ${
                                            parseInt(
                                              galImg?.GalleryImage?.Width
                                            ) /
                                              parseInt(
                                                galImg?.GalleryImage?.Height
                                              ) >=
                                            1
                                              ? "landscape"
                                              : "portrait"
                                          }`}
                                          src={`${ingeniuxURL}${galImg?.GalleryImage?.Value}`}
                                          alt={
                                            galImg?.GalleryImage?.AlternateText
                                              ? galImg?.GalleryImage
                                                  ?.AlternateText
                                              : ""
                                          }
                                          title={
                                            galImg?.GalleryImage?.AlternateText
                                              ? galImg?.GalleryImage
                                                  ?.AlternateText
                                              : ""
                                          }
                                          width="250"
                                          height="150"
                                        />
                                      )}
                                    </figure>
                                  </div>
                                )
                              );
                            }
                          )}
                    </div>
                    {gallery?.GalleryImage
                      ?.length > 0 && (
                      <div className="viewmore">
                        <a
                          style={{
                            display: "block",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                          href={`/${
                            otherData?.FranchiseHomePage?.URL
                          }/${"our-work"}`}
                        >
                          VIEW MORE
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <OurWorkModalWindow
            show={showModal}
            handleModalToggle={(e) => handleImageModalToggle(e)}
            size={{ modalWidth: "80%", modalHeight: "80%" }}
          >
            <div id="gallery-slides" style={{ position: "relative" }}>
              <Carousel {...gallerySettings} className="gallery-view">
                {gallerySlideHanlder(
                  gallery?.GalleryImage,
                  initGallerySlide
                )?.map((galImg, index) => {
                  return (
                    <div
                      className="image twelve"
                      data-category="bathroom interior"
                      data-num="0"
                      key={index}
                    >
                      <div id="port-modal-image">
                        <figure>
                          {galImg?.GalleryImage?.Value && (
                            <img
                              className="port-image item"
                              src={`${ingeniuxURL}${galImg?.GalleryImage?.Value}`}
                              alt={
                                galImg?.GalleryImage?.AlternateText
                                  ? galImg?.GalleryImage?.AlternateText
                                  : ""
                              }
                              title={
                                galImg?.GalleryImage?.AlternateText
                                  ? galImg?.GalleryImage?.AlternateText
                                  : ""
                              }
                              width="250"
                              height="150"
                            />
                          )}
                          <div className="title">
                            {galImg?.Description?.Value}
                          </div>
                          <div className="description">
                            {galImg?.AdditionalDetails?.Value}
                          </div>
                        </figure>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </OurWorkModalWindow>
        </div>
      )}
    </>
  );
};

export default OurWorkFranchiseHome;
