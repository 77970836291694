import React from 'react';
// import { ingeniuxURL } from '../../../config/urls'
import CorpImageCopyTrayRightAlignment from './CorpImageCopyTrayRightAlignment/CorpImageCopyTrayRightAlignment';
import CorpImageCopyTrayLeftAlignment from './CorpImageCopyTrayLeftAlignment/CorpImageCopyTrayLeftAlignment';

const CorpImageCopyTray = ({ contentData = {} }) => {
    const { ImageAlignment } = contentData;
    return (
        <>
            {
                ImageAlignment?.Value === "Left" ? <CorpImageCopyTrayLeftAlignment contentData={contentData} /> :
                    <CorpImageCopyTrayRightAlignment contentData={contentData} />
            }
        </>
    );
};

export default CorpImageCopyTray;