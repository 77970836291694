import { Markup } from "interweave";
import React from "react";
import { ingeniuxURL } from "../../config/urls";
import "./CareersCommunity.css";

const CareersCommunity = ({contentData}) => {
  return (
    <section id="careers-community-content" className="content-section twelve">
      <a
        href="https://acehandymanservicescareers.careerplug.com/jobs"
        target="_blank"
        className="btn careers-applynow"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="magnifying-glass"
          className="svg-inline--fa fa-magnifying-glass"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <title>Search icon</title>
          <path
            fill="currentColor"
            d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
          ></path>
        </svg>
        Apply
        <br />
        Now
      </a>

      <div className="row careers-community">
        <div className="careers-container">
          <div className="col-sd-12 col-dd-8 pad-right-lg">
            <h3>
              {contentData?.Heading?.Value}<span>{contentData?.Subheading?.Value}</span>
            </h3>
           <Markup content={contentData?.Copy?.Value} />
            <blockquote className="lg-swoosh">
              <p className="quote">
                &ldquo;{contentData?.Testimonial?.Value}&rdquo;
              </p>
              <span>{contentData?.Attribution?.Value}</span>
            </blockquote>
          </div>
          <figure className="col-dd-4">
            {contentData?.Image?.Value && <img
              src={`${ingeniuxURL}${contentData?.Image?.Value}`}
              alt={contentData?.Image?.AlternateText ? contentData?.Image?.AlternateText : ""}
              title={contentData?.Image?.AlternateText ? contentData?.Image?.AlternateText : ""}
            />}
          </figure>
        </div>
      </div>
    </section>
  );
};

export default CareersCommunity;
