import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchHomePage } from "../../actions/fetchData";
import ScheduleItGetEstimate from "../common/ScheduleItGetEstimate";
import KitchenFSPBanner from "./KitchenFSPBanner";
import KitchenFSPBreadcrumbs from "./KitchenFSPBreadcrumbs";
import KitchenFSPDetails from "./KitchenFSPDetails";
import KitchenFSPSpecific from "./KitchenFSPSpecific";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";
import {
  getResultsArray,
  loadApiData,
  saveApiData,
} from "../../config/utility";
import withRouter from "../common/withRouter";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import { FindAnOfficeTray } from "../common/Footer";
import Page404 from "../page-404";

class KitchenFireSafetyPackagePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const packageApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;

    packageApiPath && this.props.dispatch(fetchHomePage(packageApiPath));

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.link !== nextProps.link) {
      this.props.dispatch(fetchHomePage(nextProps.link));
      window.scrollTo(0, 0);
    }
  }
  render() {
    const homeItemData = getResultsArray(this.props?.homeItem, "PackageDetail");
    const resultsData = getResultsArray(this.props?.homeItem);
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    let combinedProps;
    combinedProps = {
      ...{
        ...homeItemData[0]?.ScheduleandEstimateTabTray,
        ...{ zipCodeList: localOfficeZipcodes },
        ...{ localOfficeList: localOfficeResults },
        ...{ franchiseDataToGetEstimate },
      },
    };

    let combinedPropsFindOffice;
    combinedPropsFindOffice = {
      ...{
        ...headerItemData[0]?.Footer?.FindAnOffice,
        ...{ zipCodeList: localOfficeZipcodes },
        ...{ localOfficeList: localOffices },
      },
    };

    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;

    return (
      <>
        {homeItemData.length === 0 &&
        this.props?.homeItem?.totalResults === 0 ? (
          <Page404 />
        ) : (
          <>
            <Helmet>
              <title>
                {pageTitle ? `${pageTitle} | ${browserSuffix}` : browserSuffix}
              </title>
            </Helmet>
            <HeaderWrapper
              headerItemData={{
                ...headerItemData,
                metaDescription: homeItemData[0]?.Description?.Value,
              }}
            />

            <KitchenFSPBanner contentData={resultsData[0]} />
            <KitchenFSPBreadcrumbs contentData={resultsData[0]} />
            <KitchenFSPSpecific contentData={resultsData[0]} />
            <KitchenFSPDetails contentData={resultsData[0]} />

            <a id="book-online">
              <ScheduleItGetEstimate contentData={combinedProps} />
            </a>
            <div className="findoffice col-">
              <FindAnOfficeTray contentData={combinedPropsFindOffice} />
            </div>

            {homeItemData[0]?.Trays?.map((tray, index) => {
              if (
                Object.keys(corpDynamicTrayList)?.find(
                  (trayName) => trayName === tray?.TrayName
                )
              ) {
                let combinedProps;

                if (
                  tray?.TrayName === "ScheduleandEstimateTabTray" ||
                  tray?.TrayName === "ScheduleandEstimateTray"
                ) {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                      ...{ franchiseDataToGetEstimate },
                    },
                  };
                } else if (tray?.TrayName === "ServicesTray") {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{
                        franchiseData:
                          franchiseHomePage && franchiseHomePage[0],
                      },
                    },
                  };
                } else if (tray?.TrayName === "FindAnOffice") {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                    },
                  };
                } else {
                  combinedProps = { ...tray };
                }

                return (
                  <CorpDynamicTrays
                    trayName={tray?.TrayName}
                    contentData={combinedProps}
                    key={index}
                  />
                );
              }
            })}

            <FooterWrapper headerItemData={headerItemData} />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default withRouter(
  connect(mapStateToProps)(KitchenFireSafetyPackagePage)
);
