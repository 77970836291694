import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../../config/utility";
import "./detailpageBanner.css";

export const DetailPageBanner = (props) => {
  const {
    bannerContent,
    headingContent,
    paintStrokes,
    franchiseName = "",
    franchiseCity = "",
    franchiseState = "",
    stateAbbr = "",
  } = props;
  const [leftStroke, middleStroke, rightStroke] = paintStrokes;
  return (
    <section id="detail-banner">
      <div
        className="detail-page-banner"
        style={{
          backgroundImage: bannerContent?.Value ? `url(${ingeniuxURL}${bannerContent?.Value})` : "none"
        }}
      >
        <div className="container">
          <div className="banner-heading-container">
            <div
              className="paintstroke-left"
              style={{
                backgroundImage: leftStroke?.Value ? `url(${ingeniuxURL}${leftStroke?.Value})` : "none"
              }}
            ></div>
            <h1
              className="header h1"
              style={{
                backgroundImage: middleStroke?.Value ? `url(${ingeniuxURL}${middleStroke?.Value})` : "none"
              }}
            >
              <Markup
                content={headingContent?.Value?.replace("{City}", franchiseCity)
                  ?.replace("{State}", franchiseState)
                  ?.replace("{FranchiseName}", franchiseName)
                  ?.replace("{StateAbbreviation}", stateAbbr)
                  ?.replace(
                    "{Phone}",
                    formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
                  )}
              />
            </h1>
            <div
              className="paintstroke-right"
              style={{
                backgroundImage: rightStroke?.Value ? `url(${ingeniuxURL}${rightStroke?.Value})` : "none"
              }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};
