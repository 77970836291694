export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_ERROR = "FETCH_DATA_ERROR";

export const FETCH_HEADER_DATA_REQUEST = "FETCH_HEADER_DATA_REQUEST";
export const FETCH_HEADER_DATA_SUCCESS = "FETCH_HEADER_DATA_SUCCESS";
export const FETCH_HEADER_DATA_ERROR = "FETCH_HEADER_DATA_ERROR";

export const FETCH_BLOG_DATA_REQUEST = "FETCH_BLOG_DATA_REQUEST";
export const FETCH_BLOG_DATA_SUCCESS = "FETCH_BLOG_DATA_SUCCESS";
export const FETCH_BLOG_DATA_ERROR = "FETCH_BLOG_DATA_ERROR";

export const FETCH_BLOG_PAGE_REQUEST = "FETCH_BLOG_PAGE_REQUEST";
export const FETCH_BLOG_PAGE_SUCCESS = "FETCH_BLOG_PAGE_SUCCESS";
export const FETCH_BLOG_PAGE_ERROR = "FETCH_BLOG_PAGE_ERROR";

export const FETCH_PAGE_404_REQUEST = "FETCH_PAGE_404_REQUEST";
export const FETCH_PAGE_404_SUCCESS = "FETCH_PAGE_404_SUCCESS";
export const FETCH_PAGE_404_ERROR = "FETCH_PAGE_404_ERROR";

export const FETCH_FRANCHISE_HOME_REQUEST = "FETCH_FRANCHISE_HOME_REQUEST";
export const FETCH_FRANCHISE_HOME_SUCCESS = "FETCH_FRANCHISE_HOME_SUCCESS";
export const FETCH_FRANCHISE_HOME_ERROR = "FETCH_FRANCHISE_HOME_ERROR";

export const FETCH_FRANCHISE_DETAILPAGE_REQUEST =
  "FETCH_FRANCHISE_DETAILPAGE_REQUEST";
export const FETCH_FRANCHISE_DETAILPAGE_SUCCESS =
  "FETCH_FRANCHISE_DETAILPAGE_SUCCESS";
export const FETCH_FRANCHISE_DETAILPAGE_ERROR =
  "FETCH_FRANCHISE_DETAILPAGE_ERROR";

export const FETCH_LOCAL_OFFICE_REQUEST = "FETCH_LOCAL_OFFICE_REQUEST";
export const FETCH_LOCAL_OFFICE_SUCCESS = "FETCH_LOCAL_OFFICE_SUCCESS";
export const FETCH_LOCAL_OFFICE_ERROR = "FETCH_LOCAL_OFFICE_ERROR";

export const FETCH_INDIVIDUAL_BLOG_REQUEST = "FETCH_INDIVIDUAL_BLOG_REQUEST";
export const FETCH_INDIVIDUAL_BLOG_SUCCESS = "FETCH_INDIVIDUAL_BLOG_SUCCESS";
export const FETCH_INDIVIDUAL_BLOG_ERROR = "FETCH_INDIVIDUAL_BLOG_ERROR";

export const FETCH_LAST_VISTED_FRANCHISE_REQUEST = "FETCH_LAST_VISTED_FRANCHISE_REQUEST"
export const FETCH_LAST_VISTED_FRANCHISE_SUCCESS = "FETCH_LAST_VISTED_FRANCHISE_SUCCESS"
export const FETCH_LAST_VISTED_FRANCHISE_ERROR = "FETCH_LAST_VISTED_FRANCHISE_ERROR"

export const START_HANDLE_ERRORBOUNDARY = "START_HANDLE_ERRORBOUNDARY"
export const END_HANDLE_ERRORBOUNDARY = "END_HANDLE_ERRORBOUNDARY"
export const HANDLE_ERRORBOUNDARY = "HANDLE_ERRORBOUNDARY"


//Franhcise Individual Requests

export const FETCH_INDIVIDUAL_FRANCHISE_BLOG_REQUEST = "FETCH_INDIVIDUAL_FRANCHISE_BLOG_REQUEST"
export const FETCH_INDIVIDUAL_FRANCHISE_BLOG_SUCCESS = "FETCH_INDIVIDUAL_FRANCHISE_BLOG_SUCCESS"
export const FETCH_INDIVIDUAL_FRANCHISE_BLOG_ERROR = "FETCH_INDIVIDUAL_FRANCHISE_BLOG_ERROR"


export const FETCH_FRANCHISE_HOME_BLOG_SUCCESS = "FETCH_FRANCHISE_HOME_BLOG_SUCCESS"
export const FETCH_FRANCHISE_HOME_BLOG_REQUEST = "FETCH_FRANCHISE_HOME_BLOG_REQUEST"
export const FETCH_FRANCHISE_HOME_BLOG_ERROR = "FETCH_FRANCHISE_HOME_BLOG_ERROR"
