import React, { Component } from "react";
import { connect } from "react-redux";
import Page503 from './Page503';
import PageLoader from '../PageLoader';
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false,loading:true };
    }
    componentDidUpdate(){
        if(this?.props?.error?.response?.status === 503 && this.state.hasError===false){
          this.setState({ hasError: true,loading:false });  
        }
      }
    componentDidCatch(error, info) {
           // Display fallback UI   
            this.setState({ hasError: true });    
        }
    render() {
     
      
      if (this.state.hasError) {      
        // You can render any custom fallback UI     
         return <Page503 />;
        }    
        if(this?.props?.error_loading === true){
          //  return <PageLoader />;
          }
         return this.props.children;
    }
  }
  const mapStateToProps = (state) => ({
    error_loading: state.errorBoundaryReducer.error_loading,
    error: state.errorBoundaryReducer.error,
  });
  
  export default connect(mapStateToProps)(ErrorBoundary);