import React from "react";
import { Markup } from "interweave";
import { ingeniuxURL } from "../../../../config/urls";
import "./CorpImageCopyTrayLeftAlignment.css";
import { HashLink as Link } from "react-router-hash-link";

// leftTrayContent means:
// heading means:
// img means:
// bgColor means:
// displyGrd means:
// CTA means:

// CTA={(tray?.Page)}
// displyGrd={tray?.DisplayGrid?.Value}
// bgColor={tray?.BackgroundColor?.Value}
// img={tray?.Image?.Value}
// heading={tray?.Heading?.Value}
// leftTrayContent={(tray?.Copy?.Value)}
// const ingeniuxURL = "https://services.ingeniux.com/acehandymandss/";
const viewPortWidth = window.screen.width;

const CorpImageCopyTrayLeftAlignment = ({ contentData }) => {
  const isVideo = false;
  const vimeoUrl = "https://player.vimeo.com/video/548521485?h=23cb3572ae";
  const youtubeUrl = "";
  return (
    <section
      className="remodel bg-grey twelve"
      style={{
        // changed gray color from #d8d8da to #e9e9e9
        background:
          contentData?.BackgroundColor?.Value === "Gray"
            ? "#e9e9e9"
            : contentData?.BackgroundColor?.Value === "White"
            ? "#fff"
            : contentData?.BackgroundColor?.Value === "Gray_Gradient"
            ? "linear-gradient(to bottom,#fff 0,#d9d9d9 100%)"
            : "none",
      }}
    >
      <div className="container container-1040">
        <div className="row mb-0">
          <div
            className="left col-sm-12 col-dd-4"
            style={{
              boxShadow: `${
                contentData?.AddShadow?.Value === "True"
                  ? "0 4px 10px rgb(0 0 0 / 50%)"
                  : "none"
              }`,
              width: `${
                viewPortWidth >= 800 &&
                contentData?.DisplayGrid?.Value === "50-50" &&
                "50%"
              }`,
            }}
          >
            {isVideo ? (
              <iframe
                className="image-copy-Tray"
                src={vimeoUrl}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              contentData?.Image?.Value && <img
                className="lazy loaded"
                data-src="images/home-remodel.jpg"
                alt={contentData?.Image?.AlternateText ? contentData?.Image?.AlternateText : ""}
                title={contentData?.Image?.AlternateText ? contentData?.Image?.AlternateText : ""}
                loading="lazy" src={`${ingeniuxURL}${contentData?.Image?.Value}`} data-ll-status="loaded" 
                width="600"
                height="500"
                />
            )}
          </div>
          <div
            className="right col-sd-12 col-dd-8"
            style={{
              marginLeft: `${
                viewPortWidth >= 800 &&
                contentData?.DisplayGrid?.Value === "50-50" &&
                "50%"
              }`,
            }}
          >
            {contentData?.Heading?.Value &&
            <h3> {contentData?.Heading?.Value}</h3>}
            {/* added the replace code for the why choose us graphic image to display */}
            {/* changed .replace("Corporate%20Site", `${ingeniuxURL}Corporate%20Site`)
              to .replace("Images", `${ingeniuxURL}Images`) for image to display
              */}
            <Markup
              content={contentData?.Copy?.Value?.replace(
                "Images",
                `${ingeniuxURL}Images`
              )}
            />
            {contentData?.Page && (
              <a className="btn" target="_blank" href={contentData?.Page?.URL}>
                {contentData?.Page?.Link}
              </a>
            )}
            {/* {contentData?.CTA && contentData?.DisplayGrid?.Value === "50-50" && (
              <Link
                to={`/#GetEstimateLink`}
                data-tab="get-a-quote"
                className="btn fifty-fifty-schedule-cta"
              >
                <span>SCHEDULE ESTIMATE</span>
              </Link>
            )} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CorpImageCopyTrayLeftAlignment;
