import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../config/utility";
import "./index.css";
import { find } from "domutils";
import DynamicTrays, { dynamicTrayList } from "../common/DynamicTrays";

export const FranchiseNewsAndEvents = (props) => {
    const { contentData = {} } = props;
    const { dataforReplaceMethod = {} } = contentData;
    const { franchiseName = "", franchiseCity = "", franchiseState = "", stateAbbr = "" } = dataforReplaceMethod
    return (
        <div className="content-wrap row">
            <div className="newsAndEvents-container">
                <div className="content-full col-dd-10">
                    <p><strong><span style={{ textDecoration: "underline" }}> </span></strong></p>
                    {
                        Array?.isArray(contentData?.BodyCopyTray) ?
                            contentData?.BodyCopyTray?.map((tray, index) => {
                                return (
                                    <div key={index} style={{ border: `${tray?.AddOutline?.Value === "Yes" ? '1px solid #ccc' : 'none'}`, marginBottom: '20px', padding: '20px' }}>
                                        <h1 style={{
                                            textAlign: `${tray?.HeadingAlignment?.Value === 'Left' ? 'left' :
                                                tray?.HeadingAlignment?.Value === 'Center' ? 'center' : 'right'}`
                                        }}>
                                            <Markup content={tray?.Heading?.Value
                                                ?.replaceAll("{City}", franchiseCity)
                                                ?.replace("{Phone}", formatPhone(getFranchiseDataFromLS("recentFranchisPhone")))
                                                ?.replace("{FranchiseName}", franchiseName)
                                                ?.replace("{State}", franchiseState)
                                                ?.replace("{StateAbbreviation}", stateAbbr)
                                            } />
                                        </h1>
                                        <Markup content={tray?.Copy?.Value
                                            ?.replaceAll("{City}", franchiseCity)
                                            ?.replace("{Phone}", formatPhone(getFranchiseDataFromLS("recentFranchisPhone")))
                                            ?.replace("{FranchiseName}", franchiseName)
                                            ?.replace("{State}", franchiseState)
                                            ?.replace("{StateAbbreviation}", stateAbbr)
                                            ?.replaceAll("Images/Import%20Images/", `${ingeniuxURL}Images/Import%20Images/`)
                                            ?.replaceAll("•", `<br> •`)
                                            ?.replaceAll(`<br> <br> •`, `<br> •`)
                                            ?.replaceAll("<a", `<a target="_blank"`)
                                        } />
                                        {
                                            tray?.Page?.Name === "CTA"
                                            &&
                                            <div style={{ textAlign: 'center' }}><a className="bodycopytray-cta" target="_blank"
                                                href={tray?.Page?.URL}>
                                                {tray?.Page?.Link}
                                            </a></div>
                                        }
                                    </div>
                                )
                            })
                            :
                            <div style={{ border: `${contentData?.BodyCopyTray?.AddOutline?.Value === "Yes" ? '1px solid #000' : 'none'}`, padding: '5px' }}>
                                <h1 style={{
                                    textAlign: `${contentData?.BodyCopyTray?.HeadingAlignment?.Value === 'Left' ? 'left' :
                                        contentData?.BodyCopyTray?.HeadingAlignment?.Value === 'Center' ? 'center' : 'right'}`
                                }}>
                                    <Markup content={contentData?.BodyCopyTray?.Heading?.Value
                                        ?.replaceAll("{City}", franchiseCity)
                                        ?.replace("{Phone}", formatPhone(getFranchiseDataFromLS("recentFranchisPhone")))
                                        ?.replace("{FranchiseName}", franchiseName)
                                        ?.replace("{State}", franchiseState)
                                        ?.replace("{StateAbbreviation}", stateAbbr)
                                    } />
                                </h1>
                                <Markup content={contentData?.BodyCopyTray?.Copy?.Value
                                    ?.replaceAll("{City}", franchiseCity)
                                    ?.replace("{Phone}", formatPhone(getFranchiseDataFromLS("recentFranchisPhone")))
                                    ?.replace("{FranchiseName}", franchiseName)
                                    ?.replace("{State}", franchiseState)
                                    ?.replace("{StateAbbreviation}", stateAbbr)
                                    ?.replaceAll("Images/Import%20Images/", `${ingeniuxURL}Images/Import%20Images/`)
                                    ?.replaceAll("•", `<br> •`)
                                    ?.replaceAll(`<br> <br> •`, `<br> •`)
                                    ?.replaceAll("<a", `<a target="_blank"`)
                                } />
                                {
                                    contentData?.BodyCopyTray?.Page?.Name === "CTA"
                                    &&
                                    <div style={{ textAlign: 'center' }}><a className="bodycopytray-cta" target="_blank"
                                        href={contentData?.BodyCopyTray?.Page?.URL}>
                                        {contentData?.BodyCopyTray?.Page?.Link}
                                    </a></div>
                                }
                            </div>
                    }
                    {/* <Markup content={props?.contentData?.NewsEvents?.BodyCopyTray?.Copy?.Value} /> */}
                    <p>&nbsp;</p>
                </div>
            </div>
            {/* {Array.isArray(props?.contentData?.otherDetailPageTrays) && props?.contentData?.otherDetailPageTrays?.map((tray, index) => {
                if (Object.keys(dynamicTrayList)?.find(trayName => trayName === tray?.TrayName)) {
                    return (
                        <DynamicTrays
                            trayName={tray?.TrayName}
                            contentData={tray}
                            key={index}
                        />
                    )
                }
            })} */}
        </div>
    )
}

export default FranchiseNewsAndEvents;
