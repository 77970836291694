import { Markup } from "interweave";
import React from "react";

import { ingeniuxURL } from "../../config/urls";
import GetEstimateJotFrom from "../JotForm";
import "./getEstimate.css";

const GetEstimate = ({
  estimateData,
  hashId,
  franchiseDataToGetEstimate = {},
  handymanmatters,
  currentTab,
}) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     const name = document.getElementById(hashId)
  //     if (window.screen.width > 500) {
  //       window.scrollTo({ top: name?.offsetTop - 215, left: 0, behavior: 'smooth' });
  //     } else {
  //       window.scrollTo({ top: name?.offsetTop - 272, left: 0, behavior: 'smooth' });
  //     }
  //   }, 500)

  // }, [])

  // commented this because it was affecting landing/scrolling to top of page
  //   useEffect(() => {
  //     setTimeout(() => {
  //         const name = document.getElementById(hashId);
  //         if(window.location.pathname.includes("offices")){
  //           if(window.screen.width > 500){
  //             window.scrollTo({top:name?.offsetTop-215, left:0, behavior:'smooth'});
  //         } else {
  //             window.scrollTo({top:name?.offsetTop-272, left:0, behavior:'smooth'});
  //         }
  //         }else if(!window.location.pathname.includes("offices")){
  //           if(window.screen.width > 500){
  //             window.scrollTo({top:name?.offsetTop-265, left:0, behavior:'smooth'});
  //         } else {
  //             window.scrollTo({top:name?.offsetTop-272, left:0, behavior:'smooth'});
  //         }
  //         }

  //     }, 500)
  // }, [])

  // useEffect(() => {
  //   setTimeout(() => {

  //     const name = document.getElementById(hashId)

  //     window.scrollTo({ top: name?.offsetTop - 215, left: 0, behavior: 'smooth' });
  //   }, 1000)
  // }, [])

  // commented this because it was affecting landing/scrolling to top of page
  //   useEffect(() => {
  //     setTimeout(() => {
  //         const name = document.getElementById(hashId);
  //         if(window.location.pathname.includes("offices")){
  //           if(window.screen.width > 600){
  //             window.scrollTo({top:name?.offsetTop-268, left:0, behavior:'smooth'});
  //         } else {
  //             window.scrollTo({top:name?.offsetTop-272, left:0, behavior:'smooth'});
  //         }
  //         }else if(!window.location.pathname.includes("offices")){
  //           if(window.screen.width > 600){
  //             window.scrollTo({top:name?.offsetTop-265, left:0, behavior:'smooth'});
  //         } else {
  //             window.scrollTo({top:name?.offsetTop-272, left:0, behavior:'smooth'});
  //         }
  //         }

  //     }, 200)
  // }, [])

  const viewPortWidth = window.screen.width;

  return (
    // <div className="tabbed-top-wrap row container">
    //   <div className="tabbed-content-container row twelve">
    <div id={viewPortWidth > 600 ? hashId : ""} className="tab-content-wrap">
      <div className="get-estimate-container">
        <div id="get-a-quote" className="tab-content row current">
          {/* jotform */}
          <GetEstimateJotFrom
            contentData={estimateData}
            franchiseDataToGetEstimate={franchiseDataToGetEstimate}
            handymanmatters={handymanmatters}
            currentTab={currentTab}
          />

          <figure className="col-dd-6 splash-image">
          {estimateData?.EstimateImage?.Value && <img className="lazy loaded"
              data-src={`${ingeniuxURL}${estimateData?.EstimateImage?.Value}`}
              alt={estimateData?.EstimateImage?.AlternateText ? estimateData?.EstimateImage?.AlternateText : ""}
              title={estimateData?.EstimateImage?.AlternateText ? estimateData?.EstimateImage?.AlternateText : ""}
              width={estimateData?.EstimateImage?.Width}
              height={estimateData?.EstimateImage?.Height} loading="lazy"
              src={`${ingeniuxURL}${estimateData?.EstimateImage?.Value}`}
              data-ll-status="loaded" />}
            <figcaption className="estimate-figure-caption">
              {" "}
              {/* <strong>INTERIOR AND EXTERIOR PAINTING PROFESSIONALS</strong> */}
              <br />
              <br />
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
    // </div>
    // </div>
  );
};

export default GetEstimate;
