import { Markup } from 'interweave';
import React from 'react';
import { ingeniuxURL } from '../../config/urls';
import "./careersHeroWithOverlay.css";

const CareersHeroWithOverlayTray = ({ BackgroundImage, Heading, Subheading = "", CopyText = "", PageUrl, PageLink, TextBoxBackgroundColor, TextBoxAlignment }) => {

    return (
        <section id="join-banner">
            <div className="careers" style={{
                background: `#efeff0 url(${ingeniuxURL}${BackgroundImage}) no-repeat top center`
            }}>
                <div className="careers-banner-text">
                    <div className="banner-careers-wrap">
                        <div className="careers-bg-wrap" style={{
                            backgroundColor: `${TextBoxBackgroundColor === "Dark" ? 'rgba(0, 0, 0, 0.8)' : TextBoxBackgroundColor === "Light" ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)'}`,
                            float: `${TextBoxAlignment === "Left" ? 'left' : TextBoxAlignment === "Right" ? 'right' : 'none'}`
                        }}>
                            <div className="header">
                                <h1 className="headline">
                                    <Markup content={Heading} />
                                </h1>
                            </div>
                            <h2>
                                <Markup content={Subheading} />
                            </h2>

                            <p className="copy-text">
                                <Markup content={CopyText} />
                            </p>
                            <a className="btn careers-btn" target="_blank" href={PageUrl}>
                                {PageLink}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CareersHeroWithOverlayTray;