import axios from "axios";
import {
  handleErrorBoundary,
  fetchFranchiseHomeBlogRequest,
  fetchFranchiseHomeBlogSuccess,
  fetchFranchiseHomeBlogError,
} from "./action";
import { ingeniuxURL } from "../config/urls";
export function fetchFranchiseHomeBlog(pageLink) {
  return (dispatch) => {
    dispatch(fetchFranchiseHomeBlogRequest());
    axios
      .get(`${ingeniuxURL}api/page?path=${pageLink}`)
      .then((response) => {
        dispatch(fetchFranchiseHomeBlogSuccess(response.data));
      })
      .catch((error) => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchFranchiseHomeBlogError(error));
      });
  };
}
