import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

const DynamicLinkList = ({ pageApiUrl = "", isExternal = false, pageName = "", pagePath = "", basePageURL = "", pagePhone = "" }) => {
    const isFranchiseLocalServices = pagePath?.includes('offices')&&pagePath?.includes('services');
    let stateValue = isFranchiseLocalServices ? "": { pagePath, apiPath: pageApiUrl, basePageURL, pagePhone };
    return (
        isExternal ?
            <a href={pagePath} target="_blank" rel="noopener noreferrer">{pageName}</a>
            :
            <Link
                to={pagePath}
                state={stateValue}>
                {pageName}
            </Link>

    )
}

export default DynamicLinkList;