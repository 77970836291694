import React, { Component } from "react";

import Footers from "../common/Footer";
import { loadApiData } from "../../config/utility";

class FooterWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalSiteCrl: []
        }
    };
    componentDidMount() {
        //Data passed from Parent component if lost and coming as undefined,
        //setting the value in local state and reading from LocalStorage
        const headerItemData = this.props.headerItemData;
        if (headerItemData === undefined || headerItemData?.length === 0) {
            this.setState({ globalSiteCrl: loadApiData('x31') })
        }
    }
    render() {
        //if 'headerItemData' present, using as it is, otherwise taking the data from the local state
        const headerItemData = this.props.headerItemData ? this.props.headerItemData : this.state.globalSiteCrl;
        return (
            <>
                <Footers footerContent={headerItemData && headerItemData[0]?.Footer}
                    phoneIcon={headerItemData && headerItemData[0]?.TelephoneIcon}
                    phoneNum={headerItemData && headerItemData[0]?.TelephoneNumberDisplay}
                    tel={headerItemData && headerItemData[0]?.MainTelephoneNumber}
                />
            </>
        )
    }
}

export default FooterWrapper;