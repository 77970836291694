import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./KitchenFSPSpecific.css";

const KitchenFSPSpecific = ({ contentData }) => {
  return (
    <div className="kitchenfspspecific">
      <div className="kitchenfspspecific-body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
        <section id="content" className="content-section twelve">
          <div className="package-specific twelve">
            <div className="container row">
              <div className="content-left col-sd-12 col-dd-7">
                <Markup content={contentData?.PackageDetail?.IntroCopy?.Value} />
                <div className="time-estimate">
                  <h4>
                    {contentData?.PackageDetail?.PackageTimeEstimateLabel?.Value}:
                    <span className="clock">
                      <i className="fa-solid fa-clock"></i>{" "}
                      {contentData?.PackageDetail?.PackageTimeEstimate?.Value}
                    </span>
                  </h4>
                  {/* <a href={`#${contentData?.PackageDetail?.ScheduleandEstimateTabTray?.Name}`} data-tab="book-online" className="btn"> */}
                  {/* <a href="#book-online" data-tab="book-online" className="btn"> */}
                  <a href={contentData?.PackageDetail?.Page?.URL} data-tab="book-online" className="btn" target="_blank">
                    {contentData?.PackageDetail?.Page?.Link}
                  </a>
                  <Markup
                    content={
                      contentData?.PackageDetail?.ContactLocalOfficeCopy?.Value
                    }
                  />
                </div>
              </div>
              <div className="content-right col-sd-12 col-dd-5 pad-left-lg">
                <div className="single-border">
                  <ul>
                    {
                      Array?.isArray(contentData?.PackageDetail?.IconAndHighlight) ?
                        contentData?.PackageDetail?.IconAndHighlight?.map((item) => {
                          return (
                            <li>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <i
                                  className={`${item?.FontAwesomeClass?.Value}`}
                                ></i>
                                <Markup
                                  content={
                                    item?.HighlightText?.Value
                                  }
                                />
                              </div>
                            </li>
                          );
                        })
                        :
                        <li>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <i
                              className={`${contentData?.PackageDetail?.IconAndHighlight?.FontAwesomeClass?.Value}`}
                            ></i>
                            <Markup
                              content={
                                contentData?.PackageDetail?.IconAndHighlight?.HighlightText?.Value
                              }
                            />
                          </div>
                        </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default KitchenFSPSpecific;
