import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import CampaignForm from "./CampaignForm";
import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";
import { fetchFranchiseDetailPage } from "../../actions/fetchFranchiseDetailPage";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import HeaderFranchise from "../FranchiseCommon/Header/HeaderFranchise";
import FootersFranchise from "../FranchiseCommon/Footer/FooterFranchise";
import DetailPageBanner from "../FranchiseCommon/DetailPageBanner";
import BreadCrumb from "../FranchiseCommon/BreadCrumb";
import DynamicTrays, {
  dynamicTrayList,
  combineCommonTrayProps,
} from "../common/DynamicTrays";
import {
  getResultsArray,
  findAndGetObjData,
  getFranchiseDataFromLS,
  storeFranchiseDataToLS,
  formatPhone,
  loadApiData,
  saveApiData,
  deleteItemFromSS,
  setNoIndexTag,
  getScheduleEstimateTabTray,
} from "../../config/utility";
import withRouter from "../common/withRouter";
import { dynamicComponentTree } from "../common/DynamicComponents";
import "./index.css";
import Page404 from "../page-404";
import { CampaignPageBanner } from "../FranchiseCommon/CampaignDetailPageBanner/CampaignPageBanner";
import Footer from "./Footer";

class FranchiseDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      franchiseHomeData: loadApiData("franchiseHomeData"),
    };
  }
  //this fn to invoke on page load and component update
  initPageApi(apiPath) {
    this.props.dispatch(fetchFranchiseDetailPage(apiPath?.toLowerCase()));
  }

  //extarct the base URL of the page from page path when there is an undefined error
  extractBaseURL(apiPath = "") {
    const split = apiPath?.replace(/\/+$/, "")?.split("/");
    const baseURL = split.slice(0, 3).join("/");
    return baseURL;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const franchiseDetailPageApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const franchiseBaseUrl = this.props?.apiRef?.basePageURL
      ? this.props?.apiRef?.basePageURL
      : this?.extractBaseURL(this.props?.apiRef);
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const corpHomeItem = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;

    //To eliminate the hash url, splitting the path.
    const rgxforSlashes = /^\/|\/$/g;
    const franchiseAbsPath = franchiseBaseUrl?.split("/#");
    storeFranchiseDataToLS("recentFranchisePath", franchiseAbsPath[0]);
    const localStateSURL =
      this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;
    const localStorageSURL = getFranchiseDataFromLS("recentFranchisePath");

    this.initPageApi(franchiseDetailPageApiPath);

    //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
    //then load the franchisehomepage api again.

    //scenario 2 - in else block if the cached data and the visited franchise SURLS
    //are no the same, removed the cached data from the Session storage and
    //fetch the api again.
    if (
      (this.state.franchiseHomeData?.length === 0 ||
        this.state.franchiseHomeData === undefined) &&
      franchiseHomePage?.length === 0
    ) {
      this.props.dispatch(fetchFranchiseHome(franchiseBaseUrl?.toLowerCase()));
    } else if (
      this.state.franchiseHomeData &&
      localStateSURL?.replace(rgxforSlashes, "") !==
        localStorageSURL?.replace(rgxforSlashes, "")
    ) {
      deleteItemFromSS("franchiseHomeData");
      this.props.dispatch(fetchFranchiseHome(franchiseBaseUrl?.toLowerCase()));
    }

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      corpHomeItem?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }

  componentDidUpdate(prevProps) {
    //window.scrollTo(0, 0);
    if (
      prevProps?.apiRef?.apiPath !== this.props?.apiRef?.apiPath ||
      prevProps?.apiRef !== this.props?.apiRef
    ) {
      const franchiseDetailPageApiPath = this.props?.apiRef?.apiPath
        ? this.props?.apiRef?.apiPath
        : this.props?.apiRef;
      this.initPageApi(franchiseDetailPageApiPath);
    }

    window.scrollTo(0, 0);
  }

  render() {
    const isCampaignPage = window.location.pathname.includes("campaign");
    const franchiseHomeData = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const franchiseDetailPage = getResultsArray(
      this.props?.detailPage,
      "FranchiseDetailPage"
    );
    const corpHomeData = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let franchiseHomePage = loadApiData("franchiseHomeData");
    let corpHomeItem = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        apiFranchisePath?.replace(rgxforSlashes, "")
      ) {
        saveApiData("franchiseHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseHomeData");
      }
    }

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (corpHomeItem === undefined || corpHomeItem?.length === 0) &&
      corpHomeData
    ) {
      saveApiData("x31", corpHomeData);
      corpHomeItem = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const franchiseCity = franchiseCorpCtrl?.City?.Value;
    const franchiseState = franchiseCorpCtrl?.State?.Value;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };
    const dynamicCMSFields = {
      franchiseName,
      franchiseCity,
      franchiseState,
      stateAbbr,
    };

    const franchiseBaseUrl = this.props?.apiRef?.basePageURL
      ? this.props?.apiRef?.basePageURL
      : this?.extractBaseURL(this.props?.apiRef);
    const franchiseOwnersControl =
      franchiseHomePage &&
      franchiseHomePage[0]?.FranchiseCorporateControl
        ?.FranchiseOwnersControlNew;
    const currentDetailPage =
      franchiseDetailPage &&
      franchiseDetailPage[0]?.AddOwnerManagedContent?.Value;

    //currentDetailPage - getting from AddOwnerManagedContent
    //any miscellaneous page will not be part/available in AddOwnerManagedContent
    //in such cases taking the data  from franchiseDetailPage
    //and there is no FranchiseCorporateControl or FranchiseOwnersControl for such pages
    //SeasonalServices is a generic page and can accomodate any avaialbe trays
    const currentPageSchema =
      currentDetailPage && `${currentDetailPage}OwnersContent`;
    const data = findAndGetObjData(
      franchiseOwnersControl?.FranchisePages,
      currentPageSchema
    );

    // previous logic when FranchiseCorporateControl was part of FranchiseDetailPage api
    const detailPageComponentData =
      currentDetailPage && (data && Object.keys(data))?.length > 0
        ? data
        : currentDetailPage && (data && Object.keys(data))?.length === 0
        ? franchiseHomePage && franchiseHomePage[0]
        : null;

    const componentDetails =
      detailPageComponentData &&
      Object.keys(dynamicComponentTree)?.filter((el) => {
        let componentName = detailPageComponentData?.Schema?.includes(el);
        return componentName
          ? componentName
          : !!currentDetailPage
          ? el === currentDetailPage
          : el === "SeasonalServices";
      });
    //Dynamic component rendering logic
    const DynamicComponent = (props) => {
      const SelectComponent = dynamicComponentTree[props?.componentName];
      return <SelectComponent contentData={props?.contentData} />;
    };

    //combining the props in the case of Reviews, Whychooseus, offers etc.
    //for Review - as the data coming from s3.amazon and
    //merging with any default components data coming from IGX api
    const combineProps = () => {
      if (!componentDetails) {
        return null;
      }
      if (componentDetails) {
        const otherDetailPageTrays = franchiseDetailPage[0]?.Trays;
        if (componentDetails[0] === "Reviews") {
          const rateaBizUId =
            franchiseHomePage &&
            franchiseHomePage[0]?.FranchiseCorporateControl?.RateABizUUID;
          const addOwnerManagedContent =
            franchiseDetailPage[0]?.AddOwnerManagedContent;
          return {
            ...{
              ...detailPageComponentData,
              ...{ AddOwnerManagedContent: addOwnerManagedContent },
              ...{ rateaBiz: rateaBizUId },
              ...{ reviewsTrays: otherDetailPageTrays },
              ...{ dataforReplaceMethod: dynamicCMSFields },
            },
          };
        } else if (componentDetails[0] === "Offers") {
          const corpControl =
            franchiseHomePage &&
            franchiseHomePage[0]?.FranchiseCorporateControl;
          return {
            ...{
              ...detailPageComponentData,
              ...{ corp: corpControl },
              ...{ dataforReplaceMethod: dynamicCMSFields },
            },
          };
        }

        return {
          ...detailPageComponentData,
          ...{ dataforReplaceMethod: dynamicCMSFields },
        };
      }
    };

    const breadcrumbsStructuredData =
      franchiseCorpCtrl?.BreadcrumbsStructuredData?.Value;
    const localBusinessStructuredData =
      franchiseCorpCtrl?.LocalBusinessStructuredData?.Value;

    const franchiseHeadMetaTag = franchiseCorpCtrl?.HeadScripts?.MetaData;
    const franchiseHeadScriptd = franchiseCorpCtrl?.HeadScripts?.Script;
    const franchiseFooterScript = franchiseCorpCtrl?.FooterScripts?.Script;
    const franchiseFooterNoScript =
      franchiseCorpCtrl?.FooterScripts?.NoScriptTag;
      
    const estimateFormZipData = {
      zipCodeList: localOfficeZipcodes,
      localOfficeList: localOfficeResults,
      toSchedulingPage: true,
    };

    const estimateFormData = getScheduleEstimateTabTray(franchiseHomePage);
    const estimateTrayData = {
      ...{ estimateFormZipData },
      ...{ estimateFormData },
      ...{ franchiseDataToGetEstimate },
    };

    return (
      <>
        {franchiseDetailPage.length === 0 &&
        this.props?.detailPage?.totalResults === 0 ? (
          <Page404 />
        ) : (
          <>
            <Helmet>
              <title>
                {franchiseDetailPage[0]?.Title?.Value?.replace(
                  "{City}",
                  franchiseCity
                )?.replace("{StateAbbreviation}", stateAbbr)}
              </title>

              {/* Adding BreadcrumbsStructuredData to Head of the page */}
              {breadcrumbsStructuredData && (
                <script type="application/ld+json">
                  {breadcrumbsStructuredData
                    ?.replace("context", "@context")
                    ?.replaceAll("type", "@type")}
                </script>
              )}
              {/* Adding LocalBusinessStructuredData to Head of the page */}
              {localBusinessStructuredData && (
                <script type="application/ld+json">
                  {localBusinessStructuredData
                    ?.replace("context", "@context")
                    ?.replaceAll("type", "@type")}
                </script>
              )}
              {setNoIndexTag() && <meta name="robots" content="noindex" />}
            </Helmet>
            {!isCampaignPage && (
              <HeaderFranchise
                metaData={corpHomeItem[0]?.SEO}
                franchiseMetaData={[
                  franchiseHeadMetaTag,
                  franchiseHeadScriptd,
                  franchiseFooterScript,
                  franchiseFooterNoScript,
                ]}
                alertContent={corpHomeItem[0]?.Alerts}
                // alertContent={franchiseCorpCtrl?.Alerts}
                headerContent={franchiseCorpCtrl?.FranchiseHeader}
                otherProps={[franchiseCorpCtrl, franchiseBaseUrl]}
                corpHeaderProps={[
                  corpHomeItem && corpHomeItem[0]?.Header?.BookOnlineIcon,
                  corpHomeItem && corpHomeItem[0]?.Header?.GetEstimateIcon,
                  corpHomeItem && corpHomeItem[0]?.Header?.Page[0],
                  corpHomeItem && corpHomeItem[0]?.Header?.Page[1],
                  corpHomeItem && corpHomeItem[0]?.GlobalSettings,
                ]}
                metaDescription={franchiseDetailPage[0]?.Description?.Value?.replaceAll(
                  "{City}",
                  franchiseCity
                )
                  ?.replace(
                    "{Phone}",
                    formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
                  )
                  ?.replace("{FranchiseName}", franchiseName)
                  ?.replace("{StateAbbreviation}", stateAbbr)}
                estimateTrayData={estimateTrayData}
              />
            )}

            {!isCampaignPage && (
              <DetailPageBanner
                bannerContent={franchiseDetailPage[0]?.Banner}
                headingContent={franchiseDetailPage[0]?.Heading}
                paintStrokes={[
                  franchiseDetailPage[0]?.LeftCapPaintStroke,
                  franchiseDetailPage[0]?.MiddlePaintStroke,
                  franchiseDetailPage[0]?.RightCapPaintStroke,
                ]}
              />
            )}
            <div>
              {!isCampaignPage && (
                <div className="franchise-detailpage-container">
                  <BreadCrumb
                    breadcrumbData={
                      franchiseDetailPage[0]?.BreadcrumbNavigation
                    }
                  />
                </div>
              )}
              {/* Franchise Detail Page synamic component and data sent as props here */}

              {isCampaignPage && (
                <CampaignPageBanner
                  bannerContent={franchiseDetailPage[0]?.Banner}
                  headingContent={franchiseDetailPage[0]?.Heading}
                  headerContent={franchiseCorpCtrl?.FranchiseHeader}
                  otherProps={[franchiseCorpCtrl, franchiseBaseUrl]}
                  paintStrokes={[
                    franchiseDetailPage[0]?.LeftCapPaintStroke,
                    franchiseDetailPage[0]?.MiddlePaintStroke,
                    franchiseDetailPage[0]?.RightCapPaintStroke,
                  ]}
                />
              )}
              {isCampaignPage && (
                <CampaignForm
                  contentData={{
                    zipCodeList: localOfficeZipcodes,
                    localOfficeList: localOfficeResults,
                  }}
                  franchiseDetails={franchiseDetailPage}
                />
              )}
              {componentDetails && (
                <DynamicComponent
                  componentName={componentDetails && componentDetails[0]}
                  contentData={combineProps()}
                />
              )}
              {/* Dynamic Trays rendering */}
              {franchiseDetailPage[0]?.Trays?.map((tray, index) => {
                if (
                  Object.keys(dynamicTrayList)?.find(
                    (trayName) => trayName === tray?.TrayName
                  )
                ) {
                  let combinedProps;
                  if (
                    componentDetails &&
                    componentDetails[0] === "Reviews" &&
                    tray?.TrayName === "ReviewCarousel"
                  ) {
                    return;
                  } else if (tray?.TrayName === "ReviewCarousel") {
                    const rateaBizUId =
                      franchiseHomePage &&
                      franchiseHomePage[0]?.FranchiseCorporateControl
                        ?.RateABizUUID;
                    combinedProps = {
                      ...{
                        ...franchiseDetailPage[0],
                        ...{ rateaBiz: rateaBizUId },
                        ...{ reviewsTrays: tray },
                      },
                    };
                  } else if (tray?.TrayName === "FranchisePackages") {
                    const data =
                      franchiseHomePage[0]?.FranchiseCorporateControl
                        ?.FranchiseOwnersControlNew?.Packages;
                    let packageCpyData = [];
                    Object.keys(sessionStorage).forEach((key) => {
                      key !== "undefined" &&
                        packageCpyData.push({
                          name: key?.split("-").join(" "),
                          copyText: sessionStorage.getItem(key),
                        });
                    });
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{ otherProps: data },
                        ...{ packageCopy: packageCpyData },
                      },
                    };
                  } else if (tray?.TrayName === "ServicesTray") {
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{ franchiseData: franchiseHomePage[0] },
                      },
                    };
                  } else if (
                    tray?.TrayName === "ScheduleandEstimateTabTray" ||
                    tray?.TrayName === "ScheduleandEstimateTray"
                  ) {
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{ zipCodeList: localOfficeZipcodes },
                        ...{ localOfficeList: localOfficeResults },
                        ...{ franchiseDataToGetEstimate },
                      },
                    };
                  } else {
                    combinedProps = { ...tray };
                  }

                  return (
                    <DynamicTrays
                      trayName={tray?.TrayName}
                      contentData={combinedProps}
                      key={index}
                    />
                  );
                }
              })}

              {Object.keys(dynamicTrayList)?.find(
                (trayName) =>
                  trayName ===
                  franchiseDetailPage[0]?.ScheduleandEstimateTabTray?.Name
              ) && (
                <DynamicTrays
                  trayName={
                    franchiseDetailPage[0]?.ScheduleandEstimateTabTray?.Name
                  }
                  contentData={combineCommonTrayProps(
                    franchiseDetailPage[0]?.ScheduleandEstimateTabTray,
                    { zipCodeList: localOfficeZipcodes },
                    { localOfficeList: localOfficeResults },
                    { franchiseDataToGetEstimate }
                  )}
                />
              )}

              {Object.keys(dynamicTrayList)?.find((trayName) => {
                if (
                  trayName === franchiseDetailPage[0]?.ImageCopywithSlider?.Name
                ) {
                  /* To remove the imagecopytraywithslider in franchise */
                  return null;
                }
              }) && (
                <DynamicTrays
                  trayName={franchiseDetailPage[0]?.ImageCopywithSlider?.Name}
                  contentData={combineCommonTrayProps(
                    franchiseDetailPage[0]?.ImageCopywithSlider
                  )}
                />
              )}
            </div>
            {isCampaignPage ? (
              <Footer
                homePageData={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                }
              />
            ) : (
              <FootersFranchise
                footerContent={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseFooter
                }
                headerContentForMenu={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseHeader?.MenuTab
                }
                otherProps={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                }
                corpFooterProps={[
                  corpHomeItem && corpHomeItem[0]?.Footer?.FooterLogo,
                  corpHomeItem && corpHomeItem[0]?.Footer?.TextAbovePhoneNumber,
                  corpHomeItem && corpHomeItem[0]?.Footer?.Copyright,
                  corpHomeItem && corpHomeItem[0]?.Footer?.CopyrightLinks?.Page,
                  corpHomeItem && corpHomeItem[0]?.Footer?.DisclaimerNotes,
                  franchiseHomePage &&
                    franchiseHomePage[0]?.FranchiseCorporateControl
                      ?.FranchiseName?.Value,
                  franchiseHomePage && franchiseHomePage[0]?.SURL,
                ]}
              />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  detailPage: state?.franchiseDetailPageReducer?.franchseDetailPage,
  loading: state?.franchiseDetailPageReducer?.loading,
  error: state?.franchiseDetailPageReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  corpHomeItem: state?.headerReducer?.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
});

export default withRouter(connect(mapStateToProps)(FranchiseDetailPage));
