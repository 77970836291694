import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { HashLink as Link } from "react-router-hash-link";
import { fetchHomePage } from "../../actions/fetchData";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";
import { ingeniuxURL } from "../../config/urls";
import {
  getResultsArray,
  loadApiData,
  saveApiData,
  bgPatternTriangles,
  getFranchiseDataFromLS,
} from "../../config/utility";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import CareersHeroWithOverlayTray from "../common/CareersHeroWithOverlayTray";
import CopyAside from "../common/CopyAside";
import "./resouces.css";

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;
    this.props.dispatch(fetchHomePage("/resources"));

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }
  render() {
    const homeItemData = getResultsArray(this.props?.homeItem, "DetailPage");
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };
    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;
    return (
      <>
        <Helmet>
          <title>
            {pageTitle ? `${pageTitle} | ${browserSuffix}` : browserSuffix}
          </title>
        </Helmet>
        <HeaderWrapper
          headerItemData={{
            ...headerItemData,
            metaDescription: homeItemData[0]?.Description?.Value,
          }}
        />
        <div
          className="resource-body-wrap"
          style={{
            backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none"
          }}
        >
          <div className="page-wrap">
            {homeItemData[0]?.HeroWithOverlay !== undefined && (
              <CareersHeroWithOverlayTray
                BackgroundImage={
                  homeItemData[0]?.HeroWithOverlay?.BackgroundImage?.Value
                }
                Heading={homeItemData[0]?.HeroWithOverlay?.Heading?.Value}
                Subheading={homeItemData[0]?.HeroWithOverlay?.Subheading?.Value}
                PageLink={homeItemData[0]?.HeroWithOverlay?.Page?.Link}
                PageUrl={homeItemData[0]?.HeroWithOverlay?.Page?.URL}
                TextBoxBackgroundColor={
                  homeItemData[0]?.HeroWithOverlay?.TextBoxBackgroundColor
                    ?.Value
                }
                TextBoxAlignment={
                  homeItemData[0]?.HeroWithOverlay?.TextBoxAlignment?.Value
                }
              />
            )}
            <section
              id="services-banner"
              style={{
                display: `${
                  homeItemData[0]?.HeroWithOverlay === undefined
                    ? "block"
                    : "none"
                }`,
              }}
            >
              <div className="banner-slide services">
                <div className="Resource-container">
                  <div className="banner-text">
                    <h1
                      className={`header ${
                        getFranchiseDataFromLS("recentFranchisePath") !==
                        undefined
                          ? "resources-padding-with-last-franchise-ribbon"
                          : ""
                      }`}
                    >
                      {homeItemData[0]?.Title?.Value}
                    </h1>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="services-content"
              className="content-section twelve"
              //             style={{paddingRight:"12px"}}
            >
              <div
                className="breadcrumbs"
                style={{
                  display: `${
                    homeItemData[0]?.HeroWithOverlay === undefined
                      ? "block"
                      : "none"
                  }`,
                }}
              >
                <div className="Resource-container">
                  <ul>
                    {/* <BreadCrumb breadcrumbData={homeItemData[0]?.Navigation?.Page} /> */}

                    {homeItemData[0]?.BreadcrumbNavigation?.map(
                      (breadCrum, i, row) => {
                        if (i + 1 === row.length) {
                          return (
                            <li>
                              <span> {breadCrum?.Name}</span>
                            </li>
                          );
                        } else {
                          return (
                            <li>
                              <Link to={`/${breadCrum?.URL}`}>
                                {" "}
                                {breadCrum?.Name}
                              </Link>{" "}
                              {" | "}
                            </li>
                          );
                        }
                      }
                    )}
                  </ul>
                </div>
              </div>
              <div className="content-wrap">
                <div className="Resource-container row">
                  <div className="col-dd-8">
                    {(homeItemData[0]?.Copy || homeItemData[0]?.AsideImage) && (
                      <CopyAside contentData={homeItemData[0]} />
                    )}
                    {/* <Markup content={homeItemData[0]?.Copy?.Value} /> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {homeItemData[0]?.Trays?.map((tray, index) => {
          if (
            Object.keys(corpDynamicTrayList)?.find(
              (trayName) => trayName === tray?.TrayName
            )
          ) {
            let combinedProps;

            if (
              tray?.TrayName === "ScheduleandEstimateTabTray" ||
              tray?.TrayName === "ScheduleandEstimateTray"
            ) {
              combinedProps = {
                ...{
                  ...tray,
                  ...{ zipCodeList: localOfficeZipcodes },
                  ...{ localOfficeList: localOfficeResults },
                  ...{ franchiseDataToGetEstimate },
                },
              };
            } else if (tray?.TrayName === "ServicesTray") {
              combinedProps = {
                ...{ ...tray, ...{ franchiseData: franchiseHomePage[0] } },
              };
            } else if (tray?.TrayName === "FindAnOffice") {
              combinedProps = {
                ...{
                  ...tray,
                  ...{ zipCodeList: localOfficeZipcodes },
                  ...{ localOfficeList: localOfficeResults },
                },
              };
            } else {
              combinedProps = { ...tray };
            }

            return (
              <CorpDynamicTrays
                trayName={tray?.TrayName}
                contentData={combinedProps}
                key={index}
              />
            );
          }
        })}

        <FooterWrapper headerItemData={headerItemData} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default connect(mapStateToProps)(Resources);
