import React from "react";
import "./ourStory.css";
import { HashLink as Link } from 'react-router-hash-link';
// import { Markup } from "interweave";
import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import CareersHeroWithOverlayTray from "../common/CareersHeroWithOverlayTray";

const OurStory = ({ contentData }) => {
    return (
        <div className="body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
            {contentData?.DetailPage?.HeroWithOverlay !== undefined &&
                            <CareersHeroWithOverlayTray
                                BackgroundImage={contentData?.DetailPage?.HeroWithOverlay?.BackgroundImage?.Value}
                                Heading={contentData?.DetailPage?.HeroWithOverlay?.Heading?.Value}
                                Subheading={contentData?.DetailPage?.HeroWithOverlay?.Subheading?.Value}
                                PageLink={contentData?.DetailPage?.HeroWithOverlay?.Page?.Link}
                                PageUrl={contentData?.DetailPage?.HeroWithOverlay?.Page?.URL}
                                TextBoxBackgroundColor={contentData?.DetailPage?.HeroWithOverlay?.TextBoxBackgroundColor?.Value}
                                TextBoxAlignment={contentData?.DetailPage?.HeroWithOverlay?.TextBoxAlignment?.Value} />
                        }
            <section id="our-story-banner" style={{display: `${contentData?.DetailPage?.HeroWithOverlay === undefined ? 'block' : 'none'}`}}>
                <div className="banner-slide about-us">
                    <div className="container">
                        <div className="banner-text">
                            <h1 className="header" style={{ fontSize: "35px" }}>{contentData?.DetailPage?.Heading?.Value}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section id="our-story-content" className="content-section twelve">
                <div className="breadcrumbs" style={{display: `${contentData?.DetailPage?.HeroWithOverlay === undefined ? 'block' : 'none'}`}}>
                    <div className="container">
                        <ul>
                            {contentData?.DetailPage?.BreadcrumbNavigation?.map((breadCrum, i, row) => {
                                if (i + 1 === row.length) {
                                    return (
                                        <li><span> {breadCrum?.Name}</span></li>
                                    )
                                } else {
                                    return (
                                        <li><Link to={`/${breadCrum?.URL}`}> {breadCrum?.Name}</Link> {" | "}</li>
                                    )
                                }
                            })}
                            {/* <li className="home"><a href="/">Home</a></li>
                            <li>Our Story</li>       */}
                        </ul>
                    </div>
                </div>
                {/* <div className="content-wrap">
                    <div className="row about-welcome">
                        <div className="container">
                            <div 
                            // className="pad-right col-sd-12 col-md-12 col-dd-8"
                            >
                                <h3>{contentData?.DetailPage?.Trays[0]?.Heading?.Value}</h3>
                                <Markup content={contentData?.DetailPage?.Trays[0]?.Copy?.Value} />
                            </div>
                            <div className="col-sd-12 col-md-8 col-dd-4">
                                <div className="message">
                                    <h3>A Message From Our Founder, Andy Bell</h3>
                                    <figure><img src=
                                    {`${ingeniuxURL}${contentData?.DetailPage?.Trays[0]?.Image?.Value}`}
                                     alt="Our Founder" /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        </div>

    )
}

export default OurStory;