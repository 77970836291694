import React, { Component } from "react";
import { connect } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";

import { fetchHomePage } from "../../actions/fetchData";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";
import {
  getResultsArray,
  loadApiData,
  saveApiData,
  bgPatternTriangles,
} from "../../config/utility";
import { ingeniuxURL } from "../../config/urls";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import CareersHeroWithOverlayTray from "../common/CareersHeroWithOverlayTray";
import CopyAside from "../common/CopyAside";
import withRouter from "../common/withRouter";
import "./services.css";
import Page404 from "../page-404";

class FooterLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsPage: "",
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.homeItem) {
        this.setState({ detailsPage: this.props.homeItem });
      }
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    const footerApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    footerApiPath && this.props.dispatch(fetchHomePage(footerApiPath));

    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.link !== nextProps.link) {
      this.props.dispatch(fetchHomePage(nextProps.link));
      window.scrollTo(0, 0);
    }
  }
  render() {
    const homeItemData = getResultsArray(this.state?.detailsPage, "DetailPage");
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;
    return (
      <>
        {this.props?.homeItem.totalResults === 0 ? (
          <Page404 />
        ) : (
          <>
            <Helmet>
              <title>
                {pageTitle ? `${pageTitle} | ${browserSuffix}` : browserSuffix}
              </title>
            </Helmet>
            <HeaderWrapper
              headerItemData={{
                ...headerItemData,
                metaDescription: homeItemData[0]?.Description?.Value,
              }}
            />
            <div
              className="body-wrap"
              style={{
                backgroundImage: bgPatternTriangles ?`url(${ingeniuxURL}${bgPatternTriangles})` : "none"
              }}
            >
              <div className="page-wrap">
                {homeItemData[0]?.HeroWithOverlay !== undefined && (
                  <CareersHeroWithOverlayTray
                    BackgroundImage={
                      homeItemData[0]?.HeroWithOverlay?.BackgroundImage?.Value
                    }
                    Heading={homeItemData[0]?.HeroWithOverlay?.Heading?.Value}
                    Subheading={
                      homeItemData[0]?.HeroWithOverlay?.Subheading?.Value
                    }
                    PageLink={homeItemData[0]?.HeroWithOverlay?.Page?.Link}
                    PageUrl={homeItemData[0]?.HeroWithOverlay?.Page?.URL}
                    TextBoxBackgroundColor={
                      homeItemData[0]?.HeroWithOverlay?.TextBoxBackgroundColor
                        ?.Value
                    }
                    TextBoxAlignment={
                      homeItemData[0]?.HeroWithOverlay?.TextBoxAlignment?.Value
                    }
                  />
                )}
                <section
                  id="services-banner"
                  style={{
                    display: `${
                      homeItemData[0]?.HeroWithOverlay === undefined
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  <div className="banner-slide services">
                    <div className="resources-container">
                      <div className="banner-text">
                        <h1 className="header">{homeItemData[0]?.Name}</h1>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="services-content"
                  className="content-section twelve"
                  style={{ paddingRight: "12px" }}
                >
                  <div
                    className="breadcrumbs"
                    style={{
                      display: `${
                        homeItemData[0]?.HeroWithOverlay === undefined
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    <div className="resources-container">
                      <ul>
                        {homeItemData[0]?.BreadcrumbNavigation?.map(
                          (breadCrum, i, row) => {
                            if (i + 1 === row.length) {
                              return (
                                <li>
                                  <span> {breadCrum?.Name}</span>
                                </li>
                              );
                            } else if (
                              i + 1 === 1 &&
                              breadCrum.Name !== "Home"
                            ) {
                              return (
                                <>
                                  <li>
                                    <Link to={`/`}>Home</Link> {" | "}
                                  </li>
                                  <li>
                                    <Link to={`/${breadCrum?.URL}`}>
                                      {" "}
                                      {breadCrum?.Name}
                                    </Link>{" "}
                                    {" | "}
                                  </li>
                                </>
                              );
                            } else {
                              return (
                                <li>
                                  <Link to={`/${breadCrum?.URL}`}>
                                    {" "}
                                    {breadCrum?.Name}
                                  </Link>{" "}
                                  {" | "}
                                </li>
                              );
                            }
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  {(homeItemData[0]?.Copy || homeItemData[0]?.AsideImage) && (
                    <CopyAside contentData={homeItemData[0]} />
                  )}
                </section>
              </div>
            </div>

            {homeItemData[0]?.Trays?.map((tray, index) => {
              if (
                Object.keys(corpDynamicTrayList)?.find(
                  (trayName) => trayName === tray?.TrayName
                )
              ) {
                let combinedProps;

                if (
                  tray?.TrayName === "ScheduleandEstimateTabTray" ||
                  tray?.TrayName === "ScheduleandEstimateTray"
                ) {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                      ...{ franchiseDataToGetEstimate },
                    },
                  };
                } else if (tray?.TrayName === "ServicesTray") {
                  combinedProps = {
                    ...{ ...tray, ...{ franchiseData: franchiseHomePage[0] } },
                  };
                } else if (tray?.TrayName === "FindAnOffice") {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                    },
                  };
                } else {
                  combinedProps = { ...tray };
                }

                return (
                  <CorpDynamicTrays
                    trayName={tray?.TrayName}
                    contentData={combinedProps}
                    key={index}
                  />
                );
              }
            })}
            <FooterWrapper headerItemData={headerItemData} />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default withRouter(connect(mapStateToProps)(FooterLink));
