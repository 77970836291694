import { Markup } from "interweave";
import React from "react";
import { ingeniuxURL } from "../../config/urls";
import "./WhyChooseUsLife.css";

const BodyCon = ({ contentData }) => {
  return (
    <div className="whychooseuslife-body-wrap">
      <section id="content" className="content-section twelve">
        <div className="container row">
          {contentData?.AddOutline?.Value === "Yes" && (
            <p className="choose-subheading">
              <h3
                style={{
                  textAlign: `${
                    contentData?.HeadingAlignment?.Value === "Left"
                      ? "left"
                      : contentData?.HeadingAlignment?.Value === "Center"
                      ? "center"
                      : "right"
                  }`,
                }}
              >
                {contentData?.Heading?.Value}
              </h3>
              <Markup content={contentData?.Copy?.Value} />
              {contentData?.Page?.Name === "CTA" && (
                <div style={{ textAlign: "center" }}>
                  <a
                    className="bodycopytray-cta"
                    target="_blank"
                    href={contentData?.Page?.URL}
                  >
                    {contentData?.Page?.Link}
                  </a>{" "}
                </div>
              )}
            </p>
          )}
          {contentData?.AddOutline?.Value === "No" && (
            <blockquote>
              <h3
                style={{
                  textAlign: `${
                    contentData?.HeadingAlignment?.Value === "Left"
                      ? "left"
                      : contentData?.HeadingAlignment?.Value === "Center"
                      ? "center"
                      : "right"
                  }`,
                }}
              >
                {contentData?.Heading?.Value}
              </h3>
              <Markup
                content={contentData?.Copy?.Value?.replace(
                  "Images",
                  `${ingeniuxURL}Images`
                )}
              />
              {contentData?.Page?.Name === "CTA" && (
                <a
                  className="bodycopytray-cta"
                  target="_blank"
                  href={contentData?.Page?.URL}
                >
                  {contentData?.Page?.Link}
                </a>
              )}
            </blockquote>
          )}
        </div>
      </section>
    </div>
  );
};

export default BodyCon;
