import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import axios from "axios";
import { fetchFranchiseHomeBlog } from "../../../actions/fetchFranchiseHomeBlog";
import { fetchHeaderPage } from "../../../actions/fetchHeaderData";
import { fetchIndividualFranchiseBlog } from "../../../actions/fetchIndividualFranchiseBlogs";
import { fetchFranchiseHome } from "../../../actions/fetchFranchiseHome";
import Blog from "../Blog";
import BlogAside from "../BlogAside";
import HeaderFranchise from "../../FranchiseCommon/Header/HeaderFranchise";
import FootersFranchise from "../../FranchiseCommon/Footer/FooterFranchise";
import {
  deleteItemFromSS,
  getFranchiseDataFromLS,
  extractingLastPath,
  getResultsArray,
  extractingSTLPath,
  extractingFTLPath,
  loadApiData,
  saveApiData,
  storeFranchiseDataToLS,
  extractingSTLPathFranchise,
  extractingSTLPathFranchisePage,
  getScheduleEstimateTabTray
} from "../../../config/utility";
import withRouter from "../../BlogPostContent../../common/withRouter";
import { ingeniuxURL } from "../../../config/urls";
import PageLoader from "../../common/PageLoader";
import Page404 from "../../page-404";

class FranchiseBlogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryState: "",
      authorState: "",
      yearState: "",
      individualPostState: "",
      pageState: "",
      searchInputState: "",
      globalSiteCrl: loadApiData("x31"),
      recentBlogPosts: [],
      localOfficeArray: loadApiData("localoffice"),
      franchiseHomeData: loadApiData("franchiseHomeData"),
      contentData: [],
      breadcrumbData: "",
      recentBlog: [],
      individualPostData: [],
    };
  }

  extractBaseURL(apiPath = "") {
    const split = apiPath?.replace(/\/+$/, "")?.split("/");
    let baseURL = split.slice(0, split.length - 1).join("/");
    const baseURLSplit = baseURL?.split("/");
    if (baseURLSplit?.length > 3) {
      baseURL = baseURLSplit?.slice(0, baseURLSplit?.length - 1).join("/");
    }
    return baseURL;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const franchiseDetailPageApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const franchiseBaseUrl = this.props?.apiRef?.basePageURL
      ? this.props?.apiRef?.basePageURL
      : this?.extractBaseURL(this.props?.apiRef);
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    this.props.dispatch(fetchFranchiseHomeBlog(`${franchiseBaseUrl}/blog`));

    //To eliminate the hash url, splitting the path.
    const rgxforSlashes = /^\/|\/$/g;
    const franchiseAbsPath = franchiseBaseUrl?.split("/#");
    storeFranchiseDataToLS("recentFranchisePath", franchiseAbsPath[0]);
    const localStateSURL =
      this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;
    const localStorageSURL = getFranchiseDataFromLS("recentFranchisePath");


    //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
    //then load the franchisehomepage api again.

    //scenario 2 - in else block if the cached data and the visited franchise SURLS
    //are no the same, removed the cached data from the Session storage and
    //fetch the api again.
    if (
      (this.state.franchiseHomeData?.length === 0 ||
        this.state.franchiseHomeData === undefined) &&
      franchiseHomePage?.length === 0
    ) {
      this.props.dispatch(fetchFranchiseHome(franchiseBaseUrl?.toLowerCase()));
    } else if (
      this.state.franchiseHomeData &&
      localStateSURL?.replace(rgxforSlashes, "") !==
        localStorageSURL?.replace(rgxforSlashes, "")
    ) {
      deleteItemFromSS("franchiseHomeData");
      this.props.dispatch(fetchFranchiseHome(franchiseBaseUrl?.toLowerCase()));
    }

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
        headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }

    if (extractingSTLPathFranchise(franchiseDetailPageApiPath) !== "blog") {
      this.setState({ individualPostState: franchiseDetailPageApiPath });
      this.props.dispatch(
        fetchIndividualFranchiseBlog(franchiseDetailPageApiPath)
      );
    }

    if (extractingSTLPath(franchiseDetailPageApiPath) === "page") {
      this.setState({ individualPostState: undefined });
      this.props.dispatch(
        fetchIndividualFranchiseBlog(franchiseDetailPageApiPath)
      );
    }

    if (extractingSTLPathFranchisePage(franchiseDetailPageApiPath) === "page") {
      this.setState({
        pageState: extractingLastPath(franchiseDetailPageApiPath),
      });
    }

    //fetching the recent blogPosts which sorted by the date updated
    const recentBlogData = async () => {
      if (!window.location.pathname.endsWith("/blog")) {
        try {
          const res = await axios.get(
            `${ingeniuxURL}api/page?path=${franchiseBaseUrl}/blog`
          );
          const { data } = res;
          let blogData = data?.results[0]?.FranchiseBlogIndex?.Navigation?.Page;
          let recentBlogs = blogData.sort(
            (post1, post2) =>
              new Date(post1?.Changed) - new Date(post2?.Changed)
          );
          this.setState((prevState) => ({
            ...prevState,
            recentBlog: recentBlogs.slice(0, 5),
          }));
        } catch (err) {
          console.log(err);
        }
      }
    };
    recentBlogData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.homeItem !== prevProps.homeItem) {
      this.setState((prevState) => ({
        ...prevState,
        contentData: getResultsArray(this.props.homeItem)[0],
        recentBlog: getResultsArray(this.props.homeItem)[0]?.FranchiseBlogIndex
          ?.Navigation?.Page,
        breadcrumbData: getResultsArray(this.props?.homeItem)[0]
          ?.FranchiseBlogIndex,
      }));
    }
    window.scrollTo(0, 0);
  }

  render() {
    const resultsData = getResultsArray(this.props.homeItem);
    const franchiseHomeData = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
  
    //in componentDidUpdate the recent franchise path not always saved to LS
    //hence adding extra logic to save the franchisepath to LS
    const franchisePath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this?.extractBaseURL(this.props?.apiRef);
    const serviceApiPath = franchisePath;
    if (this.props?.homeItem.totalResults === 1) {
      storeFranchiseDataToLS("recentFranchisePath", franchisePath);
    }
    const individualResultsData = getResultsArray(this.props.individualItem);

    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;
    let franchiseHomePage = loadApiData("franchiseHomeData");
    let corpHomeItem = loadApiData("x31");
    let headerItemData = corpHomeItem;
    let localOfficeResults = loadApiData("localoffice");
    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      //loading franchiepath from LS key recntfranchisepath
      //rather than current-franchise-path
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        franchisePath?.replace(rgxforSlashes, "")
      ) {
        saveApiData("franchiseHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseHomeData");
      }
    }

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (corpHomeItem === undefined || corpHomeItem?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      corpHomeItem = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;

    //Franchise Hero can have hero content from FranchiseHomePage and
    //FranchiseOwnerControl, combining the data for hero into single array
    const franchiseHero =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseHero;
    const franchiseOwnerCtrlHero =
      franchiseCorpCtrl &&
      franchiseCorpCtrl?.FranchiseOwnersControlNew?.FranchiseHero;

    const packageDetail = getResultsArray(
      this.props?.featurePkg,
      "PackageDetail"
    );
    const mergeData = (...objects) =>
      objects.reduce((acc, cur) => ({ ...acc, ...cur }));
    const pkgData = mergeData(packageDetail);
    const pkgCpyName = pkgData[0]?.Name;
    sessionStorage.setItem(
      pkgCpyName,
      JSON.stringify(pkgData[0]?.IntroCopy?.Value)
    );

    const franchiseApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;

    if (this.props?.homeItem === 1) {
      storeFranchiseDataToLS("url-path", franchiseApiPath);
    } else {
      storeFranchiseDataToLS(
        "current-franchise-url",
        getFranchiseDataFromLS("url-path")
      );
    }

    const breadcrumbsStructuredData =
      franchiseCorpCtrl?.BreadcrumbsStructuredData?.Value;

    const franchiseHeadMetaTag = franchiseCorpCtrl?.HeadScripts?.MetaTag;
    const franchiseHeadScriptd = franchiseCorpCtrl?.HeadScripts?.Script;
    const franchiseFooterScript = franchiseCorpCtrl?.FooterScripts?.Script;
    const franchiseFooterNoScript =
      franchiseCorpCtrl?.FooterScripts?.NoScriptTag;

    const BrowserLocalSuffix = () => {
      if (extractingSTLPath(serviceApiPath) === "blog") {
        return individualResultsData[0]?.BlogPost?.Name + " - ";
      }
      if (
        (extractingSTLPath(serviceApiPath) === "search" &&
          extractingFTLPath(serviceApiPath) === "page") ||
        extractingSTLPath(serviceApiPath) === "search"
      ) {
        return this.state.searchInputState + " - ";
      }
      if (
        extractingSTLPath(serviceApiPath) === "year" ||
        (extractingSTLPath(serviceApiPath) === "page" &&
          extractingFTLPath(serviceApiPath) === "year")
      ) {
        return this.state.yearState + " - ";
      }
      if (extractingSTLPath(serviceApiPath) === "category") {
        return this.state.categoryState + " - ";
      }
      if (extractingSTLPath(serviceApiPath) === "author") {
        for (var i = 0; i < this?.props?.bItem?.results?.length; i++) {
          if (
            Array.isArray(
              this?.props?.bItem?.results[i]?.BlogPost?.IGX_Categories?.Authors
            )
          ) {
            for (
              var j = 0;
              j <
              this?.props?.bItem?.results[i]?.BlogPost?.IGX_Categories?.Authors
                ?.length;
              j++
            ) {
              if (
                this?.props?.bItem?.results[
                  i
                ]?.BlogPost?.IGX_Categories?.Authors[j]?.Name?.replace(
                  /\s+/g,
                  ""
                ) === this.state.authorState
              ) {
                return (
                  this?.props?.bItem?.results[i]?.BlogPost?.IGX_Categories
                    ?.Authors[j]?.Name + " - "
                );
              }
            }
          } else if (
            this?.props?.bItem?.results[
              i
            ]?.BlogPost?.IGX_Categories?.Authors?.Name?.replace(/\s+/g, "") ===
            this.state.authorState
          ) {
            return (
              this?.props?.bItem?.results[i]?.BlogPost?.IGX_Categories?.Authors
                ?.Name + " - "
            );
          }
        }
      }
      if (extractingSTLPath(serviceApiPath) === "page") {
        return "";
      }
      return "";
    };

    const blogPageTitle = resultsData[0]?.BlogIndex?.Title?.Value;
    const blogPageDescription = resultsData[0]?.BlogIndex?.Description?.Value;
    const pageTitle =
      this.state?.individualPostState &&
      window.location.pathname !== "/blog" &&
      individualResultsData[0]?.BlogPost?.Heading?.Value?.replace("?", "");
    const metaTitle = individualResultsData[0]?.BlogPost?.Title?.Value;
    const metaDescription =
      individualResultsData[0]?.BlogPost?.Description?.Value;

    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;
    const localBusinessStructuredData =
      franchiseCorpCtrl?.LocalBusinessStructuredData?.Value;
    const localSuffixLength = BrowserLocalSuffix()?.length;
    const browserLocalSuffix = BrowserLocalSuffix();
    const browserLocalSuffixWithoutHypen = BrowserLocalSuffix()
      ?.trim()
      ?.endsWith("-")
      ? BrowserLocalSuffix()?.slice(0, localSuffixLength - 2)
      : BrowserLocalSuffix();

    const getmetaTagTitle = () => {
      // for "/blog/*" page
      if (
        this.state?.individualPostState &&
        window.location.pathname !== "/blog"
      ) {
        if (metaTitle) {
          return `${metaTitle}`;
        }
        return `Blog | ${browserSuffix}`;
      }
      // for "/blog" page
      else {
        if (blogPageTitle) {
          return `${blogPageTitle}`;
        }
        return `Blog | ${browserSuffix}`;
      }
    };

    const getmetaTagDescription = () => {
      // for "/blog/*" page
      if (
        this.state?.individualPostState &&
        window.location.pathname !== "/blog"
      ) {
        if (metaDescription) {
          return `${metaDescription}`;
        } else if (pageTitle) {
          return `${pageTitle}`;
        }
        return browserLocalSuffixWithoutHypen;
      }
      // for "/blog" page
      else {
        if (blogPageDescription) {
          return `${blogPageDescription}`;
        }
        return `${browserLocalSuffix} Blog | ${browserSuffix}`;
      }
    };

    const metaTagTitle = getmetaTagTitle();
    const metaTagDescription = getmetaTagDescription();

    const getRecentBlogs = () => {
      const { recentBlog } = this.state;
      if (recentBlog) {
        let recentBlogs = recentBlog.sort(
          (post1, post2) => new Date(post1?.Changed) - new Date(post2?.Changed)
        );
        return recentBlogs.slice(0, 5);
      }
    };

    const recentPosts = getRecentBlogs();
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };
    const estimateFormZipData = {
      zipCodeList: localOfficeZipcodes,
      localOfficeList: localOfficeResults,
      toSchedulingPage: true,
    };

    const estimateFormData = getScheduleEstimateTabTray(franchiseHomePage);
    const estimateTrayData = {
      ...{ estimateFormZipData },
      ...{ estimateFormData },
      ...{ franchiseDataToGetEstimate },
    };

    return (
      <>
        {this?.props?.loading === false ? (
          this.props?.homeItem && this.props?.homeItem?.totalResults === 0 ? (
            <Page404 />
          ) : (
            <>
              <Helmet>
                <title>
                  {franchiseHomePage && franchiseHomePage[0]?.Title?.Value}
                </title>

                {/* Adding BreadcrumbsStructuredData to Head of the page */}
                {breadcrumbsStructuredData && (
                  <script type="application/ld+json">
                    {breadcrumbsStructuredData
                      ?.replace("context", "@context")
                      ?.replaceAll("type", "@type")}
                  </script>
                )}
                {/* Adding LocalBusinessStructuredData to Head of the page */}
                {localBusinessStructuredData && (
                  <script type="application/ld+json">
                    {localBusinessStructuredData
                      ?.replace("context", "@context")
                      ?.replaceAll("type", "@type")}
                  </script>
                )}
              </Helmet>
              {/* we have data based on franchise api path and data based on localoffice endpoint.
                 get the value of the Name field in FranchiseHomePage object. that tells us the 
                 Name of the current franchise of the page we are on.
                 we should also iterate through each result of the localoffice api response
                 and check if that result's FranchiseName value matches Name value of the FranchiseHomePage object.
                 if it does, then get the the FranchiseId value of the appropriate localoffice result
                 and convert it to an int. 
               */}
              <HeaderFranchise
                metaData={corpHomeItem && corpHomeItem[0]?.SEO}
                franchiseMetaData={[
                  franchiseHeadMetaTag,
                  franchiseHeadScriptd,
                  franchiseFooterScript,
                  franchiseFooterNoScript,
                ]}
                alertContent={corpHomeItem && corpHomeItem[0]?.Alerts}
                headerContent={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseHeader
                }
                otherProps={[
                  franchiseHomePage &&
                    franchiseHomePage[0]?.FranchiseCorporateControl,
                  franchiseHomePage && franchiseHomePage[0]?.SURL,
                ]}
                corpHeaderProps={[
                  corpHomeItem && corpHomeItem[0]?.Header?.BookOnlineIcon,
                  corpHomeItem && corpHomeItem[0]?.Header?.GetEstimateIcon,
                  corpHomeItem && corpHomeItem[0]?.Header?.Page[0],
                  corpHomeItem && corpHomeItem[0]?.Header?.Page[1],
                  corpHomeItem && corpHomeItem[0]?.GlobalSettings,
                ]}
                metaDescription={
                  franchiseHomePage && franchiseHomePage[0]?.Description?.Value
                }
                isFranchiseBlogs={true}
                pagePath={window.location.pathname}
                estimateTrayData={estimateTrayData}
              />
              <div className="blogBox" style={{ marginTop: "150px" }}>
                <div className="blogBoxOne">
                  <Blog
                    contentData={this.state?.contentData}
                    isFranchiseBlog={true}
                    individualPost={this.state.individualPostState}
                    pagePath={window.location.pathname}
                    page={this.state?.pageState}
                    individualPostContent={
                      individualResultsData?.length
                        ? individualResultsData[0]
                        : []
                    }
                    bookOnlineLink={
                      headerItemData && headerItemData[0]?.Header?.Page[0]?.Name
                    }
                    breadcrumbs={this.state?.breadcrumbData}
                  />
                </div>
                <div className="blogBoxTwo">
                  <BlogAside recentData={recentPosts} isFranchiseBlog={true} />
                </div>
              </div>

              <FootersFranchise
                footerContent={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseFooter
                }
                headerContentForMenu={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseHeader?.MenuTab
                }
                otherProps={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                }
                corpFooterProps={[
                  corpHomeItem && corpHomeItem[0]?.Footer?.FooterLogo,
                  corpHomeItem && corpHomeItem[0]?.Footer?.TextAbovePhoneNumber,
                  corpHomeItem && corpHomeItem[0]?.Footer?.Copyright,
                  corpHomeItem && corpHomeItem[0]?.Footer?.CopyrightLinks?.Page,
                  corpHomeItem && corpHomeItem[0]?.Footer?.DisclaimerNotes,
                  franchiseHomePage && franchiseHomePage[0]?.Name,
                  franchiseHomePage && franchiseHomePage[0]?.SURL,
                ]}
              />
            </>
          )
        ) : (
          <PageLoader />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.franchiseHomeBlogReducer.item,
  loading: state.franchiseHomeBlogReducer.loading,
  error: state.franchiseHomeBlogReducer.error,
  bItem: state.blogReducer.blogItem,
  bLoading: state.blogReducer.blogLoading,
  bError: state.blogReducer.blogError,
  headerItem: state.headerReducer.headerItem,
  individualItem: state.individualFranchiseBlogReducer.item,
  individualLoading: state.individualFranchiseBlogReducer.loading,
  individualError: state.individualFranchiseBlogReducer.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
});

export default withRouter(connect(mapStateToProps)(FranchiseBlogs));
