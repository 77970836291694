import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchFranchiseHome } from "../../../actions/fetchFranchiseHome";
import { getResultsArray, getFranchiseDataFromLS } from "../../../config/utility";

class LocalJSON extends Component {

    componentDidMount() {
        const franchiseBaseUrl = getFranchiseDataFromLS("recentFranchisePath");
        this.props.dispatch(fetchFranchiseHome(franchiseBaseUrl));
    }
    render() {
        const franchiseHomePage = getResultsArray(this.props?.homeItem, "FranchiseHomePage");
        const localJsonData = franchiseHomePage[0]?.FranchiseCorporateControl?.localJSON;
        return (
            <div>
                {!localJsonData ? "Loading..."
                    : JSON.stringify(localJsonData?.Value, null, 2)}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    homeItem: state?.franchiseHomePageReducer?.franchseHome,
    loading: state?.franchiseHomePageReducer?.loading,
    error: state?.franchiseHomePageReducer?.error
});

export default connect(mapStateToProps)(LocalJSON);