import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import DynamicLinkList from '../common/DynamicLinks';
import "./servicePackages.css";

const ServicesPackages = (props) => {
    let location = useLocation();
    const { contentData } = props;
    const { otherProps, packageCopy } = contentData;
    const viewPortWidth = window.screen.width;
    // const { FeaturedPackage: featuredPkgs } = otherProps;

    // useEffect(() => {
    //     const getCopyData = () => Promise.all(
    //         featuredPkgs?.map(url =>
    //             axios.get(`${ingeniuxURL}${url?.URL}`, {
    //                 headers: {"Access-Control-Allow-Origin": "*"}
    //             })
    //                 .then(res => console.log("data...cpy", res.status))
    //                 .catch(err => console.log("err..in cpy", err))
    //         )
    //     ).then(packages => packages.forEach(pkg => console.log("final...", pkg)));
    //     getCopyData()
    // }, [])

    useEffect(() => {
        const hashValue = location?.hash?.replace(/^#/, "")
        if (hashValue === "packages") {
            setTimeout(() => {
                const preBuiltPackages = document.getElementById("packages")
                window.scrollTo({ top: preBuiltPackages?.offsetTop - 190, left: 0, behavior: 'smooth' });
            }, 370)
        }
    }, [location])

    return (
        <>
            <div id="packages" className="services-packages-wrap row twelve">
                <div className="tools">
                    {contentData?.TrayImage?.Value && <img
                        src={`${ingeniuxURL}${contentData?.TrayImage?.Value}`}
                        alt={contentData?.TrayImage?.AlternateText ? contentData?.TrayImage?.AlternateText : "Home Tools"}
                        title={contentData?.TrayImage?.AlternateText ? contentData?.TrayImage?.AlternateText : "Home Tools"}
                    />}
                </div>
                <div className="services-packages-container">
                    <div className="left-col pad-right-lg col-dd-7">
                        <div className="services-packages-container">
                            <h3>
                                {contentData?.Heading?.Value}
                            </h3>
                            <p>
                                {contentData?.IntroCopy?.Value}
                            </p>
                            <ul className="accent-sq col-md-12 two-col">
                                {otherProps?.LocalPackages?.map((localPackages, index) => {
                                    return (<>
                                        <li key={index}>
                                            <DynamicLinkList
                                                pagePath={`/${localPackages?.URL}`}
                                                pageApiUrl={localPackages?.URL}
                                                pageName={localPackages.Name}
                                                basePageURL={"/packages"} />
                                        </li>{index === 4 && viewPortWidth <= 400 ? <br /> : <></>}</>

                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="right-col col-dd-5 col-md-12">
                        {otherProps?.FeaturedPackage?.map((featuredPackage, index) => {
                            const featuredPackageCpy = packageCopy?.find(el => el?.name === featuredPackage?.Name)
                            return (
                                <div className="featured-package single-border" key={index}>
                                    <div className="content">
                                        <h3>
                                            {index > 0 ? featuredPackage?.Name : "Featured Package"}
                                        </h3>
                                        <h5 style={{display: `${index > 0 ? 'none' : 'block'}`}}>
                                            {featuredPackage?.Name}
                                        </h5>
                                        <Markup content={featuredPackageCpy?.copyText?.replace(/['"]+/g, '')} />
                                        <ul>
                                            <li>
                                                <a className="learn-more" href={`/${featuredPackage?.URL}`}>
                                                    Learn More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div >
        </>
    );
};

export default ServicesPackages;