import React from "react";
import { Markup } from 'interweave';
import { HashLink as Link } from 'react-router-hash-link';

import { ingeniuxURL } from "../../../config/urls";
import { bgPatternTriangles } from "../../../config/utility";
import "./PackagesHowItWorksFeatured.css";

const PackagesHowItWorksFeatured = ({ title, bread, heading, content, boxTitle, boxHeading, boxIntro, boxList, boxURL }) => {
    return (
        <div>
            <div className="body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
                <section id="package-banner">
                    <div className="banner-slide packages">
                        <div className="package-container">
                            <div className="banner-text">
                                <h1 className="header">{title}</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="package-content" className="content-section twelve">
                    <div className="breadcrumbs">
                        <div className="package-container">
                            <ul>
                                {/* {bread?.PackagesIndex?.Navigation[1]?.Page?.map */}
                                {bread?.BreadcrumbNavigation?.map((breadCrum, i, row) => {
                                    if (i + 1 === row.length) {
                                        return (
                                            <li><span> {breadCrum?.Name}</span></li>
                                        )
                                    } else {
                                        return (
                                            <li><Link to={`/${breadCrum?.URL}`}> {breadCrum?.Name}</Link> {" | "}</li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="package-content-wrap twelve">
                        <div className="package-container row">
                            <div className="col-sdd-12 col-ddd-6">
                                <h3>{heading}</h3>
                                <Markup content={content} />
                            </div>
                            <div className="col-sdd-12 col-ddd-6 pad-left-lg">
                                <div className="single-border featured-package">
                                    <h3 style={{ fontSize: "1.75rem" }}>{boxTitle}</h3>
                                    <h5>{boxHeading}</h5>
                                    <Markup content={boxIntro} />
                                    <ul className="accent-sq">
                                        <Markup content={boxList} />
                                        <ul className="accent-sq">
                                            <li><a href={`/${boxURL}`}>Learn More</a></li>
                                        </ul></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PackagesHowItWorksFeatured;