import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import ZipChooser from "../common/ZipChooser";
import "./HomeBanner.css";

const HomeBanner = ({
  homeBannerContent = {},
  zipCodeList,
  localOfficeList,
}) => {
  const blockPlacement = homeBannerContent?.ContentBlockPlacement;

  const posBanner = (pos) => {
    let cssClass;
    if (pos === "Right") {
      cssClass = "pos-right";
    } else if (pos === "Center") {
      cssClass = "pos-center";
    } else cssClass = "pos-left";
    return cssClass;
  };

  const bannerMargin = (pos) => {
    let cssClass;
    if (pos === "Right") {
      cssClass = "mr-50";
    } else if (pos === "Center") {
      cssClass = "";
    } else cssClass = "ml-50";
    return cssClass;
  };

  return (
    <section id="banner">
      <div
        className={`banner-slide home display-flex ${posBanner(
          blockPlacement?.Value
        )}`}
        style={{
          background: homeBannerContent?.BackgroundImage?.Value ? `#efeff0 url(${ingeniuxURL}${homeBannerContent?.BackgroundImage?.Value}) no-repeat top / cover` : "#efeff0"
        }}
      >
        <div
          className={`home-banner-container ${bannerMargin(
            blockPlacement?.Value
          )}`}
        >
          <div className="banner-text">
            <div className="banner-home-wrap">
              {homeBannerContent?.ForegroundLogo?.Value && <img
                src={`${ingeniuxURL}${homeBannerContent?.ForegroundLogo?.Value}`}
                alt={homeBannerContent?.ForegroundLogo?.AlternateText}
                title={homeBannerContent?.ForegroundLogo?.AlternateText}
                width="650" height="225"
              />}
              <div className="header corporate-home">
                <h1 className="headline">
                  <Markup content={homeBannerContent?.Heading?.Value} />
                  <Markup content={homeBannerContent?.Copy?.Value} />
                </h1>
              </div>

              <center>
                <ZipChooser
                  submitAlignment={
                    homeBannerContent?.FindAnOffice?.SubmitAlignment?.Value
                  }
                  headingAlignment={
                    homeBannerContent?.FindAnOffice?.HeadingAlignment?.Value
                  }
                  zipInputs={{
                    PlaceholderText:
                      homeBannerContent?.FindAnOffice?.PlaceholderText?.Value?.replace(
                        "zip",
                        "ZIP"
                      ),
                    TextBelowInput:
                      homeBannerContent?.FindAnOffice?.TextBelowInput?.Value,
                    Heading: homeBannerContent?.FindAnOffice?.Heading?.Value,
                  }}
                  zipCodeList={zipCodeList}
                  localOfficeList={localOfficeList}
                  toSchedulingPage={false}
                />
              </center>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HomeBanner;
