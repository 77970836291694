import React from "react";

// import { tabNames, hashParams } from "../../config/urls";
import DynamicLinkList from "../common/DynamicLinks";
import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./searchByRoom.css";

const SearchByRoom = ({ subData, currentHash }) => {
  function extractServiceTypeURL(childURL) {
    let arr = [];
    let path = "";
    arr = childURL?.split("/");
    for (let i = 0; i < arr?.length - 1; i++) {
      path += "/" + arr[i];
    }
    return path;
  }
  // useEffect(() => {
  //     setTimeout(() => {
  //         const name = document.getElementById(currentHash)
  //         if (window.screen.width > 500) {
  //             window.scrollTo({ top: name?.offsetTop - 286, left: 0, behavior: 'smooth' });
  //         } else {
  //             window.scrollTo({ top: name?.offsetTop - 272, left: 0, behavior: 'smooth' });
  //         }
  //     }, 500)
  // }, [])
  let t = 0;

  return (
    <div
      className="body-wrap"
      style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}
    >
      <section id="content" className="content-section twelve">
        <div className="content-wrap" style={{ marginBottom: "-40px" }}>
          <div className="tabb-content-wrap row">
            <div className="services-container">
              <div id={currentHash} className="tab-content row current">
                {subData?.Page
                  ? subData?.Page?.map((servTypes) => {
                      // changed ? to &&
                      // undid this change
                      return Array.isArray(servTypes?.Page) ? (
                        <div className="col-sd-12 col-md-6 colm-dd-4">
                          <h4 className="services-name">
                            <DynamicLinkList
                              pagePath={`/${servTypes?.URL}`}
                              pageApiUrl={servTypes?.URL}
                              pageName={servTypes?.Name}
                              basePageURL={"/services"}
                            />
                            {/* <a href={`/${servTypes?.URL}`}>{servTypes?.Name}</a> */}
                          </h4>
                          <ul className="accent-sq">
                            {servTypes?.Page.length > 0 &&
                              servTypes?.Page?.map((servDetails) => {
                                return (
                                  <li className="service-list-item">
                                    {/* <DynamicLinkList
                                      pagePath={`/${servDetails?.URL.replace(
                                        /\/(?=[^\/]*$)/,
                                        "#"
                                      )}`}
                                      pageApiUrl={servDetails?.URL.replace(
                                        /\/(?=[^\/]*$)/,
                                        "#"
                                      )}
                                      pageName={servDetails?.Name}
                                      basePageURL={"/services"}
                                    /> */}

                                    {servDetails?.Name}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      ) : (
                        // commented this else condition so that service type doesn't display if there's no
                        // subservices set for it
                        // undid this comment
                        <div className="col-sd-12 col-md-6 colm-dd-4">
                          <h4 className="services-name">
                            <DynamicLinkList
                              pagePath={`/${servTypes?.URL}`}
                              pageApiUrl={servTypes?.URL}
                              pageName={servTypes?.Name}
                              basePageURL={"/services"}
                            />
                            {/* <a href={`/${servTypes?.URL}`}>{servTypes?.Name}</a> */}
                          </h4>
                          <ul className="accent-sq">
                            <li className="service-list-item">
                              {/* <DynamicLinkList
                                pagePath={`/${servTypes?.Page?.URL.replace(
                                  /\/(?=[^\/]*$)/,
                                  "#"
                                )}`}
                                pageApiUrl={servTypes?.Page?.URL.replace(
                                  /\/(?=[^\/]*$)/,
                                  "#"
                                )}
                                pageName={servTypes?.Page?.Name}
                                basePageURL={"/services"}
                              /> */}
                              {servTypes?.Page?.Name}
                              {/* <a href={`/${servTypes?.Page?.URL.replace(/\/(?=[^\/]*$)/, '#')}`}> {servTypes?.Page?.Name}</a> */}
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  : subData?.map((servTypes) => {
                      return Array.isArray(servTypes?.Page) ? (
                        <div className="col-sd-12 col-md-6 colm-dd-4">
                          <h4 className="services-name">
                            <DynamicLinkList
                              pagePath={extractServiceTypeURL(
                                servTypes?.Page[0]?.URL
                              )}
                              pageApiUrl={extractServiceTypeURL(
                                servTypes?.Page[0]?.URL
                              )}
                              pageName={servTypes?.Title}
                              basePageURL={"/services"}
                            />
                            {/* <a href={`/${servTypes?.URL}`}>{servTypes?.Name}</a> */}
                          </h4>
                          <ul className="accent-sq">
                            {servTypes?.Page.length > 0 &&
                              servTypes?.Page?.map((servDetails) => {
                                return (
                                  <li className="service-list-item">
                                    {/* <DynamicLinkList
                                      pagePath={`/${servDetails?.URL.replace(
                                        /\/(?=[^\/]*$)/,
                                        "#"
                                      )}`}
                                      pageApiUrl={servDetails?.URL.replace(
                                        /\/(?=[^\/]*$)/,
                                        "#"
                                      )}
                                      pageName={servDetails?.Name}
                                      basePageURL={"/services"}
                                    /> */}
                                    {servDetails?.Name}
                                    {/* <a href={`/${servDetails?.URL.replace(/\/(?=[^\/]*$)/, '#')}`}> {servDetails?.Name}</a> */}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      ) : // if servType has a non-array Page object, then that means there is 1 subservice
                      servTypes?.Page ? (
                        <div className="col-sd-12 col-md-6 colm-dd-4">
                          <h4 className="services-name">
                            <DynamicLinkList
                              pagePath={extractServiceTypeURL(
                                servTypes?.Page?.URL
                              )}
                              pageApiUrl={extractServiceTypeURL(
                                servTypes?.Page?.URL
                              )}
                              pageName={servTypes?.Title}
                              basePageURL={"/services"}
                            />
                            {/* <a href={`/${servTypes?.URL}`}>{servTypes?.Name}</a> */}
                          </h4>
                          <ul className="accent-sq">
                            <li className="service-list-item">
                              {/* <DynamicLinkList
                                pagePath={`/${servTypes?.Page?.URL.replace(
                                  /\/(?=[^\/]*$)/,
                                  "#"
                                )}`}
                                pageApiUrl={servTypes?.Page?.URL.replace(
                                  /\/(?=[^\/]*$)/,
                                  "#"
                                )}
                                pageName={servTypes?.Page?.Name}
                                basePageURL={"/services"}
                              /> */}
                              {servTypes?.Page?.Name}
                              {/* the below was causing the same li to show again */}
                              {/* <a href={`/${servTypes?.Page?.URL.replace(/\/(?=[^\/]*$)/, '#')}`}> {servTypes?.Page?.Name}</a> */}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        // else the servType has no subservice, so don't display the service at all
                        <></>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SearchByRoom;
