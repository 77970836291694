import React from "react";
import { Link } from "react-router-dom";
import "./PreviewCard.css";

const PreviewCard = ({
  title,
  previewText,
  link,
  bookOnlineLink,
  button,
  res,
  isFranchiseBlog,
}) => {
  return (
    <div
      className={`preview-card ${
        isFranchiseBlog ? "franchise-preview-card" : ""
      }`}
    >
      <h3 className={isFranchiseBlog? "preview-title-franchise-blog preview-title" : "preview-title"}>
        <a href={isFranchiseBlog ? `/${link}` : link}>{title}</a>
      </h3>
      {!isFranchiseBlog && (
        <p>
          {previewText}...
          <a href={link}>Read More</a>
        </p>
      )}
    </div>
  );
};

export default PreviewCard;
