import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./BathroomRRBreadcrumbs.css";

const BathroomRRBreadcrumbs = ({ contentData }) => {
  return (
    <body className="bathroomrr-body">
      <div
        className="bathroomrrbreadcrumbs-body-wrap"
        style={{
          backgroundImage: bgPatternTriangles
            ? `url(${ingeniuxURL}${bgPatternTriangles})`
            : "none",
        }}
      >
        <section id="content" className="content-section twelve">
          <div className="breadcrumbs">
            <div className="br-container">
              <ul>
                {/* replaced Navigation?.Page with BreadcrumbNavigation */}
                {contentData?.ServicesDetail?.BreadcrumbNavigation?.map(
                  (breadCrum, i, row) => {
                    if (i + 1 === row.length) {
                      return (
                        // rendering all links except the last link
                        <li>
                          <span>{breadCrum?.Name}</span>
                        </li>
                      );
                    } else {
                      return (
                        <li>
                          <Link to={`/${breadCrum?.URL}`}>
                            {" "}
                            {breadCrum?.Name}
                          </Link>{" "}
                          {" | "}
                        </li>
                      );
                    }
                  }
                )}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};

export default BathroomRRBreadcrumbs;
