import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchHomePage } from "../../actions/fetchData";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import Offices from "./Offices";
import {
  getResultsArray,
  loadApiData,
  saveApiData,
} from "../../config/utility";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";

import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import PageLoader from "../common/PageLoader";

class FindACraftsman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const localOfficeResults = this?.props?.localOfficeItem;
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );

    this.props.dispatch(fetchHomePage("/offices"));

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
    this.setState({ loading: false });
  }
  render() {
    const localOffices = this?.props?.localOfficeItem;
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const homeItemData = getResultsArray(this.props?.homeItem, "OfficesIndex");
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");

    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;

    return (
      <>
        {this.state.loading === false && this?.props?.loading === false ? (
          <>
            <Helmet>
              <title>{`${pageTitle}`}</title>
              <meta
                name="description"
                content={homeItemData[0]?.Description?.Value}
              />
            </Helmet>
            <HeaderWrapper
              headerItemData={{
                ...headerItemData,
              }}
            />
            {/* office renders only whne the FranchiseIds are reday */}
            <Offices
              states={homeItemData[0]?.Navigation?.Page}
              ctaData={homeItemData[0]}
              localOffices={localOfficeResults}
              zipcodes={localOfficeZipcodes}
            />

            {homeItemData[0]?.Trays?.map((tray, index) => {
              if (
                Object.keys(corpDynamicTrayList)?.find(
                  (trayName) => trayName === tray?.TrayName
                )
              ) {
                let combinedProps;

                if (
                  tray?.TrayName === "ScheduleandEstimateTabTray" ||
                  tray?.TrayName === "ScheduleandEstimateTray"
                ) {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                      ...{ franchiseDataToGetEstimate },
                    },
                  };
                } else if (tray?.TrayName === "ServicesTray") {
                  combinedProps = {
                    ...{ ...tray, ...{ franchiseData: franchiseHomePage[0] } },
                  };
                } else if (tray?.TrayName === "FindAnOffice") {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                    },
                  };
                } else {
                  combinedProps = { ...tray };
                }

                return (
                  <CorpDynamicTrays
                    trayName={tray?.TrayName}
                    contentData={combinedProps}
                    key={index}
                  />
                );
              }
            })}
            <FooterWrapper headerItemData={headerItemData} />
          </>
        ) : (
          <PageLoader />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  franchiseData: state.serviceReducer.serviceItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default connect(mapStateToProps)(FindACraftsman);
