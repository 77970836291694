import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import FooterLinks from "./FooterLinks";
import Resources from "./Resources";
import Services from "./Services";
import Packages from "./Packages";
import BlogPage from "./BlogPage";
import FranchiseHomePage from "./FranchiseHomePage";
import FranchiseDetailPage from "./FranchiseDetailPage";
import FranchiseServiceCityDetailPage from "./FranchiseServiceCityDetailPage";
import LocalJSON from "./FranchiseCommon/LocalJSON";
import ServiceDetailPage from "./BathroomRefreshRepairPage";
import PackageDetailPage from "./PackageOptions";
import FAQsPage from "./FAQsPage";
import CareersPage from "./CareersPage";
import WhyChooseUsPage from "./WhyChooseUsPage";
import FindACraftsman from "./FindACraftsman";
import YourTeamPage from "./YourTeamPage";
import CommunityMattersPage from "./CommunityMattersPage";
import OurStoryPage from "./OurStoryPage";
import Partners from "./Partners";
import EstimateSubmissionPage from "./EstimateSubmissionPage";
import Page404 from "./page-404";
import FranchiseBlogs from "./BlogPage/FranchiseBlogs";

const loading = (
  <div style={{ "z-index": 999 }}>
    <p>loading...</p>
    <p>Please wait</p>
  </div>
);
class Main extends Component {
  render() {
    return (
      <>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" element={<Home />} />

            {/* Services */}
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/Services/:id" element={<ServiceDetailPage />} />

            <Route
              exact
              path="/acehandymandss/Services/:id/Doors"
              element={<ServiceDetailPage />}
            />
            <Route
              exact
              path="/Services/:id/Doors"
              element={<ServiceDetailPage />}
            />

            <Route
              exact
              path="/acehandymandss/Services/:id/Windows"
              element={<ServiceDetailPage />}
            />
            <Route
              exact
              path="/Services/:id/Windows"
              element={<ServiceDetailPage />}
            />

            {/* Packages */}
            <Route exact path="/Packages" element={<Packages />} />

            <Route
              exact
              path="/Packages/:id/"
              element={<PackageDetailPage />}
            />

            {/* Others */}
            <Route exact path="/FindACraftsman" element={<FindACraftsman />} />
            <Route exact path="/offices" element={<FindACraftsman />} />
            {/* route to FranchiseHomePage from FindACraftsman*/}

            <Route exact path="/offices/:id" element={<FranchiseHomePage />} />
            <Route
              exact
              path="/offices/:id/contact_success"
              element={<EstimateSubmissionPage />}
            />
            <Route
              exact
              path="/offices/:id/city/:cityid"
              element={<FranchiseServiceCityDetailPage />}
            />
            <Route
              exact
              path="/offices/:id/:franchiseDetailPage/"
              element={<FranchiseDetailPage />}
            />
            <Route
              exact
              path="/offices/:id/careers"
              element={<FranchiseDetailPage />}
            />
            <Route
              exact
              path="/offices/:franchiseName/blog/:blogid"
              element={<FranchiseBlogs />}
            />

            <Route
              exact
              path="/offices/:id/services/:pageid"
              element={<FranchiseDetailPage />}
            />
            <Route
              exact
              path="/offices/:id/promotions/:pageid"
              element={<FranchiseDetailPage />}
            />
            <Route
              exact
              path="/offices/:id/campaign/:campaignid"
              element={<FranchiseDetailPage />}
            />
            <Route
              exact
              path="/offices/:id/campaign/:campaignId"
              element={<FranchiseDetailPage />}
            ></Route>
            <Route
              exact
              path="/offices/:id/local.json"
              element={<LocalJSON />}
            />
            <Route
              exact
              path="/acehandymandss/FindACraftsman"
              element={<FindACraftsman />}
            />

            {/* Blog Page */}
            <Route exact path="/blog" element={<BlogPage />} />
            <Route exact path="/blog/page/:id" element={<BlogPage />} />

            <Route exact path="/blog/search/:id" element={<BlogPage />} />
            <Route
              exact
              path="/blog/page/:id/search/:sid"
              element={<BlogPage />}
            />

            <Route exact path="/blog/category/:id" element={<BlogPage />} />
            <Route
              exact
              path="/blog/category/:id/page/:pid"
              element={<BlogPage />}
            />

            <Route exact path="/blog/author/:id" element={<BlogPage />} />
            <Route
              exact
              path="/blog/author/:id/page/:pid"
              element={<BlogPage />}
            />

            <Route exact path="/blog/year/:id" element={<BlogPage />} />
            <Route
              exact
              path="/blog/year/:id/page/:pid"
              element={<BlogPage />}
            />

            <Route exact path="/blog/:id" element={<BlogPage />} />

            {/*Franchise blogs */}
            <Route
              exact
              path="/offices/:franchiseName/blog"
              element={<FranchiseBlogs />}
            />
            <Route
              exact
              path="/offices/:franchiseName/blog/page/:pageid"
              element={<FranchiseBlogs />}
            />

            {/* Career */}
            <Route
              exact
              path="/careers"
              element={<CareersPage link={"/careers"} />}
            />

            <Route exact path="/why-choose-us" element={<WhyChooseUsPage />} />
            <Route exact path="/who-we-are" element={<WhyChooseUsPage />} />

            <Route
              exact
              path="/WhyChooseUs/MeetTheTeam"
              element={<YourTeamPage link={"/WhyChooseUs/MeetTheTeam"} />}
            />
            <Route
              exact
              path="/WhyChooseUs/CommunityMatters"
              element={
                <CommunityMattersPage link={"/WhyChooseUs/CommunityMatters"} />
              }
            />
            <Route
              exact
              path="/WhyChooseUs/OurStory"
              element={<OurStoryPage link={"/WhyChooseUs/OurStory"} />}
            />

            {/* Resources */}
            <Route exact path="/resources" element={<Resources />} />

            {/* Footer Resources */}
            <Route exact path="/Resources/:id" element={<FooterLinks />} />
            <Route exact path="/Resources/FAQs" element={<FAQsPage />} />
            <Route exact path="/Resources/Partners" element={<Partners />} />

            <Route
              exact
              path="/contact_success"
              element={<EstimateSubmissionPage />}
            />

            {/*Footer Links */}
            <Route
              exact
              path="/WhyChooseUs/Goals"
              element={<FooterLinks link={"/WhyChooseUs/Goals"} />}
            />
            <Route exact path="/Resources/:id" element={<FooterLinks />} />
            <Route exact path="*" element={<Page404 />} />
          </Routes>
        </Suspense>
      </>
    );
  }
}

export default Main;
