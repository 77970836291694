import React from "react";

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./FAQBanner.css";

const FAQBanner = ({ contentData }) => {
  return (
    <body className="faq-body">
      <div className="faqsbanner-body-wrap" style={{ backgroundImage: bgPatternTriangles  ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
        <section id="banner">
          <div className="banner-slide faqs">
            <div className="container">
              <div className="banner-text">
                <h1 className="header">
                  {contentData?.DetailPage?.Heading?.Value}
                </h1>
              </div>
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};

export default FAQBanner;
