import React from "react";

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./YourTeamBanner.css";

const YourTeamBanner = ({heading}) => {
    return (
        <body className="meet-the-team-banner">
            <div className="body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
                <section id="banner">
                    <div className="banner-slide meet-the-team">
                        <div className="team-container">
                            <div className="banner-text">
                                <h1 className="header">{heading?.Value}</h1>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </body >
    );
};

export default YourTeamBanner;