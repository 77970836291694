import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

import "./FranchiseEstimateSubmissionBreadcrumbs.css";

export const FranchiseEstimateSubmissionBreadcrumbs = ({ breadcrumbData }) => {
    const filteredArray = breadcrumbData?.filter(breadCrumb => breadCrumb?.Schema !== "Folder" && breadCrumb?.Schema !== "PageFolder");
    return (
        <div className="franchise-estimate-submission-breadcrumb">
            <div className="breadcrumb-container">
                <ul>
                    {filteredArray?.map((breadCrumb, index) => {
                        return (
                            <li key={index}>
                {index + 1 === 1 && breadCrumb?.Name !== "Home" && (
                  <Link to={`/`}>Home {"|"}</Link>
                )}
                {index + 1 === filteredArray?.length ? (
                  <Link to={`/${breadCrumb?.URL}`}>ACE HARDWARE PAINTING SERVICES {breadCrumb?.Name}</Link>) : ""}
                {index + 1 === filteredArray?.length ? " | Contact Us" : ""}          
            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default FranchiseEstimateSubmissionBreadcrumbs