import React from 'react';
import Collapsible from "react-collapsible";

import { BsChevronDown } from "react-icons/bs"; //react-icon

import "./popular-services.css";
import { ingeniuxURL } from '../../config/urls'

const PopularServices = ({ contentData }) => {
    return (
        <div className="most-popular-services row" style={{ position: 'static' }}>
            <div className="most-popular-services-container">
                <div className="heading-wrap toggle-heading notiphone">
                    <div className="heading">

                        {contentData?.Heading?.Value}

                    </div>
                    <span className="fa fa-angle-down arrow"></span>
                </div>
                <div className="services content notiphone">

                    {
                        Array?.isArray(contentData?.ImageLink) ?
                            contentData?.ImageLink?.map(child => {
                                return <div className="item col-sd-6">
                                    <a href={`/${child?.Page?.URL}`}>
                                        {child?.Image?.Value && <img
                                            src={`${ingeniuxURL}${child?.Image?.Value}`}
                                            alt={child?.Image?.AlternateText ? child?.Image?.AlternateText : ""}
                                            title={child?.Image?.AlternateText ? child?.Image?.AlternateText : ""}
                                            width="120" height="70"
                                        />}

                                        <span>
                                            {/* {child?.Caption?.Value} */}
                                            {/* caption values seem to be deleted so switched to
                                                child?.Page?.Link for now
                                            */}
                                            {child?.Page?.Link}
                                        </span>
                                    </a>
                                </div>
                            })
                            :
                            <div className="item col-sd-6">
                                <a href={`${contentData?.ImageLink?.Page?.URL}`}>
                                    {contentData?.ImageLink?.Image?.Value && <img
                                        src={`${ingeniuxURL}${contentData?.ImageLink?.Image?.Value}`}
                                        alt={contentData?.ImageLink?.Image?.AlternateText ? contentData?.ImageLink?.Image?.AlternateText : ""}
                                        title={contentData?.ImageLink?.Image?.AlternateText ? contentData?.ImageLink?.Image?.AlternateText : ""}
                                    />}

                                    <span>
                                        {/* {contentData?.ImageLink?.Caption?.Value} */}
                                        {/* caption values seem to be deleted so switched to
                                                contentData?.ImageLink?.Page?.Link for now
                                            */}
                                        {contentData?.ImageLink?.Page?.Link}
                                    </span>
                                </a>
                            </div>
                    }

                </div>

                <Collapsible
                    trigger={[<div className="heading-wrap toggle-heading iphoneview" style={{ marginLeft: '20%' }}><div className="heading">{contentData?.Heading?.Value}</div>
                    </div>,
                    // removed marginLeft:"57px" in the span style below to fix most popular services mobile view heading
                    <span style={{ color: "#fff", fontSize: "22px", marginLeft: "-57px" }}><BsChevronDown className="iphoneview" /></span>]}
                >
                    <div className="services content iphoneview">
                        {
                            Array?.isArray(contentData?.ImageLink) ?
                                contentData?.ImageLink?.map((child, index) => {
                                    // added padding to make items look less cramped
                                    return <div className="item col-sd-6" key={index} style={{ padding: '10px' }}>
                                        <a href={`${child?.Page?.URL}`}>
                                            {child?.Image?.Value && <img
                                                src={`${ingeniuxURL}${child?.Image?.Value}`}
                                                alt={child?.Image?.AlternateText ? child?.Image?.AlternateText : ""}
                                                title={child?.Image?.AlternateText ? child?.Image?.AlternateText : ""}
                                                width="120" height="70"
                                            />}

                                            <span>
                                                {/* {child?.Caption?.Value} */}
                                                {/* caption values seem to be deleted so switched to
                                                child?.Page?.Link for now
                                            */}
                                                {child?.Page?.Link}
                                            </span>
                                        </a>
                                    </div>
                                })
                                :
                                <div className="item col-sd-6">
                                    <a href={`${contentData?.ImageLink?.Page?.URL}`}>
                                        {contentData?.ImageLink?.Image?.Value && <img
                                            src={`${ingeniuxURL}${contentData?.ImageLink?.Image?.Value}`}
                                            alt={contentData?.ImageLink?.Image?.AlternateText ? contentData?.ImageLink?.Image?.AlternateText : ""}
                                            title={contentData?.ImageLink?.Image?.AlternateText ? contentData?.ImageLink?.Image?.AlternateText : ""}
                                            width="120" height="70"
                                        />}

                                        <span>
                                            {/* {contentData?.ImageLink?.Caption?.Value} */}
                                            {/* caption values seem to be deleted so switched to
                                                contentData?.ImageLink?.Page?.Link for now
                                            */}
                                            {contentData?.ImageLink?.Page?.Link}
                                        </span>
                                    </a>
                                </div>
                        }

                    </div>
                </Collapsible>
            </div>
        </div >
    );
};

export default PopularServices;