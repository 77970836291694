import React from "react";
import ServicesTrayWithoutTab from "../../FranchiseCommon/ServicePackages/ServicesPackages";
import ServicesTrayWithTab from "./CorpServicesTray";

const ServicesTrayMaster = ({ contentData }) => {
  return (
    <>
      {contentData?.AddTabs?.Value === "True" ? (
        <ServicesTrayWithTab contentData={contentData} />
      ) : (
        <ServicesTrayWithoutTab contentData={contentData} />
      )}
    </>
  );
};

export default ServicesTrayMaster;
