import React from 'react'
import "./homeDetails.css";
import { ingeniuxURL } from "../../config/urls";

export const EmbedVideo = ({ iframeContent }) => {
    return (<div dangerouslySetInnerHTML={{ __html: iframeContent?.Value ? iframeContent?.Value : "" }} />);
}

export const WhyChooseUs = ({ contentData }) => {
    return (
        <>
            <div id="newsweek">
                <div className="text">
                    {contentData?.LeadIn?.Value}
                </div>
            </div>
            <h3 className="reasons-heading">{contentData?.Heading?.Value}</h3>
            <ul className="reasons">
                {contentData?.IconWithLabel?.map((iconData, index) => {

                    return (
                        <li className="col-sd-12" key={index}>
                            {iconData?.Icon?.Value && <img
                                src={`${ingeniuxURL}${iconData?.Icon?.Value}`}
                                alt={iconData?.Icon?.AlternateText}
                                title={iconData?.Icon?.AlternateText}
                                width="92"
                                height="90"
                            />}
                            <span>{iconData?.Caption?.Value}</span>
                        </li>
                    )
                })}

            </ul>
        </>

    )
}

export default WhyChooseUs