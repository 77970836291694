import { useRef, useEffect } from "react";

const useClickOutside = (callBackHandler) => {
  let elementNode = useRef();
  useEffect(() => {
    let clickOutsideHandler = (event) => {
      if (!elementNode.current.contains(event.target)) {
        callBackHandler();
      }
    };
    document.addEventListener("mousedown", clickOutsideHandler);
    return () => {
      document.removeEventListener("mousedown", clickOutsideHandler);
    };
  }, [callBackHandler, elementNode]);
  return elementNode;
};

export default useClickOutside;
