import React from "react";

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./KitchenFSPBanner.css";

const KitchenFSPBanner = ({ contentData }) => {
  return (
    <div className="kitchen-body">
      <div className="kitchenfspbanner-body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
        <section id="banner">
          <div
            className="banner-slide kitchen_fire_safety_package"
            style={{
              "backgroundImage": `url(${ingeniuxURL}${contentData?.PackageDetail?.BannerImage?.Value})`,
            }}
          >
            <div className="container">
              <div className="banner-heading-container">
                <div className="paintstroke-left" style={{
                  backgroundImage: `url(${ingeniuxURL}${contentData?.PackageDetail?.LeftCapPaintStroke?.Value})`
                }}></div>
                <h1 className="header" style={{
                  backgroundImage: `url(${ingeniuxURL}${contentData?.PackageDetail?.MiddlePaintStroke?.Value})`
                }}>
                  {contentData?.PackageDetail?.Title?.Value}
                </h1>
                <div className="paintstroke-right" style={{
                  backgroundImage: `url(${ingeniuxURL}${contentData?.PackageDetail?.RightCapPaintStroke?.Value})`
                }}></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default KitchenFSPBanner;
