import React, { useState } from "react";
import { Markup } from "interweave";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./index.css";
import { jotFormURL } from "../../config/urls";
import {
  regxEmail,
  regxUSPhone,
  regxUSZipcode,
  aceBusinessName,
  multipartFormHeader,
  readCookieValue,
  extractPath,
  getFranchiseDataFromLS,
  storeFranchiseDataToLS,
  deleteItemFromLS,
  generateUniqueId,
} from "../../config/utility";
import LocalOfficeNonExistent from "../common/LocalOfficeNonExistent";
import {
  FORM_START_GET_AN_ESTIMATE,
  FORM_SUBMIT,
  trackAnalytics,
} from "../../config/gTrack";
import "../common/getEstimate.css";

const GetEstimateJotFrom = ({
  viewPortWidth = "",
  franchiseDataToGetEstimate: { franchiseId, franchiseName, stateAbbr } = {},
  contentData: estimateData,
  currentTab,
  handymanmatters,
  isNewFranchise,
  newFranchiseHeader,
  newFranchiseSubheading,
  successModalTriggeringFunc,
}) => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const initStatePrjImgs = {
    images: {
      q8_picture1: "",
      q10_picture2: "",
      q11_picture3: "",
    },
    errors: "",
  };
  const initStateYotrackData = {
    formID: "230454322493250",
    website: "",
    simple_spc: "230454322493250-230454322493250",
    q12_typeA: "",
    q13_send_to: "",
    q14_from_page: "",
    q15_business_name: "",
    q17_territory: "not-swapped",
    q19_uid: "",
    q20_freeColor:
      currentTab === "GET A FREE COLOR CONSULTATION" ? true : false,
    event_id: "",
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [emailValue, setEmailValue] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [zipcodeValue, setZipcodeValue] = useState("");
  const [isValidZipcode, setIsValidZipcode] = useState(true);
  const [zipLenErr, setZipLenErr] = useState(false);
  const [fullnameValue, setFullnameValue] = useState("");
  const [isPopulatedFullname, setIsPopulatedFullname] = useState(true);
  const [projectValue, setProjectValue] = useState("");
  const [isPopulatedProject, setIsPopulatedProject] = useState(true);
  const [isErrorFree, setIsErrorFree] = useState(true);
  const [projectImg, setProjectImg] = useState(initStatePrjImgs);
  const [projectImgPreview, setProjectImgPreview] = useState(initStatePrjImgs);
  const [yotrackData, setYotrackData] = useState(initStateYotrackData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isServiceDown, setIsServiceDown] = useState(false);
  const [serviceDownMessage, setServiceDownMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formStart, setFormStart] = useState(false);
  const [matchLoc, setMatchLoc] = useState({ locUrl: "", locName: "" });

  const handymanId = process.env.REACT_APP_ACEPAINTID;
  const corpClientId = process.env.REACT_APP_CORP_CLIENTID;
  const corpEmailId = process.env.REACT_APP_JOTFORM_EMAIL;
  const corpSiteTrackId = process.env.REACT_APP_CORP_SITETRACKINGID;

  const getFormSubmissionLocationDetails = () => {
    let origin = !franchiseName ? matchLoc.locName : franchiseName;
    let url = `${pathname}${hash}`;
    if (Boolean(origin)) {
      return { locationName: origin, url };
    }
    return { locationName: "Corporate", url };
  };

  const trackFormStartStatusForGA = (e) => {
    if (!formStart) {
      setFormStart(true);
      e.stopPropagation();
      const { locationName, url } = getFormSubmissionLocationDetails();
      trackAnalytics(FORM_START_GET_AN_ESTIMATE, locationName, url);
    }
  };

  const handleFullnameChange = (e) => {
    setFullnameValue(e.target.value);
    trackFormStartStatusForGA(e);
  };

  const handleFullnameFocus = (e) => {
    const fullName = e.target;
    const label = document.querySelector('label[for="' + fullName.id + '"]');
    if (!fullName.value || fullName.value === label.innerHTML) {
      setIsPopulatedFullname(true);
      setFullnameValue("");
    }
  };

  const formatPhoneWithBracket = (phone) => {
    const x = phone?.replace(/\D/g, "");
    if (x.length > 3 && x.length < 7) {
      return "(" + x.slice(0, 3) + ") " + x.slice(3);
    } else if (x.length >= 7) {
      return "(" + x.slice(0, 3) + ") " + x.slice(3, 6) + "-" + x.slice(6);
    }
    return x;
  };

  const handlePhoneChange = (e) => {
    let formattedValue = regxUSPhone.test(e.target.value)
      ? e.target.value
      : formatPhoneWithBracket(e.target.value);

    if (formattedValue.length > 14) {
      formattedValue = formattedValue.slice(0, 14);
    }
    if (!isValidPhone) {
      setIsValidPhone(true);
    }
    setPhoneValue(formattedValue);
    trackFormStartStatusForGA(e);
  };

  const handlePhoneFocus = (e) => {
    const phoneVal = e.target;
    const label = document.querySelector('label[for="' + phoneVal.id + '"]');
    if (!phoneVal.value || phoneVal.value === label.innerHTML) {
      setIsValidPhone(true);
      setPhoneValue("");
    }
  };

  const handleEmailChange = (e) => {
    if (!isValidEmail) {
      setIsValidEmail(true);
    }
    setEmailValue(e.target.value);
    trackFormStartStatusForGA(e);
  };
  const handleEmailFocus = (e) => {
    const emailVal = e.target;
    const label = document.querySelector('label[for="' + emailVal.id + '"]');

    if (!emailVal.value || emailVal.value === label.innerHTML) {
      setIsValidEmail(true);
      setEmailValue("");
    }
  };

  var matchingZipcode = "";

  const handleZipcodeChange = (e) => {
    e.preventDefault();

    let isValidZip;
    const zipVal = e.target.value;
    setZipcodeValue(zipVal);
    trackFormStartStatusForGA(e);
    isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipVal);

    if (!isValidZip || zipVal === "") {
      setZipLenErr(false);
    }
    for (let i = 0; i < estimateData?.zipCodeList?.length; i++) {
      for (let j = 0; j < estimateData?.zipCodeList[i]?.length; j++) {
        if (zipVal === estimateData?.zipCodeList[i][j]) {
          matchingZipcode = zipVal;
        }
      }
    }

    if (matchingZipcode !== "") {
      setShowModal(false);
    } else if (matchingZipcode === "" && isValidZip) {
      setShowModal(true);
      // boolean set to true to show modal
    }

    if (!zipVal) setZipLenErr(false);
    else if (zipVal?.length === 5 && isValidZipcode) {
      //checking the LocalOffices array contains any match with user entered ZIPCODE
      const locationMatch = estimateData?.localOfficeList?.filter(
        (office) =>
          office?.OwnedZipcodes?.some((zip) => zip === zipVal) ||
          office?.AllowedZipcodes?.some((zip) => zip === zipVal)
      );

      const zipLocationMatch =
        locationMatch && locationMatch[0]?.HomePageLink?.URL;
      const zipLocName = locationMatch && locationMatch[0]?.FranchiseName;
      zipLocationMatch &&
        setMatchLoc((prevState) => ({
          ...prevState,
          locUrl: zipLocationMatch,
          locName: zipLocName,
        }));
      if (zipLocationMatch)
        sessionStorage.setItem("locationMatch", zipLocationMatch);
      else sessionStorage.setItem("locationMatch", zipLocationMatch);
      //deleting the recent submission data in the case of no location match scenario
      if (zipLocationMatch)
        storeFranchiseDataToLS(
          "recentEstimateSubmissionPath",
          `/${zipLocationMatch}`
        );
      else deleteItemFromLS("recentEstimateSubmissionPath");

      const franchiseClientId = locationMatch && locationMatch[0]?.ClientId;

      //siteTrackingId available as part of localoffice api, assigning to
      //siteTrackId from the matched location
      const locSiteTrackId = locationMatch && locationMatch[0]?.SiteTrackingId;
      const siteTrackId = locSiteTrackId ? locSiteTrackId : corpSiteTrackId;
      if (siteTrackId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q14_from_page: siteTrackId,
        }));
      }

      //siteId either FranchiseClientId or corpId
      let siteId;
      if (franchiseClientId) {
        siteId = franchiseClientId;
      } else siteId = corpClientId;

      //YoTrack script added in the index.html, hence accessible only vis window object.
      window.YoTrack(handymanId, siteId, function (err, api) {
        // const yoState = yoTrack.getYoState();
        console.log("err", err, "api", api);
      });

      if (locationMatch?.length > 0 && franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: "",
          q15_business_name: `${
            locationMatch[0]?.FranchiseName?.includes("Ace")
              ? locationMatch[0]?.FranchiseName
              : `${aceBusinessName} ${locationMatch[0]?.FranchiseName}`
          }, ${locationMatch[0]?.StateAbbreviation}`,
          q17_territory: extractPath(
            locationMatch[0]?.HomePageLink?.URL
          )?.replace("/", ""),
        }));
      } else if (locationMatch?.length === 0 && franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: "",
          q15_business_name: `${
            franchiseName?.includes("Ace")
              ? franchiseName
              : `${aceBusinessName} ${franchiseName}`
          }, ${stateAbbr}`,
          q17_territory: "no-location-returned",
        }));
      } else if (locationMatch?.length > 0 && !franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: corpEmailId,
          q15_business_name: `${
            locationMatch[0]?.FranchiseName?.includes("Ace")
              ? locationMatch[0]?.FranchiseName
              : `${aceBusinessName} ${locationMatch[0]?.FranchiseName}`
          }, ${locationMatch[0]?.StateAbbreviation}`,
          q17_territory: extractPath(
            locationMatch[0]?.HomePageLink?.URL
          )?.replace("/", ""),
        }));
      } else if (locationMatch?.length === 0 && !franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: corpEmailId,
          q15_business_name: aceBusinessName,
          q17_territory: "fma-loc-not-found",
        }));
      }
      setZipLenErr(false);
    } else {
      setZipLenErr(true);
    }
  };

  const handleZipcodeFocus = (e) => {
    const zipVal = e.target;
    const label = document.querySelector('label[for="' + zipVal.id + '"]');
    if (!zipVal.value || zipVal.value === label.innerHTML) {
      setIsValidZipcode(true);
      setZipcodeValue("");
    }
  };

  const handleProjectChange = (e) => {
    setProjectValue(e.target.value);
    //reading cookie's UUID and updating the yoTrack data
    const uidCookieValue = readCookieValue("uid");

    setYotrackData((prevState) => ({
      ...prevState,
      q13_send_to: pathname,
      q19_uid: uidCookieValue ? uidCookieValue : "",
    }));
    trackFormStartStatusForGA(e);
  };
  const handleProjectFocus = (e) => {
    const prjVal = e.target;
    const label = document.querySelector('label[for="' + prjVal.id + '"]');
    if (!prjVal.value || prjVal.value === label.innerHTML) {
      setIsPopulatedProject(true);
      setProjectValue("");
    }
  };

  const handleReadURL = (e, prjImg) => {
    const input = e.target;

    if (input.files && input.files[0]) {
      if (input.files[0].size / 1024 > 20000) {
        setProjectImg((prevState) => ({
          ...prevState,
          errors:
            "Selected image is more than 20 MB, please choose a less sized image file.",
        }));
        return;
      }

      setProjectImg((prevState) => ({
        ...prevState,
        images: {
          ...prevState.images,
          [prjImg]: input.files[0],
        },
        errors: "",
      }));

      const reader = new FileReader();
      reader.readAsDataURL(input.files[0]);
      reader.onloadend = () => {
        if (reader.readyState === 2) {
          setProjectImgPreview((prevState) => ({
            ...prevState,
            images: {
              ...prevState.images,
              [prjImg]: reader.result,
            },
            errors: "",
          }));
        }
      };
    }
  };

  //GA Form Submit tracker
  const handleWindowTracker = (e, gaEventName, franchiseInfo, pageUrl) => {
    let origin = !franchiseInfo ? matchLoc.locName : franchiseInfo;
    let url = `${pageUrl}${hash}`;
    e.stopPropagation();
    trackAnalytics(gaEventName, origin, url);
  };

  const handleSubmit = (e) => {
    // prevent page from refreshing
    e.preventDefault();
    setIsServiceDown(false);
    setServiceDownMessage("");
    const isValidPhone = regxUSPhone.test(phoneValue);
    const isValidEmail = regxEmail.test(emailValue);
    const isValidZipcode = regxUSZipcode.test(zipcodeValue);
    if (!isValidPhone || phoneValue === "") {
      setIsValidPhone(false);
      // setPhoneValue("Phone Number");
    }
    if (!isValidEmail || emailValue === "") {
      setIsValidEmail(false);
      // setEmailValue("Email Address");
    }
    if (!isValidZipcode || zipcodeValue === "") {
      setIsValidZipcode(false);
      setZipcodeValue("Zip Code");
    }
    if (!fullnameValue || fullnameValue === "") {
      setIsPopulatedFullname(false);
      setFullnameValue("Full Name");
    }
    if (projectValue === "") {
      setIsPopulatedProject(false);
      setProjectValue("Tell us about your project");
    }
    if (
      isValidPhone === false ||
      isValidEmail === false ||
      isValidZipcode === false ||
      isPopulatedFullname === false ||
      isPopulatedProject === false
    ) {
      setIsErrorFree(false);
    } else {
      setIsErrorFree(true);

      const jotFormPayloadData = {
        q3_fullName: fullnameValue,
        q4_phoneNumber: phoneValue,
        q5_emailAddress: emailValue,
        q6_zipCode: zipcodeValue,
        q7_tellUs: projectValue,
        ...yotrackData,
        ...projectImg.images,
      };
      const formData = new FormData();
      Object.keys(jotFormPayloadData).forEach((key) =>
        formData.append(key, jotFormPayloadData[key])
      );

      setIsSubmitting(true);
      setIsDisabled(true);
      //GA form submit tracker
      handleWindowTracker(e, FORM_SUBMIT, franchiseName, pathname);
      axios
        .post(jotFormURL, formData, multipartFormHeader)
        .then((res) => {
          // changed !== to === because if it's equal to 200, that means the response is a success
          if (res?.request?.status === 200) {
            //forming the centermark url from the script response.
            //response format as
            //<script>try { top.location.href = 'https://ybnblackops.com/clients/ahs/s-v1.php?efn=....;}
            //catch (e) { window.location.href = 'https://ybnblackops.com/clients/ahs/s-v1.php?efn=
            //; };</script>

            //split the response at ';' to get the first url in the try block;
            //regex to get the content between single quotes, and takes the first match
            const splitUrl = res?.data && res?.data?.split(";")[0];
            const regxSingleQuote = /\'(.*)\'/i;
            //removing the single quotes in string to submit to axios,
            //otherwise the current browser url will get appended to the centermark url and
            //request fails
            const centerMarkUrl = splitUrl
              .match(regxSingleQuote)[0]
              ?.replace(/['']/g, "");

            //console.log('centerMarkUrl.....', centerMarkUrl);
            axios
              .get(centerMarkUrl)
              .then((res) => {
                if (res?.request?.status === 200) {
                  const leadCorrelationRes = res && JSON.parse(res?.data);

                  //redirection to contact success page
                  const recentFranchisePath = getFranchiseDataFromLS(
                    "recentFranchisePath"
                  );
                  const recentEstimateSubmissionPath = getFranchiseDataFromLS(
                    "recentEstimateSubmissionPath"
                  );

                  if (
                    matchLoc.locUrl &&
                    recentFranchisePath &&
                    !isNewFranchise
                  ) {
                    if (recentFranchisePath === recentEstimateSubmissionPath) {
                      const rgxforSlashes = /^\/|\/$/g;
                      const franchisePath = recentFranchisePath?.replace(
                        rgxforSlashes,
                        ""
                      );
                      navigate(
                        `/${franchisePath}/contact_success?yodle_formThankYou=${leadCorrelationRes?.leadCorrelatorId}`
                      );
                    } else if (
                      recentFranchisePath !== recentEstimateSubmissionPath
                    ) {
                      const rgxforSlashes = /^\/|\/$/g;
                      const franchisePath =
                        recentEstimateSubmissionPath?.replace(
                          rgxforSlashes,
                          ""
                        );
                      navigate(
                        `/${franchisePath}/contact_success?yodle_formThankYou=${leadCorrelationRes?.leadCorrelatorId}`
                      );
                    }
                  } else if (isNewFranchise) {
                    successModalTriggeringFunc(true);
                  } else {
                    navigate(
                      `/contact_success?yodle_formThankYou=${leadCorrelationRes?.leadCorrelatorId}`
                    );
                  }
                } else {
                  console.log("Redirection failed");
                }
              })
              .catch((err) => console.log(err));
            setTimeout(() => setIsSubmitting(false), 2000);
          } else {
            setIsSubmitting(false);
            setIsServiceDown(true);
            setIsDisabled(true);
            setServiceDownMessage(
              "Service Unavailable. Please try again later"
            );
          }
        })
        .catch((err) => {
          setIsDisabled(true);
          setIsSubmitting(false);
          console.log("AXIOS ERROR: ", err);
          setIsServiceDown(true);
          setServiceDownMessage(
            "oops, something went wrong, Please try again later"
          );
        });
    }
  };
  const submitButtonId = generateUniqueId("submit-btn");
  return (
    <>
      <div
        className={
          isNewFranchise
            ? "new-Franchise-Content col-md-12 col-dd-6 content"
            : "col-md-12 col-dd-6 content"
        }
      >
        <div className="get-quote-wrap">
          <div className="form" id="quote-form">
            <h2 className={isNewFranchise ? "estimate-header" : ""}>
              {isNewFranchise
                ? newFranchiseHeader
                : currentTab === "GET A FREE COLOR CONSULTATION"
                ? "Get A Free Color Consultation"
                : estimateData?.DemographicsFormHeading?.Value}
            </h2>
            {isNewFranchise && (
              <p className="subheading">{newFranchiseSubheading}</p>
            )}
            <div id="field-wrap" className="col-sd-12 col-md-12 col-dd-12">
              <form
                className="jotform-form photo-quote-form"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
                name="form_91254806635157"
              >
                <div role="main" className="form-all">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: viewPortWidth ? "column" : "row",
                    }}
                    className={`${
                      viewPortWidth ? "" : "jot-form-group-margin"
                    } ${isNewFranchise ? "new-franchise-main-div" : ""}`}
                  >
                    <div
                      className={`col-sd-12  col-md-6 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${isNewFranchise ? "input-fix-width" : ""}`}
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_3"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_3"
                          htmlFor="input_3"
                        >
                          Full Name
                        </label>
                        <div
                          id="cid_3"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedFullname ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_3"
                            name="q3_fullName"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required]"
                                : "form-textbox validate[required]"
                            }
                            size="20"
                            placeholder="Full Name"
                            area-label="Full Name"
                            data-component="textbox"
                            onChange={handleFullnameChange}
                            onFocus={handleFullnameFocus}
                            value={fullnameValue}
                          />
                          <p
                            className={`${
                              isPopulatedFullname ? "hideField" : ""
                            }`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                              margin: viewPortWidth ? "0 0 10px 0" : "0",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`col-sd-12  col-md-6 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${isNewFranchise ? "input-fix-width" : ""}`}
                      style={{ marginLeft: viewPortWidth ? "0" : "18px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_4"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_4"
                          htmlFor="input_4"
                        >
                          {estimateData?.PhoneNumber?.Value}
                        </label>
                        <div
                          id="cid_4"
                          className={`form-input-wide no-error-message jf-required ${
                            isValidPhone ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_4"
                            name="q4_phoneNumber"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required]"
                                : "form-textbox validate[required]"
                            }
                            size="20"
                            placeholder={estimateData?.PhoneNumber?.Value}
                            aria-label="Phone Number"
                            data-component="textbox"
                            onChange={handlePhoneChange}
                            onFocus={handlePhoneFocus}
                            // onBlur={handleFormatPhone}
                            value={phoneValue}
                          />
                          <p
                            className={`${isValidPhone ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            {phoneValue
                              ? "Please enter a valid phone number"
                              : "This field is required."}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: viewPortWidth ? "column" : "row",
                      justifyContent: "space-around",
                    }}
                    className={`${
                      viewPortWidth ? "" : "jot-form-group-margin"
                    } ${isNewFranchise ? "new-franchise-main-div" : ""}`}
                  >
                    <div
                      className={`col-sd-12  col-md-9 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${
                        isNewFranchise
                          ? "input-fix-width input-flex-setting"
                          : ""
                      }`}
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_5"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_5"
                          htmlFor="input_5"
                        >
                          {estimateData?.EmailAddress?.Value}
                        </label>
                        <div
                          id="cid_5"
                          className={`form-input-wide no-error-message jf-required ${
                            isValidEmail ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_5"
                            name="q5_emailAddress"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required] franchise-email"
                                : "form-textbox validate[required]"
                            }
                            size="20"
                            placeholder={estimateData?.EmailAddress?.Value}
                            aria-label="Email Address"
                            data-component="textbox"
                            onChange={handleEmailChange}
                            onFocus={handleEmailFocus}
                            value={emailValue}
                          />
                          <p
                            className={`${isValidEmail ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            {emailValue
                              ? "Please enter a valid emailID"
                              : "This field is required."}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`col-sd-12  col-md-3 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      } ${
                        isNewFranchise
                          ? "input-fix-width input-flex-setting"
                          : ""
                      }`}
                      style={{
                        marginLeft: viewPortWidth ? "0" : "18px",
                        flexBasis: isNewFranchise ? "0" : " 40%",
                      }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_6"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_6"
                          htmlFor="input_6"
                        >
                          {estimateData?.ZipCode?.Value}
                        </label>
                        <div
                          id="cid_6"
                          className={`form-input-wide no-error-message jf-required ${
                            isValidZipcode ? "" : "error"
                          } ${zipLenErr ? "error" : ""}`}
                        >
                          <input
                            type="text"
                            id="input_6"
                            name="q6_zipCode"
                            data-type="input-textbox"
                            required=""
                            className={
                              isNewFranchise
                                ? "input-box-newFranchise validate[required, Zipcode]"
                                : "form-textbox validate[required, Zipcode]"
                            }
                            size="20"
                            placeholder={estimateData?.ZipCode?.Value}
                            area-label="Zip Code"
                            data-component="textbox"
                            onChange={handleZipcodeChange}
                            onFocus={handleZipcodeFocus}
                            value={zipcodeValue}
                            maxLength="5"
                            autoComplete="off"
                          />
                          <p
                            className={`${isValidZipcode ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                          <p
                            className={`${!zipLenErr ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field must contain a 5-digit zip code
                          </p>
                        </div>
                      </div>
                      <LocalOfficeNonExistent
                        showModal={showModal}
                        setShowModal={setShowModal}
                      />
                    </div>
                  </div>
                  <div
                    // style={{ marginRight: viewPortWidth ? "0" : "15px" }}
                    className={viewPortWidth ? "" : "jot-form-group-margin"}
                  >
                    <div
                      className={`col-sd-12 ${
                        viewPortWidth ? "jot-form-group-element" : ""
                      }`}
                      style={{ width: "100%", paddingRight: "0" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textarea"
                        id="id_7"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_7"
                          htmlFor="input_7"
                        >
                          {estimateData?.TellUsAbout?.Value}
                        </label>
                        <div
                          id="cid_7"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedProject ? "" : "error"
                          }`}
                        >
                          <textarea
                            id="input_7"
                            className={
                              isNewFranchise
                                ? "new-franchise-text-box form-textarea validate[required] custom-hint-group form-custom-hint"
                                : "form-textarea validate[required] custom-hint-group form-custom-hint"
                            }
                            required=""
                            name="q7_tellUs"
                            cols="40"
                            rows="6"
                            data-component="textarea"
                            aria-label="Tell us about your project*"
                            data-customhint="Tell us about your project"
                            customhinted="true"
                            placeholder={estimateData?.TellUsAbout?.Value}
                            spellCheck="false"
                            onChange={handleProjectChange}
                            onFocus={handleProjectFocus}
                            value={projectValue}
                          ></textarea>
                          <p
                            className={`${
                              isPopulatedProject ? "hideField" : ""
                            }`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "1.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!isNewFranchise && (
                    <>
                      {" "}
                      <div
                        className={
                          viewPortWidth
                            ? "col-sd-12"
                            : " col-sd-12 jot-form-group-margin"
                        }
                        style={{ width: "100%" }}
                      >
                        <div
                          className="form-line"
                          data-type="control_text"
                          id="id_9"
                        >
                          <div id="cid_9" className="form-input-wide">
                            <div
                              id="text_9"
                              className="form-html"
                              data-component="text"
                            >
                              {isNewFranchise ? (
                                <h3 className="newFranchise-uploadImg">
                                  Upload photos Of your project.
                                </h3>
                              ) : (
                                <h3>
                                  {" "}
                                  {estimateData?.PhotoFormHeading?.Value}
                                </h3>
                              )}
                              <Markup
                                context={estimateData?.PhotoFormCopy?.Value}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row no-margin">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: viewPortWidth ? "column" : "row",
                            }}
                          >
                            <div
                              className="form-line col-sd-12 col-md-4"
                              data-type="control_fileupload"
                              id="id_8"
                            >
                              <div
                                id="cid_8"
                                className="form-input-wide jotform-img-container"
                              >
                                <input
                                  type="file"
                                  id="input_8"
                                  name="q8_picture1"
                                  className="form-upload validate[upload]"
                                  accept="image/png, image/jpeg, image/jpg, image/gif, image/tif"
                                  aria-label="Project Photo 1"
                                  onChange={(e) =>
                                    handleReadURL(e, "q8_picture1")
                                  }
                                />

                                <label
                                  className="form-label form-label-top file-label"
                                  id="label_8"
                                  htmlFor="input_8"
                                >
                                  {projectImgPreview?.images?.q8_picture1 ? (
                                    <img
                                      src={
                                        projectImgPreview?.images?.q8_picture1
                                      }
                                      alt="Project 1"
                                      title="Project 1"
                                      className="img-preview"
                                      width="150"
                                      height="100"
                                    />
                                  ) : (
                                    <>
                                      <span className="icon">
                                        <i
                                          className={`${estimateData?.PhotoIconClass?.Value}`}
                                        ></i>
                                      </span>
                                      <span className="photo-text">
                                        {estimateData?.AddaPhoto?.Value}
                                      </span>
                                    </>
                                  )}
                                </label>
                              </div>
                            </div>
                            <div
                              className="form-line col-sd-12 col-md-4"
                              data-type="control_fileupload"
                              id="id_10"
                            >
                              <div
                                id="cid_10"
                                className="form-input-wide jotform-img-container"
                              >
                                <input
                                  type="file"
                                  id="input_10"
                                  name="q10_picture2"
                                  className="form-upload validate[upload]"
                                  accept="image/png, image/jpeg, image/jpg, image/gif, image/tif"
                                  aria-label="Project Photo 2"
                                  onChange={(e) =>
                                    handleReadURL(e, "q10_picture2")
                                  }
                                />
                                <label
                                  className="form-label form-label-top file-label"
                                  id="label_10"
                                  htmlFor="input_10"
                                >
                                  {projectImgPreview?.images?.q10_picture2 ? (
                                    <img
                                      src={
                                        projectImgPreview?.images?.q10_picture2
                                      }
                                      className="img-preview"
                                      alt="Project 2"
                                      title="Project 2"
                                      width="150"
                                      height="100"
                                    />
                                  ) : (
                                    <>
                                      <span className="icon">
                                        <i
                                          className={`${estimateData?.PhotoIconClass?.Value}`}
                                        ></i>
                                      </span>
                                      <span className="photo-text">
                                        {estimateData?.AddaPhoto?.Value}
                                      </span>
                                    </>
                                  )}
                                </label>
                              </div>
                            </div>
                            <div
                              className="form-line col-sd-12 col-md-4"
                              data-type="control_fileupload"
                              id="id_11"
                            >
                              <div
                                id="cid_11"
                                className="form-input-wide jotform-img-container"
                              >
                                <input
                                  type="file"
                                  id="input_11"
                                  name="q11_picture3"
                                  className="form-upload validate[upload]"
                                  accept="image/png, image/jpeg, image/jpg, image/gif, image/tif"
                                  aria-label="Project Photo 3"
                                  onChange={(e) =>
                                    handleReadURL(e, "q11_picture3")
                                  }
                                />
                                <label
                                  className="form-label form-label-top file-label"
                                  id="label_11"
                                  htmlFor="input_11"
                                >
                                  {projectImgPreview?.images?.q11_picture3 ? (
                                    <img
                                      src={
                                        projectImgPreview?.images?.q11_picture3
                                      }
                                      className="img-preview"
                                      alt="Project 3"
                                      title="Project 3"
                                      width="150"
                                      height="100"
                                    />
                                  ) : (
                                    <>
                                      <span className="icon">
                                        <i
                                          className={`${estimateData?.PhotoIconClass?.Value}`}
                                        ></i>
                                      </span>
                                      <span className="photo-text">
                                        {estimateData?.AddaPhoto?.Value}
                                      </span>
                                    </>
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "0.8rem",
                        }}
                        className="jot-form-group-margin"
                      >
                        {projectImg?.errors ? projectImg?.errors : ""}
                      </div>
                    </>
                  )}

                  <div className="col-sd-12 " style={{ width: "100%" }}>
                    <div
                      className="form-line"
                      data-type="control_button"
                      id="id_2"
                    >
                      <div
                        id="cid_2"
                        className={
                          isNewFranchise
                            ? "form-input-wide new-franchise-input-btn"
                            : "form-input-wide"
                        }
                      >
                        <div className="form-buttons-wrapper">
                          <button
                            id={submitButtonId}
                            type="submit"
                            className={
                              isDisabled
                                ? "form-submit-button disable-btn simpli-btn simpli-contact"
                                : "form-submit-button submit btn simpli-btn simpli-contact"
                            }
                            data-component="button"
                            disabled={isDisabled}
                          >
                            {isNewFranchise
                              ? !isSubmitting && !isDisabled
                                ? "GET YOUR FREE ESTIMATE"
                                : "Please wait..."
                              : !isSubmitting && !isDisabled
                              ? estimateData?.SubmitButtonText?.Value
                                ? estimateData?.SubmitButtonText?.Value
                                : "Submit"
                              : "Please wait..."}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${isErrorFree ? "hideField" : ""}`}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    There are errors on the form. Please fix them before
                    continuing.
                  </div>
                  <div
                    className={`${isServiceDown ? "" : "hideField"}`}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    {serviceDownMessage}
                  </div>
                </div>
              </form>
              {handymanmatters && (
                <div
                  style={{
                    color: "#000",
                    lineHeight: "1.2em",
                    marginTop: "90px",
                    textAlign: "center",
                  }}
                >
                  <Markup content={handymanmatters} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetEstimateJotFrom;
