import React, { useState } from "react";
import { Markup } from "interweave";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { ingeniuxURL } from "../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../config/utility";
import OurWorkModalWindow from "../FranchiseCommon/OurWorkModal";
import gallerySpacerRectangle from "../FranchiseCommon/images/gallery-spacer-rectangle.png"
import gallerySpacerSquare from "../FranchiseCommon/images/gallery-spacer-square.png"
import "./index.css";

const settings = {
    dots: false,
    fade: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
};

const gallerySettings = {
    dots: false,
    fade: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
};

const BeforeandAfterView = (props) => {
    return (
        <div className="before-after-slide">
            <div className="row twelve">
                <div className="image col-sd-12 col-md-6">
                    <figure>
                        {gallerySpacerRectangle && <img src={gallerySpacerRectangle} alt="" />}
                        {props?.contentData?.Before?.Value && <img
                            className="item portrait"
                            src={`${ingeniuxURL}${props?.contentData?.Before?.Value}`}
                            alt="Before renovation"
                            title="Before renovation"
                            width="360"
                            height="360"
                        />}
                    </figure>
                    <div className="title" aria-details={props?.contentData?.Before?.AlternateText}>Before</div>
                </div>
                <div className="image col-sd-12 col-md-6">
                    <figure>
                        {gallerySpacerRectangle && <img src={gallerySpacerRectangle} alt="" />}
                        {props?.contentData?.After?.Value && <img
                            className="item portrait"
                            src={`${ingeniuxURL}${props?.contentData?.After?.Value}`}
                            alt="After renovation"
                            title="After renovation"
                            width="360"
                            height="360"
                        />}
                    </figure>
                    <div className="title" aria-details={props?.contentData?.After?.AlternateText}>After</div>
                </div>
            </div>
        </div>
    )
}

export const FranchiseOurWork = (props) => {
    const { contentData = {} } = props
    const { dataforReplaceMethod = {} } = contentData;
    const {
        franchiseName = "",
        franchiseCity = "",
        franchiseState = "",
        stateAbbr = ""
    } = dataforReplaceMethod;

    const [galTab, setGalTab] = useState("Show All");
    const [showModal, setShowModal] = useState(false);
    const [initGallerySlide, setInitGallerySlide] = useState(0);

    const handleImageModalToggle = (e, key) => {
        e.preventDefault();
        setInitGallerySlide(key);
        setShowModal(prevSt => !prevSt);
    }
    const handleImageModalToggleB = (e, key) => {
        e.preventDefault();
        setInitGallerySlide(key);
        setShowModal(prevSt => !prevSt);
    }

    // array of gallery's ImageCategories values tabs
    var superGalleryTabs = [];
    for (var i = 0; i < contentData?.OurWork?.GalleryImage?.length; i++) {
        superGalleryTabs.push(contentData?.OurWork?.GalleryImage[i]?.ImageCategories?.Value);
    }

    // if each ImageCategories value is separated by '|', then split the value into an array
    for (var i = 0; i < superGalleryTabs?.length; i++) {
        if (superGalleryTabs[i]?.includes("|")) {
            superGalleryTabs[i] = superGalleryTabs[i]?.split("|");
        }
    }

    // flatten the superGalleryTabs array and move the items into a Set to eliminate duplicates
    var galleryTabSet = new Set(["Show All"]);
    for (var i = 0; i < superGalleryTabs?.flat().length; i++) {
        galleryTabSet.add(superGalleryTabs?.flat()[i])
    }
    return (
        <>
            {
                contentData?.BodyCopyTray?.Heading?.Value &&
                <div className="our-work-intro-container asw">
                    <h1>{contentData?.BodyCopyTray?.Heading?.Value}</h1>
                    <Markup content={contentData?.BodyCopyTray?.Copy?.Value
                        ?.replace("https://www.acehardwarepainting.com/offices", "/offices")
                        ?.replaceAll("{City}", franchiseCity)
                        ?.replace("{Phone}", formatPhone(getFranchiseDataFromLS("recentFranchisPhone")))
                        ?.replace("{FranchiseName}", franchiseName)
                        ?.replace("{State}", franchiseState)
                        ?.replace("{StateAbbreviation}", stateAbbr)
                    } />
                </div>
            }

            {
                contentData?.BeforeAndAfterImage &&
                <div className="before-after-wrap row">
                    <div className="container">
                        <h3>Before &amp; After Photos</h3>
                        <div id="ba-container" style={{ position: "relative" }}>
                            <Slider {...settings}>
                                {Array.isArray(contentData?.BeforeAndAfterImage) ?
                                    contentData?.BeforeAndAfterImage?.map(beforeAndAfter => {
                                        return (
                                            <BeforeandAfterView contentData={beforeAndAfter} />
                                        )
                                    })
                                    :
                                    <BeforeandAfterView contentData={contentData?.BeforeAndAfterImage} />
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            }

            {props?.contentData?.GalleryImage &&
                <div className="our-work-gallery-wrap row">
                    <div className="container">
                        <div className="twelve">
                            <div className="col-sd-12">
                                <div id="gallery-list">
                                    <h3>Photo Gallery</h3>
                                    <div id="filters" className="button-group">
                                        {[...galleryTabSet]?.map((tab, key) => {
                                            return <button key={key}
                                                onClick={() => {
                                                    setGalTab(tab);
                                                }} className={galTab === tab ? "button is-checked" : "button"}>
                                                {tab}
                                            </button>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sd-12">
                                <div id="gallery-container" style={{ position: 'relative' }}>
                                    <div className="row twelve image-set">
                                        {galTab === "Show All" ? contentData?.GalleryImage?.map((galImg, key) => {
                                            return <div className="image image-toggle bathroom interior col-sd-6 col-md-4" data-num="0">
                                                <figure>
                                                    <button type="button" onClick={(e) => handleImageModalToggle(e, key)}>Info</button>
                                                    {gallerySpacerSquare && <img src={gallerySpacerSquare} alt="" />}
                                                    {galImg?.GalleryImage?.Value && <img
                                                        className={`item ${parseInt(galImg?.GalleryImage?.Width) / parseInt(galImg?.GalleryImage?.Height) >= 1 ? "landscape" : "portrait"}`}
                                                        src={`${ingeniuxURL}${galImg?.GalleryImage?.Value}`}
                                                        alt={galImg?.GalleryImage?.AlternateText ? galImg?.GalleryImage?.AlternateText : ""}
                                                        title={galImg?.GalleryImage?.AlternateText ? galImg?.GalleryImage?.AlternateText : ""}
                                                    />}
                                                </figure>
                                            </div>
                                        })
                                            :
                                            contentData?.GalleryImage?.map((galImg, key) => {
                                                return galImg?.ImageCategories?.Value?.split("|").includes(galTab) && <div className="image image-toggle bathroom interior col-sd-6 col-md-4" data-num="0">
                                                    <figure>
                                                        <button type="button" onClick={(e) => handleImageModalToggleB(e, key)}>Info</button>
                                                        {gallerySpacerSquare && <img src={gallerySpacerSquare} alt="" />}
                                                        {galImg?.GalleryImage?.Value && <img
                                                            className={`item ${parseInt(galImg?.GalleryImage?.Width) / parseInt(galImg?.GalleryImage?.Height) >= 1 ? "landscape" : "portrait"}`}
                                                            src={`${ingeniuxURL}${galImg?.GalleryImage?.Value}`}
                                                            alt={galImg?.GalleryImage?.AlternateText ? galImg?.GalleryImage?.AlternateText : ""}
                                                            title={galImg?.GalleryImage?.AlternateText ? galImg?.GalleryImage?.AlternateText : ""}
                                                        />}
                                                    </figure>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <OurWorkModalWindow show={showModal} handleModalToggle={(e) => handleImageModalToggle(e)} size={{ modalWidth: "80%", modalHeight: "80%" }}>
                <div id="gallery-slides" style={{ position: 'relative' }}>
                    <Slider {...gallerySettings} initialSlide={initGallerySlide}>
                        {props?.contentData?.GalleryImage?.map(galImg => {
                            return <div className="image twelve" data-category="bathroom interior" data-num="0">
                                <div id="port-modal-image">
                                    <figure>
                                        {galImg?.GalleryImage?.Value && <img
                                            className="port-image item"
                                            src={`${ingeniuxURL}${galImg?.GalleryImage?.Value}`}
                                            alt={galImg?.GalleryImage?.AlternateText ? galImg?.GalleryImage?.AlternateText : ""}
                                            title={galImg?.GalleryImage?.AlternateText ? galImg?.GalleryImage?.AlternateText : ""}
                                        />}
                                        <div className="title">{galImg?.Description?.Value}</div>
                                        <div className="description">{galImg?.AdditionalDetails?.Value}</div>
                                    </figure>
                                </div>
                            </div>
                        })}
                    </Slider>
                </div>
            </OurWorkModalWindow>
        </>
    )
}

export default FranchiseOurWork;