export const CLICK_TO_CALL = "click_to_call";
export const FORM_SUBMIT = "form_submit_get_an_estimate";
export const FORM_START_GET_AN_ESTIMATE = "form_start_get_an_estimate";

//This fn for GA dataLayer push
export const trackAnalytics = (eventName, franchiseName, franchiseUrl) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    franchise_name: franchiseName,
    page_url: franchiseUrl,
  });
};
