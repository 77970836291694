import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../config/utility";
import "./index.css";

const MemberProfile = (props) => {
  const { contentData = {}, cmsDynamicContent = {} } = props;
  const {
    franchiseName = "",
    franchiseCity = "",
    franchiseState = "",
    stateAbbr = "",
  } = cmsDynamicContent;
  return (
    <div className="bio">
      <div className="col-md-3 col-dd-2 photo">
        {contentData?.ProfileImage?.Value && (
          <img
            className="bio6280945478"
            src={`${ingeniuxURL}${contentData?.ProfileImage?.Value}`}
            alt={contentData?.ProfileImage?.AlternateText}
            title={contentData?.ProfileImage?.AlternateText}
            width="190"
            height="220"
          />
        )}
      </div>
      <div className="col-md-9 col-dd-3 title">
        <h2 className="heading">
          {contentData?.Name?.Value}
          <br/>
          <p className="designation">{contentData?.Title?.Value}</p>
        </h2>
      </div>
      <div className="col-dd-7 full-bio">
        <Markup
          content={contentData?.Copy?.Value?.replaceAll("{City}", franchiseCity)
            ?.replace(
              "{Phone}",
              formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
            )
            ?.replace("{FranchiseName}", franchiseName)
            ?.replace("{State}", franchiseState)
            ?.replace("{StateAbbreviation}", stateAbbr)}
        />
      </div>
    </div>
  );
};

export const FranchiseOurTeam = (props) => {
    const { contentData = {} } = props;
    const { dataforReplaceMethod = {} } = contentData;
    return (
        <div className="our-team-content-wrap">
            <div className="container row">
                <div className="bios-wrap">
                    {Array.isArray(contentData?.TeamMemberBioTray) ?
                        contentData?.TeamMemberBioTray?.map(bioData => {
                            return (
                                <MemberProfile contentData={bioData} cmsDynamicContent={dataforReplaceMethod} />
                            )
                        }) : <MemberProfile
                            contentData={contentData?.TeamMemberBioTray}
                            cmsDynamicContent={dataforReplaceMethod}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default FranchiseOurTeam;
