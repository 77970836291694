import React from "react";
import html2plaintext from "html2plaintext";
import PreviewCard from "../BlogPage/PreviewCard";
import "./BlogPostContent.css";

const BlogPostContent = ({
  link,
  title,
  content,
  button,
  bookOnlineLink,
  res,
  getFooter,
  isPreview,
  isFranchiseBlog
}) => {
  function AllowIframes(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }
  const strToReplace = `Images/${process.env.REACT_APP_API_BASE_URL}`;
  const strReplaceWith = `${process.env.REACT_APP_API_BASE_URL}Images/`;
  const previewText = content && html2plaintext(content.substr(0, 100));
  return (
    <div className="blogContent">
      {isPreview && (
        <PreviewCard
          title={title}
          previewText={previewText}
          link={link}
          bookOnlineLink={bookOnlineLink}
          button={button}
          res={res}
          getFooter={getFooter}
          isFranchiseBlog={isFranchiseBlog}
        />
      )}

      {!isPreview && (
        <AllowIframes
          iframe={content
            ?.replaceAll(strToReplace, strReplaceWith)
            ?.replaceAll(/<img [^>]*alt="Book Online Button[^"]*"[^>]*>/gi, "")
            ?.replaceAll(/<img [^>]*alt="Book Online Today![^"]*"[^>]*>/gi, "")
            ?.replaceAll(/<img [^>]*alt="Schedule Now Button[^"]*"[^>]*>/gi, "")
            ?.replaceAll(/<img [^>]*alt="Contact Us Today![^"]*"[^>]*>/gi, "")
            ?.replaceAll(/<img [^>]*alt="Learn More Button[^"]*"[^>]*>/gi, "")
            ?.replaceAll(
              /<a href="\/offices">(.*?)<\/a>/gi,
              "<a href='/offices' title='Link to Find a Craftsman page'>Learn More</a>"
            )}
        />
      )}

      {!isPreview && button && (
        <a
          href={`/#GetEstimateLink`}
          className="bookbtn"
          style={{
            background: `${
              button === "Red"
                ? "#d40029"
                : button === "Yellow"
                ? "yellow"
                : "green"
            }`,
            color: "#fff",
          }}
          title="Get A Free Estimate"
        >
          GET A FREE ESTIMATE
        </a>
      )}
    </div>
  );
};

export default BlogPostContent;
