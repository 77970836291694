import React, { useState, useEffect } from "react";
import { Markup } from "interweave";
import { useLocation } from "react-router-dom";

import SummerlinLogo from "../CustomLogos/SummerlinLogo";
import { ingeniuxURL } from "../../../config/urls";
import {
  getFranchiseDataFromLS,
  formatPhone,
  SUMMERLIN,
} from "../../../config/utility";
import { CLICK_TO_CALL, trackAnalytics } from "../../../config/gTrack";
import SvgPhoneIcon from "../../common/SvgPhoneIcon";
import privacyChoices from "../../../assets/images/yourprivacychoices.jpg";
import "./footerFranchise.css";

export const FooterTop = ({ footerContent }) => {
  const [formValue, setFormValue] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleChange = (e) => {
    setFormValue(e.target.value);
  };

  const handleFocus = () => {
    setIsValid(true);
    setFormValue("");
  };

  const handleSubmit = (e) => {
    // prevent page from refreshing
    e.preventDefault();
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(formValue);
    if (!isValidZip || formValue === "") {
      setIsValid(false);
      setFormValue("Please enter a valid zip code");
    }
  };
  return (
    <div>
      <footer>
        <div className="lf-cta-wrap row twelve">
          <div className="container">
            <div className="wrap-center row col-dd-8">
              <div className="cta-left col-sd-12 col-dd-6">
                <Markup content={footerContent?.FindAnOffice?.Heading?.Value} />
                {/* <span>Your Home. Our Expertise.</span>Find Your Local Office. */}
              </div>
              <div className="cta-right col-sd-12 col-dd-6">
                <form className="ctaSearchForm" onSubmit={handleSubmit}>
                  <span className={`input ${isValid ? "" : "error"}`}>
                    <input
                      type="text"
                      id="ctaSearchArea"
                      name="searchArea"
                      className="searchInput"
                      placeholder={
                        footerContent?.FindAnOffice?.PlaceholderText?.Value
                      }
                      title={
                        footerContent?.FindAnOffice?.PlaceholderText?.Value
                      }
                      value={formValue}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      maxLength="5"
                      autoComplete="off"
                      size="30"
                    />
                  </span>
                  <span className="search">
                    <button type="submit" className="searchBtn submit btn">
                      Submit
                    </button>
                  </span>
                  <div className="no-results">
                    <span className="close">x</span>
                    We're sorry, there isn't an Ace Hardware Painting Services
                    office in your immediate area.
                    <br />
                    <a href="/offices/">
                      Please click here to find the areas we do service in your
                      state.
                    </a>{" "}
                  </div>
                  <div className="instructions">
                    {footerContent?.FindAnOffice?.TextBelowInput?.Value}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

const Footers = ({
  footerContent = {},
  otherProps,
  corpFooterProps = [],
  headerContentForMenu,
}) => {
  const [
    footerLogo,
    textAbovePhoneNum,
    Copyright,
    CopyrightLinks,
    DisclaimerNotes,
    franchiseName,
    frnachiseBaseURL,
  ] = corpFooterProps;

  const [swapPhone, setSwapPhone] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    let localPhone;

    let intervalId = setInterval(() => {
      localPhone = getFranchiseDataFromLS("recentFranchisPhone");
      setSwapPhone(localPhone);
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [swapPhone]);

  const paymentTypesArray =
    otherProps?.FranchiseOwnersControlNew?.PaymentTypes?.Value?.split("|");
  const CopyrightValue = Copyright?.Value?.split("|");
  const onetrustWebform = process.env.REACT_APP_ONETRUST_WEBFORM;
  const franchiseFullName = otherProps?.FranchiseName?.Value;

  const splitFranchiseUrl =
    frnachiseBaseURL && frnachiseBaseURL?.split("/")?.filter((i) => i != "");

  const getFooterLinks = (footerCol) => {
    if (footerCol && footerCol.Page) {
      const { Page } = footerCol;
      if (Array.isArray(Page)) {
        return Page.map((colFoot, index) => {
          return (
            <li key={index}>
              <a
                href={
                  colFoot?.Schema === "VirtualLink"
                    ? colFoot?.CustomURL
                    : `/${colFoot?.URL}`
                }
                target={colFoot?.Schema === "VirtualLink" ? "_blank" : "_self"}
              >
                {colFoot?.Name}
              </a>
            </li>
          );
        });
      } else {
        return (
          <li key={Page?.ID}>
            <a
              href={
                Page?.Schema === "VirtualLink"
                  ? Page?.CustomURL
                  : `/${Page?.URL}`
              }
              target={Page?.Schema === "VirtualLink" ? "_blank" : "_self"}
            >
              {Page?.Name}
            </a>
          </li>
        );
      }
    }
  };

  //GA Phone click tracker
  const handleWindowTracker = (e, gaEventName, franchiseInfo, pageUrl) => {
    e.stopPropagation();
    trackAnalytics(gaEventName, franchiseInfo, pageUrl);
  };

  return (
    <div>
      {footerContent && (
        <footer>
          <div className="foot-wrap franchise-footer-wrap">
            <div className="footer-container row twelve">
              <div id="foot-left" className="col-dd-5 col-md-12 col-sd-12">
                <a className="logo" href={frnachiseBaseURL}>
                  {splitFranchiseUrl &&
                  splitFranchiseUrl[1]?.toLowerCase() === SUMMERLIN ? (
                    <SummerlinLogo
                      attributes={{
                        fillSolid: false,
                        width: "auto",
                        height: "70px",
                      }}
                    />
                  ) : footerLogo?.Value ? (
                    <img
                      src={`${ingeniuxURL}${footerLogo?.Value}`}
                      // style={{ maxWidth: "80%" }}
                      alt={
                        footerLogo?.AlternateText
                          ? footerLogo?.AlternateText
                          : "Ace Hardware Paint Services logo"
                      }
                      title={
                        footerLogo?.AlternateText
                          ? footerLogo?.AlternateText
                          : "Ace Hardware Paint Services logo"
                      }
                      width="150"
                      height="50"
                    />
                  ) : (
                    ""
                  )}
                </a>

                <div className="call-cta">
                  <span>{textAbovePhoneNum?.Value}</span>
                  <div className="footer-phone" title="contact-us-at">
                    <div className="yo-local">
                      <span className="yo-local-phone yo-local-href-phone"></span>
                    </div>
                    <div className="yo-corporate footer">
                      <div className="phone-svg ff-phone">
                        <SvgPhoneIcon />
                      </div>
                      <a
                        href={
                          swapPhone
                            ? `tel:${swapPhone}`
                            : otherProps?.TelephoneScript?.Value
                            ? `tel:${otherProps?.TelephoneScript?.Value}`
                            : `tel:${otherProps?.PhoneNumber?.Value}`
                        }
                        onClick={(e) =>
                          handleWindowTracker(
                            e,
                            CLICK_TO_CALL,
                            franchiseFullName,
                            pathname
                          )
                        }
                      >
                        {swapPhone
                          ? formatPhone(swapPhone)
                          : otherProps?.PhoneNumber?.Value}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="footer-address">
                  <div>
                    {/* address */}
                    {otherProps ? (
                      <p style={{ textAlign: "center" }}>
                        {otherProps?.Address?.Value}
                        <br />
                        {otherProps?.City?.Value}
                        {", "}
                        {otherProps?.StateAbbreviation?.Value}{" "}
                        {otherProps?.ZipCode?.Value}
                      </p>
                    ) : null}
                    <span>{footerContent?.TextBelowAddress?.Value}</span>
                  </div>
                </div>
              </div>
              <div id="foot-mid" className="col-sd-12 col-md-10 col-dd-6">
                <div className="row">
                  {footerContent?.Linkset ? (
                    Array.isArray(footerContent?.LinkSet) ? (
                      footerContent?.LinkSet?.map((footerCol, index) => {
                        return (
                          <nav
                            className="resources col-md-6 col-dd-6"
                            key={index}
                          >
                            <div className="heading">{footerCol?.Title}</div>
                            <ul>{getFooterLinks(footerCol)}</ul>
                          </nav>
                        );
                      })
                    ) : (
                      <nav className="resources1 col-md-6 col-dd-6">
                        <div className="heading">
                          {footerContent?.LinkSet?.Title}
                        </div>
                        <ul>{getFooterLinks(footerContent?.LinkSet)}</ul>
                      </nav>
                    )
                  ) : null}
                  <nav className="resources col-md-6 col-dd-6">
                    <div className="heading">
                      Ace Hardware Painting Services
                    </div>
                    <ul>
                      {headerContentForMenu?.map((Menu, index) => {
                        let prefixLink;
                        let postfixLink = Menu?.Page?.URL;
                        if (
                          Menu?.Page?.URL?.includes("#") ||
                          !Menu?.Page?.URL?.includes("offices")
                        ) {
                          prefixLink = Menu?.SURL;
                        } else {
                          prefixLink = "";
                        }
                        if (
                          Array.isArray(postfixLink) && !postfixLink.startsWith("/") &&
                          !postfixLink.includes("#") &&
                          !prefixLink.endsWith("/")
                        ) {
                          postfixLink = `${postfixLink}`;
                        }
                        return (
                          <li key={index}>
                            <a
                              href={
                                Menu?.Page?.Link === "Why Choose Us"
                                  ? `${prefixLink}#${postfixLink}`
                                  : `${prefixLink}/${postfixLink}`
                              }
                            >
                              {Menu?.Page?.Link}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>

                  <div className="payment col-dd-12">
                    <div className="heading">
                      {footerContent?.PaymentOptionsLabel?.Value}
                    </div>
                    <ul>
                      {Array.isArray(footerContent?.IconAndHighlight) &&
                        footerContent?.IconAndHighlight?.map((el, index) => {
                          if (
                            paymentTypesArray?.includes(
                              el?.HighlightText?.Value
                            )
                          ) {
                            return (
                              <li key={index}>
                                <span
                                  className={`fa-solid ${el?.FontAwesomeClass?.Value}`}
                                ></span>
                                <Markup content={el?.HighlightText?.Value} />
                              </li>
                            );
                          }
                        })}
                    </ul>
                    {Array.isArray(footerContent?.Page) &&
                    footerContent?.Page?.some(
                      (link) => link?.Name === "PayOnlineButton"
                    ) ? (
                      <>
                        <div className="line-separator"></div>
                        {footerContent?.Page?.map((link, index) => {
                          return (
                            link?.Name === "PayOnlineButton" && (
                              <a
                                href={link?.URL}
                                className="pay-online-btn btn"
                                target="_blank"
                                rel="noopener noreferrer"
                                key={index}
                              >
                                {link?.Link}
                              </a>
                            )
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div id="foot-right" className="col-sd-2 col-md-2 col-dd-1">
                <span className="back-to-top-wrap">
                  <a href="#top">
                    <svg
                      id="arrowUp"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 18 24.9"
                    >
                      <title>Arrow Up</title>
                      <defs>
                        <path id="arrowUpone" d="M0 0h18v25.9H0z"></path>
                      </defs>
                      <clipPath id="arrowUpclip">
                        <use overflow="visible"></use>
                      </clipPath>
                      <path
                        className="st0"
                        d="M.3 9c-.4.3-.4.9 0 1.3.3.3.9.3 1.3 0L8 3.8V25c0 .5.4.9.9.9s.9-.4.9-.9V3.8l6.4 6.4c.4.3.9.3 1.3 0 .4-.4.4-.9 0-1.3l-8-8C9.1.6 8.6.6 8.2 1L.3 9z"
                      ></path>
                    </svg>
                    <span>
                      BACK
                      <br />
                      TO
                      <br />
                      TOP
                    </span>
                  </a>
                </span>
              </div>

              <div id="foot-bottom" className="col-sd-12">
                <div className="copy-wrap col-sd-12 col-md-12 col-dd-9">
                  <p>
                    <Markup content={DisclaimerNotes?.Value} />
                    <br /> <br />
                    <div className="franchise-links-line">
                      &copy; {new Date().getFullYear()}{" "}
                      {CopyrightValue?.map((element, index) => {
                        return (
                          <>
                            {element}
                            {index + 1 === CopyrightValue?.length
                              ? ""
                              : franchiseName + " | "}{" "}
                          </>
                        );
                      })}
                      <span className="divider">{" | "}</span>
                      <span>
                        {CopyrightLinks?.map((links, index) => {
                          return (
                            <span key={index} className="link-margin">
                              <a href={`/${links.URL}`}>{links.Name}</a>
                              {index + 1 === CopyrightLinks?.length ? "" : "|"}
                            </span>
                          );
                        })}
                        {" | "}
                        <a
                          href={onetrustWebform}
                          className="privacychoices"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Your Privacy Choices{" "}
                          <img
                            src={privacyChoices}
                            alt="privacy choices"
                            width="22"
                            height="10"
                          />
                        </a>
                      </span>
                    </div>
                  </p>
                </div>
                <div className="social-wrap col-sd-12 col-md-12 col-dd-3">
                  <div id="social-wrap">
                    <ul className="social-links">
                      {Array.isArray(footerContent?.Page) ? (
                        footerContent?.Page?.map((socialIcon, index) => {
                          return (
                            socialIcon?.Name !== "PayOnlineButton" && (
                              <li className="social" key={index}>
                                <a
                                  href={socialIcon?.URL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className={socialIcon?.Link}></i>
                                </a>
                              </li>
                            )
                          );
                        })
                      ) : (
                        <li className="social">
                          <a
                            href={footerContent?.Page?.URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className={footerContent?.Page?.Link}></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Footers;
