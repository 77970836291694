import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import "../../page-404/index.css";
import "./index.css";
import {
  door,
  bathroom,
  carpentry,
  drywall,
  fencing,
  floor,
  painting,
} from "./images";
import { CLICK_TO_CALL, trackAnalytics } from "../../../config/gTrack";

const Page503 = () => {
  // The following reload condition, locastorage value
  //added for the franchise city detail pages with .html
  //extensions scenario in copy/paste the url in incognito mode.
  //Added "cityReloadCounter" LS value form city detail page
  //and accessed here and updted to 1 after successfully loading the page.
  if (parseInt(localStorage.getItem("cityReloadCounter")) === 0) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  localStorage.setItem("cityReloadCounter", 1);

  const phone = "(866)-808-8401";
  const { pathname } = useLocation();

  //GA Phone click tracker
  const handleWindowTracker = (e, gaEventName, originInfo, pageUrl) => {
    e.stopPropagation();
    trackAnalytics(gaEventName, originInfo, pageUrl);
  };

  return (
    <>
      <Header phone={phone} />
      <Helmet>
        <title>Service Down | Ace Hardware Painting Services</title>
        <meta
          name="description"
          content="Call us at location at 866-FIX-MY-HOME or request a quote online. You can also schedule an appointment using our online scheduling tool."
        />
      </Helmet>
      <div className="page-not-found page-503">
        <section id="banner"></section>
        <section id="content" className=" content-section twelve">
          <div className="not-found-wrap">
            <div className="not-found container">
              <div className="finder">
                <div className="heading">Oops...</div>
                <p>Something went wrong on our end. We will be back soon.</p>
                <div className="call-cta">
                  or call us at
                  <div className="phone">
                    <a
                      style={{ color: "inherit" }}
                      href={`tel:${phone}`}
                      onClick={(e) => {
                        handleWindowTracker(
                          e,
                          CLICK_TO_CALL,
                          "Corporate",
                          pathname
                        );
                      }}
                    >
                      {phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="most-popular-services row">
          <div className="container">
            <div className="heading-wrap toggle-heading">
              <div className="heading">Most Popular Services</div>
              <span className="fa fa-angle-down arrow"></span>
            </div>
            <div className="services content">
              <div className="item col-sd-6">
                <a href="/services/doors-and-windows">
                  {door && (
                    <img
                      src={door}
                      alt="serviceDoor"
                      title="serviceDoor"
                      width="160"
                      height="80"
                    />
                  )}
                  <span>Doors</span>
                </a>
              </div>
              <div className="item col-sd-6">
                <a href="floors.html">
                  {floor && (
                    <img
                      src={floor}
                      alt="serviceFloor"
                      title="serviceFloor"
                      width="160"
                      height="80"
                    />
                  )}
                  <span>Floors</span>
                </a>
              </div>

              <div className="item col-sd-6">
                <a href="/">
                  {bathroom && (
                    <img
                      src={bathroom}
                      alt="serviceBath"
                      title="serviceBath"
                      width="160"
                      height="80"
                    />
                  )}
                  <span>Bathroom</span>
                </a>
              </div>

              <div className="item col-sd-6">
                <a href="/services/painting-and-staining">
                  {painting && (
                    <img
                      src={painting}
                      alt="servicePaintroller icon"
                      title="servicePaintroller icon"
                      width="160"
                      height="80"
                    />
                  )}
                  <span>Painting</span>
                </a>
              </div>

              <div className="item col-sd-6">
                <a href="/services/fence">
                  {fencing && (
                    <img
                      src={fencing}
                      alt="serviceFence"
                      title="serviceFence"
                      width="160"
                      height="80"
                    />
                  )}
                  <span>Fencing</span>
                </a>
              </div>
              <div className="item col-sd-6">
                <a href="/services/drywall-repair">
                  {drywall && (
                    <img
                      src={drywall}
                      alt="serviceDrywall"
                      title="serviceDrywall"
                    />
                  )}
                  <span>Drywall</span>
                </a>
              </div>
              <div className="item col-sd-6">
                <a href="/services/carpentry">
                  {carpentry && (
                    <img
                      src={carpentry}
                      alt="serviceCarpentry"
                      title="serviceCarpentry"
                      width="160"
                      height="80"
                    />
                  )}
                  <span>Carpentry</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer phone={phone} />
      </div>
    </>
  );
};

export default Page503;
