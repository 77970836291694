import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from '../../../../config/urls';

const ImageGalleryHalfWidth = ({ imageData, FullWidth, ImagesPerRow, AddCaptions }) => {
    return (
        <div className="gallery container row">
            <h3>{imageData?.Heading?.Value}</h3>
            <div className="twelve">
                {imageData?.ImageLink?.map(image => {

                    return (
                        <div
                            className={FullWidth === "False" ?
                                (ImagesPerRow === "2" ? "col-12 col-dd-6 category space" : "col-12 col-dd-3 category space")
                                : (ImagesPerRow === "2" ? "col-12 col-dd-6 category non-space" : "col-12 col-dd-3 category non-space")}
                        >
                            <div className="kb-item item">
                                {image?.Page ?
                                    <a href={`${image?.Page?.ID ? '/'+image?.Page?.URL : image?.Page?.URL}`} target="_blank">
                                        {image?.Image?.Value && <img
                                            src={`${ingeniuxURL}${image?.Image?.Value}`}
                                            alt={image?.Image?.AlternateText ? image?.Image?.AlternateText : ""}
                                            title={image?.Image?.AlternateText ? image?.Image?.AlternateText : ""}
                                        />}
                                    </a> :
                                    image?.Image?.Value && <img
                                    src={`${ingeniuxURL}${image?.Image?.Value}`}
                                    alt={image?.Image?.AlternateText ? image?.Image?.AlternateText : ""}
                                    title={image?.Image?.AlternateText ? image?.Image?.AlternateText : ""}
                                />}
                                <div className={AddCaptions === "False" ? "no-caption" : "add-caption"}>
                                    <span>{image?.Caption?.Value}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <Markup content={imageData?.CopyBelowGallery?.Value} />
            </div>
        </div>
    )
}

export default ImageGalleryHalfWidth;