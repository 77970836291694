import React, { useState } from "react";
import { getFranchiseDataFromLS } from "../../config/utility";
import "./blogAside.css";

const BlogAside = ({ contentData, recentData, isFranchiseBlog }) => {
  const [searchTerm, setSearchTerm] = useState("");
  var revArr = [];
  var currentYear = new Date().getFullYear();
  var archiveYears = [];
  for (let i = currentYear; i >= 2020; i--) {
    archiveYears.push(i);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.pathname = `blog/search/${searchTerm}`;
  };
  return (
    <div
      id="page"
      className={
        isFranchiseBlog
          ? `site franchisePadding ${
              getFranchiseDataFromLS("recentFranchisePath") !== undefined
                ? "site-padding-with-last-franchise-ribbon"
                : ""
            }`
          : `site ${
              getFranchiseDataFromLS("recentFranchisePath") !== undefined
                ? "site-padding-with-last-franchise-ribbon"
                : ""
            }`
      }
    >
      <div className="site-inner">
        <a className="skip-link screen-reader-text" href="#content">
          Skip to content
        </a>
        <div id="blog-content" className="site-content">
          <aside
            id="secondary"
            className="sidebar widget-area"
            role="complementary"
          >
            {!isFranchiseBlog && (
              <section id="search-2" className="widget widget_search">
                <form
                  onSubmit={handleSubmit}
                  role="search"
                  method="get"
                  className="search-forms"
                >
                  <div style={{ flexDirection: "row" }}>
                    <label>
                      <span className="screen-reader-text">Search for:</span>
                      <input
                        type="search"
                        className="search-field"
                        placeholder="Search …"
                        
                        onChange={(event) => {
                          setSearchTerm(event.target.value);
                        }}
                      />
                    </label>
                    <button type="submit" className="search-submit">
                      <i className="fa-solid fa-magnifying-glass"></i>
                      <span className="screen-reader-text">Search</span>
                    </button>
                  </div>
                </form>
              </section>
            )}
            <section
              id="recent-posts-2"
              className="widget widget_recent_entries"
            >
              <div className="widget-title">Recent Posts</div>
              {!recentData?.length ? (
                "Loading...."
              ) : (
                <ul>
                  {Array.isArray(recentData) ? (
                    recentData?.map((rec, idx) => {
                  
                      if (Object.keys(rec)?.length) {
                        if (isFranchiseBlog) {
                          const { Name , URL} = rec;
                          return (
                            idx < 5 && (
                              <>
                                <li key={idx}>
                                  <a href={`/${URL}`}> {Name}</a>
                                </li>
                              </>
                            )
                          );
                        } else {
                          const { BlogPost } = rec;
                          const { Heading, SURL } = BlogPost;
                          return (
                            idx < 5 && (
                              <>
                                <li key={idx}>
                                  <a href={`${SURL}`}> {Heading?.Value}</a>
                                </li>
                              </>
                            )
                          );
                        }
                      }
                    })
                  ) : (
                    <li>
                      <a href={`/${recentData?.SURL}`}>
                        {" "}
                        {recentData?.Heading?.Value}
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </section>

          </aside>
        </div>
      </div>
    </div>
  );
};

export default BlogAside;
