import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import HeaderFranchise from "../FranchiseCommon/Header/HeaderFranchise";
import FootersFranchise from "../FranchiseCommon/Footer/FooterFranchise";
import DetailPageBanner from "../FranchiseCommon/DetailPageBanner";
import BreadCrumb from "../FranchiseCommon/BreadCrumb";
import CityDetailTemplate from "../FranchiseHomePage/ProfessionalServices";
import {
  getResultsArray,
  storeFranchiseDataToLS,
  getFranchiseDataFromLS,
  // deleteItemFromLS,
  extractPath,
  loadApiData,
  saveApiData,
  deleteItemFromSS,
  handymanId,
  getScheduleEstimateTabTray,
} from "../../config/utility";
import { ingeniuxURL } from "../../config/urls";
import withRouter from "../common/withRouter";
import DynamicTrays, { dynamicTrayList } from "../common/DynamicTrays";
import "./index.css";

class FranchiseServiceCityDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      franchiseHomeData: loadApiData("franchiseHomeData"),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // deleteItemFromLS("recentFranchisPhone");
    //there are 2 scenarios

    //scenario 1
    //franchiseURL - when navigate to other page and press the browser back button
    //the city page data lost and franchise baseurl which is sent from the parent page also lost
    //to fix this issue, taking the last visited franchise url from localStorage (LS)
    //and hitting the api to populate the franchisehome data, from that getting ServiCitySEO

    //scenario -2
    //when the user directly hitting the city detail page URL, in such case the base URL
    //will be null, LS base URL will be null and only URL param data available (this.props?.apiRef),
    //that is split at "/city" and taking the base URL
    const franchiseURL = this.props?.apiRef?.basePageURL
      ? this.props?.apiRef?.basePageURL
      : getFranchiseDataFromLS("recentFranchisePath")
      ? getFranchiseDataFromLS("recentFranchisePath")
      : this.props?.apiRef?.split("/city")[0];
    const corpHomeItem = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );

    //To eliminate the hash url, splitting the path.
    const rgxforSlashes = /^\/|\/$/g;
    const franchiseAbsPath = franchiseURL?.split("/#");
    storeFranchiseDataToLS("recentFranchisePath", franchiseAbsPath[0]);
    const localStateSURL =
      this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;
    const localStorageSURL = getFranchiseDataFromLS("recentFranchisePath");
    //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
    //then load the franchisehomepage api again.

    //scenario 2 - in else block if the cached data and the visited franchise SURLS
    //are no the same, removed the cached data from the Session storage and
    //fetch the api again.
    if (
      (this.state.franchiseHomeData?.length === 0 ||
        this.state.franchiseHomeData === undefined) &&
      franchiseHomePage?.length === 0
    ) {
      this.props.dispatch(
        fetchFranchiseHome(franchiseURL?.replace(/\/+$/, "")?.toLowerCase())
      );
    } else if (
      this.state.franchiseHomeData &&
      localStateSURL?.replace(rgxforSlashes, "") !==
        localStorageSURL?.replace(rgxforSlashes, "")
    ) {
      deleteItemFromSS("franchiseHomeData");
      this.props.dispatch(
        fetchFranchiseHome(franchiseURL?.replace(/\/+$/, "")?.toLowerCase())
      );
    }

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      corpHomeItem?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.apiRef?.apiPath !== this.props?.apiRef?.apiPath ||
      prevProps?.apiRef !== this.props?.apiRef
    ) {
      const franchiseURL = this.props?.apiRef?.basePageURL
        ? this.props?.apiRef?.basePageURL
        : getFranchiseDataFromLS("recentFranchisePath")
        ? getFranchiseDataFromLS("recentFranchisePath")
        : this.props?.apiRef?.split("/city")[0];
      this.props.dispatch(
        fetchFranchiseHome(franchiseURL?.replace(/\/+$/, "")?.toLowerCase())
      );
    }
  }

  render() {
    storeFranchiseDataToLS("cityReloadCounter", 0)
    const franchiseURL = this.props?.apiRef?.basePageURL
      ? this.props?.apiRef?.basePageURL
      : this.props?.apiRef?.split("/city")[0]

    const franchiseHomeData = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const corpHomeData = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults', 'franchiseHomePage'
    let franchiseHomePage = loadApiData("franchiseHomeData");
    let corpHomeItem = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData?.length === 0
    ) {
      storeFranchiseDataToLS("recentFranchisePath", franchiseURL);
    }
    else if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        apiFranchisePath?.replace(rgxforSlashes, "")
      ) {
        saveApiData("franchiseHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseHomeData");
      }
    }

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (corpHomeItem === undefined || corpHomeItem?.length === 0) &&
      corpHomeData
    ) {
      saveApiData("x31", corpHomeData);
      corpHomeItem = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }
    const pagePath = this.props?.apiRef?.pagePath
      ? this.props?.apiRef?.pagePath
      : this.props?.apiRef;
    const absPagePath = pagePath && pagePath?.replace(/\/+$/, "");
    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.City?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const franchiseCity = franchiseCorpCtrl?.City?.Value;
    const franchiseState = franchiseCorpCtrl?.State?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const dynamicCMSFields = {
      franchiseName,
      franchiseCity,
      franchiseState,
      stateAbbr,
    };
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    const estimateFormZipData = {
      zipCodeList: localOfficeZipcodes,
      localOfficeList: localOfficeResults,
      toSchedulingPage: true,
    };

    const estimateFormData = getScheduleEstimateTabTray(franchiseHomePage);
    const estimateTrayData = {
      ...{ estimateFormZipData },
      ...{ estimateFormData },
      ...{ franchiseDataToGetEstimate },
    };


    //filtering the ServiceCitySEO data with the pagePath data
    //example: /lakewood_services
    const pageData =
      franchiseHomePage &&
      franchiseHomePage[0]?.ServiceCitySEO.filter(
        (city) => city?.template?.CityURL?.Value === extractPath(absPagePath)
      );
      const pageTemplate = pageData && pageData[0]?.template;

      if (pageData && !pageTemplate) {
        window.location.href = `${franchiseURL}`
      }

    const cityDetailPageProps = { ...pageTemplate, ...dynamicCMSFields };

    //The following 3 api calls equired in the case city detail pages with
    //.html extension and doing a copy/paste in incognito browsing mode.
    //The api calls cancelled due to the redirection, hence made api calls
    //and storing the values to Session storage
    if (!Object.keys(cityDetailPageProps)?.length) {
      const franchisePath = getFranchiseDataFromLS("recentFranchisePath");
      fetch(`${ingeniuxURL}api/page?path=${franchisePath}`).then(
        res => res.json()
      ).then(data => {
        const cityData = getResultsArray(data, "FranchiseHomePage");
        saveApiData("franchiseHomeData", cityData);
      });
    }
    if (!corpHomeItem?.length) {
      fetch(`${ingeniuxURL}api/page?path=x31`).then(
        res => res.json()
      ).then(data => {
        const x31Data = getResultsArray(data, "GlobalSiteControl");

        saveApiData("x31", x31Data);
      });
    }
    if (!localOfficeResults?.length) {
      fetch(`${ingeniuxURL}api/localoffice/orderby/state`).then(
        res => res.json()
      ).then(data => {
        const localOfficeData = data;
        saveApiData("localoffice", localOfficeData);
      });
    }

    //creating breadcrumb data from Servic city pages
    //as they are not having sepeate urls to load the content.
    //All city detail page data from the Franchise Home Page
    const breadcrumbNavigationCityPage = [
      {
        URL: "",
        Name: "Home",
      },
      {
        URL: "./offices",
        Name: "FIND A PAINTER",
      },
      {
        URL: `.${franchiseURL}`,
        Name: `Ace Hardware Painting Services ${
          franchiseHomePage && franchiseHomePage[0]?.Name
        }`,
      },
      {
        URL: "",
        Name: pageData && pageData[0]?.city,
      },
    ];

    const breadcrumbsStructuredData =
      franchiseCorpCtrl?.BreadcrumbsStructuredData?.Value;
    const localBusinessStructuredData =
      franchiseCorpCtrl?.LocalBusinessStructuredData?.Value;

    return (
      <>
        <Helmet>
          <title>{pageTemplate?.Title?.Value}</title>

          {/* Adding BreadcrumbsStructuredData to Head of the page */}
          {breadcrumbsStructuredData && (
            <script type="application/ld+json">
              {breadcrumbsStructuredData}
            </script>
          )}
          {/* Adding LocalBusinessStructuredData to Head of the page */}
          {localBusinessStructuredData && (
            <script type="application/ld+json">
              {localBusinessStructuredData}
            </script>
          )}
        </Helmet>
        <HeaderFranchise
          alertContent={corpHomeItem[0]?.Alerts}
          headerContent={
            franchiseHomePage &&
            franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseHeader
          }
          otherProps={[
            franchiseHomePage &&
              franchiseHomePage[0]?.FranchiseCorporateControl,
            franchiseHomePage && franchiseHomePage[0]?.SURL,
          ]}
          corpHeaderProps={[
            corpHomeItem && corpHomeItem[0]?.Header?.BookOnlineIcon,
            corpHomeItem && corpHomeItem[0]?.Header?.GetEstimateIcon,
            corpHomeItem && corpHomeItem[0]?.Header?.Page[0],
            corpHomeItem && corpHomeItem[0]?.Header?.Page[1],
          ]}
          metaDescription={pageTemplate?.Description?.Value}
          estimateTrayData={estimateTrayData}
        />
        <DetailPageBanner
          bannerContent={pageTemplate?.Banner}
          headingContent={pageTemplate?.BannerHeading}
          paintStrokes={[
            pageTemplate?.LeftCapPaintStroke,
            pageTemplate?.MiddlePaintStroke,
            pageTemplate?.RightCapPaintStroke,
          ]}
        />
        <div className="city-detailpage-container">
          <BreadCrumb breadcrumbData={breadcrumbNavigationCityPage} />
          {cityDetailPageProps && <CityDetailTemplate contentData={cityDetailPageProps} />}
        </div>

        {franchiseHomePage &&
          Object.entries(pageTemplate)?.map((tray, index) => {
            if (
              Object.keys(dynamicTrayList)?.find(
                (trayName) => tray?.length > 1 && trayName === tray[1]?.Name
              )
            ) {
              let combinedProps;
              if (tray[1]?.Name === "FranchisePackages") {
                const data =
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseOwnersControlNew?.Packages;
                let packageCpyData = [];
                Object.keys(sessionStorage).forEach((key) => {
                  key !== "undefined" &&
                    packageCpyData.push({
                      name: key?.split("-").join(" "),
                      copyText: sessionStorage.getItem(key),
                    });
                });
                combinedProps = {
                  ...{
                    ...tray[1],
                    ...{ otherProps: data },
                    ...{ packageCopy: packageCpyData },
                  },
                };
              } else if (tray[1]?.Name === "ReviewCarousel") {
                const rateaBizUId =
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl?.RateABizUUID;
                combinedProps = {
                  ...{
                    ...tray[1],
                    ...(franchiseHomePage && franchiseHomePage[0]),
                    ...{ rateaBiz: rateaBizUId },
                    ...{ reviewsTrays: tray[1] },
                  },
                };
              } else if (tray[1]?.Name === "ServicesTray") {
                combinedProps = {
                  ...{
                    ...tray[1],
                    ...{
                      franchiseData: franchiseHomePage && franchiseHomePage[0],
                    },
                  },
                };
              } else if (
                tray[1]?.Name === "ScheduleandEstimateTabTray" ||
                tray[1]?.Name === "ScheduleandEstimateTray"
              ) {
                combinedProps = {
                  ...{
                    ...tray[1],
                    ...{ zipCodeList: localOfficeZipcodes },
                    ...{ localOfficeList: localOfficeResults },
                    ...{ franchiseDataToGetEstimate },
                  },
                };
              } else if (tray[1]?.Name === "FooterSliderSmall") {
                /* To remove the imagecopytraywithslider in seo city page */
                return null
              } else {
                combinedProps = { ...tray[1] };
              }

              return (
                <DynamicTrays
                  trayName={tray[1]?.Name}
                  contentData={combinedProps}
                  key={index}
                />
              );
            }
          })}

        {/* {Object.keys(dynamicTrayList)
                    ?.find(trayName => trayName === pageTemplate?.ScheduleandEstimateTabTray?.Name) &&
                    <DynamicTrays
                        trayName={pageTemplate?.ScheduleandEstimateTabTray?.Name}
                        contentData={combineCommonTrayProps(pageTemplate?.ScheduleandEstimateTabTray, { zipCodeList: localOfficeZipcodes }, { localOfficeList: localOfficeResults }, { franchiseDataToGetEstimate })}
                    />
                }
                {Object.keys(dynamicTrayList)
                    ?.find(trayName => trayName === pageTemplate?.ImageCopywithSlider?.Name) &&
                    <DynamicTrays
                        trayName={pageTemplate?.ImageCopywithSlider?.Name}
                        contentData={combineCommonTrayProps(pageTemplate?.ImageCopywithSlider)}
                    />
                } */}
        <FootersFranchise
          footerContent={
            franchiseHomePage &&
            franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseFooter
          }
          otherProps={
            franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl
          }
          corpFooterProps={[
            corpHomeItem && corpHomeItem[0]?.Footer?.FooterLogo,
            corpHomeItem && corpHomeItem[0]?.Footer?.TextAbovePhoneNumber,
            corpHomeItem && corpHomeItem[0]?.Footer?.Copyright,
            corpHomeItem && corpHomeItem[0]?.Footer?.CopyrightLinks?.Page,
            corpHomeItem && corpHomeItem[0]?.Footer?.DisclaimerNotes,
            franchiseHomePage && franchiseHomePage[0]?.Name,
            franchiseHomePage && franchiseHomePage[0]?.SURL,
          ]}
          headerContentForMenu={
            franchiseHomePage &&
            franchiseHomePage[0]?.FranchiseCorporateControl
              ?.FranchiseHeader?.MenuTab
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
  corpHomeItem: state?.headerReducer?.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
});

export default withRouter(
  connect(mapStateToProps)(FranchiseServiceCityDetailPage)
);
