import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchHomePage } from "../../actions/fetchData";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";
import {
  getFranchiseDataFromLS,
  getResultsArray,
  loadApiData,
  saveApiData,
} from "../../config/utility";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import DynamicTrays, { dynamicTrayList } from "../common/DynamicTrays";
import FranchiseEstimateSubmissionBreadcrumbs from "./FranchiseEstimateSubmissionBreadcrumbs/FranchiseEstimateSubmissionBreadcrumbs";
import FranchiseEstimateSubmissionAddressWrap from "./FranchiseEstimateSubmissionAddressWrap/FranchiseEstimateSubmissionAddressWrap";
import FranchiseEstimateSubmissionAddressMap from "./FranchiseEstimateSubmissionMap/FranchiseEstimateSubmissionMap";
import NewBodyCopy from "./NewBodyCopy/NewBodyCopy";
import "./index.css";
import DetailPageBanner from "../FranchiseCommon/DetailPageBanner";
import CorpEstimateSubmissionBanner from "./CorpEstimateSubmissionBanner/CorpEstimateSubmissionBanner";
import CorpEstimateSubmissionBreadcrumbs from "./CorpEstimateSubmissionBreadcrumbs/CorpEstimateSubmissionBreadcrumbs";
import HeaderFranchise from "../FranchiseCommon/Header/HeaderFranchise";
import FootersFranchise from "../FranchiseCommon/Footer/FooterFranchise";
import { FindAnOfficeTray } from "../common/Footer";
import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";

class EstimateSubmissionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      franchiseHomeData: loadApiData("franchiseHomeData"),
      locationMatch: sessionStorage.getItem("locationMatch"),
      reInitFranchiseYotrack: false,
      reInitCorpYotrack: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;

    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");
    const recentEstimateSubmissionPath = getFranchiseDataFromLS(
      "recentEstimateSubmissionPath"
    );
    const ziplocMatch = this.state.locationMatch;

    this.props.link && this.props.dispatch(fetchHomePage(this.props.link));

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }

    if (ziplocMatch && recentFranchisePath === recentEstimateSubmissionPath) {
      //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
      //then load the franchisehomepage api again.

      //scenario 2 - in else block if the cached data and the visited franchise SURLS
      //are no the same, removed the cached data from the Session storage and
      //fetch the api again.

      if (
        (this.state.franchiseHomeData?.length === 0 ||
          this.state.franchiseHomeData === undefined) &&
        franchiseHomePage?.length === 0
      ) {
        this.props.dispatch(fetchFranchiseHome(recentFranchisePath));
      } else if (
        ziplocMatch &&
        recentFranchisePath !== recentEstimateSubmissionPath
      ) {
        this.props.dispatch(fetchFranchiseHome(recentEstimateSubmissionPath));
      }
  
      if (ziplocMatch) {
        this.setState({ reInitCorpYotrack: false });
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.apiRef !== this.props.apiRef) {
      window.location.reload();
    }
  }

  render() {
    const homeItemData = getResultsArray(this.props?.homeItem, "DetailPage");
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");
    const recentEstimateSubmissionPath = getFranchiseDataFromLS(
      "recentEstimateSubmissionPath"
    );
    const ziplocMatch = this.state.locationMatch;

    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    let franchiseHomePage;
    // changed from franchiseHomePage to franchiseHomeData
    if (ziplocMatch && recentFranchisePath === recentEstimateSubmissionPath) {
      franchiseHomePage = loadApiData("franchiseHomeData");
    } else if (
      ziplocMatch &&
      recentFranchisePath !== recentEstimateSubmissionPath
    ) {
      franchiseHomePage = getResultsArray(
        this.props?.franchiseHomeItem,
        "FranchiseHomePage"
      );
    }

    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;

    let combinedPropsFindOffice;
    combinedPropsFindOffice = {
      ...{
        ...headerItemData[0]?.Footer?.FindAnOffice,
        ...{ zipCodeList: localOfficeZipcodes },
        ...{ localOfficeList: localOfficeResults },
      },
    };

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.FranchiseID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    return (
      <>
        <Helmet>
          <title>
            {pageTitle ? `${pageTitle} | ${browserSuffix}` : browserSuffix}
          </title>
          <meta
            name="description"
            content={homeItemData[0]?.Description?.Value}
          />
        </Helmet>

        {ziplocMatch && franchiseHomePage && franchiseHomePage[0] ? (
          <>
            <HeaderFranchise
              alertContent={headerItemData[0]?.Alerts}
              headerContent={
                franchiseHomePage &&
                franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseHeader
              }
              otherProps={[
                franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl,
                franchiseHomePage && franchiseHomePage[0]?.SURL,
              ]}
              corpHeaderProps={[
                headerItemData && headerItemData[0]?.Header?.BookOnlineIcon,
                headerItemData && headerItemData[0]?.Header?.GetEstimateIcon,
                headerItemData && headerItemData[0]?.Header?.Page[0],
                headerItemData && headerItemData[0]?.Header?.Page[1],
                headerItemData && headerItemData[0]?.GlobalSettings,
              ]}
            />
            <DetailPageBanner
              bannerContent={headerItemData[0]?.ContactSuccessBanner}
              headingContent={headerItemData[0]?.Heading}
              paintStrokes={[
                headerItemData[0]?.LeftCapPaintStroke,
                headerItemData[0]?.MiddlePaintStroke,
                headerItemData[0]?.RightCapPaintStroke,
              ]}
            />
            <FranchiseEstimateSubmissionBreadcrumbs
              breadcrumbData={
                franchiseHomePage && franchiseHomePage[0]?.BreadcrumbNavigation
              }
            />
            <div className="franchise-estimate-submission col-dd-6">
              <NewBodyCopy contentData={headerItemData[0]} />
              <FranchiseEstimateSubmissionAddressWrap
                contentData={franchiseHomePage && franchiseHomePage[0]}
              />
              <FranchiseEstimateSubmissionAddressMap
                contentData={franchiseHomePage && franchiseHomePage[0]}
              />
            </div>

            {franchiseHomePage &&
              franchiseHomePage[0]?.Trays?.map((tray, index) => {
                if (
                  Object.keys(dynamicTrayList)?.find(
                    (trayName) => trayName === tray?.TrayName
                  )
                ) {
                  let combinedProps;
                  if (
                    tray?.TrayName === "ScheduleandEstimateTabTray" ||
                    tray?.TrayName === "ScheduleandEstimateTray"
                  ) {
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{ zipCodeList: localOfficeZipcodes },
                        ...{ localOfficeList: localOfficeResults },
                        ...{ franchiseDataToGetEstimate },
                      },
                    };
                  } else if (tray?.TrayName === "ImageCopywithSlider") {
                    combinedProps = { ...tray };
                  } else return null;

                  return (
                    <DynamicTrays
                      trayName={tray?.TrayName}
                      contentData={combinedProps}
                      key={index}
                    />
                  );
                }
              })}

            <FootersFranchise
              footerContent={
                franchiseHomePage &&
                franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseFooter
              }
              otherProps={
                franchiseHomePage &&
                franchiseHomePage[0]?.FranchiseCorporateControl
              }
              corpFooterProps={[
                headerItemData && headerItemData[0]?.Footer?.FooterLogo,
                headerItemData &&
                  headerItemData[0]?.Footer?.TextAbovePhoneNumber,
                headerItemData && headerItemData[0]?.Footer?.Copyright,
                headerItemData &&
                  headerItemData[0]?.Footer?.CopyrightLinks?.Page,
                headerItemData && headerItemData[0]?.Footer?.DisclaimerNotes,
                franchiseHomePage && franchiseHomePage[0]?.Name,
                franchiseHomePage && franchiseHomePage[0]?.SURL,
              ]}
              headerContentForMenu={
                franchiseHomePage &&
                franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseHeader
                  ?.MenuTab
              }
            />
          </>
        ) : (
          <>
            <HeaderWrapper headerItemData={headerItemData} />
            <CorpEstimateSubmissionBanner contentData={headerItemData[0]} />
            <CorpEstimateSubmissionBreadcrumbs />
            <div className="franchise-estimate-submission col-dd-6">
              <NewBodyCopy contentData={headerItemData[0]} />
            </div>
            <div className="findoffice col-">
              <FindAnOfficeTray contentData={combinedPropsFindOffice} />
            </div>
            <FooterWrapper headerItemData={headerItemData} />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default connect(mapStateToProps)(EstimateSubmissionPage);
