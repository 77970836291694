import React, { useState } from "react";

import DynamicLinkList from "../../common/DynamicLinks";
import ChildMenuBar from "../../common/ChildMenuBarFranchise";
import { extractPath, isExternalUrl } from "../../../config/utility";
import useClickOutside from "../../common/useClickOutSide";
import "./menuBar.css";

const MenuBar = ({
  headerContent,
  showNav,
  setShowNav,
  styles,
  franchiseBaseURL,
}) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [menuItems, setMenuItems] = useState();
  let menuRef = useClickOutside(() => {
    if (!showNav) setShowNav(true);
  });

  return (
    <div className="whole-content" style={styles} ref={menuRef}>
      <div
        className={
          showNav && menuOpen ? " mobile-nav-slide" : "mobile-nav-slide active"
        }
      >
        <div className="content">
          <nav className="mobile-nav-slide active">
            <ul>
              {!showNav && (
                <>
                  <li className="top close">
                    <a href="#">
                      <i
                        className="fa-solid fa-xmark"
                        onClick={() => setShowNav(!showNav)}
                      ></i>{" "}
                      Close
                    </a>
                  </li>
                  {headerContent?.map((menuItem, index) => {
                    const isExternal = isExternalUrl(menuItem?.URL);
                    const extractedPth = extractPath(menuItem?.URL);
                    return (
                      <li className="link has-dropdown" key={index}>
                        <DynamicLinkList
                          pagePath={
                            isExternal
                              ? `${menuItem?.URL}`
                              : `${franchiseBaseURL}${extractedPth}`
                          }
                          isExternal={isExternal}
                          pageApiUrl={`${franchiseBaseURL}${extractedPth}`}
                          pageName={menuItem?.Link}
                          basePageURL={franchiseBaseURL}
                        />
                        {menuItem?.SubLink && (
                          <i
                            className="fa-solid fa-angle-right"
                            value={menuItem?.SubLink}
                            onClick={(data) => {
                              setMenuOpen(!menuOpen);
                              setMenuItems(menuItem);
                            }}
                          ></i>
                        )}
                      </li>
                    );
                  })}
                </>
              )}
              {!menuOpen && (
                <ChildMenuBar
                  menuItem={menuItems}
                  setMenuOpen={setMenuOpen}
                  franchiseBaseURL={franchiseBaseURL}
                />
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
