import { combineReducers } from "redux";
import products from "./reducers";
import headerReducer from "./headerReducer";
import serviceReducer from "./serviceReducer";
import subServiceReducer from "./subServiceReducer";
import blogReducer from "./blogReducer";
import blogPageReducer from "./blogPageReducer";
import franchiseHomePageReducer from "./franchiseHomePageReducer";
import franchiseDetailPageReducer from "./franchiseDetailPageReducer";
import localOfficeReducer from "./localOfficeReducer";
import individualBlogReducer from "./individualBlogReducer";
import page404Reducer from "./page404Reducer";
import franchiseLastVisitedReducer from "./franchiseLastVisitedReducer";
import errorBoundaryReducer from "./errorBoundaryReducer";
import individualFranchiseBlogReducer from "./individualFranchiseBlogReducer";
import franchiseHomeBlogReducer from "./franhchiseHomeBlogReducer";

export default combineReducers({
  products,
  headerReducer,
  serviceReducer,
  subServiceReducer,
  blogReducer,
  blogPageReducer,
  franchiseHomePageReducer,
  franchiseDetailPageReducer,
  localOfficeReducer,
  individualBlogReducer,
  page404Reducer,
  franchiseLastVisitedReducer,
  errorBoundaryReducer,
  individualFranchiseBlogReducer,
  franchiseHomeBlogReducer
});
