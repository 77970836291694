import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

import "./CorpEstimateSubmissionBreadcrumbs.css";

export const CorpEstimateSubmissionBreadcrumbs = () => {
    return (
        <div className="corp-estimate-submission-breadcrumb">
            <div className="breadcrumb-container">
                <ul>

                    {/* <li>
                        {index + 1 === filteredArray?.length ? 
                        <span>{`${breadCrumb?.Schema === "FranchiseHomePage" ? "Ace Hardware Painting Services " : ""}`}{breadCrumb?.Name}</span>
                            : <Link to={`/${breadCrumb?.URL}`}>{breadCrumb?.Name}</Link>}
                        {index + 1 === filteredArray?.length ? " | Contact Us" : " | "}
                    </li> */}
                    <li>
                        <Link to={'/'}>Home</Link> {" | "} Contact Us
                    </li>
                        

                </ul>
            </div>
        </div>
    )
}

export default CorpEstimateSubmissionBreadcrumbs