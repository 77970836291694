// import { Markup } from 'interweave';
import React, { useState } from "react";
// import { ingeniuxURL } from '../../config/urls';
import "./LocalOfficeNonExistent.css";
import { BsXLg } from "react-icons/bs"; //react-icon

const LocalOfficeNonExistent = ({ showModal, setShowModal }) => {
  // const [showModal, setShowModal] = useState(showComponent);
  return (
    <div
      className="no-results"
      style={{ display: `${showModal ? "block" : "none"}` }}
    >
      <span className="close" onClick={() => setShowModal(!showModal)}>
        <BsXLg className="closeIcon" />
      </span>
      We're sorry, there isn't an Ace Hardware Painting Services office in your immediate
      area.
    </div>
  );
};

export default LocalOfficeNonExistent;
