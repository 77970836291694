import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PhoneIcon from "../SvgComponents/Phone";
import { ingeniuxURL } from "../../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../../config/utility";
import { FILL_THE_FORM_FOR_ESTIMATE, OR , CTA_HEADING} from "../Constant";
import "./herobanner.css";
import GetAnEstimate from "../../FranchiseHomePage/GetAnEstimate";

const CTALink = ({ children, ...rest }) => {
  const {
    isTel = false,
    isPrimary = false,
    tel = "",
    innerText = "",
    linkTo = "",
    handleClick,
  } = rest;
  return isTel ? (
    <a
      href={`tel:${tel}`}
      className={`btn ${isPrimary ? "btn-primary" : "btn-accent"}`}
    >
      {children}{" "}
      {innerText && (
        <span className="inline-block mlr-1">
          {innerText?.endsWith(":") ? innerText : `${innerText}:`}
        </span>
      )}
      {tel && <span className="phone-number">{formatPhone(tel)}</span>}
    </a>
  ) : (
    <Link to={linkTo} className="btn-accent text-underline" onClick={handleClick}>
      {innerText}
    </Link>
  );
};

const HeroBanner = (props) => {
  const {
    Image = {},
    franchiseName = "",
    estimateTrayData = {},
    getEstimateLinkBtn = {},
  } = props;

  const [topMargin, setTopMargin] = useState("");
  const [swapPhone, setSwapPhone] = useState("");

  const [showEstimateModal, setShowEstimateModal] = useState(false);

  useEffect(() => {
    const stickyHeaderElement = document.getElementById("sticky-wrapper");
    if (!stickyHeaderElement) return;
    const elementHeight = stickyHeaderElement.clientHeight;
    setTopMargin(`${elementHeight}px`);
  }, []);

  useEffect(() => {
    let localPhone;

    let intervalId = setInterval(() => {
      localPhone = getFranchiseDataFromLS("recentFranchisPhone");
      if (localPhone) {
        setSwapPhone(localPhone);
        clearInterval(intervalId);
      }
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [swapPhone]);

  const handleModalToggle = (e) => {
    e.preventDefault();
    setShowEstimateModal((prevStatus) => !prevStatus);
  };
  return (
    <div
      className="hero-banner"
      style={{
        backgroundImage: `url(${ingeniuxURL}${Image?.Value})`,
        marginTop: topMargin,
      }}
    >
      <div className="hero-banner-container">
        <div className="hero-banner-content-holder">
          <h1 className="heading-hero">{franchiseName}</h1>
          <div className="cta-section">
            <h2 className="subheading-hero">
              {`${CTA_HEADING}:`}
            </h2>
            <CTALink
              {...{
                isTel: true,
                isPrimary: true,
                tel: swapPhone,
              }}
            >
              <PhoneIcon />
            </CTALink>
            <div className="mt-2">
              <div className="text-white">{OR}</div>
              {/* Update the getEstimate modal window hashlink at the linkTo */}
              <CTALink
                {...{
                  linkTo: `#${getEstimateLinkBtn.Name}`,
                  innerText: FILL_THE_FORM_FOR_ESTIMATE,
                }}
                handleClick={handleModalToggle}
              />
              {showEstimateModal && (
                <GetAnEstimate
                  contentData={estimateTrayData}
                  handleModalToggle={handleModalToggle}
                  showEstimateModal={showEstimateModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
