import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import PackagesTabOptions from "./PackagesTabOptions";
import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./packageTab.css";

const TabSelector = ({ tabs, currentTab, changeTab, counts }) => {
	return (
		<div className="tab-selector-container">
			<div className="tab-options">
				{tabs?.map((tab, key) => {
					return typeof tab === typeof "" ? (
						<button
							className="tab-option"
							key={key}
							onClick={() => {
								changeTab(tab);
							}}
						>
							<h2
								tabIndex={0} className={currentTab === tab ? " tab-underline" : "tabs-underline"}>{tab}</h2>
							{counts[key] > 0 && <div>{counts[key]}</div>}
						</button>
					) : (
							<div
								className="tab-option"
								key={key}
								onClick={() => {
									changeTab(tab);
								}}
							>
								<h2 tabIndex={0} className={currentTab === tab ? " tab-underline" : ""}>{tab}</h2>
								{counts[key] > 0 && <div>{counts[key]}</div>}
							</div>
						);
				})}
			</div>
		</div>
	);
};

const PackageTab = ({ contentData, categories, allData }) => {
	const [tab, setTab] = useState("SHOW ALL");
	const [hash, setHash] = useState("all")

	let location = useLocation();

	var tabsArray = ["SHOW ALL"];
	for (var i = 0; i < categories?.length; i++) {
		tabsArray.push(categories[i]?.Name);
	}

	useEffect(() => {
		const hashValue = location?.hash?.replace(/^#/, "")
		let tabVal, hashId;
		if (hashValue === "all") {
			tabVal = "SHOW ALL"
			hashId = "all"
		}
		// else if (hashValue === "interior") {
		// 	tabVal = "Interior"
		// 	hashId = "interior"
		// } else if (hashValue === "exterior") {
		// 	tabVal = "Exterior"
		// 	hashId = "exterior"
		// } 
		// else if(hashValue === "general"){
		// 	tabVal = "General"
		// 	hashId = "general"
		// }
		else if (hashValue) {
			tabVal = hashValue?.charAt(0)?.toUpperCase() + hashValue?.slice(1);
			hashId = hashValue;
		}
		else {
			tabVal = "SHOW ALL"
			hashId = ""
		}
		setTab(tabVal)
		setHash(hashId)

		setTimeout(() => {
			const name = document.getElementById(hashId)
			if (window.screen.width > 500) {
				window.scrollTo({ top: name?.offsetTop - 300, left: 0, behavior: 'smooth' });
			} else {
				window.scrollTo({ top: name?.offsetTop - 230, left: 0, behavior: 'smooth' });
			}
		}, 500)
	}, [location])

	return (
		<div className="body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
			<section id="content" className="content-section twelve">
				<div className="content-wrap">
					<div className="tabb-line row">
						{/* <TabSelector tabs={["SHOW ALL", "GENERAL", "INTERIOR", "EXTERIOR"]} currentTab={tab} changeTab={setTab} counts={[0, 0]} /> */}
						<TabSelector tabs={tabsArray} currentTab={tab} changeTab={setTab} counts={[0, 0]} />
						{/* { tab === "SHOW ALL" && <PackagesTabOptions currentHash={hash} data={contentData}/> }
                                
								{ tab === "GENERAL" && <PackagesTabOptions data={contentData?.filter(
									word => word?.PackageDetail?.IGX_Categories?.Packages?.Name === "General")}/> }
                                {tab === "INTERIOR" && <PackagesTabOptions currentHash={hash} data={contentData?.filter(
									word => word?.PackageDetail?.IGX_Categories?.Packages?.Name === "Interior")} /> }
                                {tab === "EXTERIOR" && <PackagesTabOptions currentHash={hash} data={contentData?.filter(
									word => word?.PackageDetail?.IGX_Categories?.Packages?.Name === "Exterior")} />} */}

						{tab === "SHOW ALL" ? <PackagesTabOptions currentHash={hash} data={allData} />
							:

							<PackagesTabOptions currentHash={hash}
								data={contentData?.filter(currentTab => currentTab?.Name === tab)[0]?.LocalPage ?
									contentData?.filter(currentTab => currentTab?.Name === tab)[0]?.LocalPage
									:
									contentData?.filter(currentTab => currentTab?.Name === tab)[0]?.Pages?.Page
									// data={contentData?.filter(
									// 	word => word?.PackageDetail?.IGX_Categories?.Root?.Name === tab)
									// before it was below:
									// data={contentData?.filter(
									// 	word => word?.PackageDetail?.IGX_Categories?.Packages?.Name === tab)
								} />

						}

						{/* { tab === "GENERAL" && <PackagesTabOptions data={contentData?.filter(
									word => word?.PackageDetail?.IGX_Categories?.Packages?.Name === "General")}/> }
                                {tab === "INTERIOR" && <PackagesTabOptions currentHash={hash} data={contentData?.filter(
									word => word?.PackageDetail?.IGX_Categories?.Packages?.Name === "Interior")} /> }
                                {tab === "EXTERIOR" && <PackagesTabOptions currentHash={hash} data={contentData?.filter(
									word => word?.PackageDetail?.IGX_Categories?.Packages?.Name === "Exterior")} />}  */}
					</div>
				</div>
			</section>
		</div>
	)
}

export default PackageTab;