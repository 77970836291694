import axios from "axios";
import {handleErrorBoundary,fetchIndividualFranchiseBlogError, fetchIndividualFranchiseBlogSuccess, fetchIndividualFranchiseBlogRequest } from "./action";
import { ingeniuxURL } from "../config/urls";
export function fetchIndividualFranchiseBlog(pageLink) {
  return dispatch => {
    dispatch(fetchIndividualFranchiseBlogRequest());
    axios
      .get(
        `${ingeniuxURL}api/page?path=${pageLink}`
      )
      .then(response => {
        dispatch(fetchIndividualFranchiseBlogSuccess(response.data));
      })
      .catch(error => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchIndividualFranchiseBlogError(error));
      });
  };
}
