import React, { useState, useEffect, useCallback } from "react";
import { useLocation, Link } from "react-router-dom";
import { Markup } from "interweave";
import { Helmet } from "react-helmet";

import DynamicLinkList from "../../common/DynamicLinks";
import { ingeniuxURL } from "../../../config/urls";
import {
  isExternalUrl,
  extractPath,
  getFranchiseDataFromLS,
  formatPhone,
  storeFranchiseDataToLS,
  SUMMERLIN,
} from "../../../config/utility";
import SvgPhoneIcon from "../../common/SvgPhoneIcon";
import MenuBar from "./MenuBar";
import MetaTag from "../../common/SEO/MetaTag";
import SummerlinLogo from "../CustomLogos/SummerlinLogo";
import { aceWhite } from "../../common/ErrorBoundary/images";
import { CLICK_TO_CALL, trackAnalytics } from "../../../config/gTrack";
import GetAnEstimate from "../../FranchiseHomePage/GetAnEstimate";
import "./headerFranchise.css";

const HeaderFranchise = React.memo(
  ({
    metaData,
    franchiseMetaData = [],
    alertContent,
    headerContent,
    otherProps = [],
    corpHeaderProps = [],
    metaDescription,
    isFranchiseBlogs,
    pagePath,
    estimateTrayData = {},
  }) => {
    const [otherProp, frnachiseBaseURL] = otherProps;
    const { pathname } = useLocation();
    const franchiseName = otherProp?.FranchiseName?.Value;
    //this url split required only in the case of "Summerlin" franchise
    //as we have to show defferent logo in header & footer for legal reasons
    //returns array ["offices", "summerlin"] and checking only array[1] element
    const splitFranchiseUrl =
      frnachiseBaseURL && frnachiseBaseURL?.split("/")?.filter((i) => i != "");
    const [
      bookOnlineIcon,
      getEstimateIcon,
      bookOnlineLink,
      getEstimateLink,
      globalSettings,
    ] = corpHeaderProps;

    const [showNav, setShowNav] = useState(true);
    const [swapPhone, setSwapPhone] = useState("");
    const [showEstimateModal, setShowEstimateModal] = useState(false);

    const favIcon16x16 = globalSettings?.FavIcon16x16;
    const favIcon32x32 = globalSettings?.FavIcon32x32;
    const favIconAppleTouch = globalSettings?.FavIconAppleTouch;
    const theme = globalSettings?.ThemeColor;



    const handleModalToggle = (e) => {
      e.preventDefault();
      setShowEstimateModal((prevStatus) => !prevStatus);
    };

    useEffect(() => {
      storeFranchiseDataToLS("recentFranchisPhone", otherProp?.PhoneNumber?.Value);

      let localPhone;

      let intervalId = setInterval(() => {
        localPhone = getFranchiseDataFromLS("recentFranchisPhone");
        if (localPhone) {
          setSwapPhone(localPhone);
          clearInterval(intervalId);
        }
      }, 10);

      return () => {
        clearInterval(intervalId);
      };
    }, [otherProp?.PhoneNumber?.Value])

    //GA Phone click tracker
    const handleWindowTracker = (e, gaEventName, franchiseInfo, pageUrl) => {
      e.stopPropagation();
      trackAnalytics(gaEventName, franchiseInfo, pageUrl);
    };

    const headerArray = [];
    const partition = (array) => {
      if (array) {
        let pass = [];
        let fail = [];
        array.forEach((element) => {
          if (element?.Name === "TopLink") {
            pass.push(element);
          } else {
            fail.push(element);
          }
        });
        return [pass, fail];
      }
    };
    const ourWorkData =
      otherProp &&
      otherProp?.FranchiseOwnersControlNew?.FranchisePages?.find((ele, i) => {
        if (ele?.ComponentName === "Our Work") {
          return ele;
        }
      });
    const ourWorkGallery = ourWorkData && ourWorkData?.GalleryImage;

    let headerData = headerContent?.MenuTab?.map((headerData) => {
      if (Array.isArray(headerData?.Page)) {
        const [pass, fail] = partition(headerData?.Page);
      } else {
        if (headerData?.Page?.Name === "TopLink") {
          if (headerData?.Page?.Link === "Why Choose Us") {
            headerArray.push({
              Link: headerData?.Page?.Link,
              URL: "#why-choose-us",
              Schema: headerData?.Page?.Schema,
              CustomURL: headerData?.Page?.CustomURL,
            });
          } else if (headerData?.Page?.Link === "Our Work") {
            headerArray.push({
              Link: headerData?.Page?.Link,
              URL: "#our-work",
              Schema: headerData?.Page?.Schema,
              CustomURL: headerData?.Page?.CustomURL,
            });
          } else {
            headerArray.push({
              Link: headerData?.Page?.Link,
              URL: headerData?.Page?.URL,
              Schema: headerData?.Page?.Schema,
              CustomURL: headerData?.Page?.CustomURL,
            });
          }
        }
      }
      return headerArray;
    });

    const getFranchiseName = () => {
      let finalPath = pagePath.split("/");
      let franchiseName = "";
      if (finalPath.length > 3) {
        franchiseName = finalPath[2];
      }
      return franchiseName;
    };
    return (
      <>
        <Helmet>
          <meta name="msapplication-TileColor" content={theme?.Value} />
          <meta name="theme-color" content={theme?.Value}></meta>
          <meta
            name="bowser-title-suffix"
            content={globalSettings?.BrowserTitleSuffix?.Value}
          ></meta>
          <link
            rel="shortcut icon"
            type="image/png"
            href={`${ingeniuxURL}${favIcon16x16?.Value}`}
            sizes={`${favIcon16x16?.Width}x${favIcon16x16?.Height}`}
          />
          <link
            rel="shortcut icon"
            type="image/png"
            href={`${ingeniuxURL}${favIcon32x32?.Value}`}
            sizes={`${favIcon32x32?.Width}x${favIcon32x32?.Height}`}
          />
          <link
            rel="apple-touch-icon"
            type="image/png"
            href={`${ingeniuxURL}${favIconAppleTouch?.Value}`}
            sizes={`${favIconAppleTouch?.Width}x${favIconAppleTouch?.Height}`}
          />
          {franchiseMetaData &&
            franchiseMetaData[0] &&
            franchiseMetaData[0]?.map((headMetaTag) => (
              <meta
                name={headMetaTag?.name?.Value}
                content={headMetaTag?.content?.Value}
              />
            ))}
          {franchiseMetaData &&
            franchiseMetaData[1] &&
            franchiseMetaData[1]?.map((franchiseScript) =>
              franchiseScript?.innerContent?.Value ? (
                <script
                  type={franchiseScript?.type?.Value ?? "text/javascript"}
                  src={franchiseScript?.content?.Value}
                >
                  {franchiseScript?.innerContent?.Value}
                </script>
              ) : (
                <script
                  type={franchiseScript?.type?.Value ?? "text/javascript"}
                  src={franchiseScript?.Src?.Value}
                />
              )
            )}
        </Helmet>
        {franchiseMetaData &&
          franchiseMetaData[2] &&
          franchiseMetaData[2]?.map((franchiseFooterScript) =>
            franchiseFooterScript?.innerContent?.Value ? (
              <script
                type={franchiseFooterScript?.type?.Value ?? "text/javascript"}
                src={franchiseFooterScript?.content?.Value}
              >
                {franchiseFooterScript?.innerContent?.Value}
              </script>
            ) : (
              <script
                type={franchiseFooterScript?.type?.Value ?? "text/javascript"}
                src={franchiseFooterScript?.Src?.Value}
              />
            )
          )}
        {franchiseMetaData &&
          franchiseMetaData[3] &&
          franchiseMetaData[3]?.map((franchiseNoScript) => (
            <noscript
              type={franchiseNoScript?.type?.Value ?? "text/javascript"}
              src={franchiseNoScript?.content?.Value}
            >
              {franchiseNoScript?.innerContent?.Value}
            </noscript>
          ))}
        {metaData && (
          <MetaTag
            headerMetaData={{
              ...metaData,
            }}
          />
        )}

        {metaDescription && (
          <MetaTag
            headerMetaData={{
              MetaDescription: metaDescription,
            }}
          />
        )}
        <div id="top">
          <div id="sticky-wrapper" className="sticky-wrapper is-sticky">
            <header className="franchise-header">
              {alertContent?.DisplayAlert?.Value === "True" && (
                <div
                  id="alert-bar"
                  style={{
                    display: `${alertContent?.AlertText?.Value === "" ||
                      !alertContent?.AlertText?.Value
                      ? "none"
                      : "block"
                      }`,
                  }}
                >
                  <div className="home-container fluid max">
                    <div className="row">
                      <div className="col-12">
                        <p>
                          <center>
                            <a target="_blank" href={alertContent?.Page?.URL}>
                              <Markup
                                content={alertContent?.AlertText?.Value}
                              />
                            </a>
                          </center>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="franschise-head-wrap home-container row twelve">
                <div className="container mobile-ctas">
                  <div className="row twelve">
                    <div>

                      <Link onClick={handleModalToggle} className="get-estimate-link">
                        <svg
                          style={{ marginBottom: "-3px" }}
                          id="iconQuote"
                          height="16"
                          width="16"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 23.2 32.8"
                        >
                          <title>Get A Free Estimate</title>
                          <path
                            className="st0"
                            d="M21.3 32.8H1.9c-1 0-1.9-.9-1.9-1.9v-29C0 .9.9 0 1.9 0h13c.4 0 .7.3.7.7s-.3.7-.7.7h-13c-.3 0-.5.2-.5.5v29c0 .2.2.5.5.5h19.4c.2 0 .5-.2.5-.5V8.4c0-.4.3-.7.7-.7s.7.3.7.7v22.5c0 1-.8 1.9-1.9 1.9z"
                          ></path>
                          <path
                            className="st0"
                            d="M22.5 9.1H16c-1 0-1.9-.9-1.9-1.9V.7c0-.3.2-.6.4-.7.3-.1.6-.1.8.2L23 7.9c.2.2.3.5.2.8s-.4.4-.7.4zm-6.9-6.6v4.8c0 .2.2.5.5.5h4.8l-5.3-5.3zM11.6 7.7H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h6.2c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 13.1H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 16.2H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 19.3H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM11.6 22.5H5.4c-.4 0-.7-.3-.7-.7s.3-.8.7-.8h6.2c.4 0 .7.3.7.7s-.3.8-.7.8z"
                          ></path>
                        </svg>
                        Get A Free Estimate
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row twelve">
                    <div className="logo col-sd-5 col-md-6 col-dd-3">
                      <a href={frnachiseBaseURL}>
                        {splitFranchiseUrl &&
                          splitFranchiseUrl[1]?.toLowerCase() === SUMMERLIN ? (
                          <SummerlinLogo
                            attributes={{
                              fillSolid: true,
                              fill: "#fff",
                              width: "auto",
                              height: "90px",
                            }}
                          />
                        ) : headerContent?.Logo?.Value ? (
                          <img
                            src={`${ingeniuxURL}${headerContent?.Logo?.Value}`}
                            alt={headerContent?.Logo?.AlternateText}
                          />
                        ) : (
                          ""
                        )}
                      </a>
                    </div>
                    <div>
                      <img
                        className="nav-logo-img-cover"
                        src={aceWhite}
                        alt="Ace Handyman Services Logo"
                      />
                    </div>
                    <div className="header-top-wrap col-sd-7 col-md-6 col-dd-9">
                      <div className="header-franchise-top-container">
                        {/* on Safir Mac the phone number wrapping down, to fix that gave whiteSpace nowrap */}
                        <div
                          className="header-top"
                          style={{ textAlign: "center" }}
                        >
                          <div className="franschise-anchors">

                            <Link onClick={handleModalToggle} className="get-estimate-link">
                              <svg
                                style={{ marginBottom: "-3px" }}
                                id="iconQuote"
                                height="16"
                                width="16"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 23.2 32.8"
                              >
                                <title>Get A Free Estimate</title>
                                <path
                                  className="st0"
                                  d="M21.3 32.8H1.9c-1 0-1.9-.9-1.9-1.9v-29C0 .9.9 0 1.9 0h13c.4 0 .7.3.7.7s-.3.7-.7.7h-13c-.3 0-.5.2-.5.5v29c0 .2.2.5.5.5h19.4c.2 0 .5-.2.5-.5V8.4c0-.4.3-.7.7-.7s.7.3.7.7v22.5c0 1-.8 1.9-1.9 1.9z"
                                ></path>
                                <path
                                  className="st0"
                                  d="M22.5 9.1H16c-1 0-1.9-.9-1.9-1.9V.7c0-.3.2-.6.4-.7.3-.1.6-.1.8.2L23 7.9c.2.2.3.5.2.8s-.4.4-.7.4zm-6.9-6.6v4.8c0 .2.2.5.5.5h4.8l-5.3-5.3zM11.6 7.7H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h6.2c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 13.1H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 16.2H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 19.3H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM11.6 22.5H5.4c-.4 0-.7-.3-.7-.7s.3-.8.7-.8h6.2c.4 0 .7.3.7.7s-.3.8-.7.8z"
                                ></path>
                              </svg>
                              Get A Free Estimate
                            </Link>
                          </div>

                          <div
                            className="header-phone franchise-home"
                            title="contact-us-at"
                          >
                            <div
                              className="yo-local"
                              style={{ display: "none" }}
                            >
                              <span className="yo-local-phone yo-local-href-phone"></span>
                            </div>
                            {headerContent && (
                              <div className="yo-corporate">
                                {(swapPhone ||
                                  otherProp?.PhoneNumber?.Value) && (
                                    <SvgPhoneIcon />
                                  )}
                                <a
                                  href={
                                    swapPhone
                                      ? `tel:${swapPhone}`
                                      : otherProp?.TelephoneScript?.Value
                                        ? `tel:${otherProp?.TelephoneScript?.Value}`
                                        : `tel:${otherProp?.PhoneNumber?.Value}`
                                  }
                                  onClick={(e) =>
                                    handleWindowTracker(
                                      e,
                                      CLICK_TO_CALL,
                                      franchiseName,
                                      pathname
                                    )
                                  }
                                >
                                  {swapPhone
                                    ? formatPhone(swapPhone)
                                    : otherProp?.PhoneNumber?.Value}
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div id="page-wrapper">
                        <div id="mobile-nav" className="franchise-home">
                          <i
                            className="fa-solid fa-bars"
                            onClick={() => setShowNav(!showNav)}
                          >
                            <MenuBar
                              showNav={showNav}
                              setShowNav={setShowNav}
                              headerContent={headerData && headerData[0]}
                              franchiseBaseURL={frnachiseBaseURL}
                            >
                              <div
                                id="content-wrapper"
                                style={{
                                  left: showNav ? "280px" : "65px",
                                  width: showNav
                                    ? "calc(100% - 280px)"
                                    : "calc(100% - 55px)",
                                }}
                              ></div>
                            </MenuBar>
                          </i>
                        </div>
                      </div>

                      <nav className="franchise-topnav">
                        <ul className="first-level">
                          {headerData &&
                            headerData[0]?.map((menuItem, index) => {
                              const isExternal = isExternalUrl(menuItem?.URL);
                              const extractedPth = extractPath(menuItem?.URL);
                              return (
                                <li key={index}>
                                  <DynamicLinkList
                                    pagePath={
                                      isExternal
                                        ? `${menuItem?.URL}`
                                        : `${frnachiseBaseURL}${extractedPth}`
                                    }
                                    isExternal={isExternal}
                                    pageApiUrl={`${frnachiseBaseURL}${extractedPth}`}
                                    pageName={menuItem?.Link}
                                    basePageURL={frnachiseBaseURL}
                                  />

                                  {menuItem?.SubLink && (
                                    <div className="dropdown">
                                      <div className="home-container">
                                        <div className="header col-md-4">
                                          <DynamicLinkList
                                            pagePath={`${frnachiseBaseURL}${extractedPth}`}
                                            pageApiUrl={`${frnachiseBaseURL}${extractedPth}`}
                                            pageName={menuItem?.Link}
                                            basePageURL={frnachiseBaseURL}
                                          />
                                        </div>
                                        <div className="dropdown-nav col-sd-12 col-md-8">
                                          <ul>
                                            {menuItem?.SubLink?.map(
                                              (subLink, index) => {
                                                const extractedSubPath =
                                                  extractPath(subLink?.URL);
                                                return (
                                                  <li key={index}>
                                                    <DynamicLinkList
                                                      pagePath={`${subLink?.ID
                                                        ? "/" + subLink?.URL
                                                        : frnachiseBaseURL +
                                                        extractedSubPath
                                                        }`}
                                                      pageApiUrl={`${subLink?.ID
                                                        ? "/" + subLink?.URL
                                                        : frnachiseBaseURL +
                                                        extractedSubPath
                                                        }`}
                                                      pageName={subLink?.Link}
                                                      basePageURL={
                                                        frnachiseBaseURL
                                                      }
                                                    />
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              {showEstimateModal && (
                <GetAnEstimate
                  contentData={estimateTrayData}
                  handleModalToggle={handleModalToggle}
                  showEstimateModal={showEstimateModal}
                />
              )}
            </header>
          </div>
        </div>
      </>
    );
  }
);

export default HeaderFranchise;
