import axios from "axios";
import {handleErrorBoundary, fetchBlogDataRequest, fetchBlogDataSuccess, fetchBlogDataError } from "./action";
import { ingeniuxURL } from "../config/urls";
export function fetchBlogData(a, b) {
  return dispatch => {
    dispatch(fetchBlogDataRequest());
    axios
      .get(
        `${ingeniuxURL}api/${a}?${b}`
      )
      .then(response => {
        dispatch(fetchBlogDataSuccess(response.data));
      })
      .catch(error => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchBlogDataError(error));
      });
  };
}