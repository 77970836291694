import React from "react";
import "./reviews.css";

// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ingeniuxURL } from "../../config/urls";
import { Markup } from "interweave";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    // items: 5
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    // items: 3
    items: 1,
    // partialVisibilityGutter: 80
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    // items: 2
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const settings = {
  dots: false,
  fade: true,
  centerMode: true,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
};

window.addEventListener("load", function () {
  var nextButtons = document.getElementsByClassName("slick-next");
  var prevButtons = document.getElementsByClassName("slick-prev");

  for (var i = 0; i < nextButtons.length; i++) {
    nextButtons[i].setAttribute("title", "Arrow Right");
  }
  for (var i = 0; i < prevButtons.length; i++) {
    prevButtons[i].setAttribute("title", "Arrow Left");
  }
});

const Reviews = ({ contentData }) => {
  return (
    <section className="review-twelve">
      <div
        className="reviews-wrap row lazy-bg"
        style={{
          backgroundImage: `url(${ingeniuxURL}${contentData?.BackgroundImage?.Value})`,
        }}
      >
        <div className="container">
          <div className="home-reviews">
            <Slider {...settings}>
              {Array?.isArray(contentData?.Review) ? (
                contentData?.Review?.map((reviewData, index) => {
                  return (
                    <div className="item" key={index}>
                      {/* <div className="rating">
                                                {reviewData?.Stars && [...Array(Number(reviewData?.Stars?.Value))].map((elem, index) => <span className="r-star-full" key={index}></span>)}
                                            </div> */}
                      <div className="heading">
                        {reviewData?.Heading?.Value}
                      </div>
                      <div className="text">
                        <Markup content={reviewData?.Review?.Value} />
                      </div>
                      <div className="signature">
                        {reviewData?.ReviewerName?.Value}
                      </div>
                      <div className="location">{reviewData?.Store?.Value}</div>
                    </div>
                  );
                })
              ) : (
                <div className="item">
                  {/* <div className="rating">
                                            {contentData?.Review?.Stars && [...Array(Number(contentData?.Review?.Stars?.Value))].map((elem, index) => <span className="r-star-full" key={index}></span>)}
                                        </div> */}
                  <div className="heading">
                    {contentData?.Review?.Heading?.Value}
                  </div>
                  <div className="text">
                    <Markup content={contentData?.Review?.Review?.Value} />
                  </div>
                  <div className="signature">
                    {contentData?.Review?.ReviewerName?.Value}
                  </div>
                  <div className="location">
                    {contentData?.Review?.Store?.Value}
                  </div>
                </div>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
