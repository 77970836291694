import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchHomePage } from "../../actions/fetchData";
import PackageTab from "./PackageTab";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";
import {
  getResultsArray,
  getFranchiseDataFromLS,
  loadApiData,
  saveApiData,
  deleteItemFromSS,
} from "../../config/utility";
import PackagesHowItWorksFeatured from "../FranchiseCommon/PackagesHowItWorksFeatured/PackagesHowItWorksFeatured";
import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import { FindAnOfficeTray } from "../common/Footer";

class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      franchiseHomeData: loadApiData("franchiseHomeData"),
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const rgxforSlashes = /^\/|\/$/g;
    const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");

    this.props.dispatch(fetchHomePage("/Packages"));

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }

    if (recentFranchisePath) {
      const localStateSURL =
        this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;

      //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
      //then load the franchisehomepage api again.

      //scenario 2 - in else block if the cached data and the visited franchise SURLS
      //are no the same, removed the cached data from the Session storage and
      //fetch the api again.
      if (
        (this.state.franchiseHomeData?.length === 0 ||
          this.state.franchiseHomeData === undefined) &&
        franchiseHomePage?.length === 0
      ) {
        this.props.dispatch(fetchFranchiseHome(recentFranchisePath));
      } else if (
        this.state.franchiseHomeData &&
        localStateSURL?.replace(rgxforSlashes, "") !==
          recentFranchisePath?.replace(rgxforSlashes, "")
      ) {
        deleteItemFromSS("franchiseHomeData");
        this.props.dispatch(fetchFranchiseHome(recentFranchisePath));
      }
    }
  }

  render() {
    const homeItemData = getResultsArray(this.props.homeItem, "PackagesIndex");
    const linkedItemData = getResultsArray(
      this.props?.linkedData,
      "PackageDetail"
    );
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;
    const franchiseHomeData = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults', 'franchiseHomePage'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");
    let franchiseHomePage = loadApiData("franchiseHomeData");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        apiFranchisePath?.replace(rgxforSlashes, "")
      ) {
        // console.log(localStorageFranchisePath?.replace(rgxforSlashes, '') === apiFranchisePath?.replace(rgxforSlashes, ''))
        saveApiData("franchiseHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseHomeData");
      }
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    var localPackages = [];
    for (
      let i = 0,
        len =
          franchiseHomePage &&
          franchiseHomePage[0]?.FranchiseCorporateControl
            ?.FranchiseOwnersControlNew?.Packages?.LocalPackages?.length;
      i < len;
      i++
    ) {
      localPackages.push(
        franchiseHomePage &&
          franchiseHomePage[0]?.FranchiseCorporateControl
            ?.FranchiseOwnersControlNew?.Packages?.LocalPackages[i]?.Name
      );
    }

    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;

    let combinedPropsFindOffice;
    combinedPropsFindOffice = {
      ...{
        ...headerItemData[0]?.Footer?.FindAnOffice,
        ...{ zipCodeList: localOfficeZipcodes },
        ...{ localOfficeList: localOfficeResults },
      },
    };

    var filteredData = homeItemData[0]?.TaxonomyNavigation?.Packages?.map(
      (currentTab) => {
        return {
          ...currentTab,
          LocalPage: currentTab?.Pages?.Page?.filter((currentPackage) =>
            localPackages?.includes(currentPackage?.Name)
          ),
        };
      }
    );

    var allCorpPackagesArray = [];
    for (
      var i = 0;
      i < homeItemData[0]?.TaxonomyNavigation?.Packages?.length;
      i++
    ) {
      for (
        var j = 0;
        j <
        homeItemData[0]?.TaxonomyNavigation?.Packages[i]?.Pages?.Page?.length;
        j++
      ) {
        allCorpPackagesArray.push(
          homeItemData[0]?.TaxonomyNavigation?.Packages[i]?.Pages?.Page[j]
        );
      }
    }

    return (
      <>
        <Helmet>
          <title>
            {pageTitle ? `${pageTitle} | ${browserSuffix}` : browserSuffix}
          </title>
        </Helmet>
        <HeaderWrapper
          headerItemData={{
            ...headerItemData,
            metaDescription: homeItemData[0]?.Description?.Value,
          }}
        />
        <PackagesHowItWorksFeatured
          title={homeItemData[0]?.Heading?.Value}
          bread={homeItemData[0]}
          heading={homeItemData[0]?.IntroHeading?.Value}
          content={homeItemData[0]?.IntroCopy?.Value}
          // boxTitle={homeItemData[0]?.FeaturedPackage?.Value}
          // boxHeading={homeItemData[0]?.Page?.Link}
          // boxIntro={linkedItemData[0]?.IntroCopy?.Value}
          // boxList={linkedItemData[0]?.WhatYouGet?.Value}
          // boxURL={homeItemData[0]?.Page?.URL}
          boxTitle={homeItemData[0]?.FeaturedPackageHeading?.Value}
          boxHeading={homeItemData[0]?.FeaturedPackages[0]?.Name}
          boxIntro={homeItemData[0]?.FeaturedPackages[0]?.PackageIntroCopy}
          boxList={homeItemData[0]?.FeaturedPackages[0]?.PackageWhatYouGet}
          boxURL={homeItemData[0]?.FeaturedPackages[0]?.URL}
        />

        <PackageTab
          contentData={
            franchiseHomePage && franchiseHomePage[0]
              ? filteredData
              : homeItemData[0]?.TaxonomyNavigation?.Packages
          }
          allData={
            franchiseHomePage && franchiseHomePage[0]
              ? // allCorpPackagesArray?.filter(currentPackage => localPackages?.includes(currentPackage?.Name))
                [...new Set(allCorpPackagesArray?.map(JSON.stringify))]
                  .map(JSON.parse)
                  ?.filter((currentPackage) =>
                    localPackages?.includes(currentPackage?.Name)
                  )
              : // allCorpPackagesArray
                [...new Set(allCorpPackagesArray?.map(JSON.stringify))]?.map(
                  JSON.parse
                )
          }
          categories={homeItemData[0]?.TaxonomyNavigation?.Packages}
          // before it was below:
          // categories={homeItemData[0]?.TaxonomyNavigation?.Packages}
        />
        <div className="findoffice col-">
          <FindAnOfficeTray contentData={combinedPropsFindOffice} />
        </div>

        {homeItemData[0]?.Trays?.map((tray, index) => {
          if (
            Object.keys(corpDynamicTrayList)?.find(
              (trayName) => trayName === tray?.TrayName
            )
          ) {
            let combinedProps;

            if (
              tray?.TrayName === "ScheduleandEstimateTabTray" ||
              tray?.TrayName === "ScheduleandEstimateTray"
            ) {
              combinedProps = {
                ...{
                  ...tray,
                  ...{ zipCodeList: localOfficeZipcodes },
                  ...{ localOfficeList: localOfficeResults },
                  ...{ franchiseDataToGetEstimate },
                },
              };
            } else if (tray?.TrayName === "ServicesTray") {
              combinedProps = {
                ...{
                  ...tray,
                  ...{
                    franchiseData: franchiseHomePage && franchiseHomePage[0],
                  },
                },
              };
            } else if (tray?.TrayName === "FindAnOffice") {
              combinedProps = {
                ...{
                  ...tray,
                  ...{ zipCodeList: localOfficeZipcodes },
                  ...{ localOfficeList: localOfficeResults },
                },
              };
            } else {
              combinedProps = { ...tray };
            }

            return (
              <CorpDynamicTrays
                trayName={tray?.TrayName}
                contentData={combinedProps}
                key={index}
              />
            );
          }
        })}

        <FooterWrapper headerItemData={headerItemData} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  linkedData: state.serviceReducer.serviceItem,
  subData: state.subServiceReducer.subServiceItem,
  headerItem: state.headerReducer.headerItem,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
});

export default connect(mapStateToProps)(Packages);
