import React from "react";
import { getFranchiseDataFromLS } from "../../config/utility";
import "./WhyChooseUsBanner.css";

const WhyChooseUsBanner = ({ contentData }) => {
  return (
    <div className="whychooseusbanner-body-wrap">
      <section id="why-banner">
        <div className="banner-slide why-choose-us">
          <div className="container">
            <div className="banner-text">
              <h1
                className={`header ${
                  getFranchiseDataFromLS("recentFranchisePath") !== undefined
                    ? "padding-with-last-franchise-ribbon"
                    : ""
                }`}
              >
                {contentData}
              </h1>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyChooseUsBanner;
