import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import ImageGallery from "../common/ImageGallery/";
import "./KitchenFSPDetails.css";

const KitchenFSPDetails = ({ contentData }) => {
  const ImgGallery = contentData?.PackageDetail?.ImageGallery
  return (
    <div className="kitchen-body">
      <div className="kitchenfspdetails-body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
        <section id="content" className="content-section twelve">
          <div className="content-wrap twelve">
            <div className="container row">
              <div className="col-sd-12 col-dd-12">
                <h3>{contentData?.PackageDetail?.Title?.Value}</h3>
                <Markup content={contentData?.PackageDetail?.PackageDescription?.Value} />
              </div>
            </div>
            <div className="container row">
              <h3 className="headline-border">
                {contentData?.PackageDetail?.WhatYouGetLabel?.Value}
              </h3>
              <div className="twelve">
                <div className="col-sd-12 col-dd-4 content-left pad-right">
                  <Markup content={contentData?.PackageDetail?.WhatYouGet?.Value} />
                </div>
                <div className="col-md-12 col-dd-8 content-right splash">
                  <figure>
                    {contentData?.PackageDetail?.WhatYouGetImage?.Value && <img
                      className="item"
                      src={`${ingeniuxURL}${contentData?.PackageDetail?.WhatYouGetImage?.Value}`}
                      alt={contentData?.PackageDetail?.WhatYouGetImage?.AlternateText ? contentData?.PackageDetail?.WhatYouGetImage?.AlternateText : "Kitchen Fire Safety Package"}
                      title={contentData?.PackageDetail?.WhatYouGetImage?.AlternateText ? contentData?.PackageDetail?.WhatYouGetImage?.AlternateText : "Kitchen Fire Safety Package"}
                      width="250" height="150"
                    />}
                  </figure>
                </div>
              </div>
            </div>

            {contentData?.PackageDetail?.AdditionalOptions?.Value &&
              <div className="container row">
                <h3 className="headline-border">Additional Options</h3>
                <div className="twelve">
                  <div className="options">
                    <Markup content={contentData?.PackageDetail?.AdditionalOptions?.Value} />
                  </div>
                </div>
              </div>
            }

            {ImgGallery &&
              <ImageGallery contentData={ImgGallery} />
            }

            <div className="container row">
              <div className="terms-and-conditions">
                <Markup content={contentData?.PackageDetail?.TermsAndConditions?.Value} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default KitchenFSPDetails;
