import React, { useState } from "react";
import { Markup } from "interweave";
import { useLocation } from "react-router-dom";

import "./footer.css";
import { ingeniuxURL } from "../../config/urls";
import { getFranchiseDataFromLS, formatPhone } from "../../config/utility";
import LocalOfficeNonExistent from "./LocalOfficeNonExistent";
import SvgPhoneIcon from "./SvgPhoneIcon";
import ZipChooser from "./ZipChooser";
import privacyChoices from "../../assets/images/yourprivacychoices.jpg";
import { CLICK_TO_CALL, trackAnalytics } from "../../config/gTrack";

export const FooterTop = ({
  footerContent,
  zipCodeList,
  localOfficeList,
  toSchedulingPage,
}) => {
  const [formValue, setFormValue] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const scheduleAppointmentLink = process.env.REACT_APP_DISPATHME;

  var matchingZipcode = "";
  var matchingZipcodeExtOrgId = "";
  var matchingZipcodeHomeLink = "";

  const handleChange = (e) => {
    setFormValue(e.target.value);
  };

  const handleFocus = () => {
    setIsValid(true);
    setFormValue("");
  };

  const handleSubmit = (e) => {
    // prevent page from refreshing
    e.preventDefault();
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(formValue);
    if (!isValidZip || formValue === "") {
      setIsValid(false);
      setFormValue("Please enter a valid zip code");
    }
    for (let i = 0; i < zipCodeList?.length; i++) {
      for (let j = 0; j < zipCodeList[i]?.length; j++) {
        if (formValue === zipCodeList[i][j]) {
          matchingZipcode = formValue;
          matchingZipcodeExtOrgId = localOfficeList[i]?.ExternalOrganizationId;
          matchingZipcodeHomeLink = localOfficeList[i]?.HomePageLink?.URL;
        }
      }
    }

    if (matchingZipcode !== "" && toSchedulingPage) {
      // console.log("found a matching zip code");
      setShowModal(false);
      if (matchingZipcodeExtOrgId)
        window.location.href = `${scheduleAppointmentLink}${matchingZipcodeExtOrgId}`;
      // window.location.href='https://www.google.com';
    } else if (matchingZipcode !== "" && !toSchedulingPage) {
      // console.log("found a matching zip code");
      setShowModal(false);
      window.location.pathname = matchingZipcodeHomeLink;
      // window.location.href='https://www.google.com';
    } else if (matchingZipcode === "" && isValidZip) {
      setShowModal(true);
      // boolean set to true to show modal
    }
  };
  return (
    <div>
      <footer>
        <div className="lf-cta-wrap row twelve">
          <div className="container">
            <div className="wrap-center row col-dd-8">
              <div className="cta-left col-sd-12 col-dd-6">
                <Markup
                  content={footerContent?.Heading?.FindAnOffice?.Heading?.Value}
                />
                {/* <span>Your Home. Our Expertise.</span>Find Your Local Office. */}
              </div>
              <div className="cta-right col-sd-12 col-dd-6">
                <form className="ctaSearchForm" onSubmit={handleSubmit}>
                  <span className={`input ${isValid ? "" : "error"}`}>
                    <input
                      type="text"
                      id="ctaSearchArea"
                      name="searchArea"
                      className="searchInput"
                      placeholder={
                        footerContent?.PlaceholderText?.FindAnOffice
                          ?.PlaceholderText?.Value
                      }
                      title={
                        footerContent?.PlaceholderText?.FindAnOffice
                          ?.PlaceholderText?.Value
                      }
                      value={formValue}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      maxLength="5"
                      autoComplete="off"
                      size="30"
                    />
                  </span>
                  <span className="search">
                    <button type="submit" className="searchBtn submit btn">
                      Submit
                    </button>
                  </span>
                  <LocalOfficeNonExistent
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                  <div className="instructions">
                    {
                      footerContent?.TextBelowInput?.FindAnOffice
                        ?.TextBelowInput?.Value
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export const ZipNextToHeading = (props) => {
  const [formValue, setFormValue] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const scheduleAppointmentLink = process.env.REACT_APP_DISPATHME;

  var matchingZipcode = "";
  var matchingZipcodeExtOrgId = "";
  var matchingZipcodeHomeLink = "";

  const handleChange = (e) => {
    setFormValue(e.target.value);
    console.log(formValue);
  };

  const handleFocus = () => {
    setIsValid(true);
    setFormValue("");
  };

  const handleSubmit = (e) => {
    // prevent page from refreshing
    e.preventDefault();
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(formValue);
    if (!isValidZip || formValue === "") {
      setIsValid(false);
      setFormValue("Please enter a valid zip code");
    }
    for (let i = 0; i < props?.zipCodeList?.length; i++) {
      for (let j = 0; j < props?.zipCodeList[i]?.length; j++) {
        if (formValue === props?.zipCodeList[i][j]) {
          matchingZipcode = formValue;
          matchingZipcodeExtOrgId =
            props?.localOfficeList[i]?.ExternalOrganizationId;
          matchingZipcodeHomeLink =
            props?.localOfficeList[i]?.HomePageLink?.URL;
        }
      }
    }

    if (matchingZipcode !== "" && props?.toSchedulingPage) {
      // console.log("found a matching zip code");
      setShowModal(false);
      if (matchingZipcodeExtOrgId)
        window.location.href = `${scheduleAppointmentLink}${matchingZipcodeExtOrgId}`;
      // window.location.href='https://www.google.com';
    } else if (matchingZipcode !== "" && !props?.toSchedulingPage) {
      // console.log("found a matching zip code");
      setShowModal(false);
      window.location.pathname = matchingZipcodeHomeLink;
      // window.location.href='https://www.google.com';
    } else if (matchingZipcode === "" && isValidZip) {
      setShowModal(true);
      // boolean set to true to show modal
    }
  };
  return (
    // <div>
    //   <footer>
    //     <div className="lf-cta-wrap row twelve">
    //       <div className="container">
    <div className="wrap-center row col-dd-8">
      <div className="cta-left col-sd-12 col-dd-6">
        <Markup content={props?.zipData?.Heading} />
        {/* <span>Your Home. Our Expertise.</span>Find Your Local Office. */}
      </div>
      <div className="cta-right col-sd-12 col-dd-6">
        <form className="ctaSearchForm" onSubmit={handleSubmit}>
          <span className={`input ${isValid ? "" : "error"}`}>
            <input
              type="text"
              id="ctaSearchArea"
              name="searchArea"
              className="searchInput"
              placeholder={props?.zipData?.PlaceholderText}
              title={props?.zipData?.PlaceholderText}
              value={formValue}
              onChange={handleChange}
              onFocus={handleFocus}
              maxLength="5"
              autoComplete="off"
              size="30"
            />
          </span>
          <span className="search">
            <button type="submit" className="searchBtn submit btn">
              Submit
            </button>
          </span>
          <LocalOfficeNonExistent
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <div className="instructions">{props?.zipData?.TextBelowInput}</div>
        </form>
        {/* <p
          style={{
            color: "#fff",
            lineHeight: "1.2em",
            marginTop: "25px",
            marginBottom: "0",
          }}
        >
          <Markup content={props?.zipData?.HandymanMatters} />
        </p> */}
      </div>
    </div>
    //       </div>
    //     </div>
    //   </footer>
    // </div>
  );
};

export const ZipNextToHeading2 = (props) => {
  const [formValue, setFormValue] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const scheduleAppointmentLink = process.env.REACT_APP_DISPATHME;

  var matchingZipcode = "";
  var matchingZipcodeExtOrgId = "";
  var matchingZipcodeHomeLink = "";

  const handleChange = (e) => {
    setFormValue(e.target.value);
  };

  const handleFocus = () => {
    setIsValid(true);
    setFormValue("");
  };

  const handleSubmit = (e) => {
    // prevent page from refreshing
    e.preventDefault();
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(formValue);
    if (!isValidZip || formValue === "") {
      setIsValid(false);
      setFormValue("Please enter a valid zip code");
    }
    for (let i = 0; i < props?.zipCodeList?.length; i++) {
      for (let j = 0; j < props?.zipCodeList[i]?.length; j++) {
        if (formValue === props?.zipCodeList[i][j]) {
          matchingZipcode = formValue;
          matchingZipcodeExtOrgId =
            props?.localOfficeList[i]?.ExternalOrganizationId;
          matchingZipcodeHomeLink =
            props?.localOfficeList[i]?.HomePageLink?.URL;
        }
      }
    }

    if (matchingZipcode !== "" && props?.toSchedulingPage) {
      // console.log("found a matching zip code");
      setShowModal(false);
      if (matchingZipcodeExtOrgId)
        window.location.href = `${scheduleAppointmentLink}${matchingZipcodeExtOrgId}`;
      // window.location.href='https://www.google.com';
    } else if (matchingZipcode !== "" && !props?.toSchedulingPage) {
      // console.log("found a matching zip code");
      setShowModal(false);
      window.location.pathname = matchingZipcodeHomeLink;
      // window.location.href='https://www.google.com';
    } else if (matchingZipcode === "" && isValidZip) {
      setShowModal(true);
      // boolean set to true to show modal
    }
  };
  return (
    // <div>
    //   <footer>
    //     <div className="lf-cta-wrap row twelve">
    //       <div className="container">
    <div className="wrap-center row col-dd-8">
      <div className="cta-left col-sd-12 col-dd-6">
        <Markup content={props?.zipData?.Heading} />
        {/* <span>Your Home. Our Expertise.</span>Find Your Local Office. */}
      </div>
      <div className="cta-right col-sd-12 col-dd-6">
        <form
          className="ctaSearchForm"
          onSubmit={handleSubmit}
          style={{ textAlign: "center" }}
        >
          <span className={`input ${isValid ? "" : "error"}`}>
            <input
              type="text"
              id="searchAreaMini"
              name="searchAreaMini"
              className="searchInput"
              placeholder={props?.zipData?.PlaceholderText}
              title={props?.zipData?.PlaceholderText}
              value={formValue}
              onChange={handleChange}
              onFocus={handleFocus}
              maxLength="5"
              autoComplete="off"
              size="30"
            />
          </span>
          <LocalOfficeNonExistent
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <div className="instructions">{props?.zipData?.TextBelowInput}</div>
          <span className="search">
            <button type="submit" className="submit btn">
              Submit
            </button>
          </span>
        </form>
        <p
          style={{
            color: "#fff",
            lineHeight: "1.2em",
            marginTop: "25px",
            marginBottom: "0",
          }}
        >
          <Markup content={props?.zipData?.HandymanMatters} />
        </p>
      </div>
    </div>
    //       </div>
    //     </div>
    //   </footer>
    // </div>
  );
};

export const FindAnOfficeTray = ({ contentData = {} }) => {
  const { zipCodeList = [], localOfficeList = [] } = contentData;
  return (
    <div>
      <footer>
        <div className="lf-cta-wrap row twelve">
          <div className="container">
            <ZipChooser
              submitAlignment={contentData?.SubmitAlignment?.Value}
              headingAlignment={contentData?.HeadingAlignment?.Value}
              zipInputs={{
                PlaceholderText: contentData?.PlaceholderText?.Value?.replace(
                  "zip",
                  "ZIP"
                ),
                TextBelowInput: contentData?.TextBelowInput?.Value,
                Heading: contentData?.Heading?.Value,
                HandymanMatters: contentData?.HandymanMatters?.Value,
              }}
              zipCodeList={zipCodeList}
              localOfficeList={localOfficeList}
              toSchedulingPage={false}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

const getIGXFooterLinks = (footerCol) => {
  if (footerCol && footerCol.Page) {
    const { Page } = footerCol;
    if (Array.isArray(Page)) {
      return Page.map((colFoot, index) => {
        return (
          <li key={colFoot?.URL}>
            <a
              href={
                colFoot?.Schema === "VirtualLink"
                  ? colFoot?.CustomURL
                  : `/${colFoot?.URL}`
              }
              target={colFoot?.Schema === "VirtualLink" ? "_blank" : "_self"}
            >
              {colFoot?.Name}
            </a>
          </li>
        );
      });
    } else {
      return (
        <li key={Page?.ID}>
          <a
            href={
              Page?.Schema === "VirtualLink" ? Page?.CustomURL : `/${Page?.URL}`
            }
            target={Page?.Schema === "VirtualLink" ? "_blank" : "_self"}
          >
            {Page?.Name}
          </a>
        </li>
      );
    }
  }
};

const getLocalFooterLinks = (Page) => {
  if (Page) {
    if (Array.isArray(Page)) {
      return Page.map((colFoot, index) => {
        return (
          <li key={colFoot?.URL}>
            <a
              href={
                colFoot?.Schema === "VirtualLink"
                  ? colFoot?.CustomURL
                  : `/${colFoot?.URL}`
              }
              target={colFoot?.Schema === "VirtualLink" ? "_blank" : "_self"}
            >
              {colFoot?.Name}
            </a>
          </li>
        );
      });
    } else {
      return (
        <li key={Page?.ID}>
          <a
            href={
              Page?.Schema === "VirtualLink" ? Page?.CustomURL : `/${Page?.URL}`
            }
            target={Page?.Schema === "VirtualLink" ? "_blank" : "_self"}
          >
            {Page?.Name}
          </a>
        </li>
      );
    }
  }
};

const Footers = ({ footerContent, phoneIcon, phoneNum, tel }) => {
  const localStoragePhone = getFranchiseDataFromLS("recentFranchisPhone");
  const onetrustWebform = process.env.REACT_APP_ONETRUST_WEBFORM;
  const { pathname } = useLocation();

  //GA Phone click tracker
  const handleWindowTracker = (e, gaEventName, originInfo, pageUrl) => {
    e.stopPropagation();
    trackAnalytics(gaEventName, originInfo, pageUrl);
  };
  return (
    <div>
      <footer>
        <div className="foot-wrap">
          <div className="footer-container row twelve">
            <div id="foot-left" className="col-dd-5 col-md-12 col-sd-12">
              <a className="logo" href="/">
                {footerContent?.FooterLogo?.Value && (
                  <img
                    src={`${ingeniuxURL}${footerContent?.FooterLogo?.Value}`}
                    // style={{ maxWidth: "80%" }}
                    alt={
                      footerContent?.FooterLogo?.AlternateText
                        ? footerContent?.FooterLogo?.AlternateText
                        : "Ace Hardware Painting Services logo"
                    }
                    title={
                      footerContent?.FooterLogo?.AlternateText
                        ? footerContent?.FooterLogo?.AlternateText
                        : "Ace Hardware Painting Services logo"
                    }
                    width="120"
                    height="60"
                  />
                )}
              </a>
              <div className="call-cta">
                <span>{footerContent?.TextAbovePhoneNumber?.Value}</span>
                <div className="footer-phone" title="contact-us-at">
                  <div className="yo-local">
                    <span className="yo-local-phone yo-local-href-phone"></span>
                  </div>
                  <div className="yo-corporate footer">
                    <div className="phone-svg">
                      <SvgPhoneIcon attributes={{ fill: "#d40029" }} />
                    </div>
                    <a
                      href={
                        localStoragePhone
                          ? `tel:${localStoragePhone}`
                          : `tel:${tel?.Value}`
                      }
                      onClick={(e) => {
                        handleWindowTracker(
                          e,
                          CLICK_TO_CALL,
                          "Corporate",
                          pathname
                        );
                      }}
                    >
                      {localStoragePhone
                        ? formatPhone(localStoragePhone)
                        : phoneNum?.Value}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div id="foot-mid" className="col-sd-12 col-md-10 col-dd-6">
              <div className="row">
                {footerContent?.FooterLinkColumns &&
                Array.isArray(footerContent?.FooterLinkColumns) ? (
                  footerContent?.FooterLinkColumns?.map((footerCol, index) => {
                    return (
                      <nav
                        className="resources col-md-6 col-dd-6"
                        key={footerCol?.Title}
                      >
                        <div className="heading">{footerCol?.Title}</div>
                        <ul key={footerCol?.Title}>
                          {getIGXFooterLinks(footerCol)}
                        </ul>
                      </nav>
                    );
                  })
                ) : (
                  <nav className="resources col-md-6 col-dd-6">
                    <div className="heading">
                      {footerContent?.FooterLinkColumns?.Title}
                    </div>
                    <ul>
                      {getLocalFooterLinks(
                        footerContent?.FooterLinkColumns?.Page
                      )}
                    </ul>
                  </nav>
                )}
              </div>
            </div>
            <div id="foot-right" className="col-sd-2 col-md-2 col-dd-1">
              {footerContent && (
                <span className="back-to-top-wrap">
                  <a href="#top">
                    <svg
                      id="arrowUp"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 18 24.9"
                    >
                      <title>Arrow Up</title>
                      <defs>
                        <path id="arrowUpone" d="M0 0h18v25.9H0z"></path>
                      </defs>
                      <clipPath id="arrowUpclip">
                        <use overflow="visible"></use>
                      </clipPath>
                      <path
                        className="st0"
                        d="M.3 9c-.4.3-.4.9 0 1.3.3.3.9.3 1.3 0L8 3.8V25c0 .5.4.9.9.9s.9-.4.9-.9V3.8l6.4 6.4c.4.3.9.3 1.3 0 .4-.4.4-.9 0-1.3l-8-8C9.1.6 8.6.6 8.2 1L.3 9z"
                      ></path>
                    </svg>
                    <span>
                      BACK
                      <br />
                      TO
                      <br />
                      TOP
                    </span>
                  </a>
                </span>
              )}
            </div>
            <div id="foot-bottom" className="col-sd-12">
              <div className="copy-wrap col-sd-12 col-md-12 col-dd-9">
                <Markup content={footerContent?.DisclaimerNotes?.Value} />
                <br /> <br />
                <div className="links-line">
                  &copy; {new Date().getFullYear()}{" "}
                  {footerContent?.Copyright?.Value}
                  {" | "}
                  <span>
                    {footerContent?.CopyrightLinks?.Page?.map(
                      (links, index) => {
                        return (
                          <span key={links.URL}>
                            <a key={index} href={`/${links.URL}`}>
                              {links.Name}
                            </a>
                            {index + 1 ===
                            footerContent?.CopyrightLinks?.Page?.length
                              ? ""
                              : " | "}{" "}
                          </span>
                        );
                      }
                    )}
                    {" | "}
                    <a
                      href={onetrustWebform}
                      className="privacychoices"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Your Privacy Choices{" "}
                      <img
                        src={privacyChoices}
                        alt="privacy choices"
                        width="22"
                        height="10"
                      />
                    </a>
                  </span>
                </div>
              </div>
              <div className="social-wrap col-sd-12 col-md-12 col-dd-3">
                <div id="social-wrap">
                  <ul className="social-links">
                    {footerContent?.Page?.map((icon, index) => {
                      return (
                        <li className="social" id="facebook-f" key={icon?.URL}>
                          <a href={icon?.URL} target="_blank">
                            <i className={icon?.Link}>facebook</i>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footers;
