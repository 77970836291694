import React, { useEffect, useState } from "react";
import "./blog.css";
import BlogPostContent from "../common/BlogPostContent";
import ReactPaginate from "react-paginate";
import { ingeniuxURL } from "../../config/urls";
import BlogBreadcrumbs from "./BlogBreadcrumbs";
import { getFranchiseDataFromLS } from "../../config/utility";

const Blog = ({
  contentData,
  categoryName,
  authorName,
  individualPost,
  archiveYear,
  page,
  searchInput,
  individualPostContent,
  bookOnlineLink,
  breadcrumbs,
  pagePath,
  isFranchiseBlog,
}) => {
  var data = contentData;
  var categoryData = [];
  var authorData = [];
  if (categoryName) {
    for (var i = 0; i < contentData?.results?.length; i++) {
      if (
        Array.isArray(
          contentData?.results[i]?.BlogPost?.IGX_Categories?.BlogCategories
        )
      ) {
        for (
          var j = 0;
          j <
          contentData?.results[i]?.BlogPost?.IGX_Categories?.BlogCategories
            ?.length;
          j++
        ) {
          if (
            contentData?.results[i]?.BlogPost?.IGX_Categories?.BlogCategories[j]
              ?.Name === categoryName
          ) {
            categoryData.push(contentData?.results[i]);
          }
        }
      } else if (
        contentData?.results[i]?.BlogPost?.IGX_Categories?.BlogCategories
          ?.Name === categoryName
      ) {
        categoryData.push(contentData?.results[i]);
      }
    }
  }
  if (authorName) {
    for (var i = 0; i < contentData?.results?.length; i++) {
      if (
        Array.isArray(
          contentData?.results[i]?.BlogPost?.IGX_Categories?.Authors
        )
      ) {
        for (
          var j = 0;
          j <
          contentData?.results[i]?.BlogPost?.IGX_Categories?.Authors?.length;
          j++
        ) {
          if (
            contentData?.results[i]?.BlogPost?.IGX_Categories?.Authors[
              j
            ]?.Name?.replace(/\s+/g, "") === authorName
          ) {
            authorData.push(contentData?.results[i]);
          }
        }
      } else if (
        contentData?.results[
          i
        ]?.BlogPost?.IGX_Categories?.Authors?.Name?.replace(/\s+/g, "") ===
        authorName
      ) {
        authorData.push(contentData?.results[i]);
      }
    }
  }

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // useEffect for pagination implementation
  useEffect(() => {
    // endOffset is initialized to 0 + 10 = 10
    const endOffset = itemOffset + itemsPerPage;

    // currentItems is set so that it takes the current 10 blog posts
    // itemOffset and endOffset is always updating based on what pagination page number we are on
    if (isFranchiseBlog) {
      setCurrentItems(data?.FranchiseBlogIndex?.Navigation?.Page);
    } else {
      setCurrentItems(data?.results?.slice(itemOffset, endOffset));
      // pageCount is set to the totalPages value in the api response
      setPageCount(Math.ceil(data?.totalPages));
    }
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    // event?.selected is the page number we select
    const newOffset = (event?.selected * itemsPerPage) % data?.results?.length;
    setItemOffset(newOffset);
    // setCurrentPage(event?.selected + 1);
    // if there is nothing for the category, author, archive, individualPost, search states,
    // then we will navigate to the main blog page, page number whatever the user selects
    if (
      !categoryName &&
      !authorName &&
      !archiveYear &&
      !individualPost &&
      !searchInput &&
      !isFranchiseBlog
    ) {
      window.location.pathname = `blog/page/${event?.selected + 1}`;
    }
    // if there is a search state, then we navigate to the path specifically for the search scenario
    // these scenarios are also detailed in index.js file
    else if (searchInput) {
      window.location.pathname = `blog/page/${
        event?.selected + 1
      }/search/${searchInput}`;
    }
    // if there is a archive state, then we navigate to the path specifically for the archive scenario
    // these scenarios are also detailed in index.js file
    else if (archiveYear) {
      window.location.pathname = `blog/year/${archiveYear}/page/${
        event?.selected + 1
      }`;
    }
    // if there is a category state, then we navigate to the path specifically for the category scenario
    // these scenarios are also detailed in index.js file
    else if (categoryName) {
      window.location.pathname = `blog/category/${categoryName}/page/${
        event?.selected + 1
      }`;
    }
    // if there is a author state, then we navigate to the path specifically for the author scenario
    // these scenarios are also detailed in index.js file
    else if (authorName) {
      window.location.pathname = `blog/author/${authorName}/page/${
        event?.selected + 1
      }`;
    } else if (isFranchiseBlog) {
      let finalPath = pagePath.split("/");
      let franchiseName = "";
      if (finalPath.length > 3) {
        franchiseName = finalPath[2];
      }
      window.location.pathname = `offices/${franchiseName}/blog/page/${
        event?.selected + 1
      }`;
    }
  };

  const pageLocation = () => {
    let isBlogHomePage;
    if(isFranchiseBlog){
      isBlogHomePage = window.location.pathname.endsWith("/blog");
    }else{
      isBlogHomePage = window.location.pathname === "/blog"
      
    }

    return isBlogHomePage;
  }

  const isBlogHomePage = pageLocation();

 
  return (
    <>
      <div
        id="page"
        className={
          isFranchiseBlog
            ? `site franchiseRemovePadding`
            : `site ${
                getFranchiseDataFromLS("recentFranchisePath") !== undefined
                  ? "site-padding-with-last-franchise-ribbon"
                  : ""
              }`
        }
      >
        <div className="site-inner">
          <a className="skip-link screen-reader-text" href="#content">
            Skip to content
          </a>
          <div id="blog-content" className="site-content">
            {isBlogHomePage &&  <h1 className="blog-header">Blogs</h1>}
            {individualPost && !isBlogHomePage && (
              <h1>
                {isFranchiseBlog
                  ? individualPostContent?.FranchiseBlogPost?.Heading?.Value
                  : individualPostContent?.BlogPost?.Heading?.Value}
              </h1>
            )}
            {/* moving blog detail page breadcrumbs above blog deatil header */}
            <BlogBreadcrumbs
              contentData={breadcrumbs}
              individualPostContent={individualPostContent}
              individualPost={individualPost}
              isBlogHome={isBlogHomePage}
              isFranchiseBlog={isFranchiseBlog}
            />
            {individualPost && !isBlogHomePage && (
              <div className="blog-entry-header">
                <h1 className="entry-title">
                  {individualPostContent?.BlogPost?.Heading?.Value}
                </h1>
              </div>
            )}
            <div id="primary" className="content-area">
              <main id="main" className="site-main" role="main">
                {categoryName && (
                  <div className="page-header">
                    <h1 className="page-title">
                      Category: <span>{categoryName}</span>
                    </h1>
                  </div>
                )}
                {authorName && (
                  <div className="page-header">
                    <h1 className="page-title">
                      Author: <span>{authorName}</span>
                    </h1>
                  </div>
                )}
                {archiveYear && (
                  <div className="page-header">
                    <h1 className="page-title">
                      Year: <span>{archiveYear}</span>
                    </h1>
                  </div>
                )}
                {searchInput && (
                  <div className="page-header">
                    <h1 className="page-title">
                      Search Results for:{" "}
                      <span>{searchInput.replaceAll("%20", " ")}</span>
                    </h1>
                  </div>
                )}
                {individualPost && !isBlogHomePage ? (
                  <>
                    <article
                      id="post-9251"
                      className="post-9251 post type-post status-publish format-standard hentry category-uncategorized"
                    >
                      <BlogPostContent
                        link={
                          isFranchiseBlog
                            ? individualPostContent?.FranchiseBlogPost?.SURL
                            : individualPostContent?.BlogPost?.SURL
                        }
                        title={
                          isFranchiseBlog
                            ? individualPostContent?.FranchiseBlogPost?.Heading
                                ?.Value
                            : individualPostContent?.BlogPost?.Heading?.Value
                        }
                        content={
                          isFranchiseBlog
                            ? individualPostContent?.FranchiseBlogPost?.Post?.Value.replaceAll(
                                "_Multi-Site%20Shared",
                                `${ingeniuxURL}_Multi-Site%20Shared`
                              )
                            : individualPostContent?.BlogPost?.Post?.Value?.replaceAll(
                                "Corporate%20Site",
                                `${ingeniuxURL}Corporate%20Site`
                              )
                        }
                        button={
                          isFranchiseBlog
                            ? individualPostContent?.FranchiseBlogPost?.CTAColor
                                ?.Value
                            : individualPostContent?.BlogPost?.CTAColor?.Value
                        }
                        bookOnlineLink={bookOnlineLink}
                      />
                    </article>
                  </>
                ) : (
                  <>
                    <div className="preview-cards-container">
                      {/* this is using the currentItems which changes everytime we change the pagination page number */}
                      {currentItems?.map((res) => {
                        const blogPost = isFranchiseBlog ? res : res?.BlogPost;
                        return (
                          <>
                            {isFranchiseBlog ? (
                              <BlogPostContent
                                link={res?.URL}
                                title={res?.Name}
                                isPreview
                                isFranchiseBlog
                              />
                            ) : (
                              blogPost?.Post?.Value && (
                                <BlogPostContent
                                  res={res}
                                  link={blogPost?.SURL}
                                  title={blogPost?.Heading?.Value}
                                  content={blogPost?.Post?.Value?.replaceAll(
                                    "Corporate%20Site",
                                    `${ingeniuxURL}Corporate%20Site`
                                  )}
                                  button={blogPost?.CTAColor?.Value}
                                  bookOnlineLink={bookOnlineLink}
                                  isPreview
                                />
                              )
                            )}
                          </>
                        );
                      })}
                    </div>
                    {!isFranchiseBlog && (
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        forcePage={page - 1}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={Math.ceil(pageCount)}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="page-num"
                        previousLinkClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active"
                      />
                    )}
                  </>
                )}
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
