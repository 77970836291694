import React from 'react';
import { ingeniuxURL } from '../../../../config/urls';
import "./ImageGalleryFullWidth.css";

const ImageGalleryFullWidth = ({ contentData, AddCaptions, ImagesPerRow, FullWidth }) => {

    return (
        <section id="careers-collage-content" className="content-section twelve">
            <div className="row careers-collage">
                <ul>
                    {contentData?.ImageLink?.map(imgL => {
                        return <li className={FullWidth === "False" ?
                            (ImagesPerRow !== "2" ? "category space" : "")
                            : (ImagesPerRow !== "2" ? "category non-space" : "")}>
                            {imgL?.Image?.Value && <img src={`${ingeniuxURL}${imgL?.Image?.Value}`}
                                alt={imgL?.Image?.AlternateText ? imgL?.Image?.AlternateText : ""}
                                title={imgL?.Image?.AlternateText ? imgL?.Image?.AlternateText : ""}
                            />}
                            <div className={AddCaptions === "False" ? "no-caption" : "add-caption"}>
                                <span>
                                    {imgL?.Caption?.Value}
                                </span>
                            </div>
                        </li>
                    })}
                </ul>
            </div>
        </section>
    );
};

export default ImageGalleryFullWidth;