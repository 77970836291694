import React, { useState, useEffect, useCallback } from "react";
import { Markup } from "interweave";
import { useLocation } from "react-router-dom";

import WhyChooseUsBanner from "../../WhyChooseUsPage/WhyChooseUsBanner";
import {
  formatPhone,
  getFranchiseDataFromLS,
  formatPhoneforCampaign,
  storeFranchiseDataToLS,
} from "../../../config/utility";
import { ingeniuxURL } from "../../../config/urls";
import { CLICK_TO_CALL, trackAnalytics } from "../../../config/gTrack";
import "./campaignPageBanner.css";
import "./campaignFranchise.css";

export const CampaignPageBanner = (props) => {
  const {
    bannerContent,
    headingContent,
    headerContent,
    otherProps = [],
    franchiseName = "",
    franchiseCity = "",
    franchiseState = "",
    stateAbbr = "",
  } = props;
  const [otherProp, frnachiseBaseURL] = otherProps;
  const [swapPhone, setSwapPhone] = useState("");
  
  const { pathname } = useLocation();


  useEffect(() => {
    storeFranchiseDataToLS("recentFranchisPhone", otherProp?.PhoneNumber?.Value);

    let localPhone;

    let intervalId = setInterval(() => {
      localPhone = getFranchiseDataFromLS("recentFranchisPhone");
      if (localPhone) {
        setSwapPhone(localPhone);
        clearInterval(intervalId);
      }
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [otherProp?.PhoneNumber?.Value])


  //GA Phone click tracker
  const handleWindowTracker = (e, gaEventName, franchiseInfo, pageUrl) => {
    e.stopPropagation();
    let origin = !franchiseInfo ? "Corporate" : franchiseInfo;
    trackAnalytics(gaEventName, origin, pageUrl);
  };

  return bannerContent?.Value ? (
    <section id="detail-banner">
      <div
        className="detail-page-banner campaign"
        style={{
          backgroundImage:
            bannerContent?.Value &&
            `url(${ingeniuxURL}${bannerContent?.Value})`,
        }}
      >
        <div className="container">
          <div className="row twelve">
            <div className="logo col-sd-5 col-md-6 col-dd-3">
              <div className="campaign-banner-logo">
                <a href={frnachiseBaseURL}>
                  {headerContent?.Logo?.Value ? (
                    <img
                      src={`${ingeniuxURL}${headerContent?.Logo?.Value}`}
                      alt={headerContent?.Logo?.AlternateText}
                      width="230"
                      height="90"
                    />
                  ) : (
                    ""
                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="header-phone franchise-home">
          {headerContent && (
            <div className="campaign-corporate">
              <button>
                <a
                  className="campaign-phone"
                  href={
                    swapPhone
                      ? `tel:${swapPhone}`
                      : otherProp?.TelephoneScript?.Value
                      ? `tel:${otherProp?.TelephoneScript?.Value}`
                      : `tel:${otherProp?.PhoneNumber?.Value}`
                  }
                  onClick={(e) => {
                    handleWindowTracker(
                      e,
                      CLICK_TO_CALL,
                      franchiseName,
                      pathname
                    );
                  }}
                >
                  {swapPhone
                    ? "CALL " + formatPhoneforCampaign(swapPhone)
                    : "CALL " +
                      formatPhoneforCampaign(otherProp?.PhoneNumber?.Value)}
                </a>
              </button>
            </div>
          )}
        </div>
        <div className="container">
          <div className="banner-heading-container campaign">
            <h1 className="header">
              <Markup
                content={headingContent?.Value?.replace("{City}", franchiseCity)
                  ?.replace("{State}", franchiseState)
                  ?.replace("{FranchiseName}", franchiseName)
                  ?.replace("{StateAbbreviation}", stateAbbr)
                  ?.replace(
                    "{Phone}",
                    formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
                  )}
              />
            </h1>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="why-choose-us-franchise">
      <WhyChooseUsBanner contentData={headingContent?.Value} />
    </div>
  );
};
