import React from 'react';
import ImageGalleryFullWidth from "./ImageGalleryFullWidth/ImageGalleryFullWidth";
import { ingeniuxURL } from '../../../config/urls'
import ImageGalleryHalfWidth from './ImageGalleryHalfWidth/ImageGalleryHalfWidth';

const ImageGallery = ({ contentData }) => {
    const FullWidth = contentData?.FullWidth?.Value;
    const ImagesPerRow = contentData?.ImagesPerRow?.Value
    const AddCaptions = contentData?.AddCaptions?.Value
    return (
        <>
            {
                ImagesPerRow === "4" ? <ImageGalleryFullWidth contentData={contentData} ImagesPerRow={ImagesPerRow} AddCaptions={AddCaptions} FullWidth={FullWidth} /> :
                    <ImageGalleryHalfWidth imageData={contentData}
                    FullWidth={FullWidth} ImagesPerRow={ImagesPerRow} AddCaptions={AddCaptions}  />
            }
        </>
    );
};

export default ImageGallery;