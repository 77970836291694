import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./breadcrumb.css";

export const BreadCrumb = ({ breadcrumbData }) => {
  const filteredArray = breadcrumbData?.filter(
    (breadCrumb) =>
      breadCrumb?.Schema !== "Folder" && breadCrumb?.Schema !== "PageFolder"
  );
  return (
    <div className="breadcrumb">
      <div className="breadcrumb-container">
        <ul>
          {filteredArray?.map((breadCrumb, index) => {
            return (
              <li key={index}>
                {/* rendering Home link */}
                {index === 0 && breadCrumb?.Name !== "Home" && (
                  <><Link to={`/`}>Home</Link>{" | "}</>
                )}
                {/* rendering all links except the last link */}
                {index + 1 !== filteredArray?.length ?
                  <>
                    {/* Due to data inconsistancy, added condition for franchiseHome link */}
                    <Link to={`/${breadCrumb?.URL}`} style={{ wordWrap: "break-word" }}>
                      {`${breadCrumb?.Schema === "FranchiseHomePage"
                        ? "Ace Hardware Painting Services "
                        : ""
                        }`}
                      {breadCrumb?.Name?.split("-").join(" ")}
                    </Link>
                    {" | "}
                  </>
                  :
                  // rendering the last link without anchor tag. Added condition for FranchiseHome page
                  <span style={{ display: "inline-block", marginLeft: "8px" }}>
                    {`${breadCrumb?.Schema === "FranchiseHomePage"
                      ? "Ace Hardware Painting Services " +
                      breadCrumb?.Name?.split("-").join(" ")
                      : breadCrumb?.Name?.split("-").join(" ")
                      }`}
                  </span>
                }
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default BreadCrumb;
