import { Markup } from "interweave";
import React from "react";
import { ingeniuxURL } from "../../config/urls";
import "./WhyChooseUsPromise.css";

const WhyChooseUsPromise = ({ contentData }) => {
  return (
    <div className="whychooseuspromise-body-wrap">
      <section id="content" className="content-section twelve">
        <div className="promise-wrap row">
          <figure>
            {contentData?.Image?.Value && <img
              src={`${ingeniuxURL}${contentData?.Image?.Value}`}
              alt={contentData?.Image?.AlternateText}
              title={contentData?.Image?.AlternateText}
              width="1200"
              height="650"
            />}
          </figure>
          <div className="container">
            <div className="offset-right single-border col-sd-12 col-dd-10">
              <div id="promise" className="lg-swoosh heading">
                {contentData?.Heading?.Value}
              </div>
              <Markup content={contentData?.Copy?.Value} />
              {contentData?.Page?.URL &&
                <a href={contentData?.Page?.URL} className="btn">
                  {contentData?.Page?.Link}
                </a>}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyChooseUsPromise;