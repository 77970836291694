import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./FAQBreadcrumbs.css";

const FAQBreadcrumbs = ({ contentData }) => {
  return (
    <body className="faq-body">
      <div className="faqsbreadcrumbs-body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
        <section id="content" className="content-section twelve">
          <div className="breadcrumbs">
            <div className="container">
              <ul>
                {contentData?.DetailPage?.BreadcrumbNavigation.map((breadCrum, i, row) => {
                if (i + 1 === row.length) {
                  return (
                    <li><span> {breadCrum?.Name}</span></li>
                )
                } else {
                  return (
                    <li><Link to={`/${breadCrum?.URL}`}> {breadCrum?.Name}</Link> {" | "}</li>
                )
                }       
                        })}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};

export default FAQBreadcrumbs;
