import React from "react";
import { Markup } from "interweave";
import { HashLink as Link } from "react-router-hash-link";
import { ingeniuxURL } from "../../../../config/urls";
import "./CorpImageCopyTrayRightAlignment.css";

// displyGrd={tray?.DisplayGrid?.Value}
// bgColor={tray?.BackgroundColor?.Value}
// img={tray?.Image?.Value}
// heading={tray?.Heading?.Value}
// rightTrayContent={(tray?.Copy?.Value)}
// const ingeniuxURL = "https://services.ingeniux.com/acehandymandss/";
const viewPortWidth = window.screen.width;

const CorpImageCopyTrayRightAlignment = ({ contentData }) => {
  // console.log("CONTENTDATA:", contentData);
  const isVideo = false;
  const youtubeUrl = "https://www.youtube.com/embed/CK1J_54KEPk";
  return (
    <section
      className="changing bg-grey twelve two"
      style={{
        // changed gray color from #d8d8da to #e9e9e9
        background:
          contentData?.BackgroundColor?.Value === "Gray"
            ? "#efeff0"
            : contentData?.BackgroundColor?.Value === "White"
            ? "#fff"
            : contentData?.BackgroundColor?.Value === "Gray_Gradient"
            ? "linear-gradient(to bottom,#fff 0,#d9d9d9 100%)"
            : "none",
      }}
    >
      <div className="container container-1040">
        <div className="row mb-0">
          <div
            className="left cta-container col-sd-12 col-dd-8"
            style={{
              marginRight: `${
                viewPortWidth >= 800 &&
                contentData?.DisplayGrid?.Value === "50-50" &&
                "50%"
              }`,
            }}
          >
            <h3>{contentData?.Heading?.Value}</h3>
            <Markup content={contentData?.Copy?.Value} />
            {contentData?.Page && (
              <a className="btn" target="_blank" href={contentData?.Page?.URL}>
                {contentData?.Page?.Link}
              </a>
            )}
            {/* {console.log(contentData, '8888')} */}
            {contentData?.CTA && (
              <Link
                to={`/#GetEstimateLink`}
                data-tab="get-a-quote"
                className="btn"
              >
                <span>SCHEDULE A FREE ESTIMATE</span>
              </Link>
            )}
          </div>
          <div
            className="right col-sm-12 col-dd-4"
            style={{
              boxShadow: `${
                contentData?.AddShadow?.Value === "True"
                  ? "0 4px 10px rgb(0 0 0 / 50%)"
                  : "none"
              }`,
              width: `${
                viewPortWidth >= 800 &&
                contentData?.DisplayGrid?.Value === "50-50" &&
                "50%"
              }`,
            }}
          >
            {isVideo ? (
              <iframe
                className="image-copy-Tray"
                src={youtubeUrl}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="custom"
              ></iframe>
            ) : (
              contentData?.Image?.Value && <img className="lazy loaded"
              data-src="images/home-maintenance.jpg"
              alt={contentData?.Image?.AlternateText ? contentData?.Image?.AlternateText : ""}
              title={contentData?.Image?.AlternateText ? contentData?.Image?.AlternateText : ""}
              loading="lazy"
              src={`${ingeniuxURL}${contentData?.Image?.Value}`}
              data-ll-status="loaded"
              width="420"
              height="400" 
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CorpImageCopyTrayRightAlignment;
