import React from 'react';
import { Markup } from 'interweave';

import { ingeniuxURL } from '../../config/urls';
import "./trayLeft.css";

const TrayLeft = ({leftTrayContent, heading, img, bgColor,displyGrd,CTA}) => {
    return (
        <section className="remodel bg-grey twelve"
        style={{ 
            background: bgColor === "Gray"? '#d8d8da' : `${bgColor}`
          }}>
        <div className="container container-1040">
          <div className="row mb-0">
            <div className="left col-sm-12 col-dd-4">
            {img && <img
              className="lazy loaded"
              data-src="images/home-remodel.jpg"
              alt="A bathroom with clean tile, a new sink and mirror, and modern touches."
              loading="lazy" src={`${ingeniuxURL}${img}`} data-ll-status="loaded"
              width="600"
              height="500"
               />}
            </div>
            <div className="right col-sd-12 col-dd-8">
              <h3> {heading}</h3>
              <Markup content={leftTrayContent} />
              {CTA && <a className="btn" target="_blank" href={CTA?.URL}>
                            {CTA.Link}
                        </a>}
            </div>
          </div>
        </div>
      </section>
    );
};

export default TrayLeft;

