import React from "react";

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./CommunityMattersBanner.css";

const CommunityMattersBanner = ({contentData}) => {
    return (
        <body className="philanthropy-banner">
            <div className="body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
                <section id="banner">
                    <div className="banner-slide philanthropy">
                        <div className="container">
                            <div className="banner-text">
                                <h1 className="header">{contentData?.DetailPage?.Heading?.Value}</h1>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </body >
    );
};

export default CommunityMattersBanner;