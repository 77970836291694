import React from "react";
import {
  successPage_header,
  successPage_msg1,
  successPage_msg2,
  btnText,
} from "./Constants";
import styles from "./successPage.module.css";

const SuccessPage = () => {
  const handleClick = (e) => {
    e.preventDefault();
    let path = window.location.pathname;
    let finalPath = path.split("/");
    let franchiseName = "";
    if (finalPath.length > 2) {
      franchiseName = finalPath[2];
    }

    window.location.pathname = `/offices/${franchiseName}`;
  };
  return (
    <>
      <div className={styles.mainContainerSuccess}>
        <div className={styles.header}>
          <p>{successPage_header}</p>
          <p>{successPage_msg1}</p>
          <p>{successPage_msg2}</p>
        </div>

        <div className={styles.btn}>
          <button className={styles.btnClass} onClick={handleClick}>
            {btnText}
          </button>
        </div>
      </div>
    </>
  );
};

export default SuccessPage;
