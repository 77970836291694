import React, { useState } from "react";
import { getAnEstimateHeader, subheading } from "./Constant.js";
import GetEstimateJotFrom from "../../JotForm/index.js";
import Modal from "./Modal";
import SuccessPage from "./SuccessPage/index.js";

const GetAnEstimate = ({
  contentData,
  showEstimateModal,
  handleModalToggle,
}) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const viewPortWidth = window.screen.width;

  const {
    franchiseDataToGetEstimate: franchiseData = {},
    estimateFormData = {},
    estimateFormZipData: { localOfficeList = [], zipCodeList = [] } = {},
  } = contentData;

  const jotFormData = {
    ...estimateFormData,
    ...{ localOfficeList },
    ...{ zipCodeList },
  };

  const successModalTriggeringFunc = (val) => {
    setShowSuccessModal(val);
  };

  return (
    <>
      {!showSuccessModal && showEstimateModal && (
        <Modal
          show={showEstimateModal}
          handleModalToggle={(e) => handleModalToggle(e)}
          size={{ modalWidth: "60%", modalHeight: "80%" }}
        >
          <GetEstimateJotFrom
            contentData={jotFormData}
            franchiseDataToGetEstimate={franchiseData}
            isNewFranchise={true}
            newFranchiseHeader={getAnEstimateHeader}
            newFranchiseSubheading={subheading}
            viewPortWidth={viewPortWidth}
            successModalTriggeringFunc={successModalTriggeringFunc}
          />
        </Modal>
      )}

      {showSuccessModal && (
        <Modal
          show={showSuccessModal}
          handleModalToggle={(e) => handleModalToggle(e)}
          size={{ modalWidth: "60%", modalHeight: "85%" }}
        >
          <SuccessPage viewPortWidth={viewPortWidth} />
        </Modal>
      )}
    </>
  );
};

export default GetAnEstimate;
