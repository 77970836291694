export const paintHeader =
  "Ace Hardware Painting Services, Bringing Helpful To Your Home.";

export const serviceError = "Service Unavailable. Please try again later";

export const aboutUs = "Tell us about your project";

export const footerStrings = {
  AboutUS: { value: "ABOUT US" },
  AboutUsContent: {
    value:
      "From the initial phone call to completion of your job, Ace Hardware Painting Services strives to be professional, courteous and competent.",
  },
  LocationInfo: { value: "LOCATION" },
  LocationAddress: { value: "12567 W. Cedar Dr #200 Lakewood, CO 80228" },
  TermsAndCondition: {
    value: "© Ace Hardware Painting Services . All rights reserved",
  },
};
