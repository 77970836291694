import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Page404 from "../page-404";
import HomeDetails from "./HomeDetails";
import HeaderFranchise from "../FranchiseCommon/Header/HeaderFranchise";
import FootersFranchise from "../FranchiseCommon/Footer/FooterFranchise";
import DynamicTrays, { dynamicTrayList } from "../common/DynamicTrays";
import PageLoader from "../common/PageLoader";
import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import { fetchHomePage } from "../../actions/fetchData";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import {
  getResultsArray,
  storeFranchiseDataToLS,
  getFranchiseDataFromLS,
  loadApiData,
  saveApiData,
  deleteItemFromSS,
  bgPatternTriangles,
  getScheduleEstimateTabTray,
} from "../../config/utility";
import withRouter from "../common/withRouter";
import { ingeniuxURL } from "../../config/urls";
import "./index.css";
import HeroBanner from "./HeroBanner";

class FranchiseHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      franchiseHomeData: loadApiData("franchiseHomeData"),
    };
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
    const franchiseApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const franchiseHomePage = getResultsArray(
      this.props?.homeItem,
      "FranchiseHomePage"
    );
    const corpHomeItem = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;

    //To eliminate the hash url, splitting the path.
    const franchiseAbsPath = franchiseApiPath?.split("#");
    storeFranchiseDataToLS(
      "current-franchise-url",
      franchiseAbsPath[0] ? franchiseAbsPath[0] : franchiseApiPath
    );
    const localStateSURL =
      this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;
    const localStorageSURL = getFranchiseDataFromLS("current-franchise-url");
    const rgxforSlashes = /^\/|\/$/g;

    //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
    //then load the franchisehomepage api again.

    //scenario 2 - in else block if the cached data and the visited franchise SURLS
    //are no the same, removed the cached data from the Session storage and
    //fetch the api again. Regex - replace the beginning or ending slashes if any.
    if (
      (this.state.franchiseHomeData?.length === 0 ||
        this.state.franchiseHomeData === undefined) &&
      franchiseHomePage?.length === 0
    ) {
      this.props.dispatch(fetchFranchiseHome(franchiseApiPath?.toLowerCase()));
    } else if (
      this.state.franchiseHomeData &&
      localStateSURL?.replace(rgxforSlashes, "") !==
        localStorageSURL?.replace(rgxforSlashes, "")
    ) {
      deleteItemFromSS("franchiseHomeData");
      this.props.dispatch(fetchFranchiseHome(franchiseApiPath?.toLowerCase()));
    }

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      corpHomeItem?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }

  componentDidUpdate(prevProps) {
    const reduxStateFranchiseHomePage = getResultsArray(
      this.props?.homeItem,
      "FranchiseHomePage"
    );
    const franchiseHomeSSData = loadApiData("franchiseHomeData");
    const sessionStorageSURL =
      franchiseHomeSSData && franchiseHomeSSData[0]?.SURL;
    const localStorageSURL = getFranchiseDataFromLS("current-franchise-url");
    const rgxforSlashes = /^\/|\/$/g;

    const franchiseHomePage =
      sessionStorageSURL?.replace(rgxforSlashes, "") ===
      localStorageSURL?.replace(rgxforSlashes, "")
        ? franchiseHomeSSData
        : reduxStateFranchiseHomePage;
    const franchiseCorpControl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseOwnrCtrl = franchiseCorpControl?.FranchiseOwnersControlNew;
    const featuredPkg = franchiseOwnrCtrl?.Packages?.FeaturedPackage;

    if (prevProps?.homeItem !== this.props?.homeItem) {
      const fetchReq = featuredPkg?.map((pkg) =>
        this.props.dispatch(fetchHomePage(pkg?.URL))
      );
      return fetchReq;
    }
    const franchiseApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const franchiseAbsPath = franchiseApiPath?.split("#");
    // Set new lastVisted successfully url
    if (this.props?.homeItem.totalResults === 1) {
      storeFranchiseDataToLS(
        "recentFranchisePath",
        franchiseAbsPath[0] ? franchiseAbsPath[0] : franchiseApiPath
      );
    }
  }

  render() {
    const franchiseHomeData = getResultsArray(
      this.props?.homeItem,
      "FranchiseHomePage"
    );

    //in componentDidUpdate the recent franchise path not always saved to LS
    //hence adding extra logic to save the franchisepath to LS
    const franchisePath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const franchiseAbsPath = franchisePath?.split("#");
    if (this.props?.homeItem.totalResults === 1) {
      storeFranchiseDataToLS(
        "recentFranchisePath",
        franchiseAbsPath[0] ? franchiseAbsPath[0] : franchisePath
      );
    }

    const corpHomeData = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;
    // const recentFranchisePhone = this.props?.apiRef?.pagePhone;
    //Reading the local storage data to the 'headerItemData', 'localOfficeResults', 'franchiseHomePage'
    let franchiseHomePage = loadApiData("franchiseHomeData");
    let corpHomeItem = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      //loading franchiepath from LS key recntfranchisepath
      //rather than current-franchise-path
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        apiFranchisePath?.replace(rgxforSlashes, "")
      ) {
        saveApiData("franchiseHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseHomeData");
      }
    }

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (corpHomeItem === undefined || corpHomeItem?.length === 0) &&
      corpHomeData
    ) {
      saveApiData("x31", corpHomeData);
      corpHomeItem = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    //Franchise Hero can have hero content from FranchiseHomePage.
    const franchiseHero =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseHero;

    const packageDetail = getResultsArray(
      this.props?.featurePkg,
      "PackageDetail"
    );
    const mergeData = (...objects) =>
      objects.reduce((acc, cur) => ({ ...acc, ...cur }));
    const pkgData = mergeData(packageDetail);
    // const pkgCpyName = pkgData[0]?.Name?.split(" ").join("-");
    const pkgCpyName = pkgData[0]?.Name;
    sessionStorage.setItem(
      pkgCpyName,
      JSON.stringify(pkgData[0]?.IntroCopy?.Value)
    );

    const franchiseApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;

    if (this.props?.homeItem === 1) {
      storeFranchiseDataToLS("url-path", franchiseApiPath);
    } else {
      storeFranchiseDataToLS(
        "current-franchise-url",
        getFranchiseDataFromLS("url-path")
      );
    }

    const breadcrumbsStructuredData =
      franchiseCorpCtrl?.BreadcrumbsStructuredData?.Value;
    const localBusinessStructuredData =
      franchiseCorpCtrl?.LocalBusinessStructuredData?.Value;
    const franchiseHeadMetaTag = franchiseCorpCtrl?.HeadScripts?.MetaTag;
    const franchiseHeadScriptd = franchiseCorpCtrl?.HeadScripts?.Script;
    const franchiseFooterScript = franchiseCorpCtrl?.FooterScripts?.Script;
    const franchiseFooterNoScript =
      franchiseCorpCtrl?.FooterScripts?.NoScriptTag;

    const estimateFormZipData = {
      zipCodeList: localOfficeZipcodes,
      localOfficeList: localOfficeResults,
      toSchedulingPage: true,
    };

    const estimateFormData = getScheduleEstimateTabTray(franchiseHomePage);
    const estimateTrayData = {
      ...{ estimateFormZipData },
      ...{ estimateFormData },
      ...{ franchiseDataToGetEstimate },
    };

    const getEstimateLinkBtn = corpHomeItem &&  corpHomeItem[0]?.Header?.Page[1];
    return (
      <>
        {this?.props?.loading === false ? (
          (franchiseHomePage && franchiseHomePage.length === 0) ||
          (franchiseHomePage === undefined &&
            this.props?.homeItem?.totalResults === 0) ? (
            <Page404 />
          ) : (
            <>
              <Helmet>
                <title>
                  {franchiseHomePage && franchiseHomePage[0]?.Title?.Value}
                </title>

                {/* Adding BreadcrumbsStructuredData to Head of the page */}
                {breadcrumbsStructuredData && (
                  <script type="application/ld+json">
                    {breadcrumbsStructuredData
                      ?.replace("context", "@context")
                      ?.replaceAll("type", "@type")}
                  </script>
                )}
                {/* Adding LocalBusinessStructuredData to Head of the page */}
                {localBusinessStructuredData && (
                  <script type="application/ld+json">
                    {localBusinessStructuredData
                      ?.replace("context", "@context")
                      ?.replaceAll("type", "@type")}
                  </script>
                )}
              </Helmet>
              {/* we have data based on franchise api path and data based on localoffice endpoint.
          get the value of the Name field in FranchiseHomePage object. that tells us the 
          Name of the current franchise of the page we are on.
          we should also iterate through each result of the localoffice api response
          and check if that result's FranchiseName value matches Name value of the FranchiseHomePage object.
          if it does, then get the the FranchiseId value of the appropriate localoffice result
          and convert it to an int. 
        */}
              <HeaderFranchise
                metaData={corpHomeItem[0]?.SEO}
                franchiseMetaData={[
                  franchiseHeadMetaTag,
                  franchiseHeadScriptd,
                  franchiseFooterScript,
                  franchiseFooterNoScript,
                ]}
                alertContent={corpHomeItem[0]?.Alerts}
                // alertContent={franchiseHomePage[0]?.FranchiseCorporateControl?.Alerts}
                headerContent={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseHeader
                }
                otherProps={[
                  franchiseHomePage &&
                    franchiseHomePage[0]?.FranchiseCorporateControl,
                  franchiseHomePage && franchiseHomePage[0]?.SURL,
                ]}
                corpHeaderProps={[
                  corpHomeItem && corpHomeItem[0]?.Header?.BookOnlineIcon,
                  corpHomeItem && corpHomeItem[0]?.Header?.GetEstimateIcon,
                  corpHomeItem && corpHomeItem[0]?.Header?.Page[0],
                  corpHomeItem && corpHomeItem[0]?.Header?.Page[1],
                  corpHomeItem && corpHomeItem[0]?.GlobalSettings,
                ]}
                metaDescription={
                  franchiseHomePage && franchiseHomePage[0]?.Description?.Value
                }
                estimateTrayData={estimateTrayData}
              />

              <HeroBanner
                {...{ ...franchiseHero, franchiseName, estimateTrayData, getEstimateLinkBtn }}
              />

              {franchiseHomePage &&
                franchiseHomePage[0]?.HomePageBanner?.Value && (
                  <div id="news-feed" style={{ marginTop: "-20px" }}>
                    <p>
                      {franchiseHomePage &&
                        franchiseHomePage[0]?.HomePageBanner?.Value}
                    </p>
                  </div>
                )}

              <div
                className="franchisehomepage-container"
                style={{
                  background: bgPatternTriangles
                    ? `#fff url(${ingeniuxURL}${bgPatternTriangles}) repeat top center`
                    : "#fff",
                }}
              >
                <HomeDetails
                  contentData={franchiseHomePage && franchiseHomePage[0]}
                />
              </div>

              {franchiseHomePage &&
                franchiseHomePage[0]?.Trays?.map((tray, index) => {
                  if (
                    Object.keys(dynamicTrayList)?.find(
                      (trayName) => trayName === tray?.TrayName
                    )
                  ) {
                    let combinedProps;
                    if (tray?.TrayName === "SEOTemplate") return;
                    if (tray?.TrayName === "FranchisePackages") {
                      const data =
                        franchiseHomePage &&
                        franchiseHomePage[0]?.FranchiseCorporateControl
                          ?.FranchiseOwnersControlNew?.Packages;
                      let packageCpyData = [];
                      Object.keys(sessionStorage).forEach((key) => {
                        key !== "undefined" &&
                          packageCpyData.push({
                            name: key,
                            // name: key?.split("-").join(" "),
                            copyText: sessionStorage.getItem(key),
                          });
                      });
                      combinedProps = {
                        ...{
                          ...tray,
                          ...{ otherProps: data },
                          ...{ packageCopy: packageCpyData },
                        },
                      };
                    } else if (tray?.TrayName === "ReviewCarousel") {
                      const rateaBizUId =
                        franchiseHomePage &&
                        franchiseHomePage[0]?.FranchiseCorporateControl
                          ?.RateABizUUID;
                      combinedProps = {
                        ...{
                          ...tray,
                          ...(franchiseHomePage && franchiseHomePage[0]),
                          ...{ rateaBiz: rateaBizUId },
                          ...{ reviewsTrays: tray },
                        },
                      };
                    } else if (tray?.TrayName === "ServicesTray") {
                      const otherData =
                        franchiseHomePage &&
                        franchiseHomePage[0]?.FranchiseCorporateControl;
                      combinedProps = {
                        ...{
                          ...tray,
                          ...{ otherData: otherData },
                          ...{
                            franchiseData:
                              franchiseHomePage && franchiseHomePage[0],
                            franchiseHomePage: true,
                          },
                        },
                      };
                    } else if (
                      tray?.TrayName === "ScheduleandEstimateTabTray" ||
                      tray?.TrayName === "ScheduleandEstimateTray"
                    ) {
                      combinedProps = {
                        ...{
                          ...tray,
                          ...{ zipCodeList: localOfficeZipcodes },
                          ...{ localOfficeList: localOfficeResults },
                          ...{ franchiseDataToGetEstimate },
                        },
                      };
                    } else if (tray?.TrayName === "ImageCopywithSlider") {
                      /* To remove the imagecopytraywithslider in seo city page */
                      return null;
                    } else {
                      combinedProps = { ...tray };
                    }

                    return (
                      <DynamicTrays
                        trayName={tray?.TrayName}
                        contentData={combinedProps}
                        key={index}
                      />
                    );
                  }
                })}

              <FootersFranchise
                footerContent={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseFooter
                }
                headerContentForMenu={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseHeader?.MenuTab
                }
                otherProps={
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                }
                corpFooterProps={[
                  corpHomeItem && corpHomeItem[0]?.Footer?.FooterLogo,
                  corpHomeItem && corpHomeItem[0]?.Footer?.TextAbovePhoneNumber,
                  corpHomeItem && corpHomeItem[0]?.Footer?.Copyright,
                  corpHomeItem && corpHomeItem[0]?.Footer?.CopyrightLinks?.Page,
                  corpHomeItem && corpHomeItem[0]?.Footer?.DisclaimerNotes,
                  franchiseHomePage && franchiseHomePage[0]?.Name,
                  franchiseHomePage && franchiseHomePage[0]?.SURL,
                ]}
              />
            </>
          )
        ) : (
          <PageLoader />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state?.franchiseHomePageReducer?.franchseHome,
  loading: state?.franchiseHomePageReducer?.loading,
  error: state?.franchiseHomePageReducer?.error,
  corpHomeItem: state?.headerReducer?.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  featurePkg: state.products.item,
});

export default withRouter(connect(mapStateToProps)(FranchiseHomePage));
