import React from "react";
import Collapsible from "react-collapsible";
import { Markup } from 'interweave';
import { BsChevronDown } from "react-icons/bs"; //react-icon

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./FAQContentWrap.css";

const FAQContentWrap = ({ contentData }) => {
  return (
    <body className="faq-body">
      <div className="faqscontentwrap-body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
        <section id="content" className="content-section twelve">
          <div className="content-wrap">
            <div className="container row">
              <div className="accordion">
                {
                  Array?.isArray(contentData?.AccordionItem) ?
                    contentData?.AccordionItem?.map((questions, idx) => {
                      if (idx == 0) {
                        return (
                          <div className="title">
                            <Collapsible open={true}
                              trigger={[
                                questions?.Heading?.Value,
                                <span><BsChevronDown /></span>,
                              ]}
                            >
                              <div className="content">
                                <Markup content={questions?.Copy?.Value
                                  ?.replaceAll("Images/Corporate%20Site", `${ingeniuxURL}Images/Corporate%20Site`)
                                } />
                              </div>
                            </Collapsible>
                          </div>
                        )
                      } else {
                        return (
                          <div className="title">
                            <Collapsible
                              trigger={[
                                questions?.Heading?.Value,
                                <span><BsChevronDown /></span>,
                              ]}
                            >
                              <div className="content">
                                <Markup content={questions?.Copy?.Value
                                  ?.replaceAll("Images/Corporate%20Site", `${ingeniuxURL}Images/Corporate%20Site`)
                                } />
                              </div>
                            </Collapsible>
                          </div>
                        )
                      }

                    })
                    :
                    <div className="title">
                      <Collapsible open={true}
                        trigger={[
                          contentData?.AccordionItem?.Heading?.Value,
                          <span><BsChevronDown /></span>,
                        ]}
                      >
                        <div className="content">
                          <Markup content={contentData?.AccordionItem?.Copy?.Value
                            ?.replaceAll("Images/Corporate%20Site", `${ingeniuxURL}Images/Corporate%20Site`)
                          } />
                        </div>
                      </Collapsible>
                    </div>

                }

              </div>
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};

export default FAQContentWrap;
