import React, { useEffect } from "react";
// import { saveApiData } from "../../../config/utility";

const MetaTag = ({ headerMetaData }) => {
  const { MetaTag, Script, NoScript, MetaDescription } = headerMetaData;
  useEffect(() => {
    callMeta(MetaTag);
    callScript(Script);
    callNoScript(NoScript);
  }, [MetaTag, Script, NoScript]);

  useEffect(() => {
    callMetaDescription(MetaDescription);
  }, [MetaDescription]);

  const callMeta = (metaSeo) => {
    const element = document.querySelectorAll('[is-igx-metatag="true"]');
    element.length === 0 &&
      Array.isArray(metaSeo) &&
      metaSeo.map((tag) => {
        let formedMeta;
        if (tag.name.Value) {
          const node = document.createElement("meta");
          node.setAttribute("name", tag.name.Value && tag.name.Value);
          node.setAttribute("content", tag.innercontent.Value);
          node.setAttribute("is-igx-metatag", true);
          let start_comment = new Comment(
            tag.commentlabel.Value ?? "Meta tag "
          );
          let end_comment = new Comment(
            "END " + tag.commentlabel.Value ?? "Meta tag "
          );
          const head = document.getElementById("main-head");
          formedMeta = head
            .insertBefore(node, head.firstChild)
            ?.before(start_comment)
            ?.after(end_comment);
        }
        return formedMeta;
      });
  };

  // render meta description
  const callMetaDescription = (metaDescription) => {
    const title = document.querySelector("#title");
    const description = document.querySelector("[name='description']");
    if (title) {
      let MetaDescription;
      if (description && metaDescription) {
        description.content = metaDescription;
      } else if (metaDescription) {
        const node = document.createElement("meta");
        node.setAttribute("name", "description");
        node.setAttribute("content", metaDescription);
        MetaDescription = title?.after(node);
        return MetaDescription;
      }
    }
  };
  //  script tag render inside head tag
  const callScript = (metaScript) => {
    const element = document.querySelectorAll('[is-igx-script="true"]');
    element.length === 0 &&
      Array.isArray(metaScript) &&
      metaScript.map((script) => {
        let formedScript;
        if (script.innerContent?.Value) {
          const node = document.createElement("script");
          node.setAttribute("type", script.type?.Value ?? "text/javascript");
          script?.nonce?.Value &&
            node.setAttribute("nonce", script?.nonce?.Value);
          script?.referrerpolicy?.Value &&
            node.setAttribute("referrerPolicy", script?.referrerpolicy?.Value);
          node.setAttribute("is-igx-script", true);
          const nodeContentWithSchema =
            script?.innerContent?.Value &&
            script?.innerContent?.Value?.replace(
              `"context"`,
              `"@context"`
            )?.replaceAll(`"type"`, `"@type"`);
          const nodeContent = document.createTextNode(nodeContentWithSchema);
          node.appendChild(nodeContent);
          let script_comment = new Comment(
            script.CommentLabel?.Value ?? "Script tag "
          );
          const head = document.getElementById("main-head");
          formedScript = head
            .insertBefore(node, head.firstChild)
            .before(script_comment);
        } else {
          const node = document.createElement("script");
          node.setAttribute("type", script.type?.Value ?? "text/javascript");
          node.async = script?.async?.Value ? script?.async?.Value : null;
          script?.nonce?.Value &&
            node.setAttribute("nonce", script?.nonce?.Value);
          script?.referrerpolicy?.Value &&
            node.setAttribute("referrerPolicy", script?.referrerpolicy?.Value);
          node.setAttribute("src", script.Src.Value);
          node.setAttribute("is-igx-script", true);
          let script_comment = new Comment(
            script.CommentLabel?.Value ?? "Script tag "
          );
          const head = document.getElementById("main-head");
          formedScript = head
            .insertBefore(node, head.firstChild)
            .before(script_comment);
        }
        return formedScript;
      });
  };
  //  no script tag render inside body tag
  const callNoScript = (metaNoScript) => {
    const element = document.querySelectorAll('[is-igx-no-script="true"]');
    element.length === 0 &&
      // added the below line because website was displaying oops page because NoScript object
      // inside x31 inside SEO object didn't have anything inside it
      Array.isArray(metaNoScript) &&
      metaNoScript.map((noscript) => {
        let formedNoscript;
        if (noscript?.Value) {
          const node = document.createElement("noscript");
          node.setAttribute("is-igx-no-script", true);
          const nodeContent = document.createTextNode(noscript.Value);
          node.appendChild(nodeContent);
          formedNoscript = document
            .getElementById("main-body")
            .appendChild(node);
        }
        return formedNoscript;
      });
  };

  return <></>;
};
export default MetaTag;
