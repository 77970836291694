import React from "react";
import Collapsible from "react-collapsible";
import { Markup } from "interweave";
import DynamicLinkList from "../../common/DynamicLinks";
import {
  getFranchiseDataFromLS,
  formatPhone,
  bgPatternTriangles,
} from "../../../config/utility";
import { ingeniuxURL } from "../../../config/urls";
import "./ServicePackages.css";
import WhyChooseUs from "../WhyChooseUs/WhyChooseUs";
import OurWorkFranchiseHome from "../OurWork/OurWork";

const Services = ({ contentData = {} }) => {
  const { franchiseData = {}, otherData } = contentData;
  var franchiseServicesNames = [];
  const franchiseLinkset = franchiseData?.FranchiseCorporateControl?.FranchiseOwnersControlNew
    ?.PaintingFranchiseServices;

  for (var i = 0; i < franchiseLinkset?.length; i++) {
    // if the service type has a Page array (which holds the subservices), only then should we push that service type name into our array
    if (franchiseLinkset[i]?.ServiceLinks) {
      for (var j = 0; j < franchiseLinkset[i]?.ServiceLinks?.length; j++) {
        franchiseServicesNames.push(franchiseLinkset[i]?.ServiceLinks[j]?.Name);
      }
    }
  }

  let filteredData;
  if (contentData?.franchiseHomePage && contentData?.Navigation?.Page.length === 0) {
    filteredData = franchiseLinkset?.map((currentTab) => {
      return {
        ...currentTab,
      };
    });
  }
  else if (!contentData?.franchiseHomePage && localStorage.getItem("recentFranchisePath")) {

    const franchiseSSData = JSON.parse(sessionStorage.getItem("franchiseHomeData"));
    const franchiseOnerCtrl = franchiseSSData && franchiseSSData[0]?.FranchiseCorporateControl?.FranchiseOwnersControlNew
    const franchiseSSLinkset = franchiseOnerCtrl?.PaintingFranchiseServices;

    filteredData = franchiseSSLinkset?.map((currentTab) => {
      return {
        ...currentTab,
      };
    });
  }
  else {
    filteredData = contentData?.Navigation?.Page?.map((currentTab) => {
      return {
        ...currentTab,
        LocalPage: Array.isArray(currentTab?.Page) && currentTab?.Page?.filter((currentServiceType) =>
          franchiseServicesNames?.includes(currentServiceType?.Name)
        ),
      };
    });
  }

  // to display no more than 9 items per column in localized case:
  for (var i = 0; i < filteredData?.length; i++) {
    // if there are more than 9 services for a category
    if (filteredData[i]?.LocalPage?.length > 9) {
      let ctr = 9;
      let fullLocalPage = filteredData[i]?.LocalPage;
      // only keep the first 9 of the current category we are on for a column
      filteredData[i].LocalPage = filteredData[i]?.LocalPage.slice(
        ctr - 9,
        ctr
      );
      // as long as the counter is less than the number of services for the current category
      while (ctr < fullLocalPage?.length) {
        if (i === filteredData?.length - 1) {
          // if we are on the last category in the filteredData array,
          // push another LocalPage object to the filteredData, but this object
          // will only contain an array of the remaining 9 services
          filteredData.push({
            LocalPage: fullLocalPage.slice(ctr, ctr + 9),
          });
          // otherwise, fit next to the current category, that LocalPage object
          // into the filteredData array,
          // which only contains an array of the remaining 9 services
        } else {
          filteredData.splice(i + 1, {
            LocalPage: fullLocalPage.slice(ctr, ctr + 9),
          });
        }
        // increment counter by 9
        ctr += 9;
      }
    }
  }

  // in mobile view, there was an extra collapsible that contained remaining services from the
  // third services type category, and adding the spread operator below resolved this bug
  var corpData = contentData?.Navigation?.Page?.map((currentTab) => {
    return { ...currentTab };
  });
  // to display no more than 9 items per column in non-localized case:
  for (var i = 0; i < corpData?.length; i++) {
    // if there are more than 9 services for a category
    if (corpData[i]?.Page?.length > 9) {
      let ctr = 9;
      let fullPage = corpData[i]?.Page;
      // only keep the first 9 of the current category we are on for a column
      corpData[i].Page = corpData[i]?.Page.slice(ctr - 9, ctr);
      // as long as the counter is less than the number of services for the current category
      while (ctr < fullPage?.length) {
        if (i === corpData?.length - 1) {
          // if we are on the last category in the filteredData array,
          // push another LocalPage object to the filteredData, but this object
          // will only contain an array of the remaining 9 services
          corpData.push({
            Page: fullPage.slice(ctr, ctr + 9),
          });
          // otherwise, fit next to the current category, that LocalPage object
          // into the filteredData array,
          // which only contains an array of the remaining 9 services
        } else {
          corpData.splice(i + 1, {
            Page: fullPage.slice(ctr, ctr + 9),
          });
        }
        // increment counter by 9
        ctr += 9;
      }
    }
  }

  //phoneToDisplay - is not JSX component, that return a string.
  //This function will replace the IGX dynamic value which is not rendering
  //with the return value in the replace method.
  const phoneToDisplay = () => {
    const cachedPhone = getFranchiseDataFromLS("recentFranchisPhone");
    const franchiseDefaultPhone =
      franchiseData?.FranchiseCorporateControl?.PhoneNumber?.Value;
    return `<a href=tel:${cachedPhone ? cachedPhone : franchiseDefaultPhone}>${cachedPhone ? formatPhone(cachedPhone) : franchiseDefaultPhone
      }</a>`;
  };

  return (
    <div className="fsp-home">
      <div className="remodal-bg">
        <div
          className="body-wrap"
          style={{
            backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none"
          }}
        >
          <section id="content" className="content-section twelve">
            <div className="content-wrap">
              <div
                className="wood-pattern twelve lazy-bg"
                style={{
                  backgroundImage: contentData?.BackgroundImage?.Value ? `url(${ingeniuxURL}${contentData?.BackgroundImage?.Value})` : "none"
                }}
              >
                <div className="services-wrap row container">
                  {!contentData?.franchiseHomePage
                    ? corpData?.map((Heading, ind) => {
                      return (
                        <div className="group-list col-sd-12 col-md-6 col-dd-3" key={ind}>
                          {Heading?.Name === undefined && (
                            <h4
                              className="heading toggle-heading headingnormal"
                              style={{ paddingTop: "25px" }}
                            ></h4>
                          )}
                          <h4 className="heading toggle-heading headingnormal">
                            <DynamicLinkList
                              pagePath={`/${Heading?.URL}`}
                              pageApiUrl={Heading?.URL}
                              pageName={Heading?.Name}
                              basePageURL={""}
                            />
                          </h4>
                          <div className="content contentnormal">
                            <ul>
                              {Heading?.Page?.map((link) => {
                                return <li key={link.URL}>{link?.Name}</li>;
                              })}
                            </ul>
                          </div>{" "}
                        </div>
                      );
                    })
                    : filteredData?.map((Heading, ind) => {
                      return (
                        <div className="group-list col-sd-12 col-md-6 col-dd-3" key={ind}>
                          {Heading?.Name === undefined && (
                            <h4
                              className="heading toggle-heading headingnormal"
                              style={{ paddingTop: "25px" }}
                            ></h4>
                          )}
                          <h4 className="heading toggle-heading headingnormal">
                            <DynamicLinkList
                              pagePath={`/${Heading?.URL}`}
                              pageApiUrl={Heading?.URL}
                              pageName={Heading?.Name}
                              basePageURL={""}
                            />
                          </h4>
                          <div className="content contentnormal">
                            <ul>
                              {Array.isArray(Heading?.Page) && Heading?.Page?.map((link, ind) => {
                                return (
                                  <li key={ind}> <a href={`/${link?.URL}`}>{link?.Name}</a></li>
                                )
                              })}
                            </ul>
                          </div>{" "}
                        </div>
                      );
                    })}

                  {!contentData?.franchiseHomePage && !localStorage.getItem("recentFranchisePath")
                    ? contentData?.Navigation?.Page?.map((Heading, ind) => {
                      return (
                        <div className="group-list col-sd-12 col-md-6 col-dd-3" key={ind}>
                          <Collapsible
                            trigger={
                              <h4 className="heading toggle-heading headingiphone">
                                <DynamicLinkList
                                  pagePath={`/${Heading?.URL}`}
                                  pageApiUrl={Heading?.URL}
                                  pageName={Heading?.Name}
                                  basePageURL={""}
                                />
                                <span className="fa-solid fa-angle-down arrow"></span>
                              </h4>
                            }
                          >
                            <div className="content contentiphone">
                              <ul>
                                {Array.isArray(Heading?.Page) && Heading?.Page?.map((link, ind) => {
                                  return <li key={ind}>{link?.Name}</li>;
                                })}
                              </ul>
                            </div>
                          </Collapsible>
                        </div>
                      );
                    })
                    : filteredData?.map((Heading, ind) => {
                      return (
                        <div className="group-list col-sd-12 col-md-6 col-dd-3" key={ind}>
                          <Collapsible
                            trigger={
                              <h4 className="heading toggle-heading headingiphone" style={{ maxWidth: "90%", wordBreak: "break-word" }}>
                                <DynamicLinkList
                                  pagePath={`/${Heading?.URL}`}
                                  pageApiUrl={Heading?.URL}
                                  pageName={Heading?.Name}
                                  basePageURL={""}
                                />
                                <span className="fa-solid fa-angle-down arrow"></span>
                              </h4>
                            }
                          >
                            <div className="content contentiphone">
                              <ul>
                                {Array.isArray(Heading?.Page) && Heading?.Page?.map((link, ind) => {
                                  return <li key={ind}><a href={`/${link?.URL}`}>{link?.Name}</a></li>;
                                })}
                              </ul>
                            </div>
                          </Collapsible>
                        </div>
                      );
                    })}

                  <div className="col-dd-2"></div>
                  {contentData?.franchiseHomePage ? null : (
                    <div className="services-tagline-wrap">
                      <Markup
                        content={contentData?.Footnotes?.Value?.replace(
                          "/acehandymandss",
                          ""
                        )?.replace("{phone}", phoneToDisplay())}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {otherData && <WhyChooseUs contentData={otherData} />}
      <OurWorkFranchiseHome otherData={otherData} />
    </div>
  );
};

export default Services;