import React from "react";

const SvgPhoneIcon = ({ attributes = {} }) => {
  const { fill, height, width, margin } = attributes;
  return (
    <>
      <svg
        style={{ margin: margin ? margin : "" }}
        version="1.1"
        height={height ? height : "16"}
        width={width ? width : "16"}
        fill={fill ? fill : "#000"}
        id="Layer_1"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        role="img"
        aria-labelledby="icon-text"
      >
        <path
          d="M0,48c0,256.5,207.9,464,464,464c11.3,0,20.9-7.8,23.4-18.6l24-104c2.6-11.3-3.3-22.9-14-27.6l-112-48
c-9.8-4.2-21.2-1.4-28,6.9l-49.6,60.6c-78.3-36.7-141.2-100.5-177.2-177.2l60.6-49.6c8.3-6.7,11.1-18.2,6.9-28l-48-112
C145.5,3.9,133.9-2,122.6,0.6l-104,24C7.7,27.1,0,36.8,0,48z"
        />
      </svg>
    </>
  );
};

export default SvgPhoneIcon;
