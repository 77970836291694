import React from "react"
import Modal from "react-modal";
import { BsXLg } from "react-icons/bs";


Modal.setAppElement("#root");

export const ModalWindow = ({ show, handleModalToggle, size = {}, children }) => {
    const { modalWidth, modalHeight, fitContent } = size;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    const setWidth = () => {
        let vWid;
        if (vw <= 600) {
            return vWid = "90%"
        }
        if (vw >= 600) {
            return vWid = "60%"
        }
        return vWid = modalWidth;
    }

    const setHeight = () => {
        let vHg;
        if(vw <= 400){
            return vHg = "85%"
        }
        if (vw > 400) {
            return vHg = "auto"
        }
        return vHg = modalHeight;
    }

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0,0,0,.8)",
            zIndex: 1000,
        },
        content: {
            top: "50%",
            left: "50%",
            right: "40%",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "0",
            boxShadow: "0 0 16px black",
            overflow: "visible",
            height: setHeight(),
            width: setWidth(),
        },
    };

    const closeBtn = {
        position: "absolute",
        height: "15px",
        top: "10px",
        right: "20px",
        fill: "#000",
        cursor: "pointer"
    };

    const contentStyle = {
        overflow: "auto",
        height: "100%",
        marginTop: "1rem",
        padding: "1rem"
    }

    return (

        <Modal isOpen={show} onRequestClose={handleModalToggle} style={customStyles} >
            <BsXLg size={30} style={closeBtn} onClick={handleModalToggle} />
            <div style={contentStyle}>
                {children}
            </div>
        </Modal>

    )
}

export default ModalWindow;