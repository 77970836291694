import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import "./KitchenFSPBreadcrumbs.css";

const KitchenFSPBreadcrumbs = ({contentData}) => {

  return (
    <div className="kitchen-body">
      <div className="kitchenfspbreadcrumbs-body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
        <section id="content" className="content-section twelve">
          <div className="breadcrumbs">
            <div className="container">
              <ul>
              {contentData?.PackageDetail?.BreadcrumbNavigation?.map((breadCrum, i, row) => {
                if (i + 1 === row.length) {
                  return (
                    <li><span> {breadCrum?.Name}</span></li>
                )
                } else {
                  return (
                    <li><Link to={`/${breadCrum?.URL}`}> {breadCrum?.Name}</Link> {" | "}</li>
                )
                }       
                        })}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default KitchenFSPBreadcrumbs;
