import React, { Component } from "react";
import { connect } from "react-redux";
import FranchiseWhyChooseUs from "../../FranchiseWhyChooseUs";
import { fetchHomePage } from "../../../actions/fetchData";
import { fetchHeaderPage } from "../../../actions/fetchHeaderData";
import {
  getResultsArray,
  loadApiData,
  saveApiData,
} from "../../../config/utility";
import { fetchLocalOfficeData } from "../../../actions/fetchLocalOfficeData";
import { corpDynamicTrayList, CorpDynamicTrays } from "../../common/DynamicTrays";
import withRouter from "../../common/withRouter";

class WhyChooseUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
          globalSiteCrl: loadApiData("x31"),
          localOfficeArray: loadApiData("localoffice"),
          loading: false,
        };
      }

      componentDidMount() {
        window.scrollTo(0, 0);
        const pageApiPath = "why-choose-us"
        
        const localOfficeResults = this?.props?.localOfficeItem;
        const headerItemData = getResultsArray(
          this.props?.headerItem,
          "GlobalSiteControl"
        );
        this.props.dispatch(fetchHomePage(pageApiPath));
    
        if (
          (this.state.globalSiteCrl?.length === 0 ||
            this.state.globalSiteCrl === undefined) &&
          headerItemData?.length === 0
        ) {
          this.props.dispatch(fetchHeaderPage("x31"));
        }
        if (
          (this.state.localOfficeArray?.length === 0 ||
            this.state.localOfficeArray === undefined) &&
          localOfficeResults?.length === 0
        ) {
          this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
        }

      }
    
  render() {

    const homeItemData = getResultsArray(this.props?.homeItem, "DetailPage");
    
    const franchiseHomeData = loadApiData("franchiseHomeData")
    const franchiseCorpControl =
    franchiseHomeData && franchiseHomeData[0]?.FranchiseCorporateControl;
    const franchiseOwnersControl = franchiseCorpControl?.FranchiseOwnersControlNew;
    const WhyChooseUs = franchiseOwnersControl?.FranchisePages?.find((ele, i)=> {
      if(ele?.ComponentName === "Why Choose Us") {
        return ele
      } 
    });
    const franchiseName = franchiseCorpControl?.FranchiseName?.Value;
    const franchiseCity = franchiseCorpControl?.City?.Value;
    const franchiseState = franchiseCorpControl?.State?.Value;
    const stateAbbr = franchiseCorpControl?.StateAbbreviation?.Value;
    const dataforReplaceMethod = { franchiseName, franchiseCity, franchiseState, stateAbbr }
    const combinedProps = { WhyChooseUs, dataforReplaceMethod }

    const isSEOTemplateTrayPresent =
    Array.isArray(franchiseHomeData[0]?.Trays) &&
    franchiseHomeData[0]?.Trays?.filter(
      (page, index) => page?.TrayName === "SEOTemplate"
    );
    
    return (
      <div id="why-choose-us">
        <div style={{ textAlign: "center", color: "white", marginBottom: "20px", backgroundColor: "#d40029" }}>
          <h2 style={{ color: "white" }}>Why Choose Us</h2>
        </div>

        <FranchiseWhyChooseUs contentData={combinedProps} />

        {/* {isSEOTemplateTrayPresent.length > 0 && isSEOTemplateTrayPresent[0]?.Copy?.Value && ( */}
        {homeItemData && homeItemData[0]?.Trays?.map((tray, index) => {
          let combinedProps;
          if (
            Object.keys(corpDynamicTrayList)?.find(
              (trayName) => trayName === tray?.TrayName
            )
          ) {
            if (tray?.TrayName === "BodyCopyTray") {
              combinedProps = { ...tray };
            }
            else if (tray?.TrayName === "IconTray") {
              combinedProps = { ...tray };
            }
            else return;
          }
          return (
            <CorpDynamicTrays
              trayName={tray?.TrayName}
              contentData={combinedProps}
              key={index}
            />
          )
        })} 
        
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  homeItem: state.products.item,
});

export default withRouter(connect(mapStateToProps)(WhyChooseUs));
