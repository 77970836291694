import React from "react";
import { ingeniuxURL } from "../../config/urls";
import "./campaignForm.css";
import { footerStrings } from "./Constant.js";

const Footer = ({ homePageData }) => {
  return (
    <div className="red-bg ">
      <div className="container main-cont">
        <div className="logo-div">
          <img
            src={`${ingeniuxURL}${homePageData?.FranchiseHeader?.Logo?.Value}`}
            alt={homePageData?.FranchiseHeader?.Logo?.AlternateText}
            width="230"
            height="90"
          ></img>
        </div>
        <div className="about-us">
          <h3>{footerStrings.AboutUS.value}</h3>
          <span>{footerStrings.AboutUsContent.value}</span>
        </div>
        <div className="locations">
          <h3>{footerStrings.LocationInfo.value}</h3>
          <span>{homePageData?.Address?.Value}{" "}{homePageData?.City?.Value}{", "}{homePageData?.State?.Value}{" "}{homePageData?.ZipCode?.Value}</span>
        </div>
      </div>
      <div className="termsandcondition">
        <span>{footerStrings.TermsAndCondition.value}</span>
      </div>
    </div>
  );
};

export default Footer;
