let initialState = {
    loading: false,
    item: [],
    error: null
  };
  
  function localOfficeReducer(state = initialState, action) {
    switch (action.type) {
      case "FETCH_LOCAL_OFFICE_REQUEST":
        return {
          ...state,
          loading: true,
          error: null
        };
      case "FETCH_LOCAL_OFFICE_SUCCESS":
        return {
          ...state,
          loading: false,
          item: action.item
        };
      case "FETCH_LOCAL_OFFICE_ERROR":
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          item: []
        };
      default:
        return state;
    }
  }
  
  export default localOfficeReducer;
  