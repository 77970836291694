import React from 'react'

const PhoneIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.1488 13.7633L16.9292 11.9543C16.0093 11.5555 14.9252 11.8207 14.2995 12.5966L13.1205 14.0361C11.1963 12.9297 9.58453 11.3183 8.47979 9.43412L9.92141 8.25332C10.691 7.62512 10.9565 6.54745 10.5668 5.63006L8.74792 1.35506C8.31135 0.35818 7.22939 -0.185809 6.17536 0.0578263L2.25443 0.962756C1.22833 1.19737 0.510742 2.09843 0.510742 3.15375C0.510742 13.548 8.96277 22 19.357 22C20.4123 22 21.3134 21.2828 21.5097 20.2585L22.4147 16.3337C22.7 15.2758 22.15 14.1929 21.1488 13.7633ZM20.4441 15.8687L19.5379 19.7965C19.5178 19.8438 19.4473 19.9375 19.3566 19.9375C10.1011 19.9375 2.57287 12.4093 2.57287 3.15379C2.57287 3.06382 2.6286 2.99335 2.71656 2.97319L6.64136 2.06741C6.65479 2.06405 6.66889 2.0627 6.68231 2.0627C6.75682 2.0627 6.82665 2.10769 6.85621 2.17481L8.66693 6.39996C8.69915 6.47585 8.67767 6.56445 8.61322 6.61747L6.51782 8.29758C6.14721 8.60103 6.03442 9.12001 6.24523 9.5497C7.66364 12.4385 10.0377 14.8134 12.9269 16.2314C13.3179 16.4422 13.8761 16.3294 14.1799 15.9588L15.8991 13.8589C15.9467 13.7985 16.036 13.7756 16.1106 13.8106L20.3327 15.6191C20.414 15.6926 20.4613 15.7828 20.4441 15.8687Z" fill="white" />
        </svg>
    )
}

export default PhoneIcon
