import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchLastVistedFranchise } from "../../actions/fetchLastVistedFranchise";
import Header from "../common/Header";
import {
  getResultsArray,
  getFranchiseDataFromLS,
  storeFranchiseDataToLS,
  saveApiData,
  loadApiData,
  deleteItemFromSS,
} from "../../config/utility";
import { ingeniuxURL } from "../../config/urls";
import MetaTag from "./SEO/MetaTag";

class HeaderWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      franchiseHomeData: loadApiData("franchiseLVHomeData"),
    };
  }
  componentDidUpdate() {
    if (this?.props?.error?.status === 503) {
      console.log("page error");
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    //Data passed from Parent component if lost and coming as undefined,
    //setting the value in local state and reading from LocalStorage
    const headerItemData = this.props.headerItemData;
    const franchiseHomePage = getResultsArray(
      this.props?.franchseLastVisited,
      "FranchiseHomePage"
    );
    const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");
    const recentSwappedPhone = getFranchiseDataFromLS("recentFranchisPhone");
    const rgxforSlashes = /^\/|\/$/g;
    const handymanId = process.env.REACT_APP_HANDYMANID;

    if (headerItemData === undefined || headerItemData?.length === 0) {
      this.setState({ globalSiteCrl: loadApiData("x31") });
    }

    if (recentFranchisePath) {
      const localStateSURL =
        this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;
      const franchiseData =
        this.state.franchiseHomeData && this.state.franchiseHomeData[0];

      //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
      //then load the franchisehomepage api again.

      //scenario 2 - in else block if the cached data and the visited franchise SURLS
      //are no the same, removed the cached data from the Session storage and
      //fetch the api again.

      if (
        (this.state.franchiseHomeData?.length === 0 ||
          this.state.franchiseHomeData === undefined) &&
        franchiseHomePage?.length === 0
      ) {
        this.props.dispatch(
          fetchLastVistedFranchise(recentFranchisePath?.toLowerCase())
        );
      } else if (
        this.state.franchiseHomeData &&
        localStateSURL?.replace(rgxforSlashes, "") !==
          recentFranchisePath?.replace(rgxforSlashes, "")
      ) {
        deleteItemFromSS("franchiseLVHomeData");
        this.props.dispatch(
          fetchLastVistedFranchise(recentFranchisePath?.toLowerCase())
        );
      }
    }
  }

  render() {
    //if 'headerItemData' present, using as it is, otherwise taking the data from the local state
    const headerItemData = this.props.headerItemData
      ? this.props.headerItemData
      : this.state.globalSiteCrl;
    const franchiseHomeData = getResultsArray(
      this.props?.franchseLastVisited,
      "FranchiseHomePage"
    );
    const globalSettings = headerItemData && headerItemData[0]?.GlobalSettings;
    const favIcon16x16 = globalSettings?.FavIcon16x16;
    const favIcon32x32 = globalSettings?.FavIcon32x32;
    const favIconAppleTouch = globalSettings?.FavIconAppleTouch;
    const theme = globalSettings?.ThemeColor;
    const corpDisplayPhone =
      headerItemData && headerItemData[0]?.TelephoneNumberDisplay?.Value;
    const corpTel =
      headerItemData && headerItemData[0]?.MainTelephoneNumber?.Value;
    sessionStorage.setItem("corpDisPhone", corpDisplayPhone);
    sessionStorage.setItem("corpTel", corpTel);
    const recentSwappedPhone = getFranchiseDataFromLS("recentFranchisPhone");

    //Reading the local storage data to the 'franchiseHomePage'
    let franchiseHomePage = loadApiData("franchiseLVHomeData");

    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        apiFranchisePath?.replace(rgxforSlashes, "")
      ) {
        saveApiData("franchiseLVHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseLVHomeData");
      }
    }

    return (
      <>
        <Helmet>
          <meta name="msapplication-TileColor" content={theme?.Value} />
          <meta name="theme-color" content={theme?.Value} />
          <meta
            name="bowser-title-suffix"
            content={globalSettings?.BrowserTitleSuffix?.Value}
          />

          <link
            rel="shortcut icon"
            type="image/png"
            href={`${ingeniuxURL}${favIcon16x16?.Value}`}
            sizes={`${favIcon16x16?.Width}x${favIcon16x16?.Height}`}
          />
          <link
            rel="shortcut icon"
            type="image/png"
            href={`${ingeniuxURL}${favIcon32x32?.Value}`}
            sizes={`${favIcon32x32?.Width}x${favIcon32x32?.Height}`}
          />
          <link
            rel="apple-touch-icon"
            type="image/png"
            href={`${ingeniuxURL}${favIconAppleTouch?.Value}`}
            sizes={`${favIconAppleTouch?.Width}x${favIconAppleTouch?.Height}`}
          />
        </Helmet>
        <Header
          alertContent={headerItemData && headerItemData[0]?.Alerts}
          headerContent={headerItemData && headerItemData[0]?.Header}
          phoneIcon={headerItemData && headerItemData[0]?.TelephoneIcon}
          phoneNum={headerItemData && headerItemData[0]?.TelephoneNumberDisplay}
          tel={headerItemData && headerItemData[0]?.MainTelephoneNumber}
          recentFranchise={franchiseHomePage && franchiseHomePage[0]}
        />

        {/* SEO Meta data Integration */}
        {headerItemData[0]?.SEO && (
          <MetaTag
            headerMetaData={{
              ...headerItemData[0]?.SEO,
              MetaDescription: headerItemData?.metaDescription,
            }}
          />
        )}
        {/* SEO Meta data Integration */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.products.error,
  franchseLastVisited: state?.franchiseLastVisitedReducer?.franchseLastVisited,
  franchiseLoading: state?.franchiseLastVisitedReducer?.loading,
  franchiseError: state?.franchiseLastVisitedReducer?.error,
});

export default connect(mapStateToProps)(HeaderWrapper);
