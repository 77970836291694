import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import ZipChooser from "../common/ZipChooser";
import "./Page404Banner.css";

const HomeBanner = ({
  homeBannerContent = {},
  zipCodeList,
  localOfficeList,
  children,
}) => {
  const blockPlacement = homeBannerContent?.ContentBlockPlacement;

  const posBanner = (pos) => {
    let cssClass;
    if (pos === "Right") {
      cssClass = "pos-right";
    } else if (pos === "Center") {
      cssClass = "pos-center";
    } else cssClass = "pos-left";
    return cssClass;
  };

  const bannerMargin = (pos) => {
    let cssClass;
    if (pos === "Right") {
      cssClass = "mr-50";
    } else if (pos === "Center") {
      cssClass = "";
    } else cssClass = "ml-50";
    return cssClass;
  };

  return (
    <>
      <section className="content-section twelve">
        <div
          className={`not-found-wrap ${posBanner(blockPlacement?.Value)}`}
          style={{
            background: homeBannerContent?.BackgroundImage?.Value ? `#9f9798 url(${ingeniuxURL}${homeBannerContent?.BackgroundImage?.Value}) no-repeat bottom center` : "#9f9798"
          }}
        >
          <div
            className={`not-found container ${bannerMargin(
              blockPlacement?.Value
            )}`}
          >
            <div
              className="finder"
              style={{
                background: `url(https://www.acehandymanservices.com/images/404-cta-bg-blur.png) no-repeat center center`,
              }}
            >
              <div className="heading">{homeBannerContent?.Heading?.Value}</div>
              <p>{homeBannerContent?.Copy?.Value}</p>
              <center>
                <ZipChooser
                  submitAlignment={
                    homeBannerContent?.FindAnOffice?.SubmitAlignment?.Value
                  }
                  headingAlignment={
                    homeBannerContent?.FindAnOffice?.HeadingAlignment?.Value
                  }
                  zipInputs={{
                    PlaceholderText:
                      homeBannerContent?.FindAnOffice?.PlaceholderText?.Value?.replace(
                        "zip",
                        "ZIP"
                      ),
                    TextBelowInput:
                      homeBannerContent?.FindAnOffice?.TextBelowInput?.Value,
                    Heading: homeBannerContent?.FindAnOffice?.Heading?.Value,
                  }}
                  zipCodeList={zipCodeList}
                  localOfficeList={localOfficeList}
                  toSchedulingPage={false}
                />
              </center>
              <div className="call-cta">
                or call us at
                <div className="phone">866-FIX-MY-HOME</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {children}
    </>
  );
};
export default HomeBanner;