import React from "react";
import { Markup } from "interweave";

import "./CommunityMattersContentWrap.css";
import WhyChooseUsPromise from "../WhyChooseUsPage/WhyChooseUsPromise";
import TrayLeft from "../common/TrayLeft";
import { ingeniuxURL } from '../../config/urls';

const CommunityMattersContentWrap = ({ contentData }) => {
    return (
        <body className="philanthropy-content">
            <div className="body-wrap">
                <section id="content" className="content-section twelve">
                    <div className="content-wrap">
                        {contentData?.DetailPage?.Trays?.map(element => {
                            if (element?.TrayName === "ImageCopyTray") {
                                if (element?.Copy?.Value) {
                                    if (element?.AddOutline?.Value === 'Yes') {
                                        return <WhyChooseUsPromise contentData={element} />
                                    } else if (element?.ImageAlignment?.Value === "Right") {
                                        return <>
                                            <div className="container row">
                                                <div className="col-dd-8 pad-right-lg">
                                                    <Markup content={element?.Copy?.Value} />
                                                    {/* <p>In 1999 and 2000, Ace Hardware Painting Services sponsored a Battle of the Bathrooms event, in conjunction with Habitat for Humanity.  We put out the challenge to twelve contractors in the Denver area to come and participate.  Each group was to build a complete—and working—bathroom over the course of two days.  The event would be judged and winners awarded prizes, and then all of the completed bathrooms would be donated to Habitat for Humanity to be installed in actual homes.</p>
                                <p>This marked the beginning of a dedicated effort of the Ace Hardware Painting Services staff finding ways in which to give back to our local community.  Over the past many years, "Team Matters," as the corporate staff has dubbed themselves, have volunteered at women’s shelters, food banks, senior citizen facilities and a variety of other places where our abilities can be put to good use.</p>
                                <p>One of our cornerstone beliefs is that we aren’t just in the communities we service; we are of those communities, as well.  This means regularly finding ways to give back, to improve the lives and the situations of those around us.</p>
                                <p>All of our franchise owners similarly give back in their own communities, reaching out in countless ways to support their fellow citizens, doing whatever they can to improve lives and situations.</p>
                                <p>As founder Andy Bells puts it, "Never underestimate your ability to make a difference in someone's life."</p> */}
                                                </div>
                                                <div className="col-dd-4">
                                                    <figure>
                                                    {element?.Image?.Value && <img
                                                            src={`${ingeniuxURL}${element?.Image?.Value}`}
                                                            alt={element?.Image?.AlternateText ? element?.Image?.AlternateText : ""}
                                                            title={element?.Image?.AlternateText ? element?.Image?.AlternateText : ""}
                                                        />}
                                                        {/* <img src="https://www.acehandymanservices.com/images/philanthropy.png" alt="Building bathrooms and donating them is one of the many ways Ace Hardware Painting Services gives back to the community." /> */}
                                                    </figure>
                                                </div>
                                            </div></>
                                    }
                                    else if (element?.ImageAlignment?.Value === "Left") {
                                        return <TrayLeft
                                            CTA={(element?.CTAStyle)}
                                            displyGrd={element?.DisplayGrid?.Value}
                                            bgColor={element?.BackgroundColor?.Value}
                                            img={element?.Image?.Value}
                                            heading={element?.Heading?.Value}
                                            leftTrayContent={(element?.Copy?.Value)} />
                                    }
                                }
                            }
                        })}

                    </div>
                </section>
            </div>
        </body >
    );
};

export default CommunityMattersContentWrap;