import React, { useEffect } from "react";
import { Markup } from 'interweave';

import { ingeniuxURL } from '../../config/urls';
import { bgPatternTriangles } from "../../config/utility";
import "./packagesTabOptions.css";


const PackagesTabOptions = ({ data, currentHash }) => {
    // useEffect(() => {
    //     setTimeout(() => {            
    //         const name = document.getElementById(currentHash)
    //         if(window.screen.width > 500){
    //             window.scrollTo({top:name?.offsetTop-300, left:0, behavior:'smooth'});
    //         } else {
    //             window.scrollTo({top:name?.offsetTop-230, left:0, behavior:'smooth'});
    //         } 
    //     }, 500)
    //   }, [])

    return (
        <div >
            <div className="body-wrap" style={{ backgroundImage: bgPatternTriangles ? `url(${ingeniuxURL}${bgPatternTriangles})` : "none" }}>
                <section id="package-content" className="content-section twelve">
                    <div className="white">
                        <div className="package-container row">
                            <div id={currentHash} className="packages-grid">
                                {data?.map((packageData, index) => {
                                    
                                    if (index % 2 === 0) {
                                        return packageData?.Schema === "PackageDetail" &&
                                         <div className="colm-sd-12 col-md-6 col-dddd-6 item general" style={{ left: "0px", top: "0.016px" }}>
                                            <figure>
                                                {packageData?.PackageImage && <img
                                                    src={`${ingeniuxURL}${packageData?.PackageImage}`}
                                                    alt=""
                                                    // alt={packageData?.PackageDetail?.WhatYouGetImage?.Value?.AlternateText}
                                                    // title={packageData?.PackageDetail?.WhatYouGetImage?.Value?.AlternateText}
                                                />}
                                            </figure>
                                            <h5 className="title">{packageData?.Name}</h5>
                                            {/* <p>{packageData?.IntroCopy}</p> */}
                                            <Markup content={packageData?.PackageIntroCopy} />
                                            <ul className="accent-sq">
                                                <Markup content={packageData?.PackageWhatYouGet} />
                                                <ul className="accent-sq">
                                                    <li><a href={`${packageData?.URL}`}>Learn More</a></li>
                                                </ul>
                                            </ul>
                                        </div>
                                    }
                                    else {
                                        return packageData?.Schema === "PackageDetail" &&
                                         <div className="colm-sd-12 col-md-6 col-dddd-6 item general">
                                            <figure>
                                                {packageData?.PackageImage && <img
                                                    src={`${ingeniuxURL}${packageData?.PackageImage}`}
                                                    alt=""
                                                    // alt={packageData?.PackageDetail?.WhatYouGetImage?.Value?.AlternateText}
                                                    // title={packageData?.PackageDetail?.WhatYouGetImage?.Value?.AlternateText}
                                                />}
                                            </figure>
                                            <h5 className="title">{packageData?.Name}</h5>
                                            {/* <p>{packageData?.IntroCopy}</p> */}
                                            <Markup content={packageData?.PackageIntroCopy} />
                                            <ul className="accent-sq">
                                                <Markup content={packageData?.PackageWhatYouGet} />
                                                <ul className="accent-sq">
                                                    <li><a href={`${packageData?.URL}`}>Learn More</a></li>
                                                </ul>
                                            </ul>
                                        </div>
                                    }
                                })}
                            </div>
                            {/* 
                        <p class="small-para"><small>*Packages listed may not be available at all locations due to local licensing.</small></p> */}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PackagesTabOptions;