import React from "react";
import { Markup } from "interweave";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import "./PromiseRightLayout.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    // items: 5
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    // items: 3
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    // items: 2
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const PromiseRightLayout = ({ contentData, bgImage }) => {
  const currentPath = window.location.pathname;

  const currentPathCheck =
    currentPath.split("/").length === 3 &&
    currentPath.split("/")[1] !== "" &&
    currentPath.split("/")[2] !== "";

  const CopyWidgetUrl = contentData?.CopyWidget?.Page?.URL;

  const CopyWidgetPath = contentData?.CopyWidget?.Page?.ID
    ? "/" + CopyWidgetUrl
    : currentPathCheck
    ? currentPath.split("/")[2] + "/" + CopyWidgetUrl
    : CopyWidgetUrl;

  return (
    <div
      className="right-promise-wrap twelve lazy-bg"
      style={{
        backgroundImage: bgImage,
      }}
    >
      <div className="right-promise-container row">
        <div className="right-promise col-dd-6">
          <div className="right-promise-slider-wrap">
            <div className="right-promise-container">
              <div className="right-promise-slider">
                {contentData?.CopyWidget && (
                  <Carousel
                    responsive={responsive}
                    showDots={true}
                    infinite={true}
                    autoPlay={true}
                    arrows={false}
                  >
                    {contentData?.CopyWidget &&
                    Array.isArray(contentData?.CopyWidget) ? (
                      contentData.CopyWidget?.map((slideData, index) => {
                        console.log("slide", slideData);

                        const slideDataUrl = slideData?.Page?.URL;

                        const path = slideData?.Page?.ID
                          ? "/" + slideDataUrl
                          : currentPathCheck
                          ? currentPath + "/" + slideDataUrl
                          : currentPath.split("/").includes("city")
                          ? currentPath.split("/").slice(0, -2).join("/") +
                            "/" +
                            slideDataUrl
                          : currentPath.split("/").slice(0, -1).join("/") +
                            "/" +
                            slideDataUrl;

                        return (
                          <div className="slide" key={index}>
                            <div className="heading">
                              {slideData?.Heading?.Value}
                            </div>

                            <p>
                              <Markup content={slideData?.Copy?.Value} />
                            </p>
                            <a href={`${path}`} className="more">
                              {slideData?.Page?.Link}
                            </a>
                          </div>
                        );
                      })
                    ) : (
                      <div className="slide" key={contentData?.CopyWidget?.ID}>
                        <div className="heading">
                          {contentData?.CopyWidget?.Heading?.Value}
                        </div>

                        <p>
                          <Markup
                            content={contentData?.CopyWidget?.Copy?.Value}
                          />
                        </p>
                        <a href={`${CopyWidgetPath}`} className="more">
                          {contentData?.CopyWidget?.Page?.Link}
                        </a>
                      </div>
                    )}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromiseRightLayout;
