import React, { useState } from "react";
import { Markup } from "interweave";
import moment from "moment-timezone";

import { ingeniuxURL } from "../../config/urls";
import "./index.css";

export const FranchiseOffers = (props) => {
    const [showModal, setShowModal] = useState("none");
    const [currentModal, setCurrentModal] = useState();
    const [offerType, setOfferType] = useState("");
    const [offerIndex, setOfferIndex] = useState("");

    const [phoneValue, setPhoneValue] = useState("");
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [emailValue, setEmailValue] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [zipcodeValue, setZipcodeValue] = useState("");
    const [isValidZipcode, setIsValidZipcode] = useState(true);
    const [fullnameValue, setFullnameValue] = useState("");
    const [isPopulatedFullname, setIsPopulatedFullname] = useState(true);
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [wasModalClosed, setWasModalClosed] = useState(false);

    const handleOfferModalToggle = (e, key, typeOfOffer = "", offerIndx) => {
        e.preventDefault();
        setCurrentModal(key);
        setOfferType(typeOfOffer);
        setOfferIndex(offerIndx);
        if (showModal === "none") {
            setShowModal("block");
        } else {
            setShowModal("none");
        }
    }

    const printOffer = () => {
        var contents = document.getElementsByClassName("offer-print-body")[0].innerHTML;
        var frame1 = document.createElement('iframe');
        frame1.name = "frame1";
        frame1.style.position = "absolute";
        frame1.style.top = "-1000000px";
        document.body.appendChild(frame1);
        var frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
        frameDoc.document.open();
        frameDoc.document.write('</head><body><div>');
        frameDoc.document.write(contents);
        frameDoc.document.write('</body></html></div>');
        frameDoc.document.close();
        setTimeout(function () {
            window.frames["frame1"].focus();
            window.frames["frame1"].print();
            document.body.removeChild(frame1);
        }, 500);
        return false;
    }

    return (
        <>
            {props?.contentData?.Offer ?
                <>
                    <div className="offers-content-wrap">
                        <div className="container row">
                            <div className="offers-wrap">
                                {
                                    props?.contentData?.Offer[0] ?
                                        <div className="col-sd-12 col-dd-8 pad-right-lg">
                                            <div className="offer" id="offer-wrap-1">
                                                <div className="offers-module" style={{ background: props?.contentData?.BackgroundImage?.Value && `#fff url(${ingeniuxURL}${props?.contentData?.BackgroundImage?.Value}) center center repeat` }}>
                                                    <div className="offer-before" style={{ background: props?.contentData?.FeaturedOfferLogo?.Value && `transparent url(${ingeniuxURL}${props?.contentData?.FeaturedOfferLogo?.Value}) top left no-repeat` }}></div>
                                                    <div className="offer-after" style={{ background: props?.contentData?.FeaturedOfferImage?.Value && `transparent url(${ingeniuxURL}${props?.contentData?.FeaturedOfferImage?.Value}) top right no-repeat` }}></div>
                                                    <div className="module-head">
                                                        <div className="offer-module-heading offer-heading">
                                                            {props?.contentData?.Offer[0]?.OfferName?.Value}
                                                        </div>
                                                    </div>
                                                    <div className="module-body">
                                                        <div className="offer-headline offer-text">
                                                            {props?.contentData?.Offer[0]?.OfferDescription?.Value}
                                                        </div>
                                                        <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 0, "fma-127322-corporate", 1)}>
                                                            {props?.contentData?.ClaimOfferButtonText?.Value}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="col-sd-12 col-dd-8 pad-right-lg">
                                            <div className="offer" id="offer-wrap-1">
                                                <div className="offers-module" style={{ background: props?.contentData?.BackgroundImage?.Value && `#fff url(${ingeniuxURL}${props?.contentData?.BackgroundImage?.Value}) center center repeat` }}>
                                                    <div className="offer-before" style={{ background: props?.contentData?.FeaturedOfferLogo?.Value && `transparent url(${ingeniuxURL}${props?.contentData?.FeaturedOfferLogo?.Value}) top left no-repeat` }}></div>
                                                    <div className="offer-after" style={{ background: props?.contentData?.FeaturedOfferImage?.Value && `transparent url(${ingeniuxURL}${props?.contentData?.FeaturedOfferImage?.Value}) top right no-repeat` }}></div>
                                                    <div className="module-head">
                                                        <div className="offer-module-heading offer-heading">
                                                            {props?.contentData?.Offer?.OfferName?.Value}
                                                        </div>
                                                    </div>
                                                    <div className="module-body">
                                                        <div className="offer-headline offer-text">
                                                            {props?.contentData?.Offer?.OfferDescription?.Value}
                                                        </div>
                                                        <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 0, "fma-127322-corporate", 1)}>
                                                            {props?.contentData?.ClaimOfferButtonText?.Value}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }

                                <div className="col-sd-12 col-md-6 col-dd-4 right-col">
                                    {
                                        props?.contentData?.Offer[1] &&
                                        <div className="offer" id="offer-wrap-2">
                                            <div className="offers-module" style={{ background: props?.contentData?.BackgroundImage?.Value && `#fff url(${ingeniuxURL}${props?.contentData?.BackgroundImage?.Value}) center center repeat` }}>
                                                <div className="module-head">
                                                    <div className="offer-module-heading offer-heading">
                                                        {props?.contentData?.Offer[1]?.OfferName?.Value}
                                                    </div>
                                                </div>
                                                <div className="module-body">
                                                    <div className="offer-headline offer-text">
                                                        {props?.contentData?.Offer[1]?.OfferDescription?.Value}
                                                    </div>
                                                    <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 1, "fma-127322-local", 1)}>
                                                        {props?.contentData?.ClaimOfferButtonText?.Value}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        props?.contentData?.Offer[2] &&
                                        <div className="offer" id="offer-wrap-3">
                                            <div className="offers-module" style={{ background: props?.contentData?.BackgroundImage?.Value && `#fff url(${ingeniuxURL}${props?.contentData?.BackgroundImage?.Value}) center center repeat` }}>
                                                <div className="module-head">
                                                    <div className="offer-module-heading offer-heading">
                                                        {props?.contentData?.Offer[2]?.OfferName?.Value}
                                                    </div>
                                                </div>
                                                <div className="module-body">
                                                    <div className="offer-headline offer-text">
                                                        {props?.contentData?.Offer[2]?.OfferDescription?.Value}
                                                    </div>
                                                    <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 2, "fma-127322-local", 2)}>
                                                        {props?.contentData?.ClaimOfferButtonText?.Value}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    props?.contentData?.Offer[3] &&
                                    <div className="col-sd-12 col-md-6 col-dd-6 pad-right-lg">
                                        <div className="offer" id="offer-wrap-4">
                                            <div className="offers-module" style={{ background: props?.contentData?.BackgroundImage?.Value && `#fff url(${ingeniuxURL}${props?.contentData?.BackgroundImage?.Value}) center center repeat` }}>
                                                <div className="module-head">
                                                    <div className="offer-module-heading offer-heading">
                                                        {props?.contentData?.Offer[3]?.OfferName?.Value}
                                                    </div>
                                                </div>
                                                <div className="module-body">
                                                    <div className="offer-headline offer-text">
                                                        {props?.contentData?.Offer[3]?.OfferDescription?.Value}
                                                    </div>
                                                    <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 3, "fma-127322-local", 3)}>
                                                        {props?.contentData?.ClaimOfferButtonText?.Value}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    props?.contentData?.Offer[4] &&
                                    <div className="col-sd-12 col-md-6 col-dd-6">
                                        <div className="offer" id="offer-wrap-5">
                                            <div className="offers-module" style={{ background: props?.contentData?.BackgroundImage?.Value && `#fff url(${ingeniuxURL}${props?.contentData?.BackgroundImage?.Value}) center center repeat` }}>
                                                <div className="module-head">
                                                    <div className="offer-module-heading offer-heading">
                                                        {props?.contentData?.Offer[4]?.OfferName?.Value}
                                                    </div>
                                                </div>
                                                <div className="module-body">
                                                    <div className="offer-headline offer-text">
                                                        {props?.contentData?.Offer[4]?.OfferDescription?.Value}
                                                    </div>
                                                    <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 4, "fma-127322-local", 4)}>
                                                        {props?.contentData?.ClaimOfferButtonText?.Value}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div id="offer-modal-192074-yoModalized" className="yo-modal-container" style={{ display: `${showModal}` }}>
                        <div className="modal text-and-image enhanced yo-lightbox" datatemplatetype="text-and-image" style={{ width: '549.5px', display: 'block', top: '672.7px', border: 'none' }} id="offer-modal-192074">
                            <div className="yo-lightbox-close" style={{ zIndex: '52', cursor: 'pointer' }}>x</div>
                            <div className="offer-preview-container text-and-image">
                                <div className="offer-preview-header">
                                    <a className="close-button" onClick={() => [setShowModal("none"), setWasModalClosed(true)]}><i className="icon-close">X</i></a>
                                    <a href={`tel:${props?.contentData?.corp?.PhoneNumber?.Value}`} className="call-business-phone" type="submit" target="_blank" rel="noopener noreferrer">
                                        CALL {props?.contentData?.corp?.PhoneNumber?.Value}
                                    </a>
                                    <a style={{ marginRight: '35px', paddingLeft: '60px', paddingRight: '60px' }} target="_blank" rel="noopener noreferrer"
                                        onClick={printOffer}
                                        className="print-offer-button" type="submit"
                                    >PRINT OFFER</a>
                                    <a href="#" className="call-business-phone-during-hours" type="submit" style={{ display: 'none' }} target="_blank" rel="noopener noreferrer"></a>
                                </div>

                                {/* <!-- Error message if any--> */}
                                <div className="error-message" style={{ display: `${isPopulatedFullname && isValidEmail && isValidPhone && isValidZipcode ? 'none' : 'block'}` }}>
                                    <p className="error-message-text" style={{ color: '#FF0000', display: 'block', fontFamily: 'Open Sans, Arial, Helvetica, sans-serif', fontSize: '17px', textAlign: 'center' }}>
                                        {!isPopulatedFullname ? "Please enter your full name" :
                                            !isValidPhone ? "Please enter a valid 10 digit phone number" :
                                                !isValidZipcode ? "Please enter a valid US zip code" :
                                                    !isValidEmail && "Please enter a valid email address"}
                                    </p>
                                </div>

                                <div className="offer-print-body">
                                    <div className="offer-preview-body">
                                        <div className="left" style={{ width: `${props?.contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && '100%'}` }}>
                                            <h6 className="offer-heading offer-type-class DOLLARS_OFF" style={{ display: 'block', fontSize: '2em', marginTop: '0.67em', marginBottom: '0.67em', marginLeft: '0', marginRight: '0', fontWeight: 'bold', textAlign: `${props?.contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                {props?.contentData?.Offer[currentModal]?.OfferName?.Value}
                                            </h6>
                                            <p className="offer-headline offer-text" style={{ display: 'block', fontSize: '1.5em', marginTop: '0.83em', marginBottom: '0.83em', marginLeft: '0', marginRight: '0', fontWeight: 'bold', textAlign: `${props?.contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                {props?.contentData?.Offer[currentModal]?.OfferDescription?.Value}
                                            </p>
                                            <p className="expire-date" style={{ textAlign: `${props?.contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                {props?.contentData?.Offer[currentModal]?.OfferExpirationDate?.Value &&
                                                    "OFFER ENDS " +
                                                    moment(props?.contentData?.Offer[currentModal]?.OfferExpirationDate?.Value, "YYYYMMDD").format("MMMM D, YYYY")
                                                }
                                            </p>
                                            <div className="address-block business-info" style={{ textAlign: `${props?.contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                <p className="business-name" style={{ textAlign: `${props?.contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>Ace Handyman Services{" "}
                                                    {props?.contentData?.corp?.FranchiseName?.Value}
                                                </p>
                                                <p className="business-address" style={{ textAlign: `${props?.contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                    {props?.contentData?.corp?.Address?.Value}
                                                    <br />
                                                    {props?.contentData?.corp?.City?.Value}{", "}
                                                    {props?.contentData?.corp?.StateAbbreviation?.Value}{" "}
                                                    {props?.contentData?.corp?.ZipCode?.Value}
                                                </p>
                                                <p className="phone-number" style={{ textAlign: `${props?.contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                    <span className="unpaid-number">
                                                        {props?.contentData?.corp?.PhoneNumber?.Value}
                                                    </span>
                                                </p>
                                            </div>

                                            {props?.contentData?.Offer[currentModal]?.OfferImage?.Value &&
                                                <div className="right">

                                                    <div className="offer-image-preview-container has-image">
                                                        <div className="offer-image">
                                                            {props?.contentData?.Offer[currentModal]?.OfferImage?.Value &&
                                                                <img
                                                                    alt={props?.contentData?.Offer[currentModal]?.OfferImage?.AlternateText ? props?.contentData?.Offer[currentModal]?.OfferImage?.AlternateText : "Offer details"}
                                                                    title={props?.contentData?.Offer[currentModal]?.OfferImage?.AlternateText ? props?.contentData?.Offer[currentModal]?.OfferImage?.AlternateText : "Offer details"}
                                                                    src={`${ingeniuxURL}${props?.contentData?.Offer[currentModal]?.OfferImage?.Value}`}
                                                                />}
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="offer-preview-terms-and-conditions">
                                        <p className="fine-print-text" style={{ textAlign: "center" }}>
                                            <Markup content={props?.contentData?.Offer[currentModal]?.OfferTermsandConditions?.Value} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="yo-modal-overlay" style={{ position: 'fixed', top: '0px', left: '0px', margin: '0px', padding: '0px', width: '100%', height: '100%', zIndex: '997' }}></div>
                    </div>
                </>
                :
                <div className="offers-content-wrap">
                    <div className="container row">
                        <div className="offers-wrap">
                            <div className="no-offers">
                                <div className="heading">
                                    Check back soon for more offers
                                </div>
                                <p style={{ textAlign: 'center' }}>
                                    Or contact us today to find out how we can help <strong>you</strong> love <strong>your</strong> home.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default FranchiseOffers;