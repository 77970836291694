import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchHomePage } from "../../actions/fetchData";
import WhyChooseUsBanner from "./WhyChooseUsBanner";
import WhyChooseUsBreadcrumbs from "./WhyChooseUsBreadcrumbs";
import HeaderWrapper from "../common/HeaderWrapper";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import FooterWrapper from "../common/FooterWrapper";
import {
  getResultsArray,
  loadApiData,
  saveApiData,
  // bgPatternTriangles,
} from "../../config/utility";
import { ingeniuxURL } from "../../config/urls";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import withRouter from "../common/withRouter";
import CopyAside from "../common/CopyAside";
import CareersHeroWithOverlayTray from "../common/CareersHeroWithOverlayTray";
import Page404 from "../page-404";

class WhyChooseUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      loading: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const pageApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const localOfficeResults = this?.props?.localOfficeItem;
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    this.props.dispatch(fetchHomePage(pageApiPath));

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x31"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.apiRef?.apiPath !== this.props?.apiRef?.apiPath ||
      prevProps?.apiRef !== this.props?.apiRef
    ) {
      const pageApiPath = this.props?.apiRef?.apiPath
        ? this.props?.apiRef?.apiPath
        : this.props?.apiRef;
      this.props.dispatch(fetchHomePage(pageApiPath));
    }
    // added this because when switching between the 'whychoseus' pages, page didn't load from the
    // top
    window.scrollTo(0, 0);
  }

  render() {
    const homeItemData = getResultsArray(this.props?.homeItem, "DetailPage");
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };
    var trayArray = [];

    for (let i = 0; i <= this.props.homeItem?.results?.length; i++) {
      for (
        let j = 0;
        j <= this.props.homeItem?.results[i]?.DetailPage?.Trays?.length;
        j++
      ) {
        trayArray.push(this.props.homeItem?.results[i]?.DetailPage?.Trays[j]);
      }
    }
    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;
    return (
      <>
        {this.props?.homeItem.totalResults === 0 ? (
          <Page404 />
        ) : (
          <>
            <Helmet>
              <title>
                {pageTitle ? `${pageTitle} | ${browserSuffix}` : browserSuffix}
              </title>
            </Helmet>
            <HeaderWrapper
              headerItemData={{
                ...headerItemData,
                metaDescription: homeItemData[0]?.Description?.Value,
              }}
            />
            <div
              className="whyChooseUs-main"
              style={{
                backgroundImage: `url(${ingeniuxURL}${headerItemData[0]?.GlobalSettings?.GeneralPageBackground?.Value})`,
              }}
            >
              {homeItemData[0]?.HeroWithOverlay !== undefined ? (
                <CareersHeroWithOverlayTray
                  BackgroundImage={
                    homeItemData[0]?.HeroWithOverlay?.BackgroundImage?.Value
                  }
                  Heading={homeItemData[0]?.HeroWithOverlay?.Heading?.Value}
                  Subheading={
                    homeItemData[0]?.HeroWithOverlay?.Subheading?.Value
                  }
                  PageLink={homeItemData[0]?.HeroWithOverlay?.Page?.Link}
                  PageUrl={homeItemData[0]?.HeroWithOverlay?.Page?.URL}
                  TextBoxBackgroundColor={
                    homeItemData[0]?.HeroWithOverlay?.TextBoxBackgroundColor
                      ?.Value
                  }
                  TextBoxAlignment={
                    homeItemData[0]?.HeroWithOverlay?.TextBoxAlignment?.Value
                  }
                />
              ) : (
                <>
                  <WhyChooseUsBanner
                    contentData={homeItemData[0]?.Heading?.Value}
                  />
                  <WhyChooseUsBreadcrumbs
                    contentData={homeItemData[0]?.BreadcrumbNavigation}
                  />
                </>
              )}

              {(homeItemData[0]?.Copy || homeItemData[0]?.AsideImage) && (
                <CopyAside contentData={homeItemData[0]} />
              )}

              {homeItemData[0]?.Trays?.map((tray, index) => {
                if (
                  Object.keys(corpDynamicTrayList)?.find(
                    (trayName) => trayName === tray?.TrayName
                  )
                ) {
                  let combinedProps;

                  if (
                    tray?.TrayName === "ScheduleandEstimateTabTray" ||
                    tray?.TrayName === "ScheduleandEstimateTray"
                  ) {
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{ zipCodeList: localOfficeZipcodes },
                        ...{ localOfficeList: localOfficeResults },
                        ...{ franchiseDataToGetEstimate },
                      },
                    };
                  } else if (tray?.TrayName === "ServicesTray") {
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{
                          franchiseData:
                            franchiseHomePage && franchiseHomePage[0],
                        },
                      },
                    };
                  } else if (tray?.TrayName === "FindAnOffice") {
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{ zipCodeList: localOfficeZipcodes },
                        ...{ localOfficeList: localOfficeResults },
                      },
                    };
                  } else {
                    combinedProps = { ...tray };
                  }

                  return (
                    <CorpDynamicTrays
                      trayName={tray?.TrayName}
                      contentData={combinedProps}
                      key={index}
                    />
                  );
                }
              })}

              <FooterWrapper headerItemData={headerItemData} />
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default withRouter(connect(mapStateToProps)(WhyChooseUsPage));
