import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../config/utility";
import "./professionalServices.css";

const ProfessionalServices = (props) => {
  const { franchiseCity, franchiseName, franchiseState, stateAbbr } =
    props.contentData;

  const isHomeDetailsSEOTray = () => {
    let isHomeSEOTray = Object.keys(props.contentData).includes(
      "isHomeDetailsSEOTray"
    );
    return isHomeSEOTray;
  };

  let isHomeSEOTray = isHomeDetailsSEOTray();

  return (
    <section id="pro-services-content" className="content-section twelve">
      <div className="content-wrap main-content">
        <div className="professional-service-container row">
          <div className="col-dd-12">
            {props?.contentData?.Image && !isHomeSEOTray && (
              <div className="float-container image hide-sm">
                <figure>
                {props?.contentData?.Image?.Value && <img
                                        className="lazy loaded"
                                        src={`${ingeniuxURL}${props?.contentData?.Image?.Value}`}
                                        alt={props?.contentData?.Image?.AlternateText ? props?.contentData?.Image?.AlternateText : ""}
                                        title={props?.contentData?.Image?.AlternateText ? props?.contentData?.Image?.AlternateText : ""}
                                    />}
                </figure>
              </div>
            )}
            <div
              className={`truncate ${
                isHomeSEOTray ? "custom-seo-headings" : ""
              }`}
            >
              <h2>
                <Markup
                  content={props?.contentData?.Heading?.Value?.replaceAll(
                    "{City}",
                    franchiseCity
                  )
                    ?.replace(
                      "{Phone}",
                      formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
                    )
                    ?.replace("{FranchiseName}", franchiseName)
                    ?.replace("{State}", franchiseState)
                    ?.replace("{StateAbbreviation}", stateAbbr)}
                />
              </h2>
              <div>
                <Markup
                  content={props?.contentData?.Copy?.Value?.replaceAll(
                    "{City}",
                    franchiseCity
                  )
                    ?.replace(
                      "{Phone}",
                      formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
                    )
                    ?.replace("{FranchiseName}", franchiseName)
                    ?.replace("{State}", franchiseState)
                    ?.replace("{StateAbbreviation}", stateAbbr)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfessionalServices;
