import React from "react";
import CorpReviews from "../common/ReviewTile";

const ReviewsMaster = ({ contentData }) => {
  let reviewContentData = contentData?.AddOwnerManagedContent?.Value
    ? contentData?.reviewsTrays && contentData?.reviewsTrays[1]
    : contentData;
  return <CorpReviews contentData={reviewContentData} />;
};

export default ReviewsMaster;