import React from "react";
import "./BlogBreadcrumbs.css";
import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import { HashLink as Link } from "react-router-hash-link";

const BlogBreadcrumbs = ({
  contentData,
  individualPostContent,
  individualPost,
  isBlogHome,
  isFranchiseBlog,
}) => {
  let breadCrumArray = window.location.pathname.split("/").filter(Boolean);
  let prev = "";
  let pathArr = breadCrumArray.map((el) => {
    el = prev + el;
    prev = el + "/";
    return el;
  });
  const corporateBlogDetailPage = !isBlogHome && !isFranchiseBlog;
  const franchiseBlogDetailPage = !isBlogHome && isFranchiseBlog;
  return (
    <div
      className="blogbreadcrumbs-body-wrap"
      style={{
        backgroundImage: bgPatternTriangles
          ? `url(${ingeniuxURL}${bgPatternTriangles})`
          : "none",
      }}
    >
      <section id="content" className="content-section twelve">
        <div className="breadcrumbs">
          <div className="container">
            <ul>
              {contentData &&
                !franchiseBlogDetailPage &&
                contentData?.BreadcrumbNavigation.map((breadCrum, i, row) => {
                  if (i + 1 === row.length) {
                    return (
                      <li>
                        {corporateBlogDetailPage ? (
                          <Link to={`/${breadCrum?.URL}`}>
                            {" "}
                            {breadCrum?.Name}
                          </Link>
                        ) : (
                          <span>{breadCrum?.Name}</span>
                        )}
                      </li>
                    );
                  } else {
                    return (
                      <li>
                        {i === 0 && breadCrum?.Name !== "Home" && (
                          <>
                            <Link to={`/`}>Home</Link>
                            {" | "}
                          </>
                        )}
                        <Link to={`/${breadCrum?.URL}`}>
                          {`${
                            breadCrum?.Schema === "FranchiseHomePage"
                              ? "Ace Hardware Painting Services "
                              : ""
                          }`}{" "}
                          {breadCrum?.Name}
                        </Link>{" "}
                        {" | "}
                      </li>
                    );
                  }
                })}
              {franchiseBlogDetailPage &&
                breadCrumArray.length > 3 &&
                breadCrumArray.map((breadCrum, i, row) => {
                  if (i + 1 === row.length) {
                    return (
                      <li>
                        <span>{`${breadCrum.replace(/-/g, " ")}`}</span>
                      </li>
                    );
                  } else {
                    return (
                      <li>
                        {i === 0 && breadCrum !== "Home" && (
                          <>
                            <Link to={`/`}>Home</Link>
                            {" | "}
                          </>
                        )}
                        {i === 1 ? (
                          <Link to={`/${pathArr[i]}`}>
                            Ace Hardware Painting Services{" "}
                            {`${breadCrum.replace(/-/g, " ")}`}
                          </Link>
                        ) : (
                          <Link to={`/${pathArr[i]}`}> {breadCrum}</Link>
                        )}
                        {" | "}
                      </li>
                    );
                  }
                })}
              {corporateBlogDetailPage && (
                <li>
                  <span>
                    {" "}
                    {" | "}
                    {individualPostContent?.BlogPost?.Heading?.Value}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogBreadcrumbs;
